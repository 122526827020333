import {useMutation} from '@apollo/client';
import {Save} from '@mui/icons-material';
import {Button, CircularProgress, Grid} from '@mui/material';
import {useEffect, useState} from 'react';
import * as Yup from 'yup';

import CheckboxInput from '../../../components/input/checkbox';
import Input from '../../../components/input/input';
import InputMask from '../../../components/input/input-mask';
import {SelectEspecialidades} from '../../../components/input/select/espcialidades';
import SelectInput from '../../../components/input/select/select-input';
import {toastNotification} from '../../../components/toastify';
import {unidadesFederativas} from '../../../constants/uf';
import {CADASTRAR_PROFISSIONAL} from '../../../graphql/mutation';
import Masks from '../../../utils/masks';
import Object from '../../../utils/object';
import String from '../../../utils/string';

export default function DadosBaseProfissional({profissional, isAdmin}) {
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});

  const [saveProfissional, {loading}] = useMutation(CADASTRAR_PROFISSIONAL);

  const handleSubmit = (event) => {
    event.preventDefault();
    profissionalScheme
      .validate(formData, {abortEarly: false})
      .then(handleSave)
      .catch((errors) => {
        const camposComErro = {};
        errors?.inner?.forEach((error) => {
          camposComErro[error.path] = error.message;
        });

        setErrors(Object.yupArrayToJs(camposComErro));
      });
  };

  const handleSave = async () => {
    saveProfissional({
      variables: {
        profissional: {
          ...formData,
          cpf: String.removeSpecialChars(formData.cpf),
          telefone: String.removeSpecialChars(formData.telefone),
          ufConselho: formData.ufConselho?.value,
          especialidades: formData.especialidades?.map((e) => ({
            id: e.id,
            descricao: e.label,
          })),
        },
      },
    })
      .then(() => {
        toastNotification({message: 'Registro salvo!', type: 'success'});
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      );
  };

  useEffect(() => {
    setErrors({});
  }, []);

  useEffect(() => {
    if (!profissional) {
      setFormData({});
    }
    setFormData({
      ...profissional,
      ufConselho: profissional.ufConselho && {
        label: profissional.ufConselho,
        value: profissional.ufConselho,
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profissional]);

  return (
    <Grid container>
      <Grid container item sx={styles.content} gap={2}>
        <Grid container flexDirection={'row'} spacing={2}>
          <Grid item xs={12}>
            <Input
              label={'Nome'}
              name="nome"
              value={formData.nome || ''}
              type="text"
              autoCapitalize="on"
              placeholder={'Nome completo'}
              onChange={({target}) =>
                setFormData((f) => ({...f, nome: target?.value}))
              }
              errors={errors}
            />
          </Grid>
          <Grid item xs={12}>
            <InputMask
              label={'CPF'}
              name="cpf"
              value={formData.cpf || ''}
              type="tel"
              mask={Masks.cpfCnpj}
              placeholder={'Digite o número do documento'}
              onChange={({target}) =>
                setFormData((f) => ({...f, cpf: target?.value}))
              }
              errors={errors}
            />
          </Grid>
          <Grid item xs={12}>
            <InputMask
              label={'Telefone'}
              name="telefone"
              value={formData.telefone || ''}
              type="tel"
              autoComplete="tel"
              mask={Masks.telelefone}
              placeholder={'Digite seu número de telefone'}
              onChange={({target}) =>
                setFormData((f) => ({...f, telefone: target?.value}))
              }
              errors={errors}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              label={'E-mail'}
              name="email"
              value={formData.email || ''}
              type="email"
              autoComplete="email"
              placeholder={'Digite seu e-mail'}
              onChange={({target}) =>
                setFormData((f) => ({...f, email: target?.value}))
              }
              errors={errors}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              label={'CNS'}
              name="cns"
              value={formData.cns || ''}
              placeholder={'Digite o CNS'}
              onChange={({target}) =>
                setFormData((f) => ({...f, cns: target?.value}))
              }
              errors={errors}
            />
          </Grid>
          <Grid item sm={8} xs={12}>
            <Input
              label={'Nº conselho'}
              name="numeroConselho"
              value={formData.numeroConselho || ''}
              placeholder={'Digite seu número de registro'}
              onChange={({target}) =>
                setFormData((f) => ({...f, numeroConselho: target?.value}))
              }
              errors={errors}
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <SelectInput
              label={'UF conselho'}
              name="ufConselho"
              placeholder={'UF'}
              value={formData.ufConselho || ''}
              onChange={(ufConselho) =>
                setFormData((f) => ({...f, ufConselho}))
              }
              options={unidadesFederativas?.map((u) => ({
                label: u,
                value: u,
              }))}
              errors={errors}
            />
          </Grid>
          <Grid item xs={12}>
            <SelectEspecialidades
              label="Especialidades"
              name="especialidades"
              placeholder="Selecione as especialidades"
              value={formData.especialidades}
              isClearable
              menuPlacement="top"
              onChange={(e) => setFormData((f) => ({...f, especialidades: e}))}
              closeMenuOnSelect={false}
              isMulti
              creatable
              errors={errors}
            />
          </Grid>
          <Grid item xs={12}>
            {isAdmin && (
              <CheckboxInput
                name="ativo"
                label={'Ativo'}
                checked={formData.ativo || false}
                onChange={({target}) =>
                  setFormData((f) => ({...f, ativo: target?.checked}))
                }
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid container mt={'auto'}>
        <Button
          id="primary"
          onClick={handleSubmit}
          fullWidth
          endIcon={loading ? <CircularProgress size={20} /> : <Save />}>
          Salvar
        </Button>
      </Grid>
    </Grid>
  );
}

const profissionalScheme = Yup.object().shape({
  nome: Yup.string().required('Campo obrigatório'),
  telefone: Yup.string().required('Campo obrigatório'),
  cpf: Yup.string()
    .required('Campo obrigatório')
    .test('validar-cpf', 'CPF inválido', (value) => String.validaCpf(value)),
  email: Yup.string().required('Campo obrigatório').email('E-mail inválido'),
});

const styles = {
  content: {
    alignContent: 'flex-start',
    flexDirection: 'row',
    paddingBottom: '16px',
  },
};
