import { useQuery } from '@apollo/client';
import { Add, Edit } from '@mui/icons-material';
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import { useEffect, useState } from 'react';

import ButtonResponsive from '../../../components/buttons/button-responsive';
import Filtros from '../../../components/filtro/filtros';
import Input from '../../../components/input/input';
import { FIND_ALL_PLANO } from '../../../graphql/queries';
import AgendamentoModal from './plano-modal';

export default function ConsultaPlanos() {
  const [planos, setPlano] = useState([]);
  const [searchDTO, setSearchDTO] = useState({});
  const [page, setPage] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [selecionado, setSelecionado] = useState(undefined);

  const planosQuery = useQuery(FIND_ALL_PLANO, {
    variables: {
      pageableDto: {
        pageNumber: page,
        pageSize: 20,
        sortField: 'descricao',
        sortDir: 'DESC',
      },
      search: searchDTO.search || undefined,
    },
  });

  useEffect(() => {
    if (planosQuery.loading) return;

    setPlano(planosQuery.data?.plano?.content || []);
    setTotalElements(planosQuery.data?.plano?.totalElements || 0);
  }, [planosQuery]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    if (!selecionado) return;

    setOpenModal(true);
  }, [selecionado]);

  useEffect(() => {
    setPage(0);
  }, [searchDTO]);

  return (
    <Grid container item xs={12} paddingBottom={4}>
      <Filtros>
        <Grid item xs>
          <Input
            name="busca"
            placeholder="Pesquisar por nome"
            type="text"
            autoComplete="off"
            value={searchDTO.search}
            onChange={({target}) =>
              setSearchDTO((f) => ({...f, search: target?.value}))
            }
          />
        </Grid>
      </Filtros>
      <Grid
        container
        item
        xs={12}
        paddingBottom={1}
        alignItems={'center'}
        justifyContent={'flex-end'}>
        <Button id="text" endIcon={<Add />} onClick={() => setOpenModal(true)}>
          Cadastrar novo
        </Button>
      </Grid>
      <TableContainer id="table-container">
        <Table id="table">
          <TableHead id="table-head">
            <TableRow>
              <TableCell>Plano</TableCell>
              <TableCell>Valor mensal</TableCell>
              <TableCell>Valor anual</TableCell>
              <TableCell>Situação</TableCell>
              <TableCell sx={{width: '182px'}} align="center">
                Ações
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody id="table-body">
            {planos.map((a) => (
              <TableRow key={a.id} id="table-row">
                <TableCell>{a.titulo}</TableCell>
                <TableCell>{`R$ ${a.valorMensal}`}</TableCell>
                <TableCell>{`R$ ${a.valorAnual}`}</TableCell>
                <TableCell>{a.ativo ? 'Ativo' : 'Inativo'}</TableCell>
                <TableCell
                  align="right"
                  sx={{display: 'flex', flexDirection: 'row'}}>
                  <ButtonResponsive
                    id="primary"
                    value="Editar"
                    onClick={() => setSelecionado(a)}
                    icon={<Edit />}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow id="table-row-footer">
              <TableCell id="table-pagination" colSpan={6}>
                <TablePagination
                  component="div"
                  count={totalElements}
                  rowsPerPageOptions={[]}
                  rowsPerPage={20}
                  page={page}
                  labelDisplayedRows={({from, to, count}) =>
                    `${from}-${to} de ${count}`
                  }
                  onPageChange={handleChangePage}
                />
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <AgendamentoModal
        open={openModal}
        plano={selecionado}
        onClose={() => {
          setOpenModal(false);
          setSelecionado(undefined);
          planosQuery.refetch();
        }}
      />
    </Grid>
  );
}
