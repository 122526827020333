import {useMutation} from '@apollo/client';
import {
  AttachFile,
  Check,
  DirectionsRun,
  FactCheck,
  InfoRounded,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Mail,
  Person,
  PhotoCamera,
} from '@mui/icons-material';
import {
  Avatar,
  Button,
  CircularProgress,
  Collapse,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Typography,
} from '@mui/material';
import {useEffect, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import {confirmAlert} from '../../components/confirm-alert/confirm-alert';
import {Facebook, Instagram, Linkedin, X} from '../../components/icons';
import Input from '../../components/input/input';
import SelectInput from '../../components/input/select/select-input';
import {toastNotification} from '../../components/toastify';
import ToggleOptions from '../../components/toggle-options';
import Toolbar from '../../components/toolbar/toolbar';
import {ANAMNESE} from '../../constants/routes';
import {
  FIND_OR_CREATE_ANAMNESE_BY_AGENDAMENTO_ID,
  TERMINAR_ANAMNESE,
  UPDATE_ANAMNESE_RESPOSTA,
} from '../../graphql/mutation';
import Data from '../../utils/data';
import {getImageUrl} from '../../utils/file';
import Number from '../../utils/number';
import String from '../../utils/string';
import AnexoModal from './modais/anexo';
import ValoresReferenciaModal from './modais/valores-referencia';

export default function Anamnese() {
  const navigate = useNavigate();
  const {avaliacaoId} = useParams();

  const [formData, setFormData] = useState({});
  const [anamnese, setAnamnese] = useState({});
  const [respostas, setRespostas] = useState([]);
  const [grupos, setGrupos] = useState([]);
  const [selectedMenu, setSelectedMenu] = useState({});
  const [saving, setSaving] = useState(false);
  const [perguntaAnexo, setPerguntaAnexo] = useState(undefined);
  const [valoresReferencia, setValoresReferencia] = useState(undefined);

  const [findAnamnese] = useMutation(FIND_OR_CREATE_ANAMNESE_BY_AGENDAMENTO_ID);

  const [updateResposta] = useMutation(UPDATE_ANAMNESE_RESPOSTA);

  const [terminarAnamnese] = useMutation(TERMINAR_ANAMNESE);

  useEffect(() => {
    if (!avaliacaoId) return;
    findAnamnese({
      variables: {
        id: avaliacaoId,
      },
    })
      .then((result) => {
        const data = result.data?.anamnese;

        if (data.dataHoraTermino) {
          return navigate(`/${ANAMNESE}/${data.id}`);
        }

        setAnamnese(data);
        setRespostas(data?.respostas || []);
        const gruposData = data?.respostas
          .map((objeto) => objeto.modeloPergunta?.pergunta?.modeloGrupo)
          .filter(
            (grupo, index, self) =>
              index === self.findIndex((g) => g.id === grupo.id),
          )
          .sort((grupo1, grupo2) => grupo1.ordem - grupo2.ordem)
          .map((grupo) => ({value: grupo.id, label: grupo.nome}));

        setGrupos(gruposData || []);
        setSelectedMenu(gruposData[0]?.value);
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [avaliacaoId]);

  useEffect(() => {
    if (!anamnese.id) return;

    const respostas = {};
    anamnese.respostas?.forEach((r) => {
      respostas[r.id] = r.valorResposta;
    });
    setFormData(respostas);
  }, [anamnese]);

  const saveOnBlur = (respostaId, valor, anexoId) => {
    if (!respostaId) return;
    setSaving(true);
    updateResposta({
      variables: {
        anamneseModeloPergunta: {
          id: respostaId,
          valorResposta: valor || undefined,
          anexoId,
        },
      },
    }).finally(() => setSaving(false));
  };

  const onTerminarAnanmnese = () => {
    confirmAlert({
      title: 'Deseja mesmo finalizar a anamnese?',
      message: 'Não será possível realizar alterações após esta ação.',
      onConfirm: handleTerminar,
    });
  };

  const handleTerminar = () => {
    terminarAnamnese({
      variables: {
        id: anamnese.id,
      },
    })
      .then(() => {
        toastNotification({message: 'Anamnese finalizada', type: 'success'});
        navigate(`/perfil`);
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      );
  };

  const renderReferencia = (pergunta) => {
    if (!pergunta.valoresReferencia?.length > 0) return;

    return {
      endAdornment: (
        <InputAdornment
          title="Valores de referência"
          position="end"
          onClick={() => setValoresReferencia(pergunta.valoresReferencia)}
          sx={styles.referenciaIcon}>
          <InfoRounded />
        </InputAdornment>
      ),
    };
  };

  return (
    <Grid id="container" container>
      <Toolbar />
      <Grid container id="content" spacing={2}>
        <Atleta atleta={anamnese.atleta || {}} />
        <Grid container item xs={12} spacing={2}>
          <Grid container item xs={2} sx={{borderRight: '1px solid #fff'}}>
            <ToggleOptions
              options={grupos}
              selected={selectedMenu}
              orientation="vertical"
              onChange={setSelectedMenu}
            />
          </Grid>
          <Grid
            container
            item
            xs={10}
            alignContent="flex-start"
            rowGap={2}
            sx={{padding: 2}}>
            {respostas
              ?.filter(
                (r) =>
                  r.modeloPergunta?.pergunta?.modeloGrupo?.id === selectedMenu,
              )
              ?.map((r) => {
                const pergunta = r.modeloPergunta.pergunta;
                const options = pergunta.valoresReferencia?.map((v) => ({
                  value: v.id,
                  label: v.nome,
                }));

                return (
                  <Grid
                    key={r.modeloPergunta?.id}
                    container
                    item
                    xs={12}
                    columnSpacing={2}
                    alignItems="flex-end">
                    <Grid item xs={11}>
                      {pergunta.tipo === 'COMBOBOX' && (
                        <SelectInput
                          label={pergunta.pergunta}
                          placeholder={'Selecione'}
                          value={
                            options.filter(
                              (o) => o.value === formData[r.id],
                            )?.[0] || ''
                          }
                          onChange={({value}) => {
                            setFormData((f) => ({...f, [r.id]: value}));
                            saveOnBlur(r.id, value);
                          }}
                          options={options}
                        />
                      )}
                      {pergunta.tipo !== 'COMBOBOX' && (
                        <Input
                          label={pergunta.pergunta}
                          type={pergunta.tipo}
                          value={formData[r.id] || ''}
                          onChange={({target}) => {
                            setFormData((f) => ({
                              ...f,
                              [r.id]: target.value,
                            }));
                          }}
                          onBlur={({target}) =>
                            saveOnBlur(r.id, target.value, r.anexoId)
                          }
                          InputProps={renderReferencia(pergunta)}
                          sx={{width: '100%'}}
                        />
                      )}
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton
                        id={r.anexoId ? 'icon-primary' : 'icon-outlined'}
                        onClick={() => setPerguntaAnexo(r)}>
                        <AttachFile />
                      </IconButton>
                    </Grid>
                    {pergunta.urlReferencia && (
                      <Grid item xs={12}>
                        <Link
                          href={pergunta.urlReferencia}
                          target="_blank"
                          sx={{color: '#fff', fontSize: 12}}>
                          Link de referência
                        </Link>
                      </Grid>
                    )}
                  </Grid>
                );
              })}
          </Grid>
        </Grid>
        <Grid container item xs={12} justifyContent={'space-between'} mt="32px">
          <Button
            id="primary"
            endIcon={<FactCheck />}
            onClick={onTerminarAnanmnese}
            disabled={saving}>
            Finalizar
          </Button>
          <Button
            id="outlined"
            endIcon={saving ? <CircularProgress size={20} /> : <Check />}
            disableFocusRipple>
            {saving ? 'Salvando' : 'Salvo'}
          </Button>
        </Grid>
      </Grid>
      <AnexoModal
        open={!!perguntaAnexo}
        pergunta={perguntaAnexo}
        onClose={() => setPerguntaAnexo(undefined)}
        onSave={(id) => {
          saveOnBlur(perguntaAnexo.id, formData[perguntaAnexo.id], id);
          const index = respostas.findIndex(
            (obj) => obj.id === perguntaAnexo.id,
          );
          respostas[index].anexoId = id;
          setRespostas([...respostas]);
          setPerguntaAnexo(undefined);
        }}
      />
      <ValoresReferenciaModal
        open={!!valoresReferencia}
        valoresReferencia={valoresReferencia}
        onClose={() => setValoresReferencia(undefined)}
      />
    </Grid>
  );
}

const Atleta = ({atleta}) => {
  const [open, setOpen] = useState(true);

  const getStringEndereco = () => {
    if (!atleta.enderecos?.length) return '';
    const endereco =
      atleta.enderecos?.filter((e) => e.principal)?.[0] ||
      atleta.enderecos?.[0];

    return `${endereco?.cidade?.nome} - ${endereco?.cidade?.uf}`;
  };

  return (
    <Grid
      container
      item
      xs={12}
      sx={styles.menuCollapse}
      onClick={() => setOpen(!open)}>
      <Typography sx={styles.title}>Atleta</Typography>
      {open && <KeyboardArrowUp sx={styles.collapseIcon} />}
      {!open && <KeyboardArrowDown sx={styles.collapseIcon} />}
      <Grid item xs={12}>
        <Collapse in={open} timeout="auto" unmountOnExit sx={styles.collapse}>
          <Grid container sx={styles.content} padding={2} spacing={2}>
            <Grid
              container
              item
              md={6}
              xs={12}
              gap={2}
              sx={styles.dadosContainer}>
              <Grid container item gap={1} xs sx={styles.avatarContainer}>
                <Avatar
                  src={getImageUrl(atleta?.fotoPerfil)}
                  sx={[
                    styles.avatar,
                    atleta?.fotoPerfil && styles.imagemPerfil,
                  ]}>
                  <PhotoCamera />
                </Avatar>
                <Grid container item sx={styles.redesContainer} columnGap={1}>
                  {atleta.urlFacebook && (
                    <Link
                      title="Facebook"
                      href={atleta.urlFacebook}
                      target="_blank"
                      rel="noopener"
                      sx={styles.footerText}>
                      <Facebook />
                    </Link>
                  )}
                  {atleta.urlInstagram && (
                    <Link
                      title="Instragram"
                      href={atleta.urlInstagram}
                      target="_blank"
                      rel="noopener"
                      sx={styles.footerText}>
                      <Instagram />
                    </Link>
                  )}
                  {atleta.urlX && (
                    <Link
                      title={'X'}
                      href={atleta.urlX}
                      target="_blank"
                      rel="noopener"
                      sx={styles.footerText}>
                      <X style={styles.icon} />
                    </Link>
                  )}
                  {atleta.urlLinkedin && (
                    <Link
                      title="LinkedIn"
                      href={atleta.urlLinkedin}
                      target="_blank"
                      rel="noopener"
                      sx={styles.footerText}>
                      <Linkedin />
                    </Link>
                  )}
                  <Link
                    title="Email"
                    href={`mailto://${atleta.email}`}
                    sx={styles.footerText}>
                    <Mail />
                  </Link>
                </Grid>
              </Grid>
              <Grid container item flex={1} direction={'column'} xs>
                <Typography
                  id="modalidade-tag"
                  sx={{
                    visibility: atleta.modalidade ? 'visible' : 'hidden',
                  }}>
                  {atleta.modalidade?.descricao}
                </Typography>
                <Typography sx={styles.title}>{atleta.nome}</Typography>
                <Typography sx={styles.text}>{`${Data.calcularIdade(
                  atleta.dataNascimento,
                )} anos`}</Typography>
                {!!atleta.enderecos?.length && (
                  <Typography sx={styles.text}>
                    {getStringEndereco(atleta)}
                  </Typography>
                )}
                <Typography sx={styles.text}>
                  {String.formatTelefone(atleta.telefone)}
                </Typography>
                <Typography mt={1} sx={styles.title}>
                  Dados básicos
                </Typography>
                <Typography sx={styles.text}>
                  {!!atleta.funcoes?.length && <DirectionsRun />}{' '}
                  {atleta.funcoes?.map((f) => f.descricao).join(' | ')}
                </Typography>
                {atleta?.estatura && atleta?.peso && (
                  <Typography sx={styles.text}>
                    <Person />{' '}
                    {`${Number.format(atleta?.estatura, {
                      minimumFractionDigits: 2,
                    })} m | ${Number.format(atleta?.peso)} Kg`}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Grid container item md={6} xs={12} alignContent={'flex-start'}>
              <Grid container>
                <Typography sx={styles.title}>SOBRE MIM</Typography>
              </Grid>
              <Typography component="pre" sx={styles.text}>
                {atleta.descricao}
              </Typography>
            </Grid>
          </Grid>
        </Collapse>
      </Grid>
    </Grid>
  );
};

const styles = {
  container: {
    backgroundColor: (theme) => theme.palette.backgroundColor,
    paddingTop: (theme) => `calc(${theme.toolbarHeight} + 16px)`,
    justifyContent: 'center',
  },
  content: {
    maxWidth: 1200,
    alignContent: 'flex-start',
    paddingX: `16px`,

    '@media (max-width: 900px)': {
      flexWrap: 'nowrap',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  dadosContainer: {
    position: 'relative',
    alignSelf: 'flex-start',

    '@media (max-width: 900px)': {
      flexDirection: 'column',
    },
  },
  avatarContainer: {
    flexDirection: 'column',
    flexGrow: '0 !important',
  },
  avatar: {
    height: '220px',
    width: '200px',
    borderRadius: '6px',
    flexDirection: 'column',
    backgroundColor: 'transparent',
    border: (theme) => `1px solid ${theme.palette.primary.main}`,

    '@media (max-width: 900px)': {
      height: '80px',
      width: '80px',
    },

    svg: {
      color: (theme) => theme.palette.primary.main,
      width: '40px',
      height: '40px',
      maxWidth: '50%',
      maxHeight: '50%',
    },
  },
  imagemPerfil: {
    border: '2px solid #fff',
  },
  title: {
    color: '#FFF',
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: 600,
  },
  text: {
    color: '#FFF',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'break-spaces',

    svg: {
      height: '18px',
      width: '18px',
      marginRight: 1,
    },
  },
  redesContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',

    '@media (max-width: 900px)': {
      justifyContent: 'flex-start',
    },
  },
  footerText: {
    color: '#fff',
  },
  icon: {
    width: '1.2rem',
    height: '1.5rem',
  },
  collapse: {
    width: '100%',
    height: 'fit-content',
    color: '#fff',
    paddingTop: 2,
  },
  menuCollapse: {
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    borderBottom: '1px solid #fff',
    cursor: 'pointer',
    padding: 2,
    marginBottom: 2,
  },
  collapseIcon: {
    color: '#fff',
    fontSize: 32,
  },
  referenciaIcon: {
    cursor: 'pointer',
    position: 'relative',
  },
};
