import {Button, Grid, Typography} from '@mui/material';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';

import Footer from '../../components/footer';
import {Atleta, Empresario, Medico, Noticias} from '../../components/icons';
import ContatoModal from '../../components/modais/contato';
import ToolbarLogin from '../../components/toolbar/toolbar-login';

export default function CadastroTipos() {
  const navigate = useNavigate();

  const [openContato, setOpenContato] = useState(false);

  return (
    <Grid container sx={styles.container}>
      <ToolbarLogin />
      <Grid container sx={styles.content} justifyContent={'space-between'}>
        <Grid container item sx={styles.titleContainer}>
          <Typography sx={styles.title}>CRIAR CONTA</Typography>
          <Typography sx={styles.subtitle}>Selecione seu perfil</Typography>
        </Grid>
        <Grid container item sx={styles.buttonsContainer}>
          <Grid item lg={6} xs={12} p={1}>
            <Button sx={styles.button} onClick={() => navigate(`atleta`)}>
              <Atleta style={styles.buttonIconAtleta} />
              Atleta
            </Button>
          </Grid>
          <Grid item lg={6} xs={12} p={1}>
            <Button sx={styles.button} onClick={() => navigate(`empresario`)}>
              <Empresario style={styles.buttonIconEmpresario} />
              Empresário
            </Button>
          </Grid>
          <Grid item lg={6} xs={12} p={1}>
            <Button sx={styles.button} onClick={() => setOpenContato(true)}>
              <Medico style={styles.buttonIconAtleta} />
              Profissional de saúde
            </Button>
          </Grid>
          <Grid item lg={6} xs={12} p={1}>
            <Button sx={styles.button} onClick={() => setOpenContato(true)}>
              <Noticias style={styles.buttonIconEmpresario} />
              Jornalista
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Footer />
      <ContatoModal
        tipo={'CADASTRO'}
        open={openContato}
        onClose={() => setOpenContato(false)}
      />
    </Grid>
  );
}

const styles = {
  container: {
    flex: 1,
    justifyContent: 'center',
    paddingTop: (theme) => theme.toolbarHeight,
    backgroundColor: (theme) => theme.palette.blackSecundary,
  },
  content: {
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    height: (theme) => `calc(100vh - ${theme.toolbarHeight})`,
    padding: '32px',
    maxWidth: 1200,
  },
  titleContainer: {
    flexGrow: 0,
    flexDirection: 'column',
    justifyContent: 'center',
    alignSelf: 'flex-start',
    paddingBottom: '32px',

    '@media (max-width: 1200px)': {
      paddingBottom: '16px',
    },
  },
  closeIcon: {
    position: 'absolute',
    cursor: 'pointer',
    top: '28px',
    right: '28px',
    width: '32px',
    height: '32px',
  },
  title: {
    color: '#FFF',
    textAlign: 'center',
    fontFamily: 'Now',
    fontSize: '32px',
    fontWeight: '700',
    textTransform: 'uppercase',

    '@media (max-width: 1200px)': {
      fontSize: '20px',
    },
  },
  subtitle: {
    color: '#FFF',
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: '500',
  },
  buttonsContainer: {
    flex: 1,
    width: '100%',
    maxWidth: '560px',
    maxHeight: '414px',
  },
  button: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #fff',
    width: '100%',
    height: '100%',
    color: '#fff',
    textTransform: 'none',
    fontSize: '16px',
    lineHeight: '38px',
    fontWeight: '500',

    ':hover': {
      backgroundColor: '#FFEE87',
      color: (theme) => `${theme.palette.backgroundColor} !important`,
    },

    '@media (max-width: 1200px)': {
      flexDirection: 'row',
    },
  },
  buttonIconAtleta: {
    width: '28px',
    height: '28px',
    margin: '8px',
  },
  buttonIconEmpresario: {
    width: '24px',
    height: '24px',
    margin: '16px',
  },
};
