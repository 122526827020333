import {Cancel, Close, Save} from '@mui/icons-material';
import {
  Button,
  Grid,
  IconButton,
  Modal,
  Slide,
  Typography,
} from '@mui/material';
import {useEffect, useState} from 'react';
import * as Yup from 'yup';

import Input from '../../../components/input/input';
import InputMask from '../../../components/input/input-mask';
import SelectInput from '../../../components/input/select/select-input';
import Data from '../../../utils/data';
import Masks from '../../../utils/masks';
import Object from '../../../utils/object';
import String from '../../../utils/string';

const parentescoOptions = [
  {value: 'PAIS', label: 'Pais'},
  {value: 'AVOS', label: 'Avós'},
  {value: 'TIOS', label: 'Tios'},
  {value: 'OUTROS', label: 'Outros'},
];

export default function ResponsavelAtleta({open, onClose, onSave}) {
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});

  const handleSubmit = (event) => {
    event.preventDefault();
    responsavelScheme
      .validate(formData, {abortEarly: false})
      .then(() => onSave(formData))
      .catch((errors) => {
        const camposComErro = {};
        errors?.inner?.forEach((error) => {
          camposComErro[error.path] = error.message;
        });

        setErrors(Object.yupArrayToJs(camposComErro));
      });
  };

  useEffect(() => {
    setErrors({});
  }, [open]);

  return (
    <Modal open={open} onClose={onClose} sx={styles.modal}>
      <Slide in={open} appear>
        <Grid
          container
          className="styled-scroll-transparent-desktop"
          sx={styles.container}>
          <Grid container item sx={styles.header}>
            <Grid container item xs={11}>
              <Typography sx={styles.title}>Tem menos de 16 anos?</Typography>
              <Typography sx={styles.subtitle}>
                Adicione os dados de seu responsável.
              </Typography>
            </Grid>
            <Grid container item xs={1} justifyContent={'flex-end'}>
              <IconButton sx={styles.closeIcon} onClick={onClose}>
                <Close />
              </IconButton>
            </Grid>
          </Grid>
          <Grid container item sx={styles.content} spacing={2}>
            <Grid item xs={12}>
              <Input
                label={'Nome do responsável'}
                name="nome"
                value={formData.nome || ''}
                type="text"
                autoCapitalize="on"
                placeholder={'Nome completo'}
                onChange={({target}) =>
                  setFormData((f) => ({...f, nome: target?.value}))
                }
                errors={errors}
              />
            </Grid>
            <Grid item xs={12}>
              <InputMask
                label={'CPF'}
                name="cpf"
                value={formData.cpf || ''}
                type="tel"
                mask={Masks.cpf}
                placeholder={'Digite o número do documento'}
                onChange={({target}) =>
                  setFormData((f) => ({...f, cpf: target?.value}))
                }
                errors={errors}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                label={'E-mail'}
                name="email"
                value={formData.email || ''}
                type="email"
                autoComplete="email"
                placeholder={'Digite seu e-mail'}
                onChange={({target}) =>
                  setFormData((f) => ({...f, email: target?.value}))
                }
                errors={errors}
              />
            </Grid>
            <Grid item xs={12}>
              <InputMask
                label={'Telefone'}
                name="telefone"
                value={formData.telefone || ''}
                type="tel"
                autoComplete="tel"
                mask={Masks.telelefone}
                placeholder={'Digite seu número de telefone'}
                onChange={({target}) =>
                  setFormData((f) => ({...f, telefone: target?.value}))
                }
                errors={errors}
              />
            </Grid>
            <Grid item xs={12}>
              <Input
                label={'Data de nascimento'}
                name="dataNascimento"
                value={formData.dataNascimento || ''}
                type="date"
                onChange={({target}) =>
                  setFormData((f) => ({...f, dataNascimento: target?.value}))
                }
                errors={errors}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectInput
                label={'Grau de parentesco'}
                placeholder={'Selecione'}
                name="grauParentesco"
                value={formData.grauParentesco || ''}
                menuPlacement="top"
                onChange={(grau) =>
                  setFormData((f) => ({...f, grauParentesco: grau}))
                }
                options={parentescoOptions}
                errors={errors}
              />
            </Grid>
          </Grid>
          <Grid container columnSpacing={4} rowGap={2} padding={'16px 8px'}>
            <Grid item md={6} xs={12}>
              <Button
                id="outlined"
                onClick={onClose}
                fullWidth
                endIcon={<Cancel />}>
                Cancelar
              </Button>
            </Grid>
            <Grid item md={6} xs={12}>
              <Button
                id="primary"
                onClick={handleSubmit}
                fullWidth
                endIcon={<Save />}>
                Salvar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Slide>
    </Modal>
  );
}

const responsavelScheme = Yup.object().shape({
  nome: Yup.string().required('Campo obrigatório'),
  cpf: Yup.string()
    .required('Campo obrigatório')
    .test('validar-cpf', 'CPF inválido', (value) => String.validaCpf(value)),
  email: Yup.string().required('Campo obrigatório').email('E-mail inválido'),
  telefone: Yup.string().required('Campo obrigatório'),
  dataNascimento: Yup.date()
    .required('Campo obrigatório')
    .test(
      'maior-idade',
      'O responsável precisa possuir 18 anos ou mais',
      (value) => Data.calcularIdade(value) > 17,
    ),
  grauParentesco: Yup.object().required('Campo obrigatório'),
});

const styles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    position: 'relative',
    maxWidth: '683px',
    maxHeight: '95%',
    width: '40%',
    borderRadius: '6px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    padding: 3,
    paddingTop: 0,
    zIndex: (theme) => theme.zIndex.modal,
    background: (theme) => theme.palette.blackSecundary,
    boxShadow: '0px 0px 134.60001px 0px rgba(255, 204, 41, 0.25)',
    outline: 'none',
    overflowY: 'auto',

    '@media (max-width: 900px)': {
      width: '90%',
    },
  },
  header: {
    flexGrow: 0,
    paddingTop: 2,
    position: 'sticky',
    top: 0,
    background: (theme) => theme.palette.blackSecundary,
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    svg: {
      color: '#fff',
    },
    paddingBottom: 2,
    borderBottom: '1px solid #fff',
    zIndex: 2,
  },
  closeIcon: {
    width: '32px',
    height: '32px',
  },
  title: {
    color: '#FFF',
    textAlign: 'center',
    fontFamily: 'Now',
    fontSize: '24px',
    fontWeight: '700',
    textTransform: 'uppercase',

    '@media (max-width: 1200px)': {
      fontSize: '20px',
    },
  },
  subtitle: {
    color: '#E3E3E3',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: '500',

    '@media (max-width: 1200px)': {
      fontSize: '14px',
    },
  },
  enderecoItem: {
    borderBottom: '1px solid #fff',
    padding: '10px 0 20px 0',
  },
  enderecoHeader: {
    justifyContent: 'space-between',
    alignContent: 'center',
    cursor: 'pointer',
    color: '#fff',
    ':hover': {
      color: (theme) => theme.palette.yellowLight,
    },
    svg: {
      color: 'inherit',
    },
  },
  enderecoTitulo: {
    color: '#E3E3E3',
    textAlign: 'center',
    alignSelf: 'center',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: '500',
    paddingBottom: 1,
  },
  content: {
    flexDirection: 'row',
    width: '100%',
    paddingY: '16px',
  },
};
