import {Box, Button, Grid, Typography} from '@mui/material';

import MedicoImage from '../../assets/images/home-medico.png';

export default function HomeMedico() {
  return (
    <Grid container sx={styles.container}>
      <Grid container sx={styles.content} justifyContent={'space-between'}>
        <Grid container item md={5.5} xs={12} alignContent={'center'}>
          <Box sx={styles.labelContainer}>
            <Typography sx={styles.label}>
              PARA PROFISSIONAIS DA SAÚDE
            </Typography>
          </Box>
          <Typography id="home-title" sx={styles.title}>
            TODOS OS ATLETAS PASSAM POR AVALIAÇÃO CLÍNICA 360º
          </Typography>
          <Typography sx={styles.subtitle}>
            Uma equipe multidisciplinar submete o atleta a uma sequência de
            exames físicos e clínicos para avaliar pontos fortes e necessidades
            de desenvolvimento, potencial de crescimento entre outros aspectos
          </Typography>
          <Grid container sx={styles.buttonContainer}>
            <Grid item>
              <Button id="primary">SOLICITAR AVALIAÇÃO</Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item md={5.5} xs={12} sx={styles.image}>
          <Box component="img" src={MedicoImage} />
        </Grid>
      </Grid>
    </Grid>
  );
}

const styles = {
  container: {
    flex: 1,
    justifyContent: 'center',
    padding: '112px 64px',

    '@media (max-width: 900px)': {
      padding: '16px 16px 32px 16px',
    },
  },
  content: {
    maxWidth: 1200,

    '@media (max-width: 900px)': {
      flexWrap: 'nowrap',
      flexDirection: 'column-reverse',
      alignItems: 'center',
    },
  },
  labelContainer: {
    display: 'flex',
    backgroundColor: '#FEFBE8',
    marginBottom: '16px',
    padding: '4px 8px',
    borderRadius: (theme) => theme.borderRadius,
  },
  label: {
    color: '#000',
    fontSize: 16,
    fontWeight: '700',
    marginTop: '4px',
  },
  title: {
    '@media (max-width: 400px)': {
      fontSize: '32px',
    },
  },
  subtitle: {
    fontSize: 16,
    fontWeight: '400',
    fontFamily: 'Inter',
    color: '#fff',
    padding: '16px 16px 16px 0',
  },
  buttonContainer: {
    width: 'fit-content',
    marginTop: 4,

    '@media (max-width: 900px)': {
      width: '100%',
      '.MuiGrid-item': {
        width: '100%',
      },
      button: {
        width: '100%',
      },
    },
  },
  image: {
    justifyContent: 'flex-end',
    img: {
      width: '100%',
      objectFit: 'contain',
    },

    '@media (max-width: 900px)': {
      justifyContent: 'center',
      width: '100vw',
      padding: '24px 0',
    },
  },
};
