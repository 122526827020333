import { useMutation } from '@apollo/client';
import { Close, ShoppingCart } from '@mui/icons-material';
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
  Slide,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { IMask } from 'react-imask';
import 'swiper/css';
import 'swiper/css/pagination';
import * as Yup from 'yup';

import Input from '../../../components/input/input';
import InputMask from '../../../components/input/input-mask';
import { toastNotification } from '../../../components/toastify';
import { useAuth } from '../../../contexts/auth';
import { GERAR_RECORRENCIA_BY_DADOS_CARTAO } from '../../../graphql/mutation';
import Masks from '../../../utils/masks';
import String from '../../../utils/string';

const getBrand = (cardNumber) => {
  const aux = cardNumber[0];

  if (aux === '3') {
    return 'AMEX';
  }

  if (aux === '5') {
    return 'MASTERCARD';
  }

  if (aux === '6') {
    return 'DISCOVER';
  }

  return 'VISA';
};

export default function Compra({open, plano, onClose, periodo}) {
  const {usuarioLogado} = useAuth();
  const [errors, setErrors] = useState({});
  const [formData, setFormData] = useState({});

  const [gerarRecorrencia, {loading}] = useMutation(
    GERAR_RECORRENCIA_BY_DADOS_CARTAO,
  );

  const handleSubmit = (event) => {
    event.preventDefault();
    adminScheme
      .validate({...formData}, {abortEarly: false})
      .then(handleSave)
      .catch((errors) => {
        const camposComErro = {};
        errors?.inner?.forEach((error) => {
          camposComErro[error.path] = error.message;
        });
        setErrors(camposComErro);
      });
  };

  const handleSave = () => {
    gerarRecorrencia({
      variables: {
        dados: {
          descricao: plano.titulo,
          tipoDocumentoCliente: 'CPF',
          periodicidadeCobranca: periodo,
          usuario: {id: usuarioLogado.id},
          nomeCliente: formData.nomeCliente,
          emailCliente: formData.emailCliente,
          documentoCliente: String.removeSpecialChars(
            formData.documentoCliente,
          ),
          dadosCartao: {
            holder: formData.holder,
            cardNumber: formData.cardNumber,
            securityCode: formData.securityCode,
            brand: getBrand(formData.cardNumber),
            expirationDate: formData.expirationDate,
          },
        },
        plano: {
          id: plano.id,
        },
      },
    })
      .then(() => {
        toastNotification({
          message: 'Assinatura realizada com sucesso!',
          type: 'success',
        });
        onClose(true);
        window.location.reload();
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      );
  };

  return (
    <Modal open={open} onClose={onClose} sx={styles.modal}>
      <Slide in={open} appear>
        <Grid
          container
          className="styled-scroll-transparent-desktop"
          sx={styles.container}>
          <Grid container item sx={styles.header}>
            <Typography sx={styles.title}>
              Assinar Plano - {plano?.titulo}
            </Typography>
            <IconButton sx={styles.closeIcon} onClick={onClose}>
              <Close />
            </IconButton>
          </Grid>
          <Grid container item sx={styles.content} gap={2}>
            <Input
              type="text"
              label="Nome"
              errors={errors}
              name="nomeCliente"
              placeholder="Digite o seu nome"
              value={formData.nomeCliente || ''}
              onChange={({target}) =>
                setFormData((f) => ({...f, nomeCliente: target?.value}))
              }
            />
            <Input
              type="email"
              label="E-mail"
              errors={errors}
              name="emailCliente"
              value={formData.emailCliente || ''}
              placeholder={'Digite o seu e-mail'}
              onChange={({target}) =>
                setFormData((f) => ({...f, emailCliente: target?.value}))
              }
            />
            <InputMask
              type="tel"
              label="CPF"
              errors={errors}
              mask={Masks.cpf}
              name="documentoCliente"
              placeholder={'Digite o número do documento'}
              onChange={({target}) =>
                setFormData((f) => ({...f, documentoCliente: target?.value}))
              }
            />
            <InputMask
              type="tel"
              errors={errors}
              name="cardNumber"
              mask={/^\d{0,16}$/}
              label="Número do cartão"
              value={formData.cardNumber || ''}
              placeholder={'Digite o número do cartão'}
              onChange={({target}) =>
                setFormData((f) => ({...f, cardNumber: target?.value}))
              }
            />

            <Grid container item gap={2} columns={2}>
              <Grid item xs>
                <InputMask
                  type="tel"
                  errors={errors}
                  placeholder="MM/AAAA"
                  name="expirationDate"
                  label="Data de validade"
                  mask={Masks.expirationDate}
                  blocks={{
                    YYYY: {
                      mask: IMask.MaskedRange,
                      from: new Date().getFullYear(),
                      to: 9999,
                    },

                    MM: {
                      mask: IMask.MaskedRange,
                      from: 1,
                      to: 12,
                    },
                  }}
                  value={formData.expirationDate || ''}
                  onChange={({target}) =>
                    setFormData((f) => ({...f, expirationDate: target?.value}))
                  }
                />
              </Grid>
              <Grid item xs>
                <InputMask
                  type="tel"
                  mask="000"
                  errors={errors}
                  name="securityCode"
                  label="Código de segurança"
                  value={formData.securityCode || ''}
                  placeholder={'Digite o código de CVV'}
                  onChange={({target}) =>
                    setFormData((f) => ({...f, securityCode: target?.value}))
                  }
                />
              </Grid>
            </Grid>

            <Input
              type="text"
              name="holder"
              errors={errors}
              autoCapitalize="on"
              label="Nome no cartão"
              value={formData.holder || ''}
              placeholder={'Digite o nome no cartão'}
              onChange={({target}) =>
                setFormData((f) => ({...f, holder: target?.value}))
              }
            />
          </Grid>

          <Grid container mt={'auto'} sx={styles.infoCard}>
            <Grid py={1} px={2} item container direction={'column'}>
              R${' '}
              {periodo === 'Annual'
                ? Math.round(plano?.valorAnual / 12)
                : plano?.valorMensal}
              /mês
              <Typography variant="caption" color="text.secondary">
                {periodo === 'Annual'
                  ? '*Valor anual pago em uma única vez'
                  : '*Valor pago em recorrência mensal'}
              </Typography>
            </Grid>
          </Grid>

          <Grid container py={2} mt={'auto'}>
            <Button
              id="primary"
              onClick={handleSubmit}
              fullWidth
              endIcon={
                loading ? <CircularProgress size={20} /> : <ShoppingCart />
              }>
              Assinar
            </Button>
          </Grid>
        </Grid>
      </Slide>
    </Modal>
  );
}

const adminScheme = Yup.object().shape({
  nomeCliente: Yup.string().required('Campo obrigatório'),
  emailCliente: Yup.string()
    .required('Campo obrigatório')
    .email('E-mail inválido'),
  documentoCliente: Yup.string()
    .required('Campo obrigatório')
    .test('validar-cpf', 'CPF inválido', (value) => String.validaCpf(value)),
  cardNumber: Yup.string().required('Campo obrigatório'),
  expirationDate: Yup.string().required('Campo obrigatório'),
  securityCode: Yup.string().required('Campo obrigatório'),
  holder: Yup.string().required('Campo obrigatório'),
});

const styles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    position: 'relative',
    maxWidth: '1029px',
    width: '40%',
    borderRadius: '6px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    padding: 3,
    paddingTop: 0,
    zIndex: (theme) => theme.zIndex.modal,
    background: (theme) => theme.palette.blackSecundary,
    boxShadow: '0px 0px 134.60001px 0px rgba(255, 204, 41, 0.25)',
    outline: 'none',
    overflowY: 'auto',

    '@media (max-width: 900px)': {
      width: '90%',
    },
  },
  header: {
    flexGrow: 0,
    paddingTop: 2,
    position: 'sticky',
    top: 0,
    background: (theme) => theme.palette.blackSecundary,
    justifyContent: 'space-between',
    alignItems: 'center',
    svg: {
      color: '#fff',
    },
    paddingBottom: 2,
    borderBottom: '1px solid #fff',
    zIndex: 2,
  },
  closeIcon: {
    width: '32px',
    height: '32px',
  },
  title: {
    color: '#FFF',
    fontSize: '20px',
    fontWeight: '700',
    textTransform: 'uppercase',

    '@media (max-width: 1200px)': {
      fontSize: '14px',
    },
  },
  subtitle: {
    color: '#E3E3E3',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: '500',
    marginBottom: 1,
  },
  content: {
    flexDirection: 'column',
    width: '100%',
    paddingY: '16px',
  },
  maxCaracter: {
    color: '#fff',
    fontSize: '12px',
  },
  infoCard: {
    color: (theme) => theme.black,
    borderRadius: (theme) => theme.borderRadius,
    backgroundColor: (theme) => theme.palette.yellowLight,
  },
};
