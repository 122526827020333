import {Menu, Search} from '@mui/icons-material';
import {
  AppBar,
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  Slide,
} from '@mui/material';
import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {useAuth} from '../../contexts/auth';
import {setFiltrosBusca} from '../../redux/Busca/slice';
import {LogoBeta} from '../icons';
import Input from '../input/input';
import {SelectCidades} from '../input/select/cidades';
import PerfilLogado from './perfil-logado';

export default function ToolbarBusca() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {isLogged, loading} = useAuth();
  const {filtros} = useSelector((state) => state.Busca);
  const {isMobile} = useSelector((state) => state.App);

  const [openMenu, setOpenMenu] = useState(false);
  const [openFiltro, setOpenFiltro] = useState(false);
  const [filtrosMobile, setFiltrosMobile] = useState({
    search: filtros?.search,
    cidade: filtros?.cidade,
  });

  const handleChangeFilter = (field, value) => {
    dispatch(setFiltrosBusca({...filtros, [field]: value}));
  };

  const handleFilter = () => {
    dispatch(setFiltrosBusca({...filtros, ...filtrosMobile}));
    setOpenFiltro(false);
  };

  if (isMobile) {
    return (
      <AppBar sx={styles.appBar}>
        <Grid container sx={styles.container}>
          <Grid
            item
            alignSelf={'center'}
            sx={styles.logoMobile}
            onClick={() => navigate('/home')}>
            <LogoBeta />
          </Grid>
          <Grid container item width={'auto'} gap={2} alignContent={'center'}>
            <Grid item>
              <IconButton
                id="icon-outlined"
                onClick={() => setOpenFiltro(true)}
                sx={openFiltro ? styles.mobileMenuOpen : {}}>
                <Search />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                id="icon-outlined"
                onClick={() => setOpenMenu(true)}
                sx={openMenu ? styles.mobileMenuOpen : {}}>
                <Menu />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Modal
          componentsProps={{
            backdrop: {
              sx: {
                background:
                  'linear-gradient(to bottom, transparent 80px, rgb(0, 0, 0, 0.5) 80px)',
              },
            },
          }}
          onClose={() => setOpenFiltro(false)}
          open={openFiltro}>
          <Slide direction="left" in={openFiltro} appear>
            <Box sx={styles.modalBusca}>
              <Grid container sx={styles.content}>
                <Grid container rowGap={4}>
                  <Grid item xs={12}>
                    <Input
                      name="busca"
                      type="text"
                      value={filtrosMobile.search || ''}
                      autoComplete="off"
                      placeholder={'Pesquise por nome'}
                      onChange={({target}) =>
                        setFiltrosMobile((f) => ({...f, search: target.value}))
                      }
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SelectCidades
                      placeholder="Cidade"
                      value={filtrosMobile.cidade}
                      isClearable
                      onChange={(e) =>
                        setFiltrosMobile((f) => ({...f, cidade: e}))
                      }
                    />
                  </Grid>
                  <Button
                    id="primary"
                    sx={styles.filterButton}
                    fullWidth
                    onClick={handleFilter}>
                    <Search />
                    Filtrar
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Slide>
        </Modal>
        <Modal
          componentsProps={{
            backdrop: {
              sx: {
                background: 'rgb(0, 0, 0, 0.0)',
              },
            },
          }}
          onClose={() => setOpenMenu(false)}
          open={openMenu}>
          <Slide direction="left" in={openMenu} appear>
            <Box sx={styles.modal}>
              <Grid container sx={styles.content}>
                {isLogged && <PerfilLogado mobile />}
                {!isLogged && (
                  <Grid container item xs={12} gap={2}>
                    <Grid container item>
                      <Button
                        id="text"
                        sx={styles.createButton}
                        fullWidth
                        onClick={() => navigate('/cadastro')}>
                        CRIAR CONTA
                      </Button>
                    </Grid>
                    <Grid container item>
                      <Button
                        id="outlined"
                        fullWidth
                        onClick={() => navigate('/login')}>
                        Login
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Slide>
        </Modal>
      </AppBar>
    );
  }

  return (
    <AppBar sx={styles.appBar}>
      <Grid container sx={styles.container}>
        <Grid
          item
          alignSelf={'center'}
          sx={styles.logo}
          onClick={() => navigate('/home')}>
          <LogoBeta />
        </Grid>
        <Grid
          flex={1}
          container
          item
          width={'auto'}
          columnSpacing={1}
          justifyContent={'center'}
          flexDirection={'row'}
          paddingX={1}>
          <Grid item xs={8} alignSelf={'center'}>
            <Input
              name="busca"
              type="text"
              value={filtros.search || ''}
              autoComplete="off"
              placeholder={'Pesquise por nome'}
              onChange={({target}) =>
                handleChangeFilter('search', target.value)
              }
            />
          </Grid>
          <Grid item xs={4} alignSelf={'center'}>
            <SelectCidades
              placeholder="Cidade"
              value={filtros.cidade}
              isClearable
              onChange={(e) => handleChangeFilter('cidade', e)}
            />
          </Grid>
        </Grid>
        {isLogged && (
          <Grid
            container
            item
            columnGap={2}
            width={'295px'}
            alignSelf={'center'}
            justifyContent={'flex-end'}>
            <PerfilLogado />
          </Grid>
        )}
        {!isLogged && (
          <Grid
            container
            item
            columnGap={2}
            sx={{
              width: '295px',
              alignSelf: 'center',
              visibility: loading ? 'hidden' : 'visible',
            }}>
            <Grid item>
              <Button
                id="text"
                sx={styles.createButton}
                onClick={() => navigate('/cadastro')}>
                CRIAR CONTA
              </Button>
            </Grid>
            <Grid item>
              <Button id="outlined" onClick={() => navigate('/login')}>
                Login
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
    </AppBar>
  );
}

const styles = {
  appBar: {
    height: (theme) => theme.toolbarHeight,
    padding: '8px 64px',
    backgroundColor: 'inherit',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: 'none',

    '@media (max-width: 900px)': {
      padding: '8px 16px',
      background: (theme) => theme.palette.blackSecundary,
    },
  },
  container: {
    maxWidth: 1200,
    justifyContent: 'space-between',
  },
  logo: {
    cursor: 'pointer',
    width: '295px',
  },
  logoMobile: {
    cursor: 'pointer',
  },
  content: {
    height: '100%',
    alignContent: 'space-between',
    padding: 2,
  },
  createButton: {
    fontWeight: '400',
  },
  filterButton: {
    fontWeight: '400',
    svg: {
      fontSize: '20px',
      paddingBottom: '3px',
      marginRight: 1,
    },
  },
  menuButton: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    cursor: 'pointer',
    borderRadius: (theme) => theme.borderRadius,
    color: (theme) => theme.palette.white,
    padding: '8px 16px',
    '.MuiTypography-root': {
      fontWeight: '500',
      textTransform: 'none',
    },
  },
  mobileMenuOpen: {
    backgroundColor: (theme) => `${theme.palette.primary.main} !important`,
    color: '#000',
  },
  modal: {
    width: '80%',
    borderRadius: '6px',
    zIndex: (theme) => theme.zIndex.modal,
    background: (theme) => theme.palette.blackSecundary,
    boxShadow: '-48px -21px 134.60001px 0px rgba(255, 204, 41, 0.25)',
    position: 'absolute',
    right: '0px',
    top: (theme) => `calc(8px + ${theme.toolbarHeight})`,
  },
  modalBusca: {
    width: '100%',
    borderTopLeftRadius: '6px',
    zIndex: (theme) => theme.zIndex.modal,
    background: (theme) => theme.palette.blackSecundary,
    position: 'absolute',
    right: '0px',
    top: (theme) => theme.toolbarHeight,
  },
  menuButtonMobile: {
    color: '#fff',
    textTransform: 'none',
    padding: '16px 0',
    borderBottom: '1px solid #fff',
    borderRadius: '0',
    width: '100%',
    justifyContent: 'flex-start',
    '.MuiTypography-root': {
      fontSize: '16px',
      fontWeight: '500',
    },
    svg: {
      width: '24px',
      height: '24px',
      marginRight: 1,
    },
  },
};
