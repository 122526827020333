import {KeyboardArrowDown, KeyboardArrowUp} from '@mui/icons-material';
import {Button, Collapse, Grid, Typography} from '@mui/material';
import {useState} from 'react';

const questions = [
  {
    titulo: 'Pergunta 1',
    resposta: 'Adasd asdas dasd sda sd as sdasdasdas',
  },
  {
    titulo: 'Pergunta 2',
    resposta: 'Adasd asdas dasd sda sd as sdasdasdas',
  },
  {
    titulo: 'Pergunta 3',
    resposta: 'Adasd asdas dasd sda sd as sdasdasdas',
  },
];

export default function PerguntasFrequentes({onOpenContato}) {
  return (
    <Grid container sx={styles.container}>
      <Grid container sx={styles.content} justifyContent={'space-between'}>
        <Grid container item md={5.5} xs={12} alignContent={'center'}>
          <Typography id="home-title">Perguntas frequentes</Typography>
          <Typography sx={styles.subtitle}>
            Aqui estão as perguntas mais usadas por nossos usuários, se tiver
            alguma outra entre em contato.
          </Typography>
          <Grid container gap={2} sx={styles.buttonContainer}>
            <Grid item>
              <Button id="primary" onClick={onOpenContato}>
                ENTRE EM CONTATO
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item md={5.5} xs={12}>
          <Grid container item xs={12} alignContent="flex-start">
            {questions.map((q, index) => (
              <QuestionRow key={index} pergunta={q} />
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

const QuestionRow = ({pergunta = {}}) => {
  const [open, setOpen] = useState(false);
  return (
    <Grid container sx={styles.questionItem}>
      <Grid container onClick={() => setOpen(!open)} sx={styles.questionHeader}>
        <Typography sx={styles.questionTitle}>{pergunta.titulo}</Typography>
        {open && <KeyboardArrowUp />}
        {!open && <KeyboardArrowDown />}
      </Grid>
      <Collapse in={open} timeout="auto" unmountOnExit sx={styles.collapse}>
        <Typography sx={styles.answer}>{pergunta.resposta}</Typography>
      </Collapse>
    </Grid>
  );
};

const styles = {
  container: {
    flex: 1,
    justifyContent: 'center',
    padding: '112px 64px',

    '@media (max-width: 900px)': {
      padding: '32px 16px 64px 16px',
    },
  },
  content: {
    maxWidth: 1200,
  },
  title: {
    fontSize: 48,
    lineHeight: '55.2px',
    fontWeight: '700',
    textTransform: 'uppercase',
    color: '#fff',
  },
  subtitle: {
    fontSize: 16,
    fontWeight: '400',
    fontFamily: 'Inter',
    color: '#fff',
    padding: '16px 0',
  },
  questionItem: {
    borderBottom: '1px solid #fff',
    padding: '20px 0',
  },
  questionHeader: {
    justifyContent: 'space-between',
    alignContent: 'center',
    cursor: 'pointer',
    color: '#fff',
    ':hover': {
      color: theme => theme.palette.yellowLight,
    },
    svg: {
      color: 'inherit',
    },
  },
  questionTitle: {
    color: 'inherit',
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: '700',
  },
  collapse: {
    width: '100%',
    height: 'fit-content',
    color: '#fff',
    paddingTop: 2,
  },
  answer: {
    fontSize: 16,
    fontWeight: '400',
    fontFamily: 'Inter',
  },
  buttonContainer: {
    width: 'fit-content',
    marginTop: 3,

    '@media (max-width: 900px)': {
      borderBottom: '1px solid #fff',
      paddingBottom: 3,
      width: '100%',
      '.MuiGrid-item': {
        width: '100%',
      },
      button: {
        width: '100%',
      },
    },
  },
};
