import React from 'react';

import {FIND_ALL_FUNCOES as query} from '../../../graphql/queries';
import {SelectBase} from './select-apollo';

const getVariables = ({
  pageNumber,
  search = '',
  searchDTO = {},
  pageSize = 20,
}) => {
  return {
    pageable: {pageNumber, pageSize, sortField: 'descricao', sortDir: 'ASC'},
    searchDTO: {
      ...searchDTO,
      search,
    },
  };
};

export const SelectFuncoes = (props) => {
  return (
    <SelectBase
      responseObjectName="funcoes"
      labelProp="descricao"
      query={query}
      getVariables={(searchProps) => getVariables({...searchProps, ...props})}
      {...props}
    />
  );
};
