import {Button, CircularProgress, Grid, Typography} from '@mui/material';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';

import Footer from '../../components/footer';
import Input from '../../components/input/input';
import {toastNotification} from '../../components/toastify';
import ToolbarLogin from '../../components/toolbar/toolbar-login';
import {useAuth} from '../../contexts/auth';

export default function RecuperarSenha() {
  const navigate = useNavigate();
  const {recuperarSenha, loading} = useAuth();

  const [email, setEmail] = useState('');

  const handleSubmit = () => {
    recuperarSenha(email)
      .then(() => {
        toastNotification({
          message: 'Enviamos para seu email o link para redefinir sua senha!',
        });
        navigate('/login');
      })
      .catch((error) => toastNotification({message: error?.message}));
  };

  return (
    <Grid container sx={styles.container}>
      <ToolbarLogin />
      <Grid container sx={styles.content}>
        <Grid container item md={5.5} sm={12} sx={styles.form} gap={2}>
          <Grid item>
            <Typography sx={styles.title}>RECUPERE A SUA SENHA</Typography>
          </Grid>
          <Grid
            component={'form'}
            display={'flex'}
            flexDirection={'column'}
            item
            gap={2}
            padding={'0 32px'}>
            <Input
              label={'E-mail'}
              type="email"
              autoComplete="email"
              placeholder={'Digite seu e-mail'}
              onChange={({target}) => setEmail(target?.value)}
            />
          </Grid>
          <Grid item padding={'32px'}>
            <Button
              id="primary"
              onClick={handleSubmit}
              fullWidth
              endIcon={
                loading && <CircularProgress size={16} color="inherit" />
              }>
              ENVIAR
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </Grid>
  );
}

const styles = {
  container: {
    flex: 1,
    justifyContent: 'center',
    paddingTop: (theme) => theme.toolbarHeight,
    backgroundColor: (theme) => theme.palette.blackSecundary,
  },
  content: {
    height: (theme) => `calc(100vh - ${theme.toolbarHeight})`,
    justifyContent: 'center',
  },
  image: {
    justifyContent: 'center',
    height: '100%',
    img: {
      height: 'inherit',
    },

    '@media (max-width: 900px)': {
      display: 'none',
    },
  },
  form: {
    padding: '0 16px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
  },
  title: {
    textAlign: 'center',
    fontSize: '32px',
    fontWeight: '700',
    color: '#fff',
    padding: '16px 0',
  },
  recuperarSenha: {
    fontSize: '14px',
    fontWeight: '700',
    textTransform: 'uppercase',
  },
  cadastroContainer: {
    textAlign: 'center',
    color: '#fff',
    '.title': {
      fontSize: '14px',
      fontWeight: '500',
      textTransform: 'uppercase',
    },
  },
};
