import {useState} from 'react';
import 'swiper/css';
import 'swiper/css/pagination';
import {Swiper, SwiperSlide} from 'swiper/react';

import EntityCard from './entity-card';
import NewsCard from './news-card';

const getSlidesRow = (cardWidth) => {
  return window.innerWidth / cardWidth;
};

export function PeopleCarousel({content = []}) {
  const [slides, setSlides] = useState(getSlidesRow(324));

  const onResize = () => {
    setSlides(getSlidesRow(324));
  };

  return (
    <Swiper
      slidesPerView={Math.min(slides, content.length)}
      slidesOffsetBefore={20}
      slidesOffsetAfter={20}
      onResize={onResize}>
      {content.map((p) => (
        <SwiperSlide key={p.id}>
          <EntityCard item={p} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

export function NewsCarousel({content = []}) {
  const [slides, setSlides] = useState(getSlidesRow(400));

  const onResize = () => {
    setSlides(getSlidesRow(400));
  };

  return (
    <Swiper
      slidesPerView={Math.min(slides, content.length)}
      slidesOffsetBefore={20}
      slidesOffsetAfter={20}
      onResize={onResize}>
      {content.map((p) => (
        <SwiperSlide key={p.id}>
          <NewsCard item={p} />
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
