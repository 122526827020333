import {ToggleButton, ToggleButtonGroup} from '@mui/material';

import {useHorizontalScroll} from '../utils/hooks/use-horizontal-scroll';

export default function ToggleOptions({
  options,
  exclusive = true,
  selected,
  onChange,
  orientation = 'horizontal',
}) {
  const horizontalRef = useHorizontalScroll();

  const centerFocus = (value) => {
    document.getElementById(value)?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center',
    });
  };

  return (
    <ToggleButtonGroup
      ref={horizontalRef}
      value={selected}
      onChange={({target}) => onChange(target?.value)}
      exclusive={exclusive}
      color="primary"
      aria-label="menu"
      orientation={orientation}
      sx={styles.toggleContainer}>
      {options.map((o) => (
        <ToggleButton
          id={o.value}
          key={o.value}
          value={o.value}
          sx={[styles.toggleButton, orientation === 'vertical' ? styles.toggleButtonVertical : styles.toggleButtonHorizontal]}
          onFocus={() => centerFocus(o.value)}>
          {o.label}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
}

const styles = {
  toggleContainer: {
    overflowX: 'scroll',
    overflowY: 'hidden',

    '&::-webkit-scrollbar': {
      display: 'none',
    },
  },
  toggleButton: {
    fontWeight: '500',
    fontSize: '16px',
    borderRadius: '6px !important',
    border: 'none',
    color: '#fff',

    '&:hover': {
      color: 'var(--primary)',
      opacity: 0.8,
      backgroundColor: 'transparent !important',
    },
    '&.Mui-selected': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
  },
  toggleButtonHorizontal: {
    height: '30px',
    whiteSpace: 'nowrap',
  },
  toggleButtonVertical: {
    whiteSpace: 'break-spaces',
  },
};
