import {useLazyQuery} from '@apollo/client';
import {
  Cancel,
  CheckCircle,
  Edit,
  Error,
  HourglassEmpty,
  Visibility
} from '@mui/icons-material';
import {
  Button,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow
} from '@mui/material';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {useAuth} from '../../../contexts/auth';
import {FIND_ALL_NOTICIAS} from '../../../graphql/queries';
import Data from '../../../utils/data';


const StatusIcon = ({status}) => {
  switch (status) {
    case 'APROVADO':
      return <CheckCircle titleAccess="Aprovado" />;
    case 'REPROVADO':
      return <Cancel titleAccess="Reprovado" />;
    case 'PENDENTE':
      return <HourglassEmpty titleAccess="Em análise" />;
    case 'ESCREVENDO':
      return <Edit titleAccess="Escrevendo" />;
    default:
      return <Error />;
  }
};

export default function ConsultaArtigos() {
  const navigate = useNavigate();
  const {entidadeLogada: profissional} = useAuth();
  const {isMobile} = useSelector((state) => state.App);

  const [noticias, setNoticias] = useState([]);
  const [page, setPage] = useState(0);
  const [totalElements, setTotalElements] = useState(0);

  const [loadNoticias, noticiasQuery] = useLazyQuery(
    FIND_ALL_NOTICIAS,
    {
      variables: {
        pageable: {
          pageNumber: page,
          pageSize: 20,
          sortField: 'dataCadastro',
          sortDir: 'DESC',
        },
        searchDTO: {
          profissionalSaudeId: profissional?.id,
        },
      },
    },
  );

  useEffect(() => {
    if (!profissional?.id) return;

    loadNoticias();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profissional]);

  useEffect(() => {
    if (noticiasQuery.loading) return;

    setNoticias(noticiasQuery.data?.noticias?.content || []);
    setTotalElements(noticiasQuery.data?.noticias?.totalElements || 0);
  }, [noticiasQuery]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Grid container sx={styles.container}>
      <Grid container sx={styles.content}>
        <Grid container item xs={12} gap={2} sx={styles.dadosContainer}>
          <Button
            id="outlined"
            sx={{width: 'fit-content'}}
            onClick={() => navigate('/noticias/cadastrar')}>
            ESCREVER NOVA NOTÍCIA
          </Button>
        </Grid>
        <Grid container item xs={12} paddingY={2}>
          <Grid container id="table-container">
            <Table id="table">
              <TableHead id="table-head">
                <TableRow>
                  <TableCell sx={{width: '182px'}}>Data</TableCell>
                  <TableCell>Titulo</TableCell>
                  <TableCell sx={{width: '250px'}} align="center">
                    Status
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody id="table-body">
                {noticias.map((n) => (
                  <TableRow key={n.id} id="table-row">
                    <TableCell>{Data.dataFormat(n.dataCadastro)}</TableCell>
                    <TableCell>{n.titulo}</TableCell>
                    <TableCell align="right">
                      {isMobile && (
                        <IconButton
                          id="icon-outlined"
                          title="VER NOTÍCIA"
                          onClick={() => navigate(`/noticias/editar/${n.id}`)}>
                          <Visibility />
                        </IconButton>
                      )}
                      {!isMobile && (
                        <Button
                          id="outlined"
                          onClick={() => navigate(`/noticias/editar/${n.id}`)}>
                          VER NOTÍCIA
                        </Button>
                      )}
                      <IconButton
                        id="icon-outlined"
                        sx={styles.statusButton}
                        className={n.situacao?.toLowerCase()}>
                        <StatusIcon status={n.situacao} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow id="table-row-footer">
                  <TableCell id="table-pagination" colSpan={3}>
                    <TablePagination
                      component="div"
                      count={totalElements}
                      rowsPerPageOptions={[]}
                      rowsPerPage={20}
                      page={page}
                      labelDisplayedRows={({from, to, count}) =>
                        `${from}-${to} de ${count}`
                      }
                      onPageChange={handleChangePage}
                    />
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

const styles = {
  container: {
    backgroundColor: (theme) => theme.palette.backgroundColor,
    justifyContent: 'center',
    minHeight: '100vh',
  },
  content: {
    maxWidth: 1200,
    alignContent: 'flex-start',
    paddingX: `16px`,

    '@media (max-width: 900px)': {
      flexWrap: 'nowrap',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  dadosContainer: {
    position: 'relative',
    justifyContent: 'flex-end',

    '@media (max-width: 900px)': {
      flexDirection: 'column',
    },
  },
  avatarContainer: {
    flexDirection: 'column',
    flexGrow: '0 !important',
  },
  avatar: {
    height: '220px',
    width: '200px',
    borderRadius: '6px',
    flexDirection: 'column',
    backgroundColor: 'transparent',
    border: (theme) => `1px solid ${theme.palette.primary.main}`,
    cursor: 'pointer',

    '@media (max-width: 900px)': {
      height: '80px',
      width: '80px',
    },

    svg: {
      color: (theme) => theme.palette.primary.main,
      width: '40px',
      height: '40px',
      maxWidth: '50%',
      maxHeight: '50%',
    },
  },
  imagemPerfil: {
    border: '2px solid #fff',
  },
  fotoPerfilText: {
    color: (theme) => theme.palette.primary.main,
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: '700',
    width: '70%',
    marginTop: 1,

    '@media (max-width: 900px)': {
      display: 'none',
    },
  },
  title: {
    color: '#FFF',
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: 600,
  },
  sectionTitle: {
    color: (theme) => theme.palette.primary.main,
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: 600,
    paddingBottom: 2,
  },
  text: {
    color: '#FFF',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'break-spaces',

    svg: {
      height: '18px',
      width: '18px',
      marginRight: 1,
    },
  },
  statusButton: {
    marginLeft: '16px',
    cursor: 'default',
    '&.aprovado': {
      color: '#3FEA4C',
      borderColor: '#3FEA4C',
    },
    '&.reprovado': {
      color: '#F84C4C',
      borderColor: '#F84C4C',
    },
    '&.pendente': {
      color: '#9E9E9E',
      borderColor: '#9E9E9E',
    },
  },
  editarDadosContainer: {
    position: 'absolute',
    width: '42px',
    height: '42px',
    right: 0,
  },
  editarButton: {
    padding: 1,
  },
};
