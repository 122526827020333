import {Typography} from '@mui/material';
import React from 'react';
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {getIsMobile} from '../utils/screen';

export const toastNotification = ({
  title,
  message,
  autoClose = 3000,
  ...props
}) => {
  return toast(
    <div>
      {title && <Typography sx={styles.title}>{title}</Typography>}
      <Typography sx={styles.message}>
        {message?.replace('GraphQL error:', '')}
      </Typography>
    </div>,
    {
      autoClose,
      hideProgressBar: true,
      theme: 'dark',
      position: getIsMobile() ? 'top-center' : 'top-right',
      pauseOnFocusLoss: false,
      ...props,
    },
  );
};

const styles = {
  title: {
    color: '#FFF',
    fontFamily: 'Now',
    fontSize: '16px',
    fontWeight: '500',
  },
  message: {
    color: '#E3E3E3',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: '400',
  },
};
