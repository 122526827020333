import {useQuery} from '@apollo/client';
import {Edit, MedicalInformation} from '@mui/icons-material';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import ButtonResponsive from '../../../components/buttons/button-responsive';
import Filtros from '../../../components/filtro/filtros';
import Input from '../../../components/input/input';
import {FIND_ALL_AGENDAMENTOS_BY_PROFISSIONAL} from '../../../graphql/queries';
import Data from '../../../utils/data';
import AgendamentoModal from './agendamento-modal';

const getSituacoes = (situacao) => {
  switch (situacao) {
    case 'pendentes':
      return [
        'AGUARDANDO_AGENDAMENTO',
        'AGUARDANDO_AVALIACAO',
        'AVALIACAO_EM_ANDAMENTO',
      ];
    case 'realizadas':
      return ['CONCLUIDO', 'CANCELADO'];
    default:
      return undefined;
  }
};

export default function ConsultaAvaliacoes({situacao}) {
  const navigate = useNavigate();

  const [agendamentos, setAgendamentos] = useState([]);
  const [searchDTO, setSearchDTO] = useState({});
  const [page, setPage] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [selecionado, setSelecionado] = useState(undefined);

  const agendamentosQuery = useQuery(FIND_ALL_AGENDAMENTOS_BY_PROFISSIONAL, {
    variables: {
      pageable: {
        pageNumber: page,
        pageSize: 20,
        orderBy: [
          {
            campo: 'data',
            ordem: 'DESC',
          },
          {
            campo: 'hora',
            ordem: 'DESC',
          },
        ],
      },
      searchDTO: {
        search: searchDTO.search || undefined,
        dataInicial: Data.dataFormat(searchDTO.data, 'YYYY-MM-DD') || undefined,
        horaInicial: searchDTO.hora || undefined,
        situacoes: getSituacoes(situacao),
      },
    },
  });

  useEffect(() => {
    if (agendamentosQuery.loading) return;

    setAgendamentos(agendamentosQuery.data?.agendamentos?.content || []);
    setTotalElements(agendamentosQuery.data?.agendamentos?.totalElements || 0);
  }, [agendamentosQuery]);

  useEffect(() => {
    if (!selecionado) return;

    setOpenModal(true);
  }, [selecionado]);

  useEffect(() => {
    setPage(0);
  }, [searchDTO]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Grid container sx={styles.container}>
      <Grid container sx={styles.content}>
        <Grid container justifyContent="center" paddingY={2}>
          <Filtros>
            <Grid item md={6} xs={12}>
              <Input
                name="busca"
                placeholder="Pesquisar por nome"
                type="text"
                autoComplete="off"
                value={searchDTO.search || ''}
                onChange={({target}) =>
                  setSearchDTO((f) => ({...f, search: target?.value}))
                }
              />
            </Grid>
            <Grid item md={3} xs={6}>
              <Input
                name="data"
                placeholder="DD/MM/YYYY"
                type="date"
                value={searchDTO.data || ''}
                onChange={({target}) =>
                  setSearchDTO((f) => ({...f, data: target?.value}))
                }
              />
            </Grid>
            <Grid item md={3} xs={6}>
              <Input
                name="hora"
                placeholder="HH:MM"
                type="time"
                value={searchDTO.hora || ''}
                onChange={({target}) =>
                  setSearchDTO((f) => ({...f, hora: target?.value}))
                }
              />
            </Grid>
          </Filtros>
        </Grid>
        <Grid container>
          <TableContainer id="table-container">
            <Table id="table">
              <TableHead id="table-head">
                <TableRow>
                  <TableCell>Atleta</TableCell>
                  <TableCell>Agendamento</TableCell>
                  <TableCell>Modelo</TableCell>
                  <TableCell>Perfil</TableCell>
                  <TableCell
                    sx={[
                      {width: '182px'},
                      situacao === 'realizadas' && {display: 'none'},
                    ]}
                    align="center">
                    Ações
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody id="table-body">
                {agendamentos.map((a) => (
                  <TableRow key={a.id} id="table-row">
                    <TableCell>{a.atleta?.nome}</TableCell>
                    <TableCell>{`${Data.dataFormat(a.data)} ${
                      a.hora || ''
                    }`}</TableCell>
                    <TableCell>{a?.modelo?.nome}</TableCell>
                    <TableCell>
                      <Button
                        variant="text"
                        sx={styles.perfilButton}
                        onClick={() => navigate(`/atleta/${a.atleta.id}`)}>
                        VER PERFIL
                      </Button>
                    </TableCell>
                    <TableCell
                      align="center"
                      sx={situacao === 'realizadas' && {display: 'none'}}>
                      <Box
                        display="flex"
                        flexDirection="row"
                        justifyContent="flex-end"
                        gap={1}>
                        <ButtonResponsive
                          id="outlined"
                          value="Avaliar"
                          onClick={() => navigate(`/avaliacao/${a.id}`)}
                          sx={{
                            visibility: a?.modelo?.id ? 'visible' : 'hidden',
                          }}
                          icon={<MedicalInformation />}
                        />
                        <IconButton
                          id="icon-outlined"
                          onClick={() => setSelecionado(a)}
                          title="Editar">
                          <Edit />
                        </IconButton>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow id="table-row-footer">
                  <TableCell id="table-pagination" colSpan={5}>
                    <TablePagination
                      component="div"
                      count={totalElements}
                      rowsPerPageOptions={[]}
                      rowsPerPage={20}
                      page={page}
                      labelDisplayedRows={({from, to, count}) =>
                        `${from}-${to} de ${count}`
                      }
                      onPageChange={handleChangePage}
                    />
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
      <AgendamentoModal
        open={openModal}
        agendamento={selecionado}
        onClose={() => {
          setOpenModal(false);
          setSelecionado(undefined);
          agendamentosQuery.refetch();
        }}
      />
    </Grid>
  );
}

const styles = {
  container: {
    backgroundColor: (theme) => theme.palette.backgroundColor,
    justifyContent: 'center',
    minHeight: '100vh',
  },
  content: {
    maxWidth: 1200,
    alignContent: 'flex-start',

    '@media (max-width: 900px)': {
      flexWrap: 'nowrap',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  title: {
    color: '#FFF',
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: 600,
  },
  text: {
    color: '#FFF',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'break-spaces',

    svg: {
      height: '18px',
      width: '18px',
      marginRight: 1,
    },
  },
  perfilButton: {
    color: '#fff',
    borderRadius: '6px',
  },
};
