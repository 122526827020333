import {Visibility, VisibilityOff} from '@mui/icons-material';
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Typography,
} from '@mui/material';
import {useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import * as Yup from 'yup';

import Footer from '../../components/footer';
import Input from '../../components/input/input';
import {toastNotification} from '../../components/toastify';
import ToolbarLogin from '../../components/toolbar/toolbar-login';
import {useAuth} from '../../contexts/auth';

export default function RedefinirSenha() {
  const navigate = useNavigate();
  const location = useLocation();
  const {redefinirSenha, loading} = useAuth();

  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState([]);
  const [showPass, setShowPass] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    redefinirScheme
      .validate(formData, {abortEarly: false})
      .then(handleCadastrar)
      .catch((errors) => {
        const camposComErro = {};
        errors?.inner?.forEach((error) => {
          camposComErro[error.path] = error.message;
        });
        setErrors(camposComErro);
      });
  };

  const handleCadastrar = () => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');

    redefinirSenha(token, formData.senha)
      .then(() => navigate('/login'))
      .catch((error) => toastNotification({message: error?.message}));
  };

  return (
    <Grid container sx={styles.container}>
      <ToolbarLogin />
      <Grid container sx={styles.content} justifyContent={'space-between'}>
        <Grid container item sx={styles.titleContainer}>
          <Typography sx={styles.title}>REDEFINIR SENHA</Typography>
          <Typography sx={styles.subtitle}>Digite a nova senha</Typography>
        </Grid>
        <Grid
          component={'form'}
          container
          item
          sx={styles.formContainer}
          gap={2}>
          <Input
            label={'Senha'}
            name="senha"
            type={showPass ? 'text' : 'password'}
            placeholder={'***********'}
            onChange={({target}) =>
              setFormData((f) => ({...f, senha: target?.value}))
            }
            autoComplete="new-password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPass(!showPass)}
                    edge="end">
                    {showPass ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            errors={errors}
          />
          <Input
            label={'Confirme sua senha'}
            name="senhaConfirmacao"
            type={showPass ? 'text' : 'password'}
            placeholder={'***********'}
            onChange={({target}) =>
              setFormData((f) => ({...f, senhaConfirmacao: target?.value}))
            }
            autoComplete="new-password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPass(!showPass)}
                    edge="end">
                    {showPass ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            errors={errors}
          />
          <Button
            id="primary"
            sx={styles.button}
            onClick={handleSubmit}
            endIcon={loading && <CircularProgress size={16} color="inherit" />}>
            ENVIAR
          </Button>
        </Grid>
      </Grid>
      <Footer />
    </Grid>
  );
}

const redefinirScheme = Yup.object().shape({
  senha: Yup.string()
    .required('Campo obrigatório')
    .test(
      'senha-forte',
      'Precisa ter no mínimo seis caracteres sendo ao menos um número e uma letra',
      function (value) {
        const regex = /^(?=.*[0-9])(?=.*[a-zA-Z]).{6,}$/;
        return regex.test(value);
      },
    ),
  senhaConfirmacao: Yup.string()
    .required('Campo obrigatório')
    .test('senha-coincide', 'As senhas não coincidem', function (value) {
      return value === this.parent.senha;
    }),
});

const styles = {
  container: {
    flex: 1,
    justifyContent: 'center',
    paddingTop: (theme) => theme.toolbarHeight,
    backgroundColor: (theme) => theme.palette.blackSecundary,
  },
  content: {
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    padding: 3,
    paddingBottom: 9,
    maxWidth: 1200,
  },
  titleContainer: {
    flexGrow: 0,
    justifyContent: 'center',
    padding: '24px 0',
    maxWidth: '420px',

    '@media (max-width: 1200px)': {
      padding: '16px 0',
    },
  },
  title: {
    color: '#FFF',
    textAlign: 'center',
    fontFamily: 'Now',
    fontSize: '30px',
    fontWeight: '700',
    textTransform: 'uppercase',

    '@media (max-width: 1200px)': {
      fontSize: '20px',
    },
  },
  subtitle: {
    color: '#E3E3E3',
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: '500',

    '@media (max-width: 1200px)': {
      fontSize: '14px',
    },
  },
  formContainer: {
    maxWidth: '420px',
    flexDirection: 'column',
    width: '100%',
    paddingBottom: '16px',
  },
  button: {
    margin: '16px 0',
  },
};
