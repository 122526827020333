import {Switch} from '@mui/material';
import React from 'react';

const SwitchInput = ({checked = false, onChange, sx = {}, ...props}) => {
  return (
    <Switch
      sx={[styles.switch, sx]}
      checked={checked}
      onChange={onChange}
      color={'primary'}
      {...props}
    />
  );
};

const styles = {
  switch: {
    padding: '8px 8px',
    margin: '-8px -10px',
    width: '60px',

    '.MuiSwitch-thumb': {
      width: 18,
      height: 18,
      color: '#fff',
      border: `2px solid #868687`,
      boxShadow: 'none',
    },
    '.MuiSwitch-track': {
      height: 20,
      width: '41px',
      borderRadius: 11,
      opacity: 1,
      backgroundColor: '#868687 !important',
      transition: (theme) =>
        theme.transitions.create(['background-color', 'border']),
    },
    '.Mui-checked': {
      '.MuiSwitch-thumb': {
        border: (theme) => `2px solid ${theme.palette.primary.main} !important`,
      },
    },
    '.Mui-checked+': {
      '.MuiSwitch-track': {
        opacity: '1 !important',
        backgroundColor: (theme) => `${theme.palette.primary.main} !important`,
      },
    },
    '.Mui-disabled': {
      opacity: 0.3,
    },
  },
};

export default SwitchInput;
