import {Visibility, VisibilityOff} from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Typography,
} from '@mui/material';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';

import AtletaImage from '../../assets/images/login-imagens.png';
import Footer from '../../components/footer';
import Input from '../../components/input/input';
import {toastNotification} from '../../components/toastify';
import ToolbarLogin from '../../components/toolbar/toolbar-login';
import {useAuth} from '../../contexts/auth';

export default function Login() {
  const navigate = useNavigate();

  const {login, loading} = useAuth();

  const [formData, setFormData] = useState({});
  const [showPass, setShowPass] = useState(false);

  const handleLogin = () => {
    login(formData.email?.trim(), formData.senha?.trim())
      .then(() => navigate('/perfil'))
      .catch((error) => toastNotification({message: error?.message}));
  };

  return (
    <Grid container sx={styles.container}>
      <ToolbarLogin />
      <Grid container sx={styles.content} justifyContent={'space-between'}>
        <Grid container item md={5.5} sm={0} sx={styles.image}>
          <Box component={'img'} sx={styles.images} src={AtletaImage} />
        </Grid>
        <Grid container item md={5.5} sm={12} sx={styles.form} gap={2}>
          <Grid item>
            <Typography sx={styles.title}>Acesse sua conta</Typography>
          </Grid>
          <Grid
            component={'form'}
            display={'flex'}
            flexDirection={'column'}
            item
            gap={2}
            padding={'0 32px'}>
            <Input
              label={'E-mail'}
              type="email"
              autoComplete="email"
              placeholder={'Digite seu e-mail'}
              onChange={({target}) =>
                setFormData((f) => ({...f, email: target?.value}))
              }
            />
            <Input
              label={'Senha'}
              type={showPass ? 'text' : 'password'}
              placeholder={'***********'}
              onChange={({target}) =>
                setFormData((f) => ({...f, senha: target?.value}))
              }
              autoComplete="current-password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPass(!showPass)}
                      edge="end">
                      {showPass ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onKeyDown={(e) => e.key === 'Enter' && handleLogin(e)}
            />
            <Link href="#/recuperar-senha" sx={styles.recuperarSenha}>
              ESQUECEU SUA SENHA?
            </Link>
          </Grid>
          <Grid item padding={'32px'}>
            <Button
              id="primary"
              onClick={handleLogin}
              fullWidth
              endIcon={
                loading && <CircularProgress size={16} color="inherit" />
              }>
              ENTRAR
            </Button>
          </Grid>
          <Grid item sx={styles.cadastroContainer}>
            <Typography className="title">AINDA NÃO TEM UMA CONTA?</Typography>
            <Link href="#/cadastro" sx={styles.recuperarSenha}>
              FAÇA SEU CADASTRO!
            </Link>
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </Grid>
  );
}

const styles = {
  container: {
    flex: 1,
    justifyContent: 'center',
    paddingTop: (theme) => theme.toolbarHeight,
    backgroundColor: (theme) => theme.palette.backgroundColor,
  },
  content: {
    backgroundColor: (theme) => theme.palette.blackSecundary,
    height: (theme) => `calc(100vh - ${theme.toolbarHeight})`,
  },
  image: {
    justifyContent: 'center',
    height: '100%',
    img: {
      height: 'inherit',
    },

    '@media (max-width: 900px)': {
      display: 'none',
    },
  },
  form: {
    padding: '0 16px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
  },
  title: {
    textAlign: 'center',
    fontSize: '48px',
    lineHeight: '42px',
    fontWeight: '700',
    color: '#fff',
    padding: '16px 0',
  },
  recuperarSenha: {
    fontSize: '14px',
    fontWeight: '700',
    textTransform: 'uppercase',
  },
  cadastroContainer: {
    textAlign: 'center',
    color: '#fff',
    '.title': {
      fontSize: '14px',
      fontWeight: '500',
      textTransform: 'uppercase',
    },
  },
};
