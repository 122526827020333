import { Close } from '@mui/icons-material';
import { Grid, IconButton, Modal, Slide, Typography } from '@mui/material';

export default function ValoresReferenciaModal({
  valoresReferencia = [],
  open,
  onClose,
}) {
  const getLuminance = (hexColor) => {
    const rgb = parseInt(hexColor.slice(1), 16);
    const r = (rgb >> 16) & 0xff;
    const g = (rgb >> 8) & 0xff;
    const b = (rgb >> 0) & 0xff;

    return 0.2126 * r + 0.7152 * g + 0.0722 * b;
  };

  const getBackgroundColor = (textColor) => {
    const luminance = getLuminance(textColor);
  
    if (luminance > 225) return '#333';
    if (luminance > 100) return '#999';
    return '#FFF';
  };

  return (
    <Modal open={open} onClose={onClose} sx={styles.modal}>
      <Slide in={open} appear>
        <Grid
          container
          className="styled-scroll-transparent-desktop"
          sx={styles.container}>
          <Grid container item sx={styles.header}>
            <Typography sx={styles.title}>Valores de referência</Typography>
            <IconButton sx={styles.closeIcon} onClick={onClose}>
              <Close />
            </IconButton>
          </Grid>
          <Grid container sx={styles.content}>
            {valoresReferencia.map((v) => (
              <Grid container item xs={12} sx={{...styles.valorContainer, backgroundColor: getBackgroundColor(v.corHex)}}>
                <Grid item xs={6}>
                  <Typography sx={{color: v.corHex}}>{v.nome}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography sx={{color: getLuminance(v.corHex) > 100 ? "#FFF": "#000"}} >{`Valor min.: ${v.valorMin} - Valor max.: ${v.valorMax}`}</Typography>
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Slide>
    </Modal>
  );
}

const styles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    position: 'relative',
    maxHeight: '90vh',
    width: '50%',
    borderRadius: '6px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    padding: 3,
    paddingTop: 0,
    zIndex: (theme) => theme.zIndex.modal,
    background: (theme) => theme.palette.blackSecundary,
    boxShadow: '0px 0px 134.60001px 0px rgba(255, 204, 41, 0.25)',
    outline: 'none',
    overflowY: 'auto',

    '@media (max-width: 900px)': {
      width: '90%',
    },
  },
  header: {
    flexGrow: 0,
    padding: 2,
    justifyContent: 'space-between',
    svg: {
      color: '#fff',
    },
  },
  closeIcon: {
    width: '32px',
    height: '32px',
  },
  title: {
    color: '#FFF',
    textAlign: 'center',
    fontFamily: 'Now',
    fontSize: '24px',
    fontWeight: '700',
    textTransform: 'uppercase',

    '@media (max-width: 1200px)': {
      fontSize: '20px',
    },
  },
  content: {
    flexDirection: 'row',
    width: '100%',
    padding: '16px',
  },
  valorContainer: {
    backgroundColor: '#fff',
    borderRadius: '6px',
    marginY: 1,
    padding: 1,
  },
};
