import {useQuery} from '@apollo/client';
import {Button, Grid, Typography} from '@mui/material';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {PeopleCarousel} from '../../components/carousel';
import {FIND_ALL_ATLETAS} from '../../graphql/queries';

export default function HomeMain() {
  const navigate = useNavigate();

  const [atletas, setAtletas] = useState([]);

  const atletaQuery = useQuery(FIND_ALL_ATLETAS, {
    variables: {
      pageable: {
        pageNumber: 0,
        pageSize: 20,
        sortField: 'dataCadastro',
        sortDir: 'DESC',
      },
      searchDTO: {
        situacao: 'COMPLETO',
      },
    },
  });

  useEffect(() => {
    if (atletaQuery.loading) return;

    setAtletas(atletaQuery.data?.atletas?.content || []);
  }, [atletaQuery]);

  return (
    <Grid container justifyContent={'center'}>
      <Grid container item justifyContent={'center'}>
        <Typography sx={styles.title}>SPORTS SHOWCASE</Typography>
      </Grid>
      <Grid container item justifyContent={'center'} padding={'0px 16px'}>
        <Typography sx={styles.subtitle}>
          Wolf Pro Link é a plataforma que conecta atletas profissionais e
          amadores ao cenário esportivo mundial
        </Typography>
      </Grid>
      <Grid container item justifyContent={'center'} mt={2}>
        <Button
          id="primary"
          sx={styles.enterButton}
          onClick={() => navigate('/login')}>
          ENTRAR NA COMUNIDADE
        </Button>
      </Grid>
      {atletas.length >= 10 && (
        <Grid container margin={'64px 0'}>
          <PeopleCarousel content={atletas} />
        </Grid>
      )}
    </Grid>
  );
}

const styles = {
  title: {
    color: (theme) => theme.palette.white,
    fontSize: 56,
    fontWeight: '700',
    textTransform: 'uppercase',
    textAlign: 'center',
    width: 545,
  },
  subtitle: {
    color: (theme) => theme.palette.secundaryGray,
    fontSize: 20,
    fontFamily: 'Inter',
    fontWeight: '500',
    textAlign: 'center',
    width: 600,
  },
  enterButton: {
    width: 261,
  },
};
