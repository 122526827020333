import { Panorama } from '@mui/icons-material';
import { Avatar, Box, Grid, Typography } from '@mui/material';
import { convertFromRaw } from 'draft-js';
import { useNavigate } from 'react-router-dom';

import Data from '../utils/data';
import { getImageUrl } from '../utils/file';

export default function NewsCard({item = {}}) {
  const navigate = useNavigate();

  return (
    <Grid
      container
      sx={styles.container}
      onClick={() => navigate(`/noticias/${item.id}`)}>
      <Grid container sx={styles.banner}>
        {item.banner && <Box component="img" src={getImageUrl(item.banner)} />}
        {!item.banner && <Panorama sx={styles.iconCamera} />}
      </Grid>
      <Grid container sx={styles.content}>
        <Grid item xs={12}>
          <Typography sx={styles.sport}>{item.esporte}</Typography>
          <Typography title={item.titulo} sx={styles.title}>
            {item.titulo}
          </Typography>
          <Typography sx={styles.resume}>
            {convertFromRaw(JSON.parse(item.texto)).getPlainText()}
          </Typography>
        </Grid>
        <Grid container pt={1}>
          <Grid item xs={2}>
            <Avatar sx={styles.avatar} />
          </Grid>
          <Grid item xs={10} pl={2}>
            <Typography sx={styles.name}>{item.jornalista?.nome}</Typography>
            <Typography sx={styles.date}>
              {`${Data.dataFormat(item.ultimaAlteracao, 'DD MMM YYYY')} ${
                item.tempoLeitura ? `• ${item.tempoLeitura} min de leitura` : ''
              }`}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: 410,
    width: 300,
    maxWidth: '95%',
    backgroundColor: '#fff',
    cursor: 'pointer',
  },
  banner: {
    height: 200,
    width: '100%',
    backgroundColor: '#dbdbdb',
    justifyContent: 'center',
    alignItems: 'center',
    img: {
      objectFit: 'cover',
      height: 'inherit',
      width: 'inherit',
    }
  },
  iconCamera: {
    color: '#bababa',
    height: 100,
    width: 100,
  },
  content: {
    flex: 1,
    height: 'auto',
    alignContent: 'space-between',
    padding: 3,
  },
  sport: {
    fontWeight: '700',
    fontSize: '14px',
  },
  title: {
    fontWeight: '700',
    fontSize: '22px',
    textWrap: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  resume: {
    fontWeight: '400',
    fontFamily: 'Inter',
    fontSize: '14px',
    display: '-webkit-box',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  },
  avatar: {
    width: '48px',
    height: '48px',
  },
  name: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: '600',
  },
  date: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: '400',
    textWrap: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};
