import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  filtros: {
    perfil: 'ATLETAS',
  },
};

const acaoSetFiltrosBusca = (state, action) => {
  state.filtros = action.payload;
};

const resumeSlice = createSlice({
  name: 'busca',
  initialState,
  reducers: {
    setFiltrosBusca: acaoSetFiltrosBusca,
  },
});

export const {actions, reducer} = resumeSlice;
export const {setFiltrosBusca} = actions;
