import {useLazyQuery, useMutation, useQuery} from '@apollo/client';
import {
  Cancel,
  CheckCircle,
  Edit,
  Error,
  HourglassEmpty,
  PhotoCamera,
  Visibility,
} from '@mui/icons-material';
import {
  Avatar,
  Button,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {NewsCarousel} from '../../components/carousel';
import ImagemCrop from '../../components/crop-image';
import {toastNotification} from '../../components/toastify';
import {useAuth} from '../../contexts/auth';
import {UPDATE_JORNALISTA_FOTO_PERFIL} from '../../graphql/mutation';
import {
  FIND_ALL_NOTICIAS,
  FIND_ALL_NOTICIAS_JORNALISTA,
} from '../../graphql/queries';
import Data from '../../utils/data';
import {getImageUrl, uploadNestor} from '../../utils/file';
import EditarPerfilJornalista from './modal/editar-perfil';

const StatusIcon = ({status}) => {
  switch (status) {
    case 'APROVADO':
      return <CheckCircle titleAccess="Aprovado" />;
    case 'REPROVADO':
      return <Cancel titleAccess="Reprovado" />;
    case 'PENDENTE':
      return <HourglassEmpty titleAccess="Em análise" />;
    case 'ESCREVENDO':
      return <Edit titleAccess="Escrevendo" />;
    default:
      return <Error />;
  }
};

export default function JornalistaPerfil() {
  const navigate = useNavigate();
  const {entidadeLogada: jornalista, refetchEntidade} = useAuth();
  const {isMobile} = useSelector((state) => state.App);

  const [noticias, setNoticias] = useState([]);
  const [noticiasAprovadas, setNoticiasAprovadas] = useState([]);
  const [page, setPage] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [openPerfil, setOpenPerfil] = useState(false);

  const [loadNoticias, noticiasQuery] = useLazyQuery(
    FIND_ALL_NOTICIAS_JORNALISTA,
    {
      variables: {
        pageable: {
          pageNumber: page,
          pageSize: 20,
          sortField: 'dataCadastro',
          sortDir: 'DESC',
        },
        searchDTO: {},
      },
    },
  );

  const noticiasAprovadasQuery = useQuery(FIND_ALL_NOTICIAS, {
    variables: {
      pageable: {
        pageNumber: 0,
        pageSize: 20,
        sortField: 'dataCadastro',
        sortDir: 'DESC',
      },
      searchDTO: {
        situacao: 'APROVADO',
      },
    },
  });

  const [saveJornalistaPerfil] = useMutation(UPDATE_JORNALISTA_FOTO_PERFIL);

  useEffect(() => {
    if (!jornalista?.id) return;

    loadNoticias();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jornalista]);

  useEffect(() => {
    if (noticiasQuery.loading) return;

    setNoticias(noticiasQuery.data?.noticias?.content || []);
    setTotalElements(noticiasQuery.data?.noticias?.totalElements || 0);
  }, [noticiasQuery]);

  useEffect(() => {
    if (noticiasAprovadasQuery.loading) return;

    setNoticiasAprovadas(noticiasAprovadasQuery.data?.noticias?.content || []);
  }, [noticiasAprovadasQuery]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleSavePerfil = async (file) => {
    if (!file) return;

    let response = null;
    try {
      response = await uploadNestor(file);
    } catch (error) {
      console.error('Erro upload', error);
    }

    saveJornalistaPerfil({
      variables: {
        jornalista: {
          id: jornalista.id,
          fotoPerfil: response?.data?.id,
        },
      },
    })
      .then(() => {
        setOpenPerfil(false);
        toastNotification({
          message: 'Imagem de perfil atualizada!',
          type: 'success',
        });
        refetchEntidade();
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      );
  };

  return (
    <Grid container sx={styles.container}>
      <Grid container sx={styles.content} padding={2}>
        <Grid container item xs={12} gap={2} sx={styles.dadosContainer}>
          <Grid container item gap={1} xs sx={styles.avatarContainer}>
            <Avatar
              src={getImageUrl(jornalista?.fotoPerfil)}
              sx={styles.avatar}
              onClick={() => setOpenPerfil(true)}>
              <PhotoCamera />
              <Typography sx={styles.fotoPerfilText}>
                ADICIONAR FOTO DE PERFIL
              </Typography>
            </Avatar>
            <Grid container item sx={styles.editarDadosContainer} gap={1} xs>
              <IconButton
                id="icon-outlined"
                sx={styles.editarButton}
                onClick={() => setOpenModal(true)}>
                <Edit />
              </IconButton>
            </Grid>
          </Grid>
          <Grid container item flex={1} gap={1} direction={'column'} xs>
            <Typography id="modalidade-tag" className="jornalista">
              Jornalista
            </Typography>
            <Typography sx={styles.title}>{jornalista?.nome}</Typography>
            <Typography sx={styles.text}>
              Perfil criado em: {Data.dataFormat(jornalista?.dataCadastro)}
            </Typography>
            <Button
              id="outlined"
              sx={{width: 'fit-content'}}
              onClick={() => navigate('/noticias/cadastrar')}>
              ESCREVER NOVA NOTÍCIA
            </Button>
          </Grid>
        </Grid>
        <Grid container item xs={12} paddingY={4}>
          <Typography sx={styles.sectionTitle}>RESUMO</Typography>
          <Grid container id="table-container">
            <Table id="table">
              <TableHead id="table-head">
                <TableRow>
                  <TableCell sx={{width: '182px'}}>Data</TableCell>
                  <TableCell>Titulo</TableCell>
                  <TableCell sx={{width: '250px'}} align="center">
                    Status
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody id="table-body">
                {noticias.map((n) => (
                  <TableRow key={n.id} id="table-row">
                    <TableCell>{Data.dataFormat(n.dataCadastro)}</TableCell>
                    <TableCell>{n.titulo}</TableCell>
                    <TableCell align="right">
                      {isMobile && (
                        <IconButton
                          id="icon-outlined"
                          title="VER NOTÍCIA"
                          onClick={() => navigate(`/noticias/editar/${n.id}`)}>
                          <Visibility />
                        </IconButton>
                      )}
                      {!isMobile && (
                        <Button
                          id="outlined"
                          onClick={() => navigate(`/noticias/editar/${n.id}`)}>
                          VER NOTÍCIA
                        </Button>
                      )}
                      <IconButton
                        id="icon-outlined"
                        sx={styles.statusButton}
                        className={n.situacao?.toLowerCase()}>
                        <StatusIcon status={n.situacao} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableFooter>
                <TableRow id="table-row-footer">
                  <TableCell id="table-pagination" colSpan={3}>
                    <TablePagination
                      component="div"
                      count={totalElements}
                      rowsPerPageOptions={[]}
                      rowsPerPage={20}
                      page={page}
                      labelDisplayedRows={({from, to, count}) =>
                        `${from}-${to} de ${count}`
                      }
                      onPageChange={handleChangePage}
                    />
                  </TableCell>
                </TableRow>
              </TableFooter>
            </Table>
          </Grid>
        </Grid>
        <Grid container item xs={12} paddingY={4}>
          <Typography sx={styles.sectionTitle}>NOTÍCIAS</Typography>
          <Grid container>
            <NewsCarousel content={noticiasAprovadas} />
          </Grid>
        </Grid>
      </Grid>
      <ImagemCrop
        open={openPerfil}
        imagemId={jornalista?.fotoPerfil}
        onClose={() => setOpenPerfil(false)}
        onUploadImage={handleSavePerfil}
        aspect={1}
      />
      <EditarPerfilJornalista
        open={openModal}
        onClose={() => setOpenModal(false)}
        jornalistaId={jornalista?.id}
      />
    </Grid>
  );
}

const styles = {
  container: {
    backgroundColor: (theme) => theme.palette.backgroundColor,
    paddingTop: (theme) => `calc(${theme.toolbarHeight} + 16px)`,
    justifyContent: 'center',
    minHeight: '100vh',
  },
  content: {
    maxWidth: 1200,
    alignContent: 'flex-start',
    paddingX: `16px`,

    '@media (max-width: 900px)': {
      flexWrap: 'nowrap',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  dadosContainer: {
    position: 'relative',
    alignSelf: 'flex-start',

    '@media (max-width: 900px)': {
      flexDirection: 'column',
    },
  },
  avatarContainer: {
    flexDirection: 'column',
    flexGrow: '0 !important',
  },
  avatar: {
    height: '220px',
    width: '200px',
    borderRadius: '6px',
    flexDirection: 'column',
    backgroundColor: 'transparent',
    border: (theme) => `1px solid ${theme.palette.primary.main}`,
    cursor: 'pointer',

    '@media (max-width: 900px)': {
      height: '80px',
      width: '80px',
    },

    svg: {
      color: (theme) => theme.palette.primary.main,
      width: '40px',
      height: '40px',
      maxWidth: '50%',
      maxHeight: '50%',
    },
  },
  fotoPerfilText: {
    color: (theme) => theme.palette.primary.main,
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: '700',
    width: '70%',
    marginTop: 1,

    '@media (max-width: 900px)': {
      display: 'none',
    },
  },
  title: {
    color: '#FFF',
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: 600,
  },
  sectionTitle: {
    color: (theme) => theme.palette.primary.main,
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: 600,
    paddingBottom: 2,
  },
  text: {
    color: '#FFF',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'break-spaces',

    svg: {
      height: '18px',
      width: '18px',
      marginRight: 1,
    },
  },
  statusButton: {
    marginLeft: '16px',
    cursor: 'default',
    '&.aprovado': {
      color: '#3FEA4C',
      borderColor: '#3FEA4C',
    },
    '&.reprovado': {
      color: '#F84C4C',
      borderColor: '#F84C4C',
    },
    '&.pendente': {
      color: '#9E9E9E',
      borderColor: '#9E9E9E',
    },
  },
  editarDadosContainer: {
    position: 'absolute',
    width: '42px',
    height: '42px',
    right: 0,
  },
  editarButton: {
    padding: 1,
  },
};
