import React from 'react';

import {FIND_ALL_FORMULARIO_GRUPOS as query} from '../../../graphql/queries';
import {SelectBase} from './select-apollo';

const getVariables = ({pageNumber, search = '', pageSize = 20}) => {
  return {
    pageableDTO: {pageNumber, pageSize, sortField: 'nome', sortDir: 'ASC'},
    searchDTO: {search},
  };
};

export const SelectFormularioGrupo = (props) => {
  return (
    <SelectBase
      responseObjectName="grupos"
      labelProp="nome"
      query={query}
      getVariables={getVariables}
      {...props}
    />
  );
};
