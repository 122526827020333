import styled from '@emotion/styled';
import React from 'react';
import Select from 'react-select';
import {AsyncPaginate, withAsyncPaginate} from 'react-select-async-paginate';
import Creatable from 'react-select/creatable';

const CreatableAsyncPaginate = withAsyncPaginate(Creatable);

const noOptionsMessage = ({inputValue}) => {
  if (typeof inputValue === 'string' && inputValue.length) {
    return 'Nenhum item encontrado';
  }
  return 'Digite para pesquisar';
};

const SelectBase = ({
  paginate,
  loadOptions,
  creatable,
  onCreateOption,
  ...props
}) => {
  if (paginate && typeof loadOptions === 'function') {
    if (creatable) {
      return (
        <CreatableAsyncPaginateStyle
          classNamePrefix="react-select"
          loadingMessage={() => 'Carregando...'}
          noOptionsMessage={noOptionsMessage}
          loadOptions={loadOptions}
          onCreateOption={onCreateOption}
          formatCreateLabel={(value) => `Criar "${value}"`}
          {...props}
        />
      );
    }
    return (
      <AsyncPaginateStyled
        classNamePrefix="react-select"
        loadingMessage={() => 'Carregando...'}
        noOptionsMessage={noOptionsMessage}
        loadOptions={loadOptions}
        {...props}
      />
    );
  }

  return (
    <SelectStyled
      classNamePrefix="react-select"
      noOptionsMessage={noOptionsMessage}
      {...props}
    />
  );
};

const style = `
  .react-select__control {
    border-radius: 8px;
    box-shadow: none;
    top: 0px;
    right: 0px;
    left: 0px;
    width: 100%;
    border-width: 1px;
    border-color: transparent;
    &:hover {
      border-color: #212121;
    }
  }
  .react-select__control--is-focused {
    border-color: var(--primary) !important;
  }
  .react-select__control--menu-is-open {
    border-color: var(--primary) !important;

    .react-select__indicator {
      transform: rotate(180deg);
    }
  }

  .react-select__value-container {
    font-size: 14px;
  }
  .react-select__placeholder {
    font-size: 14px;
    color: #afb7c9;
  }
  .react-select__menu-notice {
    font-size: 14px;
    color: #8995b1;
  }
  .react-select__indicator {
    color: #afb7c9;
    transition: transform 0.3s ease-in-out;

    &:hover {
      color: #8995b1;
    }
  }

  .react-select__menu {
    margin-top: 4px;
    margin-bottom: 0;
    border-radius: 8px;
    box-shadow: none;
    border: 1px solid var(--primary);
    border-top: 0;
    position: absolute;
    left: 0px;
    overflow-x: auto;
  }

  .react-select__menu-list {
    padding-top: 0;
    padding-bottom: 0;
  }

  .react-select__option {
    font-size: 14px;
    color: #8995b1;
    &:active {
      background-color: rgba(207, 212, 223, 0.2);
      font-weight: bold;
    }
  }

  .react-select__option--is-selected {
    background-color: rgba(207, 212, 223, 0.2);
    font-weight: bold;
  }

  .react-select__option--is-focused {
    background-color: rgba(207, 212, 223, 0.2);
  }

  .react-select__input {
    & input {
      color: #657496 !important;
    }
  }
`;

const SelectStyled = styled(Select)`
  ${style}
`;

const AsyncPaginateStyled = styled(AsyncPaginate)`
  ${style}
`;

const CreatableAsyncPaginateStyle = styled(CreatableAsyncPaginate)`
  ${style}
`;

export default SelectBase;
