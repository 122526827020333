import {useMutation} from '@apollo/client';
import {Edit, PhotoCamera} from '@mui/icons-material';
import {Avatar, Grid, IconButton, Typography} from '@mui/material';
import {useState} from 'react';

import ImagemCrop from '../../components/crop-image';
import {toastNotification} from '../../components/toastify';
import ToggleOptions from '../../components/toggle-options';
import {useAuth} from '../../contexts/auth';
import {UPDATE_PROFISSIONAL_FOTO_PERFIL} from '../../graphql/mutation';
import {getImageUrl, uploadNestor} from '../../utils/file';
import ConsultaArtigos from './artigos/consulta';
import ConsultaAvaliacoes from './avaliacoes/consulta';
import EditarPerfilProfissional from './modal/editar-perfil';

const menuOptions = [
  {value: 'PENDENTES', label: 'Pendentes'},
  {value: 'FINALIZADOS', label: 'Finalizados'},
  {value: 'ARTIGOS', label: 'Artigos'},
];

export default function ProfissionalPerfil() {
  const {entidadeLogada: profissional, refetchEntidade} = useAuth();

  const [selectedMenu, setSelectedMenu] = useState(menuOptions[0].value);
  const [openModal, setOpenModal] = useState(false);
  const [openPerfil, setOpenPerfil] = useState(false);

  const [saveProfissionalPerfil] = useMutation(UPDATE_PROFISSIONAL_FOTO_PERFIL);

  const handleSavePerfil = async (file) => {
    if (!file) return;

    let response = null;
    try {
      response = await uploadNestor(file);
    } catch (error) {
      console.error('Erro upload', error);
    }

    saveProfissionalPerfil({
      variables: {
        profissional: {
          id: profissional.id,
          fotoPerfil: response?.data?.id,
        },
      },
    })
      .then(() => {
        setOpenPerfil(false);
        toastNotification({
          message: 'Imagem de perfil atualizada!',
          type: 'success',
        });
        refetchEntidade();
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      );
  };

  const renderContent = () => {
    switch (selectedMenu) {
      case menuOptions[0].value:
        return <ConsultaAvaliacoes situacao="pendentes" />;
      case menuOptions[1].value:
        return <ConsultaAvaliacoes situacao="realizadas" />;
      case menuOptions[2].value:
        return <ConsultaArtigos />;
      default:
        return null;
    }
  };

  return (
    <Grid container sx={styles.container}>
      <Grid container sx={styles.content} padding={2}>
        <Grid container item gap={2} sx={styles.dadosContainer}>
          <Grid container item gap={1} xs sx={styles.avatarContainer}>
            <Avatar
              src={getImageUrl(profissional?.fotoPerfil)}
              sx={styles.avatar}
              onClick={() => setOpenPerfil(true)}>
              <PhotoCamera />
              <Typography sx={styles.fotoPerfilText}>ADICIONAR FOTO</Typography>
            </Avatar>
          </Grid>
          <Grid container item flex={1} gap={1} direction={'column'} xs>
            <Typography sx={styles.title}>{profissional?.nome}</Typography>
            <Typography sx={styles.text}>
              {profissional?.especialidades
                ?.map((e) => e.descricao)
                ?.join(' | ')}
            </Typography>
          </Grid>
          <Grid container item sx={styles.editarDadosContainer} gap={1} xs>
            <IconButton
              id="icon-outlined"
              sx={styles.editarButton}
              onClick={() => setOpenModal(true)}>
              <Edit />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container item xs={12} justifyContent={'center'}>
          <ToggleOptions
            options={menuOptions}
            selected={selectedMenu}
            onChange={setSelectedMenu}
          />
        </Grid>
        <Grid container>{renderContent()}</Grid>
      </Grid>
      <ImagemCrop
        open={openPerfil}
        imagemId={profissional?.fotoPerfil}
        onClose={() => setOpenPerfil(false)}
        onUploadImage={handleSavePerfil}
        aspect={1}
      />
      <EditarPerfilProfissional
        open={openModal}
        onClose={() => setOpenModal(false)}
        profissionalId={profissional?.id}
      />
    </Grid>
  );
}

const styles = {
  container: {
    backgroundColor: (theme) => theme.palette.backgroundColor,
    paddingTop: (theme) => `calc(${theme.toolbarHeight} + 16px)`,
    justifyContent: 'center',
    minHeight: '100vh',
  },
  content: {
    maxWidth: 1200,
    alignContent: 'flex-start',
    paddingX: `16px`,

    '@media (max-width: 900px)': {
      flexWrap: 'nowrap',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  dadosContainer: {
    position: 'relative',
    alignSelf: 'flex-start',
    paddingBottom: 4,

    '@media (max-width: 900px)': {
      flexDirection: 'column',
    },
  },
  avatarContainer: {
    flexDirection: 'column',
    flexGrow: '0 !important',
  },
  avatar: {
    height: '220px',
    width: '200px',
    borderRadius: '6px',
    flexDirection: 'column',
    backgroundColor: 'transparent',
    border: (theme) => `1px solid ${theme.palette.primary.main}`,
    cursor: 'pointer',

    '@media (max-width: 900px)': {
      height: '80px',
      width: '80px',
    },

    svg: {
      color: (theme) => theme.palette.primary.main,
      width: '40px',
      height: '40px',
      maxWidth: '50%',
      maxHeight: '50%',
    },
  },
  fotoPerfilText: {
    color: (theme) => theme.palette.primary.main,
    textAlign: 'center',
    fontSize: '10px',
    fontWeight: '500',
    width: '70%',
    marginTop: 1,

    '@media (max-width: 900px)': {
      display: 'none',
    },
  },
  title: {
    color: '#FFF',
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: 600,
  },
  text: {
    color: '#FFF',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'break-spaces',

    svg: {
      height: '18px',
      width: '18px',
      marginRight: 1,
    },
  },
  toggleButton: {
    textTransform: 'none !important',
    fontWeight: '500',
    fontSize: '18px',
    borderRadius: '6px !important',
    height: '30px',
    border: 'none',
    color: '#fff',
    '&:hover': {
      color: 'var(--primary)',
      opacity: 0.8,
      backgroundColor: 'transparent !important',
    },
    '&.Mui-selected': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
  },
  editarDadosContainer: {
    position: 'absolute',
    width: '42px',
    height: '42px',
    right: 0,
  },
  editarButton: {
    padding: 1,
  },
  filtrosContainer: {
    backgroundColor: (theme) => theme.palette.blackSecundary,
    borderRadius: 1,
    padding: 2,
  },
  mobileFiltrosContainer: {
    justifyContent: 'flex-end',
    backgroundColor: (theme) => theme.palette.blackSecundary,
    borderRadius: 1,
  },
  modalBusca: {
    width: '100%',
    borderTopLeftRadius: '6px',
    zIndex: (theme) => theme.zIndex.modal,
    backgroundColor: (theme) => theme.palette.blackSecundary,
    padding: 2,
    position: 'absolute',
    right: '0px',
    top: (theme) => theme.toolbarHeight,
  },
};
