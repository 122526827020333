import {combineReducers} from '@reduxjs/toolkit';

import {ReducerApollo as Apollo} from './Apollo';
import {ReducerApp as App} from './App';
import {ReducerBusca as Busca} from './Busca';
import {ReducerHome as Home} from './Home';

const appReducer = combineReducers({Home, Apollo, Busca, App});

const rootReducer = (state, action) => {
  if (action.type === 'logoutSuccess') return appReducer(undefined, action);

  return appReducer(state, action);
};

export default rootReducer;
