import {createTheme} from '@mui/material';

const colors = {
  primary: {
    main: '#FECB29',
  },
  error: {
    main: '#DC3545',
  },
  white: '#fff',
  black: '#000',
  blackSecundary: '#141412',
  primaryGray: '#8F8F8F',
  secundaryGray: '#9E9E9E',
  backgroundColor: '#28292A',
  yellow: '#FFCC29',
  yellowLight: '#FFEE87',
};

const toolbarHeight = '75px';
const borderRadius = '3px';

export const theme = createTheme({
  // Sizes & Spacing
  toolbarHeight,
  borderRadius,

  zIndex: {
    toolbar: 8,
  },

  typography: {
    fontFamily: [
      'Now',
      'Inter',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },

  components: {
    MuiGrid: {
      variants: [
        {
          props: {id: 'container'},
          style: {
            backgroundColor: colors.backgroundColor,
            paddingTop: `calc(${toolbarHeight} + 16px)`,
            justifyContent: 'center',
            minHeight: '100vh',
          },
        },
        {
          props: {id: 'content'},
          style: {
            maxWidth: 1200,
            alignContent: 'flex-start',
            padding: `16px`,

            '@media (max-width: 900px)': {
              flexWrap: 'nowrap',
              flexDirection: 'column',
              alignItems: 'center',
            },
          },
        },
      ],
    },
    MuiTypography: {
      variants: [
        {
          props: {id: 'home-title'},
          style: {
            fontSize: 40,
            lineHeight: '55.2px',
            fontWeight: '700',
            textTransform: 'uppercase',
            color: '#fff',
            width: '100%',

            '@media (max-width: 900px)': {
              fontSize: 32,
            },
          },
        },
        {
          props: {id: 'modalidade-tag'},
          style: {
            fontSize: 14,
            fontWeight: '500',
            borderRadius: borderRadius,
            padding: '4px 8px',
            width: 'fit-content',
            marginBottom: '8px',
            backgroundColor: '#07AE13',
            color: '#fff',

            '&.empresario': {
              color: colors.blackSecundary,
              backgroundColor: colors.yellow,
            },

            '&.jornalista': {
              color: colors.blackSecundary,
              backgroundColor: '#FF8228',
            },
          },
        },
      ],
    },
    MuiIconButton: {
      variants: [
        {
          props: {id: 'icon-primary'},
          style: {
            backgroundColor: colors.yellow,
            color: colors.blackSecundary,
            borderRadius: '3px',
            margin: 0,
            ':hover': {
              boxShadow: '0px 4px 64.6px 0px rgba(255, 204, 41, 0.30)',
              backgroundColor: colors.yellow,
              color: colors.blackSecundary,
            },
          },
        },
        {
          props: {id: 'icon-primary-round'},
          style: {
            backgroundColor: colors.yellow,
            color: colors.blackSecundary,
            margin: 0,
            ':hover': {
              boxShadow: '0px 4px 64.6px 0px rgba(255, 204, 41, 0.30)',
              backgroundColor: colors.yellow,
              color: colors.blackSecundary,
            },
          },
        },
        {
          props: {id: 'icon-outlined'},
          style: {
            color: colors.primary.main,
            border: `1px solid ${colors.primary.main}`,
            borderRadius: '3px',
            margin: 0,
            ':hover': {
              backgroundColor: 'rgba(255, 204, 41, 0.30)',
            },
          },
        },
        {
          props: {id: 'icon-outlined-round'},
          style: {
            color: colors.primary.main,
            border: `1px solid ${colors.primary.main}`,
            margin: 0,
            ':disabled': {
              color: colors.yellow,
              opacity: 0.2,
            },
            ':hover': {
              backgroundColor: 'rgba(255, 204, 41, 0.30)',
            },
          },
        },
        {
          props: {id: 'icon-text'},
          style: {
            color: colors.white,
            margin: 0,
            ':hover': {
              backgroundColor: colors.yellowLight,
              color: colors.blackSecundary,
            },
          },
        },
      ],
    },
    MuiButton: {
      variants: [
        {
          props: {id: 'primary'},
          style: {
            backgroundColor: colors.yellow,
            color: colors.blackSecundary,
            border: `1px solid ${colors.yellow}`,
            borderRadius: '3px',
            fontWeight: '700',
            fontFamily: 'Now',
            fontSize: '14px',
            padding: '8px 32px',

            ':hover': {
              boxShadow: '0px 4px 64.6px 0px rgba(255, 204, 41, 0.30)',
              backgroundColor: colors.yellow,
              color: colors.blackSecundary,
            },
            '.MuiCircularProgress-root': {
              color: 'inherit',
              marginLeft: '8px',
              marginBottom: '4px',
            },
            svg: {
              marginBottom: '4px',
            },
          },
        },
        {
          props: {id: 'outlined'},
          style: {
            backgroundColor: 'transparent',
            border: `1px solid ${colors.yellow}`,
            color: colors.yellow,
            borderRadius: '3px',
            fontWeight: '700',
            fontFamily: 'Now',
            fontSize: '14px',
            padding: '8px 32px',

            ':disabled': {
              color: colors.yellow,
              opacity: 0.2,
            },
            ':hover': {
              backgroundColor: colors.yellowLight,
              color: colors.blackSecundary,
            },
            '.MuiCircularProgress-root': {
              color: 'inherit',
              marginLeft: '8px',
              marginBottom: '4px',
            },
            svg: {
              marginBottom: '4px',
            },
          },
        },
        {
          props: {id: 'text'},
          style: {
            backgroundColor: 'transparent',
            border: 'none',
            color: colors.white,
            borderRadius: '3px',
            fontWeight: '700 !important',
            fontFamily: 'Now',
            fontSize: '14px',
            padding: '8px 32px',

            '&:hover': {
              backgroundColor: colors.yellowLight,
              color: colors.backgroundColor,
            },
            '.MuiCircularProgress-root': {
              color: 'inherit',
              marginLeft: '8px',
              marginBottom: '4px',
            },
            svg: {
              marginBottom: '4px',
            },
          },
        },
        {
          props: {id: 'underline-text'},
          style: {
            backgroundColor: 'transparent',
            border: 'none',
            color: colors.white,
            borderRadius: '6px',
            fontWeight: '700',
            fontFamily: 'Now',
            fontSize: '14px',
            padding: '8px 32px',
            textTransform: 'none',
            textDecoration: 'underline !important',

            '&:hover': {
              backgroundColor: 'transparent',
              color: colors.yellowLight,
            },
            '.MuiCircularProgress-root': {
              color: 'inherit',
              marginLeft: '8px',
              marginBottom: '4px',
            },
            svg: {
              marginBottom: '4px',
            },
          },
        },
      ],
    },
    MuiTextField: {
      variants: [
        {
          props: {id: 'input'},
          style: {
            height: '40px',
            backgroundColor: '#fff',
            borderRadius: '8px',

            '&.MuiInputBase-input': {
              padding: '8.5px',
            },
            '&.Mui-focused': {
              '&>fieldset': {
                border: '1px solid #657496 !important',
              },
            },
            '&.Mui-disabled': {
              '&>fieldset': {
                border: '1px solid #CDCDCD !important',
              },
            },
          },
        },
      ],
    },
    MuiOutlinedInput: {
      variants: [
        {
          props: {id: 'input'},
          style: {
            borderRadius: '8px',
            backgroundColor: '#fff',
            color: (theme) => theme.palette.blackSecundary,
            fontFamily: 'Inter',
            fontSize: '14px',
            fontWeight: '400',

            input: {
              padding: '8px 14px',
            },
          },
        },
      ],
    },
    MuiTable: {
      variants: [
        {
          props: {id: 'table'},
          style: {
            border: '1px solid #fff',
            borderRadius: 6,
            borderCollapse: 'separate',
          },
        },
      ],
    },
    MuiTableRow: {
      variants: [
        {
          props: {id: 'table-row'},
          style: {
            td: {
              color: '#fff',
              fontFamily: 'Inter',
              fontSize: '14px',
              fontWeight: 400,
            },
          },
        },
        {
          props: {id: 'table-row-footer'},
          style: {
            td: {
              color: '#fff',
              fontFamily: 'Inter',
              fontSize: '14px',
              fontWeight: 400,
              padding: '0 16px',
              border: 'none',
            },
          },
        },
      ],
    },
    MuiTableHead: {
      variants: [
        {
          props: {id: 'table-head'},
          style: {
            th: {
              color: '#fff',
              fontFamily: 'Inter',
              fontSize: '18px',
              fontWeight: 600,
            },
          },
        },
      ],
    },
    MuiTableCell: {
      variants: [
        {
          props: {id: 'table-pagination'},
          style: {
            div: {
              color: '#fff',
              fontFamily: 'Inter',
              fontSize: '18px',
              fontWeight: 600,
              '.MuiButtonBase-root': {
                color: '#fff',
              },
            },
          },
        },
      ],
    },
    MuiSelect: {
      variants: [
        {
          props: {id: 'select'},
          style: {
            borderRadius: '8px',
            backgroundColor: '#fff',
            color: (theme) => theme.palette.blackSecundary,
            fontFamily: 'Inter',
            fontSize: '14px',
            fontWeight: '500',
          },
        },
      ],
    },
  },

  // Colors
  palette: {...colors},
});
