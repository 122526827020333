const String = {
  format(mask, value) {
    if (mask == null || value == null) return value;

    let formatted = '';
    let next = 0;
    let ignore = 0;
    for (let i = 0; i < mask.length; i++) {
      if (i - ignore >= value.length) break;
      if (mask[i] === '#') formatted += value[next++];
      else {
        formatted += mask[i];
        ignore++;
      }
    }
    return formatted;
  },

  removeSpecialChars(value = '') {
    if (value == null || value.length < 1) return '';
    return value.toString().replace(/[^A-Z0-9]+/gi, '');
  },
  validaCpf(cpf) {
    if (this.isEmpty(cpf)) {
      return false;
    }

    const CPFS_INVALIDOS = [
      '00000000000',
      '11111111111',
      '22222222222',
      '33333333333',
      '44444444444',
      '55555555555',
      '66666666666',
      '77777777777',
      '88888888888',
      '99999999999',
    ];
    cpf = this.removeSpecialChars(cpf);
    if (CPFS_INVALIDOS.indexOf(cpf) > -1) return false;

    let soma = 0;
    for (let i = 1; i <= 9; i++)
      soma += parseInt(cpf.substring(i - 1, i)) * (11 - i);
    let resto = (soma * 10) % 11;

    if (resto === 10 || resto === 11) resto = 0;

    if (resto !== parseInt(cpf.substring(9, 10))) return false;

    soma = 0;
    for (let i = 1; i <= 10; i++)
      soma += parseInt(cpf.substring(i - 1, i)) * (12 - i);
    resto = (soma * 10) % 11;

    if (resto === 10 || resto === 11) resto = 0;

    return resto === parseInt(cpf.substring(10, 11));
  },
  validaCnpj(cnpj) {
    if (this.isEmpty(cnpj)) {
      return false;
    }
    cnpj = this.removeSpecialChars(cnpj);

    if (cnpj.length !== 14) return false;

    // Elimina CNPJs invalidos conhecidos
    if (
      cnpj === '00000000000000' ||
      cnpj === '11111111111111' ||
      cnpj === '22222222222222' ||
      cnpj === '33333333333333' ||
      cnpj === '44444444444444' ||
      cnpj === '55555555555555' ||
      cnpj === '66666666666666' ||
      cnpj === '77777777777777' ||
      cnpj === '88888888888888' ||
      cnpj === '99999999999999'
    )
      return false;

    // Valida DVs
    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado !== parseInt(digitos.charAt(0))) return false;
    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
      soma += numeros.charAt(tamanho - i) * pos--;
      if (pos < 2) pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado !== parseInt(digitos.charAt(1))) return false;

    return true;
  },
  validaCpfCnpj(valor = '') {
    if (valor.length < 15) return this.validaCpf(valor);

    return this.validaCnpj(valor);
  },
  validaTelefone(tel) {
    if (this.isEmpty(tel)) {
      return false;
    }

    const text = this.removeSpecialChars(tel);
    const length = text.trim().length;

    return length >= 10 && length <= 11;
  },
  camelToSnakeCase(text) {
    const firstLetterUpperCase = text[0].toLowerCase() + text.slice(1);
    return firstLetterUpperCase
      .replace(/[A-Z]/g, (letter) => `_${letter}`)
      .toLowerCase();
  },
  isEmpty(text) {
    if (typeof text !== 'string') {
      return true;
    }

    if (text.trim().length === 0) {
      return true;
    }

    return false;
  },
  truncate(str, length, ending) {
    if (typeof str !== 'string') {
      return str;
    }
    if (length == null) {
      length = 100;
    }
    if (ending == null) {
      ending = '...';
    }
    if (str.length > length) {
      return str.substring(0, length - ending.length) + ending;
    }

    return str;
  },
  formatCpfCnpj(value) {
    if (this.isEmpty(value)) {
      return '';
    }

    if (value.length === 11) return this.format('###.###.###-##', value);

    if (value.length === 14) return this.format('##.###.###/####-##', value);

    return value;
  },
  formatTelefone(value) {
    if (this.isEmpty(value)) {
      return '';
    }

    return this.format('(##) #####-####', value);
  },
  transformCamelToSnakeCase(value = '') {
    return value.replace(/[A-Z]/g, (m) => '_' + m.toLowerCase());
  },
  abreviarNome(nome = '') {
    const conectoresNome = ['de', 'da', 'do', 'dos', 'das'];

    let palavras = nome
      .trim()
      .split(' ')
      .filter((palavra) => !conectoresNome.includes(palavra.toLowerCase()));

    let primeiraPalavra = palavras[0];

    let outrasPalavrasAbreviadas = palavras
      .slice(1)
      .map((palavra) => palavra.charAt(0).toLocaleUpperCase() + '.');

    let nomeAbreviado =
      primeiraPalavra + ' ' + outrasPalavrasAbreviadas.join(' ');

    return nomeAbreviado;
  },
};

export default String;
