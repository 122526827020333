const sexo = {
  FEMININO: 'Feminino',
  MASCULINO: 'Masculino',
  OUTRO: 'Outro',
};

const tipoContato = {
  CADASTRO: 'Cadastro',
  CRITICA: 'Crítica',
  DUVIDA: 'Dúvida',
  ELOGIO: 'Elogio',
  SUGESTAO: 'Sugestão',
};

const agendamentoSituacao = {
  AGUARDANDO_PROFISSIONAL: 'Aguardando profissional',
  AGUARDANDO_AGENDAMENTO: 'Aguardando agendamento',
  AGUARDANDO_AVALIACAO: 'Aguardando avaliação',
  AVALIACAO_EM_ANDAMENTO: 'Avaliação em andamento',
  CONCLUIDO: 'Concluído',
  CANCELADO: 'Cancelado',
};

const anamnesePerguntaTipo = {
  INPUT: 'Texto',
  TEXT_AREA: 'Caixa de texto',
  NUMBER: 'Número',
  RADIO: 'Escolha única',
  DATE: 'Data',
  TIME: 'Tempo',
  CHECKBOX: 'Multipla escolha',
  COMBOBOX: 'Seleção',
  DATETIME: 'Data e hora',
};

export {agendamentoSituacao, anamnesePerguntaTipo, sexo, tipoContato};

