import {Close, Delete} from '@mui/icons-material';
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
  Slide,
  Typography,
} from '@mui/material';
import {useEffect, useState} from 'react';

import {toastNotification} from '../../../components/toastify';
import UploadableFotoPerfil from '../../../components/uploadable-foto-perfil';
import {uploadNestor} from '../../../utils/file';

export default function AnexoModal({pergunta = {}, open, onClose, onSave}) {
  const [uploadImage, setUploadImage] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (open) return;

    setUploadImage(null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const onSaveGrupo = async () => {
    if (!uploadImage) return;

    setLoading(true);
    uploadNestor(uploadImage)
      .then(({data}) => onSave(data?.id))
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      )
      .finally(() => setLoading(false));
  };

  return (
    <Modal open={open} onClose={onClose} sx={styles.modal}>
      <Slide in={open} appear>
        <Grid
          container
          className="styled-scroll-transparent-desktop"
          sx={styles.container}>
          <Grid container item sx={styles.header}>
            <Typography sx={styles.title}>ANEXAR IMAGEM</Typography>
            <IconButton sx={styles.closeIcon} onClick={onClose}>
              <Close />
            </IconButton>
          </Grid>
          <Grid container sx={styles.content}>
            <Grid item xs={12}>
              <UploadableFotoPerfil
                onChange={setUploadImage}
                value={pergunta.anexoId}
              />
            </Grid>
            <Grid
              container
              item
              xs={12}
              justifyContent="space-between"
              paddingTop={4}>
              <Button
                id="primary"
                onClick={onSaveGrupo}
                endIcon={loading && <CircularProgress size={20} />}>
                Salvar
              </Button>
              {pergunta.anexoId && (
                <Button
                  id="outlined"
                  onClick={() => onSave()}
                  endIcon={<Delete />}>
                  Excluir
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Slide>
    </Modal>
  );
}

const styles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    position: 'relative',
    maxHeight: '90vh',
    width: '50%',
    borderRadius: '6px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    padding: 3,
    paddingTop: 0,
    zIndex: (theme) => theme.zIndex.modal,
    background: (theme) => theme.palette.blackSecundary,
    boxShadow: '0px 0px 134.60001px 0px rgba(255, 204, 41, 0.25)',
    outline: 'none',
    overflowY: 'auto',

    '@media (max-width: 900px)': {
      width: '90%',
    },
  },
  header: {
    flexGrow: 0,
    padding: 2,
    justifyContent: 'space-between',
    svg: {
      color: '#fff',
    },
  },
  closeIcon: {
    width: '32px',
    height: '32px',
  },
  title: {
    color: '#FFF',
    textAlign: 'center',
    fontFamily: 'Now',
    fontSize: '24px',
    fontWeight: '700',
    textTransform: 'uppercase',

    '@media (max-width: 1200px)': {
      fontSize: '20px',
    },
  },
  content: {
    flexDirection: 'row',
    width: '100%',
    padding: '16px',
  },
};
