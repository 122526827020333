import {useLazyQuery} from '@apollo/client';
import {Close} from '@mui/icons-material';
import {Grid, IconButton, Modal, Slide, Typography} from '@mui/material';
import {useEffect, useState} from 'react';

import ToggleOptions from '../../../components/toggle-options';
import {FIND_DADOS_ATLETA_BY_ID} from '../../../graphql/queries';
import Data from '../../../utils/data';
import DadosBaseAtleta from '../formularios/dados-base';
import EnderecosAtleta from '../formularios/enderecos';
import RedesSociaisAtleta from '../formularios/redes-sociais';
import ResponsavelAtleta from '../formularios/responsavel';

const menuOptions = [
  {
    value: 'DADOS_BASE',
    label: 'Dados básicos',
    component: (atleta) => <DadosBaseAtleta atleta={atleta} />,
  },
  {
    value: 'REDES_SOCIAIS',
    label: 'Rede sociais',
    component: (atleta) => <RedesSociaisAtleta atleta={atleta} />,
  },
  {
    value: 'ENDERECOS',
    label: 'Endereços',
    component: (atleta) => <EnderecosAtleta atleta={atleta} />,
  },
  {
    value: 'RESPONSAVEL',
    label: 'Responsável',
    component: (atleta) => <ResponsavelAtleta atleta={atleta} />,
  },
];

export default function EditarPerfilAtleta({open, onClose, atleta}) {
  const [selectedMenu, setSelectedMenu] = useState(menuOptions[0].value);
  const [atletaCompleto, setAtletaCompleto] = useState({});

  const [loadAtleta, atletaQuery] = useLazyQuery(FIND_DADOS_ATLETA_BY_ID);

  useEffect(() => {
    if (!open || !atleta) return setSelectedMenu(menuOptions[0].value);

    loadAtleta({
      variables: {
        id: atleta.id,
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, atleta]);

  useEffect(() => {
    if (atletaQuery.loading) return;

    setAtletaCompleto(atletaQuery.data?.atleta || {});
  }, [atletaQuery]);

  const renderContent = () => {
    return (
      menuOptions
        .find((o) => o.value === selectedMenu)
        ?.component(atletaCompleto) || null
    );
  };

  return (
    <Modal open={open} onClose={onClose} sx={styles.modal}>
      <Slide in={open} appear>
        <Grid
          container
          className="styled-scroll-transparent-desktop"
          sx={styles.container}>
          <Grid container item sx={styles.header}>
            <Typography sx={styles.title}>Editar</Typography>
            <IconButton sx={styles.closeIcon} onClick={onClose}>
              <Close />
            </IconButton>
            <Grid container item xs={12} paddingY={1} justifyContent={'center'}>
              <ToggleOptions
                options={menuOptions.filter(
                  (o) =>
                    o.value !== 'RESPONSAVEL' ||
                    Data.calcularIdade(atleta.dataNascimento) < 16,
                )}
                selected={selectedMenu}
                onChange={setSelectedMenu}
              />
            </Grid>
          </Grid>
          <Grid container item sx={styles.content}>
            {renderContent()}
          </Grid>
        </Grid>
      </Slide>
    </Modal>
  );
}

const styles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    position: 'relative',
    maxWidth: '683px',
    height: '95%',
    width: '40%',
    borderRadius: '6px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    padding: 3,
    paddingTop: 0,
    zIndex: (theme) => theme.zIndex.modal,
    background: (theme) => theme.palette.blackSecundary,
    boxShadow: '0px 0px 134.60001px 0px rgba(255, 204, 41, 0.25)',
    outline: 'none',
    overflowY: 'auto',

    '@media (max-width: 900px)': {
      width: '90%',
    },
  },
  header: {
    flexGrow: 0,
    paddingTop: 2,
    position: 'sticky',
    top: 0,
    background: (theme) => theme.palette.blackSecundary,
    justifyContent: 'space-between',
    alignItems: 'center',
    svg: {
      color: '#fff',
    },
    zIndex: 2,
  },
  closeIcon: {
    width: '32px',
    height: '32px',
  },
  title: {
    color: '#FFF',
    fontSize: '20px',
    fontWeight: '700',
    textTransform: 'uppercase',

    '@media (max-width: 1200px)': {
      fontSize: '14px',
    },
  },
  content: {
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    paddingTop: '16px',
  },
};
