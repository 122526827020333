import {Close, Delete} from '@mui/icons-material';
import {
  Button,
  Grid,
  IconButton,
  Modal,
  Slide,
  Typography,
} from '@mui/material';
import React, {useEffect, useState} from 'react';

import Input from '../../../../components/input/input';
import {SelectFormularioGrupo} from '../../../../components/input/select/formulario-grupo';
import SelectInput from '../../../../components/input/select/select-input';
import {toastNotification} from '../../../../components/toastify';
import {anamnesePerguntaTipo} from '../../../../constants/enum-labels';
import Number from '../../../../utils/number';
import GrupoModal from './grupo';

export default function PerguntaModal({
  pergunta,
  novaPergunta = '',
  open,
  onClose,
  onSave,
}) {
  const [openModal, setOpenModal] = useState(false);
  const [formData, setFormData] = useState({});
  const [valoresReferencia, setValoresReferencia] = useState([]);

  useEffect(() => {
    if (!open) return;

    if (!pergunta) {
      setFormData({});
      setValoresReferencia([]);
      return;
    }

    setFormData({...pergunta, pergunta: pergunta.pergunta || novaPergunta});
    setValoresReferencia(pergunta?.valoresReferencia || []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pergunta, open, novaPergunta]);

  const handleSave = () => {
    if (!validarFormulario()) return;
    onSave({...formData, valoresReferencia});
  };

  const validarFormulario = () => {
    if (!formData.tipo || !formData.pergunta || !formData.modeloGrupo?.value) {
      toastNotification({
        title: `Campos obrigatórios`,
        message: 'Pergunta, Tipo e Grupo são obrigatórios',
        type: 'warning',
      });
      return false;
    }

    if (formData.tipo === 'COMBOBOX' && valoresReferencia.length <= 0) {
      toastNotification({
        title: `Tipo Seleção`,
        message:
          'É necessário adicionar ao menos uma opção para perguntas do tipo seleção',
        type: 'warning',
      });
      return false;
    }

    for (let i = 0; i < valoresReferencia.length; i++) {
      const itemAtual = valoresReferencia[i];

      if (!itemAtual.nome) {
        toastNotification({
          title: `Item: ${i + 1}`,
          message: 'Descrição da referência é obrigatória',
          type: 'warning',
        });
        return false;
      }

      if (formData.tipo === 'COMBOBOX') continue;

      if (!itemAtual.valorMin || !itemAtual.valorMax) {
        toastNotification({
          title: `Item: ${itemAtual.nome || ''}`,
          message: 'Valores Mínimo e Máximo da referência são obrigatórios',
          type: 'warning',
        });
        return false;
      }

      // Verifica se o valorMax é menor que o valorMin
      if (
        Number.safeParseFloat(itemAtual.valorMax) <
        Number.safeParseFloat(itemAtual.valorMin)
      ) {
        toastNotification({
          title: `Item: ${itemAtual.nome || ''}`,
          message: 'Valor mínimo não pode ser maior que o valor máximo',
          type: 'warning',
        });
        return false;
      }

      // Verifica se o valor do item atual sobrepõe os outros itens
      for (let j = 0; j < valoresReferencia.length; j++) {
        if (i !== j) {
          const outroItem = valoresReferencia[j];
          if (
            (Number.safeParseFloat(itemAtual.valorMin) >=
              Number.safeParseFloat(outroItem.valorMin) &&
              Number.safeParseFloat(itemAtual.valorMin) <=
                Number.safeParseFloat(outroItem.valorMax)) ||
            (Number.safeParseFloat(itemAtual.valorMax) >=
              Number.safeParseFloat(outroItem.valorMin) &&
              Number.safeParseFloat(itemAtual.valorMax) <=
                Number.safeParseFloat(outroItem.valorMax))
          ) {
            toastNotification({
              message:
                'Os intervalos dos valores de refeência estão se sobrepondo',
              type: 'warning',
            });
            return false;
          }
        }
      }
    }

    return true;
  };

  return (
    <Modal open={open} onClose={onClose} sx={styles.modal}>
      <Slide in={open} appear>
        <Grid
          container
          className="styled-scroll-transparent-desktop"
          sx={styles.container}>
          <Grid container item sx={styles.header}>
            <Typography sx={styles.title}>CADASTRO DE PERGUNTA</Typography>
            <IconButton sx={styles.closeIcon} onClick={onClose}>
              <Close />
            </IconButton>
          </Grid>
          <Grid container sx={styles.content}>
            <Grid container item spacing={2}>
              <Grid item md={4} xs={12}>
                <Input
                  label="Pergunta"
                  placeholder={'Pergunta da avaliação'}
                  name="pergunta"
                  type="text"
                  value={formData.pergunta || ''}
                  onChange={({target}) =>
                    setFormData((f) => ({...f, pergunta: target?.value}))
                  }
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <SelectInput
                  label={'Tipo'}
                  placeholder={'Selecione'}
                  name="tipo"
                  value={
                    formData.tipo
                      ? {
                          value: formData.tipo,
                          label: anamnesePerguntaTipo[formData.tipo],
                        }
                      : ''
                  }
                  onChange={({value}) =>
                    setFormData((f) => ({...f, tipo: value}))
                  }
                  options={[
                    {value: 'INPUT', label: 'Texto'},
                    {value: 'NUMBER', label: 'Número'},
                    {value: 'DATE', label: 'Data'},
                    {value: 'COMBOBOX', label: 'Seleção'},
                  ]}
                />
              </Grid>
              <Grid item md={4} xs={12}>
                <SelectFormularioGrupo
                  label={'Grupo'}
                  placeholder={'Selecione um grupo'}
                  name="modeloGrupo"
                  value={formData.modeloGrupo}
                  menuPlacement="top"
                  onChange={(modeloGrupo) =>
                    setFormData((f) => ({...f, modeloGrupo}))
                  }
                  onCreateOption={() => setOpenModal(true)}
                  creatable
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  label="URL de referência"
                  placeholder={'URL de referência'}
                  name="urlReferencia"
                  type="text"
                  value={formData.urlReferencia || ''}
                  onChange={({target}) =>
                    setFormData((f) => ({...f, urlReferencia: target?.value}))
                  }
                />
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              sx={{
                visibility: ['NUMBER', 'COMBOBOX'].includes(formData.tipo)
                  ? 'visible'
                  : 'hidden',
              }}>
              <Grid container item xs={12} pb={2} pt={4}>
                <Grid item xs={10}>
                  <Typography
                    color="var(--primary)"
                    fontWeight="bold"
                    fontSize="14px">
                    {formData.tipo === 'COMBOBOX'
                      ? 'Opções'
                      : 'Valores de referência'}
                  </Typography>
                </Grid>
                <Grid container item xs={2} justifyContent="flex-end">
                  <Button
                    id="text"
                    onClick={() => {
                      valoresReferencia.push({});
                      setValoresReferencia([...valoresReferencia]);
                    }}>
                    Adicionar +
                  </Button>
                </Grid>
              </Grid>
              <Grid container item xs={12} spacing={2}>
                {valoresReferencia?.map((valor, index) => (
                  <Grid key={index} container item xs={12} spacing={2}>
                    <Grid item md xs={12}>
                      <Input
                        label="Descrição"
                        placeholder={'Descrição da referência'}
                        name="nome"
                        type="text"
                        value={valor.nome || ''}
                        onChange={({target}) => {
                          valoresReferencia[index] = {
                            ...valor,
                            nome: target?.value,
                          };
                          setValoresReferencia([...valoresReferencia]);
                        }}
                      />
                    </Grid>
                    {formData.tipo !== 'COMBOBOX' && (
                      <Grid item md={2} xs={12}>
                        <Input
                          label="Valor mín"
                          placeholder={'Valor mínimo da referência'}
                          name="valorMin"
                          type="text"
                          value={valor.valorMin || ''}
                          onChange={({target}) => {
                            valoresReferencia[index] = {
                              ...valor,
                              valorMin: target?.value,
                            };
                            setValoresReferencia([...valoresReferencia]);
                          }}
                        />
                      </Grid>
                    )}
                    {formData.tipo !== 'COMBOBOX' && (
                      <Grid item md={2} xs={12}>
                        <Input
                          label="Valor máx"
                          placeholder={'Valor máximo da referência'}
                          name="valorMax"
                          type="text"
                          value={valor.valorMax || ''}
                          onChange={({target}) => {
                            valoresReferencia[index] = {
                              ...valor,
                              valorMax: target?.value,
                            };
                            setValoresReferencia([...valoresReferencia]);
                          }}
                        />
                      </Grid>
                    )}
                    <Grid item md={1} xs={12}>
                      <Input
                        label="Cor"
                        placeholder={'Cor de referência'}
                        name="corHex"
                        type="color"
                        value={valor.corHex || '#ffffff'}
                        onChange={({target}) => {
                          valoresReferencia[index] = {
                            ...valor,
                            corHex: target?.value,
                          };
                          setValoresReferencia([...valoresReferencia]);
                        }}
                        inputProps={{list: 'presets'}}
                      />
                      <datalist id="presets">
                        <option value="#FFFFFF">Branco</option>
                        <option value="#FFFF00">Amarelo</option>
                        <option value="#0000FF">Azul</option>
                        <option value="#FF0000">Vermelho</option>
                        <option value="#008000">Verde</option>
                        <option value="#FFA500">Laranja</option>
                        <option value="#800080">Roxo</option>
                        <option value="#A52A2A">Marrom</option>
                        <option value="#808080">Cinza</option>
                        <option value="#000000">Preto</option>
                      </datalist>
                    </Grid>
                    <Grid item md={2} xs={12}>
                      <Input
                        label="Índice"
                        placeholder={'Índice'}
                        name="indice"
                        type="number"
                        value={valor.indice || ''}
                        onChange={({target}) => {
                          valoresReferencia[index] = {
                            ...valor,
                            indice: target?.value,
                          };
                          setValoresReferencia([...valoresReferencia]);
                        }}
                      />
                    </Grid>
                    <Grid item md={1} xs={12} alignSelf="flex-end">
                      <IconButton
                        id="icon-primary-round"
                        onClick={() => {
                          valoresReferencia.splice(index, 1);
                          setValoresReferencia([...valoresReferencia]);
                        }}>
                        <Delete />
                      </IconButton>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid
              container
              item
              xs={12}
              paddingTop={4}
              justifyContent={'flex-end'}>
              <Button id="primary" onClick={handleSave}>
                Salvar
              </Button>
            </Grid>
          </Grid>
          <GrupoModal
            open={openModal}
            onClose={() => setOpenModal(false)}
            onSave={(modeloGrupo) => {
              setFormData((f) => ({...f, modeloGrupo}));
              setOpenModal(false);
            }}
          />
        </Grid>
      </Slide>
    </Modal>
  );
}

const styles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    position: 'relative',
    maxHeight: '90vh',
    width: '80%',
    borderRadius: '6px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    padding: 3,
    paddingTop: 0,
    zIndex: (theme) => theme.zIndex.modal,
    background: (theme) => theme.palette.blackSecundary,
    boxShadow: '0px 0px 134.60001px 0px rgba(255, 204, 41, 0.25)',
    outline: 'none',
    overflowY: 'auto',

    '@media (max-width: 900px)': {
      width: '90%',
    },
  },
  header: {
    flexGrow: 0,
    padding: 2,
    justifyContent: 'space-between',
    svg: {
      color: '#fff',
    },
  },
  closeIcon: {
    width: '32px',
    height: '32px',
  },
  title: {
    color: '#FFF',
    textAlign: 'center',
    fontFamily: 'Now',
    fontSize: '24px',
    fontWeight: '700',
    textTransform: 'uppercase',

    '@media (max-width: 1200px)': {
      fontSize: '20px',
    },
  },
  content: {
    flexDirection: 'row',
    width: '100%',
    padding: '16px',
  },
};
