import {Grid} from '@mui/material';
import {useParams} from 'react-router-dom';

import Footer from '../../components/footer';
import Toolbar from '../../components/toolbar/toolbar';
import AtletaPerfil from './perfil';

export default function Atleta() {
  const {atletaId} = useParams();
  return (
    <Grid container>
      <Toolbar />
      <AtletaPerfil atletaId={atletaId} readOnly />
      <Footer />
    </Grid>
  );
}
