import { FormGroup, InputLabel, TextField } from '@mui/material';

export default function Input({
  label,
  placeholder,
  errors = {},
  name,
  InputProps = {},
  className,
  ...props
}) {
  return (
    <FormGroup className={className}>
      {label && <InputLabel sx={styles.label}>{label}</InputLabel>}
      <TextField
        placeholder={placeholder}
        fullWidth
        size="small"
        InputProps={{
          ...InputProps,
          sx: styles.input,
        }}
        name={name}
        error={!!errors[name]}
        helperText={errors[name]}
        {...props}
      />
    </FormGroup>
  );
}

const styles = {
  label: {
    color: '#fff',
    fontSize: '14px',
    fontWeight: '700',
    marginBottom: '4px',
    whiteSpace: 'normal',
  },
  input: {
    borderRadius: '8px',
    backgroundColor: '#fff',
    color: (theme) => theme.palette.blackSecundary,
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: '400',

    input: {
      padding: '8px 14px',
    },
  },
};
