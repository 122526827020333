import { FormGroup, InputLabel, TextField } from '@mui/material';
import React from 'react';
import { IMaskInput } from 'react-imask';

const TextMaskCustom = React.forwardRef(function TextMaskCustom(
  {onChange, mask, definitions, ...props},
  ref,
) {
  return (
    <IMaskInput
      {...props}
      mask={mask}
      definitions={definitions}
      inputRef={ref}
      onAccept={(value) => onChange({target: {name: props.name, value}})}
      overwrite
    />
  );
});

export default function InputMask({
  label,
  placeholder,
  errors = {},
  name,
  InputProps = {},
  mask = '',
  blocks,
  ...props
}) {
  return (
    <FormGroup>
      <InputLabel sx={styles.label}>{label}</InputLabel>
      <TextField
        placeholder={placeholder}
        fullWidth
        size="small"
        inputProps={{
          mask,
          blocks
        }}
        InputProps={{
          ...InputProps,
          inputComponent: TextMaskCustom,
          sx: styles.input,
        }}
        name={name}
        error={!!errors[name]}
        helperText={errors[name]}
        {...props}
      />
    </FormGroup>
  );
}

const styles = {
  label: {
    fontSize: '14px',
    fontWeight: '700',
    color: '#fff',
    marginBottom: '4px',
  },
  input: {
    borderRadius: '8px',
    backgroundColor: '#fff',
    color: (theme) => theme.palette.blackSecundary,
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: '400',

    input: {
      padding: '8px 14px',
    },
  },
};
