import gql from 'graphql-tag';

export const ATUALIZAR_SENHA = gql`
  mutation AtualizaSenha($novaSenha: String, $senhaAtual: String) {
    trocarSenha(novaSenha: $novaSenha, senhaAtual: $senhaAtual) {
      tokenClienteRegistrado
    }
  }
`;

export const CRIAR_EMAIL_CONTATO = gql`
  mutation createEmailContato($contato: EmailContatoInput) {
    createEmailContato(contato: $contato) {
      id
    }
  }
`;

export const CADASTRAR_ATLETA = gql`
  mutation createAtleta($atleta: AtletaInput, $usuario: UsuarioCreateDTOInput) {
    createAtleta(atleta: $atleta, usuario: $usuario) {
      id
    }
  }
`;

export const UPDATE_ATLETA = gql`
  mutation updateAtleta($atleta: AtletaInput) {
    updateAtleta(atleta: $atleta) {
      id
    }
  }
`;

export const UPDATE_ATLETA_REDES_SOCIAIS = gql`
  mutation updateAtletaRedesSociais($atleta: AtletaInput) {
    updateAtletaRedesSociais(atleta: $atleta) {
      id
    }
  }
`;

export const UPDATE_ATLETA_ENDERECOS = gql`
  mutation updateAtletaEnderecos($atleta: AtletaInput) {
    updateAtletaEnderecos(atleta: $atleta) {
      id
    }
  }
`;

export const UPDATE_ATLETA_RESPONSAVEL = gql`
  mutation updateAtletaResponsavel($atleta: AtletaInput) {
    updateAtletaResponsavel(atleta: $atleta) {
      id
    }
  }
`;

export const UPDATE_ATLETA_SOBRE = gql`
  mutation updateAtletaSobre($atleta: AtletaInput) {
    updateAtletaSobre(atleta: $atleta) {
      id
    }
  }
`;

export const UPDATE_ATLETA_EXPERIENCIA = gql`
  mutation updateAtletaExperiencia($atleta: AtletaInput) {
    updateAtletaExperiencia(atleta: $atleta) {
      id
    }
  }
`;

export const UPDATE_ATLETA_MIDIAS = gql`
  mutation updateAtletaMidias($atleta: AtletaInput) {
    updateAtletaMidias(atleta: $atleta) {
      id
    }
  }
`;

export const CREATE_ATLETA_MIDIA = gql`
  mutation createAtletaMidia($midia: MidiaLinkInput) {
    createAtletaMidia(midia: $midia)
  }
`;

export const DELETE_ATLETA_MIDIA = gql`
  mutation deleteAtletaMidia($midia: MidiaLinkInput) {
    deleteAtletaMidia(midia: $midia)
  }
`;

export const CADASTRAR_EMPRESARIO = gql`
  mutation createEmpresario(
    $empresario: EmpresarioInput
    $usuario: UsuarioCreateDTOInput
  ) {
    createEmpresario(empresario: $empresario, usuario: $usuario) {
      id
    }
  }
`;

export const CADASTRAR_JORNALISTA = gql`
  mutation createJornalista($jornalista: JornalistaInput) {
    createJornalista(jornalista: $jornalista) {
      id
    }
  }
`;

export const CADASTRAR_ADMINISTRADOR = gql`
  mutation createAdministrador($administrador: AdministradorInput) {
    createAdministrador(administrador: $administrador) {
      id
    }
  }
`;

export const CADASTRAR_PROFISSIONAL = gql`
  mutation createProfissionalSaude($profissional: ProfissionalSaudeInput) {
    createProfissionalSaude(profissional: $profissional) {
      id
    }
  }
`;

export const UPDATE_PROFISSIONAL_ENDERECOS = gql`
  mutation updateProfissionalEnderecos($profissional: ProfissionalSaudeInput) {
    updateProfissionalEnderecos(profissional: $profissional) {
      id
    }
  }
`;

export const SALVAR_NOTICIA = gql`
  mutation createNoticia($noticia: NoticiaInput) {
    noticia: createNoticia(noticia: $noticia) {
      id
    }
  }
`;

export const PUBLICAR_NOTICIA = gql`
  mutation publicarNoticia($noticia: NoticiaInput, $publicar: Boolean) {
    publicarNoticia(noticia: $noticia, publicar: $publicar)
  }
`;

export const UPDATE_EMPRESARIO = gql`
  mutation updateEmpresario($empresario: EmpresarioInput) {
    updateEmpresario(empresario: $empresario) {
      id
    }
  }
`;

export const UPDATE_EMPRESARIO_REDES_SOCIAIS = gql`
  mutation updateEmpresarioRedesSociais($empresario: EmpresarioInput) {
    updateEmpresarioRedesSociais(empresario: $empresario) {
      id
    }
  }
`;

export const UPDATE_EMPRESARIO_ENDERECOS = gql`
  mutation updateEmpresarioEnderecos($empresario: EmpresarioInput) {
    updateEmpresarioEnderecos(empresario: $empresario) {
      id
    }
  }
`;

export const UPDATE_EMPRESARIO_SOBRE = gql`
  mutation updateEmpresarioSobre($empresario: EmpresarioInput) {
    updateEmpresarioSobre(empresario: $empresario) {
      id
    }
  }
`;

export const CREATE_EMPRESARIO_MIDIA = gql`
  mutation createEmpresarioMidia($midia: MidiaLinkInput) {
    createEmpresarioMidia(midia: $midia)
  }
`;

export const UPDATE_ATLETA_FOTO_PERFIL = gql`
  mutation updateAtletaFotoPerfil($atleta: AtletaInput) {
    updateAtletaFotoPerfil(atleta: $atleta) {
      id
    }
  }
`;

export const UPDATE_EMPRESARIO_FOTO_PERFIL = gql`
  mutation updateEmpresarioFotoPerfil($empresario: EmpresarioInput) {
    updateEmpresarioFotoPerfil(empresario: $empresario) {
      id
    }
  }
`;

export const UPDATE_JORNALISTA_FOTO_PERFIL = gql`
  mutation updateJornalistaFotoPerfil($jornalista: JornalistaInput) {
    updateJornalistaFotoPerfil(jornalista: $jornalista) {
      id
    }
  }
`;

export const UPDATE_PROFISSIONAL_FOTO_PERFIL = gql`
  mutation updateProfissionalFotoPerfil($profissional: ProfissionalSaudeInput) {
    updateProfissionalFotoPerfil(profissional: $profissional) {
      id
    }
  }
`;

export const UPDATE_ADMIN_FOTO_PERFIL = gql`
  mutation updateAdministradorFotoPerfil($administrador: AdministradorInput) {
    updateAdministradorFotoPerfil(administrador: $administrador) {
      id
    }
  }
`;

export const AVALIAR_NOTICIA = gql`
  mutation avaliarNoticia(
    $noticiaId: UUID
    $usuarioId: UUID
    $avaliacao: Boolean
  ) {
    avaliarNoticia(
      noticiaId: $noticiaId
      usuarioId: $usuarioId
      avaliacao: $avaliacao
    )
  }
`;

export const UPDATE_EMPRESARIO_STATUS = gql`
  mutation alterarStatusAtivoEmpresario($empresario: EmpresarioInput) {
    alterarStatusAtivoEmpresario(empresario: $empresario) {
      id
    }
  }
`;

export const UPDATE_ATLETA_STATUS = gql`
  mutation alterarStatusAtivoAtleta($atleta: AtletaInput) {
    alterarStatusAtivoAtleta(atleta: $atleta) {
      id
    }
  }
`;

export const ADICIONAR_ATLETA_FAVORITO = gql`
  mutation adicionarFavorito($atleta: AtletaInput) {
    adicionarFavorito(atleta: $atleta) {
      id
    }
  }
`;

export const REMOVER_ATLETA_FAVORITO = gql`
  mutation removerFavorito($atleta: AtletaInput) {
    removerFavorito(atleta: $atleta)
  }
`;

export const SALVAR_FILTRO_EMPRESARIO = gql`
  mutation salvarFiltro($filtros: EmpresarioFiltrosInput) {
    salvarFiltro(filtros: $filtros)
  }
`;

export const REMOVER_FILTRO_EMPRESARIO = gql`
  mutation removerFiltro($filtros: EmpresarioFiltrosInput) {
    removerFiltro(filtros: $filtros)
  }
`;

export const CONCLUIR_CONTATO = gql`
  mutation concluirContato($contato: EmailContatoInput) {
    concluirContato(contato: $contato) {
      id
    }
  }
`;

export const CREATE_AGENDAMENTO = gql`
  mutation createAgendamento($agendamento: AgendamentoInput) {
    createAgendamento(agendamento: $agendamento) {
      id
    }
  }
`;

export const UPDATE_AGENDAMENTO = gql`
  mutation updateAgendamento($agendamento: AgendamentoInput) {
    updateAgendamento(agendamento: $agendamento) {
      id
    }
  }
`;

export const UPDATE_ANAMNESE_MODELO = gql`
  mutation createAnamneseModelo($anamneseModelo: AnamneseModeloInput) {
    createAnamneseModelo(anamneseModelo: $anamneseModelo) {
      id
    }
  }
`;

export const UPDATE_AGENDAMENTO_SITUACAO = gql`
  mutation updateAgendamentoSituacao($agendamento: AgendamentoInput) {
    updateAgendamentoSituacao(agendamento: $agendamento) {
      id
    }
  }
`;

export const CREATE_ANAMNESE_GRUPO = gql`
  mutation createAnamneseModeloGrupo(
    $anamneseModeloGrupo: AnamneseModeloGrupoInput
  ) {
    grupo: createAnamneseModeloGrupo(
      anamneseModeloGrupo: $anamneseModeloGrupo
    ) {
      id
      ordem
      nome
      value: id
      label: nome
    }
  }
`;

export const UPDATE_ANAMNESE_RESPOSTA = gql`
  mutation updateAnamnesePerguntaResposta(
    $anamneseModeloPergunta: AnamnesePerguntaRespostaInput
  ) {
    updateAnamnesePerguntaResposta(
      anamneseModeloPergunta: $anamneseModeloPergunta
    ) {
      id
    }
  }
`;

export const CREATE_ANAMNESE_BY_AGENDAMENTO = gql`
  mutation updateAnamnesePerguntaResposta(
    $anamneseModeloPergunta: AnamnesePerguntaRespostaInput
  ) {
    updateAnamnesePerguntaResposta(
      anamneseModeloPergunta: $anamneseModeloPergunta
    ) {
      id
    }
  }
`;

export const FIND_OR_CREATE_ANAMNESE_BY_AGENDAMENTO_ID = gql`
  mutation findOrCreateAnamneseByAgendamentoId($id: UUID) {
    anamnese: findOrCreateAnamneseByAgendamentoId(id: $id) {
      id
      atleta {
        id
        nome
        cpf
        email
        sexo
        peso
        estatura
        urlFacebook
        urlInstagram
        urlX
        urlLinkedin
        dataNascimento
        dataCadastro
        fotoPerfil
        telefone
        descricao
        situacao
        favorito
        midiaLinks {
          id
          url
          imagem
        }
        experiencias {
          id
          equipe
          dataInicio
          dataFim
          funcao {
            id
            descricao
            value: id
            label: descricao
          }
        }
        modalidade {
          id
          descricao
          value: id
          label: descricao
        }
        funcoes {
          id
          descricao
          value: id
          label: descricao
        }
        enderecos {
          id
          cep
          logradouro
          numero
          bairro
          complemento
          principal
          cidade {
            id
            nome
            uf
            nomeEUf
            value: id
            label: nomeEUf
          }
        }
      }
      profissionalSaude {
        id
        nome
      }
      respostas {
        id
        anexoId
        valorResposta
        modeloPergunta {
          id
          ordem
          pergunta {
            id
            pergunta
            tipo
            ordem
            urlReferencia
            modeloGrupo {
              id
              nome
              ordem
            }
            valoresReferencia {
              id
              nome
              corHex
              valorMin
              valorMax
              indice
            }
          }
        }
      }
    }
  }
`;

export const CREATE_ESPECIALIDADE_MEDICA = gql`
  mutation createEspecialidadeMedica($especialidade: EspecialidadeMedicaInput) {
    especialidade: createEspecialidadeMedica(especialidade: $especialidade) {
      id
      descricao
      value: id
      label: descricao
    }
  }
`;

export const DELETE_ESPECIALIDADE_MEDICA = gql`
  mutation deleteEspecialidadeMedicaById($especialidadeId: UUID) {
    deleteEspecialidadeMedicaById(especialidadeId: $especialidadeId)
  }
`;

export const CREATE_MODALIDADE = gql`
  mutation createModalidade($modalidade: ModalidadeInput) {
    modalidade: createModalidade(modalidade: $modalidade) {
      id
      descricao
      value: id
      label: descricao
    }
  }
`;

export const DELETE_MODALIDADE = gql`
  mutation deleteModalidadeById($modalidadeId: UUID) {
    deleteModalidadeById(modalidadeId: $modalidadeId)
  }
`;

export const CREATE_MODALIDADE_FUNCAO = gql`
  mutation createModalidadeFuncao($funcao: ModalidadeFuncaoInput) {
    modalidade: createModalidadeFuncao(funcao: $funcao) {
      id
      descricao
      value: id
      label: descricao
    }
  }
`;

export const DELETE_MODALIDADE_FUNCAO = gql`
  mutation deleteModalidadeById($funcaoId: UUID) {
    deleteModalidadeById(funcaoId: $funcaoId)
  }
`;

export const SAVE_MODALIDADE_E_FUNCOES = gql`
  mutation saveModalidadeEFuncoes($modalidade: ModalidadeInput) {
    modalidade: saveModalidadeEFuncoes(modalidade: $modalidade) {
      id
      descricao
      value: id
      label: descricao
      funcoes {
        id
        descricao
        value: id
        label: descricao
      }
    }
  }
`;

export const CREATE_ANAMNESE_PERGUNTA = gql`
  mutation createAnamnesePergunta($anamnesePergunta: AnamnesePerguntaInput) {
    pergunta: createAnamnesePergunta(anamnesePergunta: $anamnesePergunta) {
      id
    }
  }
`;


export const TERMINAR_ANAMNESE = gql`
  mutation terminiarAnamnese($id: UUID) {
    terminiarAnamnese(id: $id) {
      id
    }
  }
`;

export const CREATE_PLANO = gql`
  mutation createPlano ($plano: PlanoInput) {
    createPlano(plano: $plano){
      id
    }
  }
`;

export const GERAR_RECORRENCIA_BY_DADOS_CARTAO = gql`
  mutation gerarRecorrenciaByDadosCartao(
    $dados: DadosAssinaturaCartaoDTOInput 
    $plano: PlanoInput
  ) {
    gerarRecorrenciaByDadosCartao(
      dadosAssinaturaCartaoDTO: $dados 
      plano: $plano
    ){
      id
      plano {
        id
      }
    }
  }
`;

export const CANCELAR_RECORRENCIA = gql`
  mutation cancelarRecorrencia ($usuario: UsuarioInput){
    cancelarRecorrenciaByUsuario(usuario: $usuario) {
      id
    }
  }
`