export const HOME = '/home';
export const LOGIN = '/login';
export const CADASTRO = '/cadastro';
export const RECUPERAR_SENHA = '/recuperar-senha';
export const REDEFINIR_SENHA = '/redefinir-senha';
export const BUSCA = '/busca';
export const ATLETA = '/atleta';
export const EMPRESARIO = '/empresario';
export const PERFIL = '/perfil';
export const NOTICIAS = '/noticias';
export const AVALIACAO = '/avaliacao';
export const ANAMNESE = '/anamnese';
