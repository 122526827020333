import {useMutation} from '@apollo/client';
import {Visibility, VisibilityOff} from '@mui/icons-material';
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Typography,
} from '@mui/material';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import * as Yup from 'yup';

import Footer from '../../components/footer';
import CheckboxInput from '../../components/input/checkbox';
import Input from '../../components/input/input';
import InputMask from '../../components/input/input-mask';
import {toastNotification} from '../../components/toastify';
import ToolbarLogin from '../../components/toolbar/toolbar-login';
import {CADASTRAR_ATLETA} from '../../graphql/mutation';
import Data from '../../utils/data';
import Masks from '../../utils/masks';
import String from '../../utils/string';
import ResponsavelAtleta from './modais/responsavel';

export default function CadastroAtleta() {
  const navigate = useNavigate();

  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState([]);
  const [showPass, setShowPass] = useState(false);
  const [openResponsavel, setOpenResponsavel] = useState(false);
  const [menorDezesseis, setMenorDezesseis] = useState(false);

  const [cadastrarAtleta, {loading}] = useMutation(CADASTRAR_ATLETA);

  const handleSubmit = (event) => {
    if (loading) return;

    event.preventDefault();
    atletaScheme
      .validate(formData, {abortEarly: false})
      .then(() => handleCadastrar())
      .catch((errors) => {
        const camposComErro = {};
        errors?.inner?.forEach((error) => {
          camposComErro[error.path] = error.message;
        });
        setErrors(camposComErro);
      });
  };

  // 1 letra 1 numero 6 caracateres min

  const handleCadastrar = (responsavel) => {
    if (menorDezesseis && !responsavel) {
      return setOpenResponsavel(true);
    }

    cadastrarAtleta({
      variables: {
        atleta: {
          nome: formData.nome,
          cpf: String.removeSpecialChars(formData.cpf),
          email: formData.email,
          dataNascimento: formData.dataNascimento,
          telefone: String.removeSpecialChars(formData.telefone),
          responsavel: responsavel && {
            ...responsavel,
            cpf: String.removeSpecialChars(responsavel.cpf),
            telefone: String.removeSpecialChars(responsavel.telefone),
            grauParentesco: responsavel.grauParentesco?.value,
          },
        },
        usuario: {
          senha: formData.senha,
        },
      },
    })
      .then(() => {
        toastNotification({message: 'Cadastro realizado!', type: 'success'});
        navigate('/login');
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      );
  };

  useEffect(() => {
    if (!formData.dataNascimento) return;

    setMenorDezesseis(Data.calcularIdade(formData.dataNascimento) < 16);
  }, [formData.dataNascimento]);

  return (
    <Grid container sx={styles.container}>
      <ToolbarLogin />
      <Grid container sx={styles.content} justifyContent={'space-between'}>
        <Grid container item sx={styles.titleContainer}>
          <Typography sx={styles.title}>
            CRIE SUA CONTA COMO <br />{' '}
            <Typography
              component="span"
              sx={[styles.title, {color: 'var(--primary)'}]}>
              ATLETA
            </Typography>
          </Typography>
          <Typography sx={styles.subtitle}>
            Já possui uma conta? <Link href="#/login">ENTRAR!</Link>
          </Typography>
        </Grid>
        <Grid
          component={'form'}
          container
          item
          sx={styles.formContainer}
          gap={2}>
          <Input
            label={'Nome'}
            name="nome"
            type="text"
            autoCapitalize="name"
            placeholder={'Nome completo'}
            onChange={({target}) =>
              setFormData((f) => ({...f, nome: target?.value}))
            }
            errors={errors}
          />
          <InputMask
            label={'CPF'}
            name="cpf"
            autoComplete="off"
            type="text"
            mask={Masks.cpf}
            placeholder={'Digite seu CPF'}
            onChange={({target}) =>
              setFormData((f) => ({...f, cpf: target?.value}))
            }
            errors={errors}
          />
          <Input
            label={'Data de nascimento'}
            name="dataNascimento"
            type="date"
            placeholder={'Digite sua data de nascimento'}
            onChange={({target}) =>
              setFormData((f) => ({...f, dataNascimento: target?.value}))
            }
            errors={errors}
          />
          <Input
            label={'E-mail'}
            name="email"
            type="email"
            autoComplete="email"
            placeholder={'Digite seu e-mail'}
            onChange={({target}) =>
              setFormData((f) => ({...f, email: target?.value}))
            }
            errors={errors}
          />
          <InputMask
            label={'Telefone'}
            name="telefone"
            type="tel"
            autoComplete="tel"
            mask={Masks.telelefone}
            placeholder={'Digite seu número de telefone'}
            onChange={({target}) =>
              setFormData((f) => ({...f, telefone: target?.value}))
            }
            errors={errors}
          />
          <Input
            label={'Senha'}
            name="senha"
            type={showPass ? 'text' : 'password'}
            placeholder={'***********'}
            onChange={({target}) =>
              setFormData((f) => ({...f, senha: target?.value}))
            }
            autoComplete="new-password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPass(!showPass)}
                    edge="end">
                    {showPass ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            errors={errors}
          />
          <Input
            label={'Confirme sua senha'}
            name="senhaConfirmacao"
            type={showPass ? 'text' : 'password'}
            placeholder={'***********'}
            onChange={({target}) =>
              setFormData((f) => ({...f, senhaConfirmacao: target?.value}))
            }
            autoComplete="new-password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPass(!showPass)}
                    edge="end">
                    {showPass ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            errors={errors}
          />
          <CheckboxInput
            name="politicaPrivacidade"
            customLabel={
              <Typography sx={styles.checkboxLabel}>
                ESTOU CIENTE E ACEITO OS TERMOS DA{' '}
                <Link
                  href="https://www.wolfprolink.com.br/contratos/politica-privacidade.html"
                  target="_blank">
                  POLÍTICA DE PRIVACIDADE
                </Link>
                .
              </Typography>
            }
            onChange={({target}) =>
              setFormData((f) => ({...f, politicaPrivacidade: target?.checked}))
            }
            errors={errors}
          />
          <CheckboxInput
            name="termoResponsabilidade"
            label={
              'DECLARO SER MAIOR DE 16 ANOS E RESPONSÁVEL LEGAL PELOS DADOS FORNECIDOS.'
            }
            onChange={({target}) =>
              setFormData((f) => ({
                ...f,
                termoResponsabilidade: target?.checked,
              }))
            }
            sx={{visibility: menorDezesseis ? 'hidden' : 'visible'}}
            errors={errors}
          />
          <Button
            id="primary"
            onClick={handleSubmit}
            endIcon={loading && <CircularProgress size={20} />}
            disabled={loading}>
            ENVIAR
          </Button>
        </Grid>
      </Grid>
      <Footer />
      <ResponsavelAtleta
        open={openResponsavel}
        onClose={() => setOpenResponsavel(false)}
        onSave={handleCadastrar}
      />
    </Grid>
  );
}

const atletaScheme = Yup.object().shape({
  nome: Yup.string().required('Campo obrigatório'),
  cpf: Yup.string()
    .required('Campo obrigatório')
    .test('validar-cpf', 'CPF inválido', (value) => String.validaCpf(value)),
  dataNascimento: Yup.date().required('Campo obrigatório'),
  email: Yup.string().required('Campo obrigatório').email('E-mail inválido'),
  telefone: Yup.string().required('Campo obrigatório'),
  senha: Yup.string()
    .required('Campo obrigatório')
    .test(
      'senha-forte',
      'Precisa ter no mínimo seis caracteres sendo ao menos um número e uma letra',
      function (value) {
        const regex = /^(?=.*[0-9])(?=.*[a-zA-Z]).{6,}$/;
        return regex.test(value);
      },
    ),
  senhaConfirmacao: Yup.string()
    .required('Campo obrigatório')
    .test('senha-coincide', 'As senhas não coincidem', function (value) {
      return value === this.parent.senha;
    }),
  politicaPrivacidade: Yup.boolean()
    .required('Você precisa concordar com os termos')
    .oneOf([true], 'Você precisa concordar com os termos'),
  termoResponsabilidade: Yup.boolean().test(
    'maior-dezesseie',
    'Você precisa concordar com os termos',
    function (value) {
      return (
        value ||
        (this.parent.dataNascimento &&
          Data.calcularIdade(this.parent.dataNascimento) < 16)
      );
    },
  ),
});

const styles = {
  container: {
    flex: 1,
    justifyContent: 'center',
    paddingTop: (theme) => theme.toolbarHeight,
    backgroundColor: (theme) => theme.palette.blackSecundary,
  },
  content: {
    justifyContent: 'space-evenly',
    alignItems: 'center',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    padding: 3,
    paddingBottom: 9,
    maxWidth: 1200,
  },
  titleContainer: {
    flexGrow: 0,
    justifyContent: 'center',
    padding: '24px 0',
    maxWidth: '420px',

    '@media (max-width: 1200px)': {
      padding: '16px 0',
    },
  },
  title: {
    color: '#FFF',
    textAlign: 'center',
    fontFamily: 'Now',
    fontSize: '30px',
    fontWeight: '700',
    textTransform: 'uppercase',

    '@media (max-width: 1200px)': {
      fontSize: '20px',
    },
  },
  subtitle: {
    color: '#E3E3E3',
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: '500',

    '@media (max-width: 1200px)': {
      fontSize: '14px',
    },
  },
  formContainer: {
    maxWidth: '420px',
    flexDirection: 'column',
    width: '100%',
    paddingBottom: '16px',
  },
  checkbox: {
    color: '#FFF',

    '& .MuiSvgIcon-root': {
      fontSize: 32,
    },
  },
  checkboxLabel: {
    color: '#FFF',
    fontSize: '14px',
    fontWeight: '400',
    letterSpacing: '0.42px',
    lineHeight: 'normal',
    textTransform: 'uppercase',

    a: {
      fontWeight: '700',
      color: '#FFF',
      textDecorationColor: '#FFF',
    },
  },
};
