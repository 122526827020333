import React from 'react';
import {confirmAlert as alert} from 'react-confirm-alert';
import CustomUi from './custom-ui';

import './styles.css';

export const confirmAlert = ({
  open,
  title,
  onConfirm,
  message,
  loading,
  closeOnClickOutside = true,
}) => {
  alert({
    closeOnClickOutside: closeOnClickOutside,
    customUI: ({onClose}) => {
      return (
        <CustomUi
          onClose={onClose}
          open={open}
          title={title}
          onConfirm={onConfirm}
          message={message}
          loading={loading}
        />
      );
    },
  });
};
