import {Grid} from '@mui/material';

import Footer from '../../components/footer';
import Toolbar from '../../components/toolbar/toolbar';
import {useAuth} from '../../contexts/auth';
import AdministradorPerfil from '../administrador/perfil';
import AtletaPerfil from '../atleta/perfil';
import EmpresarioPerfil from '../empresario/perfil';
import JornalistaPerfil from '../jornalista/perfil';
import ProfissionalPerfil from '../profissional-saude/perfil';

const perfilRender = (role, entidadeLogada) => {
  switch (role) {
    case 'ROLE_ADMINISTRADOR':
      return <AdministradorPerfil />;
    case 'ROLE_JORNALISTA':
      return <JornalistaPerfil />;
    case 'ROLE_ATLETA':
      return <AtletaPerfil atletaId={entidadeLogada?.id} readOnly={false} />;
    case 'ROLE_EMPRESARIO':
      return (
        <EmpresarioPerfil empresarioId={entidadeLogada?.id} readOnly={false} />
      );
    case 'ROLE_PROFISSIONAL_SAUDE':
      return <ProfissionalPerfil />;
    default:
      return <Grid container sx={styles.container} />;
  }
};

export default function Perfil() {
  const {entidadeLogada, role} = useAuth();

  return (
    <Grid container>
      <Toolbar />
      {perfilRender(role, entidadeLogada)}
      <Footer />
    </Grid>
  );
}

const styles = {
  container: {
    minHeight: '100vh',
  },
};
