import {useMutation} from '@apollo/client';
import {
  Cancel,
  ChevronLeft,
  ChevronRight,
  Delete,
  YouTube,
} from '@mui/icons-material';
import {Box, Button, Grid, Modal, Slide} from '@mui/material';
import {useEffect, useState} from 'react';
import youtubeThumbnail from 'youtube-thumbnail';

import {DELETE_ATLETA_MIDIA} from '../graphql/mutation';
import {getImageUrl} from '../utils/file';
import {confirmAlert} from './confirm-alert/confirm-alert';
import {toastNotification} from './toastify';

const imageDefault = require('../assets/images/default-image.png');

export default function Midia({midias = [], index, readOnly, refetch}) {
  const [formData, setFormData] = useState({
    url: midias[index]?.url,
    imagem: midias[index]?.url
      ? youtubeThumbnail(midias[index]?.url).high.url
      : getImageUrl(midias[index]?.imagem),
  });
  const [formDataModal, setFormDataModal] = useState(formData);
  const [indiceSelecionado, setIndiceSelecionado] = useState(index);
  const [open, setOpen] = useState(false);

  const [deleteMidia] = useMutation(DELETE_ATLETA_MIDIA);

  const handleClickDelete = () => {
    confirmAlert({
      title: 'Deseja mesmo excluir?',
      onConfirm: handleDelete,
    });
  };

  const handleDelete = () => {
    deleteMidia({
      variables: {
        midia: {
          id: midias[indiceSelecionado]?.id,
        },
      },
    })
      .then(() => {
        refetch && refetch();
        setOpen(false);
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      );
  };

  useEffect(() => {
    setFormDataModal({
      url: midias[indiceSelecionado]?.url,
      imagem: midias[indiceSelecionado]?.url
        ? youtubeThumbnail(midias[indiceSelecionado]?.url).high.url
        : getImageUrl(midias[indiceSelecionado]?.imagem),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indiceSelecionado]);

  const handleLeft = () => {
    setIndiceSelecionado(indiceSelecionado <= 1 ? 0 : indiceSelecionado - 1);
  };

  const handleRight = () => {
    setIndiceSelecionado(
      indiceSelecionado >= midias?.length - 1
        ? midias?.length - 1
        : indiceSelecionado + 1,
    );
  };

  const handleArrowsKey = ({key}) => {
    if (key === 'ArrowLeft') handleLeft();
    if (key === 'ArrowRight') handleRight();
  };

  return (
    <Grid container>
      <Grid
        container
        item
        sx={{position: 'relative'}}
        onClick={() => setOpen(true)}>
        <Box
          component="img"
          src={formData.imagem}
          onError={() => setFormData((f) => ({...f, imagem: imageDefault}))}
          sx={styles.imagemGaleria}
        />
        {formData.url && <YouTube sx={styles.yotubeIcon} />}
      </Grid>
      <Modal open={open} onClose={() => setOpen(false)} sx={styles.modal}>
        <Slide in={open} appear>
          <Grid container onKeyUp={handleArrowsKey} sx={styles.container}>
            <Grid item>
              <ChevronLeft onClick={handleLeft} sx={styles.setasIcon} />
            </Grid>
            <Grid container item xs sx={styles.content}>
              <Grid container>
                {formDataModal.url && (
                  <Box
                    component="iframe"
                    src={formDataModal.url}
                    sx={styles.frame}
                  />
                )}
                {!formDataModal.url && (
                  <Box
                    component="img"
                    src={formDataModal.imagem}
                    sx={styles.image}
                  />
                )}
                <Grid container pt={1} justifyContent={'center'} columnGap={2}>
                  {!readOnly && (
                    <Button
                      id="text"
                      title="Remover"
                      onClick={handleClickDelete}
                      endIcon={<Delete />}>
                      Remover
                    </Button>
                  )}
                  <Button
                    id="underline-text"
                    onClick={() => setOpen(false)}
                    endIcon={<Cancel />}>
                    Fechar
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <ChevronRight onClick={handleRight} sx={styles.setasIcon} />
            </Grid>
          </Grid>
        </Slide>
      </Modal>
    </Grid>
  );
}

const styles = {
  imagemGaleria: {
    width: '100%',
    aspectRatio: '4/3',
    borderRadius: '6px',
    objectFit: 'cover',
    cursor: 'pointer',
  },
  deleteContainer: {
    position: 'absolute',
    bottom: 10,
    right: 10,
  },
  yotubeIcon: {
    position: 'absolute',
    color: '#FF0000',
    left: 'calc(50% - 40px)',
    top: 'calc(50% - 40px)',
    height: '80px !important',
    width: '80px !important',
    cursor: 'pointer',
    ':hover': {
      opacity: 0.5,
    },
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    maxWidth: '80%',
    zIndex: (theme) => theme.zIndex.modal,
    background: (theme) => theme.palette.blackSecundary,
    boxShadow: '0px 0px 134.60001px 0px rgba(255, 204, 41, 0.25)',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'row',
    borderRadius: '6px',
    outline: 'none',
    overflowY: 'auto',
    '@media (min-width: 901px)': {
      height: '90%',
    },
    '@media (max-width: 900px)': {
      maxWidth: '100%',
      width: '95%',
    },
  },
  content: {
    aspectRatio: '4/3',
    height: '100%',
    position: 'relative',
    flexWrap: 'nowrap',
    padding: 1,
  },
  frame: {
    width: '100%',
    height: 'calc(100% - 50px)',
    border: 'none',
    borderRadius: '6px',
    aspectRatio: '16/9',
  },
  image: {
    background: '#fff1',
    width: '100%',
    objectFit: 'contain',
    height: 'calc(100% - 50px)',
    border: 'none',
    borderRadius: '6px',
  },
  setasIcon: {
    cursor: 'pointer',
    color: '#fff',
    fontSize: 45,
    margin: 1,
  },
};
