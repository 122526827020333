import {Box, Button, Grid, Typography} from '@mui/material';
import {useNavigate} from 'react-router-dom';

import EmpresrioImage from '../../assets/images/home-empresario.png';

export default function HomeEmpresario() {
  const navigate = useNavigate();

  return (
    <Grid container sx={styles.container}>
      <Grid container sx={styles.content} justifyContent={'space-between'}>
        <Grid container item md={5.5} xs={12} alignContent={'center'}>
          <Box sx={styles.labelContainer}>
            <Typography sx={styles.label}>PARA EMPRESÁRIOS E CLUBES</Typography>
          </Box>
          <Typography id="home-title">
            Encontre os melhores atletas a um clique
          </Typography>
          <Typography sx={styles.subtitle}>
            Descubra as melhores oportunidades de conexão com os atletas
            analisando aspectos importantes como:
          </Typography>
          <Grid container gap={1}>
            <Box sx={styles.topicContainer}>
              <Typography sx={styles.topic}>• Nível de maturidade</Typography>
            </Box>
            <Box sx={styles.topicContainer}>
              <Typography sx={styles.topic}>
                • Condicionamento físico
              </Typography>
            </Box>
            <Box sx={styles.topicContainer}>
              <Typography sx={styles.topic}>
                • Pontos fortes no esporte
              </Typography>
            </Box>
            <Box sx={styles.topicContainer}>
              <Typography sx={styles.topic}>• Habilidades</Typography>
            </Box>
          </Grid>
          <Grid container gap={2} sx={styles.buttonContainer}>
            <Grid item>
              <Button id="outlined" onClick={() => navigate('/cadastro/empresario')}>
                CRIAR CONTA
              </Button>
            </Grid>
            <Grid item>
              <Button id="primary" onClick={() => navigate('/login')}>
                LOGIN
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item md={5.5} xs={12} sx={styles.image}>
          <Box component="img" src={EmpresrioImage} />
        </Grid>
      </Grid>
    </Grid>
  );
}

const styles = {
  container: {
    flex: 1,
    justifyContent: 'center',
    padding: '112px 64px',

    '@media (max-width: 900px)': {
      padding: '16px 16px 32px 16px',
    },
  },
  content: {
    maxWidth: 1200,

    '@media (max-width: 900px)': {
      flexWrap: 'nowrap',
      flexDirection: 'column-reverse',
      alignItems: 'center',
    },
  },
  labelContainer: {
    display: 'flex',
    backgroundColor: '#FEFBE8',
    marginBottom: '16px',
    padding: '4px 8px',
    borderRadius: (theme) => theme.borderRadius,
  },
  label: {
    color: '#000',
    fontSize: 16,
    fontWeight: '700',
    marginTop: '4px',
  },
  subtitle: {
    fontSize: 16,
    fontWeight: '400',
    fontFamily: 'Inter',
    color: '#fff',
    padding: '16px 16px 16px 0',
  },
  topicContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },
  topic: {
    fontSize: 16,
    fontWeight: '400',
    fontFamily: 'Inter',
    color: '#fff',
  },
  buttonContainer: {
    width: 'fit-content',
    marginTop: 4,

    '@media (max-width: 900px)': {
      width: '100%',
      '.MuiGrid-item': {
        width: '100%',
      },
      button: {
        width: '100%',
      },
    },
  },
  image: {
    justifyContent: 'flex-end',
    img: {
      width: '100%',
      objectFit: 'contain',
    },

    '@media (max-width: 900px)': {
      justifyContent: 'center',
      width: '100vw',
      padding: '24px 0',
    },
  },
};
