import {ApolloProvider} from '@apollo/client';
import {ThemeProvider} from '@emotion/react';
import React, {useEffect} from 'react';
import {Helmet} from 'react-helmet';
import {Provider as ReduxProvider, useDispatch} from 'react-redux';
import {ToastContainer} from 'react-toastify';

import './App.css';
import {client} from './config/apollo';
import {AuthContextProvider, useAuth} from './contexts/auth';
import {store} from './redux';
import {setIsMobile} from './redux/App/slice';
import {RouterAuthComponent, RouterComponent} from './router/router';
import {theme} from './theme/theme';
import {getIsMobile} from './utils/screen';

const Main = () => {
  const {isLogged} = useAuth();

  const dispatch = useDispatch();

  useEffect(() => {
    const handleResize = () => {
      dispatch(setIsMobile(getIsMobile()));
    };

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLogged === undefined) {
    return null;
  }

  return isLogged ? <RouterComponent /> : <RouterAuthComponent />;
};

function App() {
  const [isShown] = React.useState(true);

  return (
    <ApolloProvider client={client}>
      <AuthContextProvider>
        <ReduxProvider store={store()}>
          <ThemeProvider theme={theme}>
            <Helmet>
              <html lang="pt-BR" />
              <link rel="preconnect" href="https://fonts.googleapis.com" />
              <link
                rel="stylesheet"
                href="https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap"
              />
              <link
                rel="stylesheet"
                href="https://fonts.googleapis.com/css2?family=Roboto:wght@100;200;300;400;500;600;700;800;900&display=swap"
              />
            </Helmet>
            {isShown ? <Main /> : <div />}
            <ToastContainer />
          </ThemeProvider>
        </ReduxProvider>
      </AuthContextProvider>
    </ApolloProvider>
  );
}

export default App;
