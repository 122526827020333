import {ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY} from '../constants/storage-keys';
import config from './config';

const getFormData = (username, password) => {
  const formData = new FormData();
  formData.append('username', username);
  formData.append('password', password);
  formData.append('client_id', String(config.AUTH_USERNAME));
  formData.append('grant_type', 'password');
  return formData;
};

export const getTokens = async (username, password) => {
  return new Promise(async (resolve, reject) => {
    const response = await fetch(`${String(config.OAUTH_BASE_URL)}`, {
      method: 'POST',
      headers: {
        Authorization: `Basic ${btoa(
          config.AUTH_USERNAME + ':' + config.AUTH_PASSWORD,
        )}`,
      },
      body: getFormData(username, password),
    });
    const tokens = await response.json();
    if (tokens.error) {
      return reject(new Error(tokens.error_description));
    }
    const save = await saveTokens(tokens);
    resolve(save);
  });
};

const saveTokens = async (data) => {
  if (data.access_token && data.refresh_token) {
    await window.localStorage.setItem(ACCESS_TOKEN_KEY, data.access_token);
    await window.localStorage.setItem(REFRESH_TOKEN_KEY, data.refresh_token);
    return true;
  }
  return false;
};

export const removeAllTokens = async () => {
  await window.localStorage.removeItem(ACCESS_TOKEN_KEY);
  await window.localStorage.removeItem(REFRESH_TOKEN_KEY);
};

export const refreshAccessToken = async () => {
  const refreshToken = await window.localStorage.getItem(REFRESH_TOKEN_KEY);
  const formData = new FormData();
  formData.append('grant_type', 'refresh_token');
  formData.append('refresh_token', refreshToken || '');
  try {
    const response = await fetch(`${String(config.OAUTH_BASE_URL)}`, {
      method: 'POST',
      headers: {
        Authorization: `Basic ${btoa(
          config.AUTH_USERNAME + ':' + config.AUTH_PASSWORD,
        )}`,
      },
      body: formData,
    });
    const tokens = await response.json();
    const save = await saveTokens(tokens);
    if (save) {
      return {success: save, error: false};
    } else {
      throw new Error('Erro ao atualizar token');
    }
  } catch (error) {
    await removeAllTokens();
    return {
      success: false,
      error: error,
    };
  }
};

export const recoverPassword = (email) => {
  return new Promise(async (resolve, reject) => {
    const response = await fetch(
      `${String(config.BASE_URL)}/public/v1/usuario/redefinir-senha`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email,
          clientId: config.AUTH_USERNAME,
        }),
      },
    );

    const body = await response.json();

    if (response.ok) return resolve(body);

    reject(new Error(body.mensagem));
  });
};

export const resetPassword = (token, password) => {
  return new Promise(async (resolve, reject) => {
    const response = await fetch(
      `${String(config.BASE_URL)}/public/v1/usuario/redefinir-senha/${token}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          senha: password,
        }),
      },
    );

    const body = await response.json();
    if (response.ok) return resolve(body);

    reject(new Error(body.mensagem));
  });
};

export const logoutAccount = async () => {
  try {
    const accessToken = await window.localStorage.getItem(ACCESS_TOKEN_KEY);
    await fetch(
      `${String(config.BASE_URL)}/api/v1/logout?token=${accessToken}`,
      {method: 'GET'},
    );
    return {success: true, error: false};
  } catch (error) {
    return {success: false, error: true};
  }
};
