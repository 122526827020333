import React from 'react';

import {FIND_ALL_ATLETAS as query} from '../../../graphql/queries';
import String from '../../../utils/string';
import {SelectBase} from './select-apollo';

const transformOptions = ({content}) => {
  return Array.isArray(content)
    ? content.map((item) => ({
        ...item,
        label: `${item.nome} - ${String.formatCpfCnpj(item.cpf)}`,
        value: item.id,
      }))
    : [];
};

const getVariables = ({
  pageNumber,
  search = '',
  searchDTO = {},
  pageSize = 20,
}) => {
  return {
    pageable: {pageNumber, pageSize, sortField: 'nome', sortDir: 'ASC'},
    searchDTO: {
      ...searchDTO,
      search,
    },
  };
};

export const SelectAtletas = (props) => {
  return (
    <SelectBase
      responseObjectName="atletas"
      transformOptions={transformOptions}
      query={query}
      getVariables={(searchProps) => getVariables({...searchProps, ...props})}
      {...props}
    />
  );
};
