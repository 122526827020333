import {KeyboardArrowDown, KeyboardArrowUp} from '@mui/icons-material';
import {Collapse, Grid, Link, Typography} from '@mui/material';
import moment from 'moment/moment';
import {useState} from 'react';

import {
  Facebook,
  Instagram,
  Kwai,
  Linkedin,
  Logo,
  Tiktok,
  X,
  Youtube,
} from './icons';

export default function Footer() {
  return (
    <Grid container sx={styles.container}>
      <Grid container sx={styles.content}>
        <MobileMenu />
        <Grid container className="footer-menu" sx={styles.menuFooter}>
          <Grid item>
            <Logo />
            <Typography sx={styles.options}>
               Rua Rev Simonton, 131 - Jardim Redenção
            </Typography>
            <Typography sx={styles.options}>
              São Paulo - SP, 03284-150, Brasil
            </Typography>
          </Grid>
          <Grid item>
            <Typography sx={styles.optionsTitle}>Para atletas</Typography>
            <Link href="#/cadastro/atleta" sx={styles.options}>
              Criar conta
            </Link>
            <Link href="#/login" sx={styles.options}>
              Entrar no sistema
            </Link>
          </Grid>
          <Grid item>
            <Typography sx={styles.optionsTitle}>Para empresários</Typography>
            <Link href="#/cadastro/empresario" sx={styles.options}>
              Criar conta
            </Link>
            <Link href="#/login" sx={styles.options}>
              Entrar no sistema
            </Link>
          </Grid>
          <Grid item>
            <Typography sx={styles.optionsTitle}>
              Para profissionais de saúde
            </Typography>
            <Link href="#/cadastro" sx={styles.options}>
              Criar conta
            </Link>
            <Link href="#/login" sx={styles.options}>
              Entrar no sistema
            </Link>
          </Grid>
          <Grid item>
            <Typography sx={styles.optionsTitle}>Para jornalistas</Typography>
            <Link href="#/cadastro" sx={styles.options}>
              Criar conta
            </Link>
            <Link href="#/login" sx={styles.options}>
              Entrar no sistema
            </Link>
          </Grid>
        </Grid>
        <Grid container sx={styles.footer} rowGap={2}>
          <Grid item>
            <Typography sx={styles.footerText}>
              Desenvolvido com 💛 em Maringá por Grupo Azul
            </Typography>
          </Grid>
          <Grid item>
            <Typography sx={styles.footerText}>
              © {moment().format('YYYY')} WOLF PRO LINK LTDA - 53.609.014/0001-73. Todos os direitos
              reservados
            </Typography>
          </Grid>
          <Grid item>
            <Link
              href="https://www.wolfprolink.com.br/contratos/politica-privacidade.html"
              target="_blank"
              sx={styles.footerText}>
              Policia de privacidade e Termos de Uso
            </Link>
          </Grid>
          <Grid display={'flex'} item>
            <Link
              href="https://www.facebook.com/wolfprolink"
              target="_blank"
              sx={styles.footerText}>
              <Facebook title="Facebook" style={styles.icon} />
            </Link>
            <Link
              href="https://www.instagram.com/wolfprolinksports"
              target="_blank"
              sx={styles.footerText}>
              <Instagram title="Instragram" style={styles.icon} />
            </Link>
            <Link
              href="https://twitter.com/wolfprolink"
              target="_blank"
              sx={styles.footerText}>
              <X title="X" style={styles.icon} />
            </Link>
            <Link
              href="https://www.linkedin.com/company/wolf-pro-link"
              target="_blank"
              sx={styles.footerText}>
              <Linkedin title="LinkedIn" style={styles.icon} />
            </Link>
            <Link
              href="https://www.youtube.com/@wolfprolink"
              target="_blank"
              sx={styles.footerText}>
              <Youtube title="YouTube" style={styles.icon} />
            </Link>
            <Link
              href="https://www.kwai.com/@wolfprolink"
              target="_blank"
              sx={styles.footerText}>
              <Kwai title="Kwai" style={styles.icon} />
            </Link>
            <Link
              href="https://www.tiktok.com/@wolfprolink"
              target="_blank"
              sx={styles.footerText}>
              <Tiktok title="TikTok" style={styles.icon} />
            </Link>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

const MobileMenu = () => {
  const [openAtleta, setOpenAtleta] = useState(false);
  const [openEmpresario, setOpenEmpresario] = useState(false);
  const [openMedico, setOpenMedico] = useState(false);
  const [openJornalista, setOpenJornalista] = useState(false);
  return (
    <Grid container className="footer-menu-mobile" sx={styles.menuFooter}>
      <Grid
        container
        item
        xs={12}
        sx={[styles.menuCollapse, openAtleta && styles.menuCollapseOpen]}
        onClick={() => setOpenAtleta(!openAtleta)}>
        <Typography sx={styles.menuTitle}>Para atletas</Typography>
        {openAtleta && <KeyboardArrowUp />}
        {!openAtleta && <KeyboardArrowDown />}
        <Grid item xs={12}>
          <Collapse
            in={openAtleta}
            timeout="auto"
            unmountOnExit
            sx={styles.collapse}>
            <Link sx={styles.collapseOptions}>Criar conta</Link>
            <Link sx={styles.collapseOptions}>Entrar no sistema</Link>
            <Link sx={styles.collapseOptions}>Peça a avaliação médica</Link>
          </Collapse>
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={12}
        sx={[styles.menuCollapse, openEmpresario && styles.menuCollapseOpen]}
        onClick={() => setOpenEmpresario(!openEmpresario)}>
        <Typography sx={styles.menuTitle}>Para empresários</Typography>
        {openEmpresario && <KeyboardArrowUp />}
        {!openEmpresario && <KeyboardArrowDown />}
        <Grid item xs={12}>
          <Collapse
            in={openEmpresario}
            timeout="auto"
            unmountOnExit
            sx={styles.collapse}>
            <Link sx={styles.collapseOptions}>Criar conta</Link>
            <Link sx={styles.collapseOptions}>Entrar no sistema</Link>
          </Collapse>
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={12}
        sx={[styles.menuCollapse, openMedico && styles.menuCollapseOpen]}
        onClick={() => setOpenMedico(!openMedico)}>
        <Typography sx={styles.menuTitle}>
          Para profissionais de saúde
        </Typography>
        {openMedico && <KeyboardArrowUp />}
        {!openMedico && <KeyboardArrowDown />}
        <Grid item xs={12}>
          <Collapse
            in={openMedico}
            timeout="auto"
            unmountOnExit
            sx={styles.collapse}>
            <Link sx={styles.collapseOptions}>Criar conta</Link>
            <Link sx={styles.collapseOptions}>Entrar no sistema</Link>
          </Collapse>
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={12}
        sx={[styles.menuCollapse, openJornalista && styles.menuCollapseOpen]}
        onClick={() => setOpenJornalista(!openJornalista)}>
        <Typography sx={styles.menuTitle}>Para jornalistas</Typography>
        {openJornalista && <KeyboardArrowUp />}
        {!openJornalista && <KeyboardArrowDown />}
        <Grid item xs={12}>
          <Collapse
            in={openJornalista}
            timeout="auto"
            unmountOnExit
            sx={styles.collapse}>
            <Link sx={styles.collapseOptions}>Criar conta</Link>
            <Link sx={styles.collapseOptions}>Entrar no sistema</Link>
          </Collapse>
        </Grid>
      </Grid>
      <Grid container textAlign={'center'} rowGap={1} pt={'32px'}>
        <Grid item xs={12}>
          <Logo />
        </Grid>
        <Grid item xs={12}>
          <Typography sx={styles.options}>
            Av. Tamandaré, 100 - Sobreloja - Zona 01
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography sx={styles.options}>
            Maringá - PR, 87013-210, Brasil
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

const styles = {
  container: {
    justifyContent: 'center',
    backgroundColor: (theme) => theme.palette.backgroundColor,
    borderTop: (theme) => `1px solid ${theme.palette.yellow}`,
    padding: '80px 64px',

    '@media (max-width: 900px)': {
      padding: '80px 16px',
    },
  },
  content: {
    maxWidth: 1200,

    '.footer-menu': {
      display: 'flex',
    },
    '.footer-menu-mobile': {
      display: 'none',
    },

    '@media (max-width: 900px)': {
      '.footer-menu': {
        display: 'none',
      },
      '.footer-menu-mobile': {
        display: 'flex',
      },
    },
  },
  menuFooter: {
    justifyContent: 'space-between',
    paddingBottom: '32px',

    '@media (max-width: 900px)': {
      paddingBottom: '64px',
    },
  },
  optionsTitle: {
    color: (theme) => theme.palette.white,
    margin: '16px 0',
    fontSize: 16,
    fontFamily: 'Roboto',
    fontWeight: '600',
  },
  options: {
    display: 'block',
    color: (theme) => theme.palette.white,
    margin: '8px 0',
    fontSize: 14,
    fontFamily: 'Roboto',
    fontWeight: '400',
    textDecoration: 'none',
  },
  footer: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignContent: 'flex-start',
    justifyContent: 'space-between',
    borderTop: (theme) => `1px solid ${theme.palette.white}`,
    padding: '32px 0',

    '@media (max-width: 900px)': {
      flexWrap: 'wrap',
      justifyContent: 'center',
      textAlign: 'center',
    },
  },
  footerText: {
    display: 'flex',
    color: (theme) => theme.palette.white,
    textDecorationColor: '#fff',
    alignSelf: 'center',
    fontSize: 12,
    lineHeight: '32px',
    fontFamily: 'Roboto',
    fontWeight: '400',
  },
  icon: {
    marginLeft: '12px',
  },
  menuCollapse: {
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    borderBottom: '1px solid #fff',
    cursor: 'pointer',
    padding: 2,
    svg: {
      color: '#fff',
      fontSize: 32,
    },
  },
  menuCollapseOpen: {
    transition: 'background-color 0.5s ease',
    borderBottom: 'none !important',
    backgroundColor: (theme) => theme.palette.blackSecundary,
    borderRadius: '6px',
    marginBottom: 1,
  },
  menuTitle: {
    fontFamily: 'Roboto',
    alignSelf: 'center',
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '24px',
    color: '#fff',
  },
  collapse: {
    width: '100%',
    height: 'fit-content',
    color: '#fff',
    paddingTop: 2,
  },
  collapseOptions: {
    display: 'block',
    color: '#fff',
    margin: '8px 0',
    fontSize: 14,
    fontFamily: 'Roboto',
    fontWeight: '400',
    textDecoration: 'none',
    borderBottom: '1px solid #fff',
    padding: '8px 0',
    '&:last-child': {
      borderBottom: 'none',
    },
  },
};
