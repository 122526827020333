import {useQuery} from '@apollo/client';
import {Add, Edit} from '@mui/icons-material';
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import {useEffect, useState} from 'react';

import ButtonResponsive from '../../../components/buttons/button-responsive';
import Filtros from '../../../components/filtro/filtros';
import Input from '../../../components/input/input';
import {SelectCidades} from '../../../components/input/select/cidades';
import {FIND_ALL_ADMINISTRADORES} from '../../../graphql/queries';
import EditarPerfilAdministrador from '../modal/editar-perfil';

export default function ConsultaAdministradores() {
  const [administradores, setAdministradores] = useState([]);
  const [searchDTO, setSearchDTO] = useState({});
  const [page, setPage] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [idSelecionado, setIdSelecioando] = useState(null);

  const adminQuery = useQuery(FIND_ALL_ADMINISTRADORES, {
    variables: {
      pageable: {
        pageNumber: page,
        pageSize: 20,
        sortField: 'nome',
        sortDir: 'ASC',
      },
      searchDTO: {
        search: searchDTO.search,
        cidadeId: searchDTO.cidade?.id,
      },
    },
  });

  useEffect(() => {
    if (adminQuery.loading) return;

    setAdministradores(adminQuery.data?.administradores?.content || []);
    setTotalElements(adminQuery.data?.administradores?.totalElements || 0);
  }, [adminQuery]);

  useEffect(() => {
    if (!idSelecionado) return;

    setOpenModal(true);
  }, [idSelecionado]);

  useEffect(() => {
    setPage(0);
  }, [searchDTO]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Grid container item xs={12} paddingBottom={4}>
      <Filtros>
        <Grid item md={6} xs={12}>
          <Input
            name="busca"
            placeholder="Pesquisar por nome"
            type="text"
            autoComplete="off"
            value={searchDTO.search || ""}
            onChange={({target}) =>
              setSearchDTO((f) => ({...f, search: target?.value}))
            }
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <SelectCidades
            placeholder="Pesquisar por cidade"
            value={searchDTO.cidade}
            onChange={(cidade) => setSearchDTO((f) => ({...f, cidade}))}
            isClearable
          />
        </Grid>
      </Filtros>
      <Grid
        container
        item
        xs={12}
        paddingBottom={1}
        alignItems={'center'}
        justifyContent={'flex-end'}>
        <Button id="text" endIcon={<Add />} onClick={() => setOpenModal(true)}>
          Cadastrar novo
        </Button>
      </Grid>
      <TableContainer id="table-container">
        <Table id="table">
          <TableHead id="table-head">
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell>E-mail</TableCell>
              <TableCell>Cidade</TableCell>
              <TableCell sx={{width: '182px'}} align="center">
                Ações
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody id="table-body">
            {administradores.map((a) => (
              <TableRow key={a.id} id="table-row">
                <TableCell>{a.nome}</TableCell>
                <TableCell>{a.email}</TableCell>
                <TableCell>{a.endereco?.cidade?.nomeEUf}</TableCell>
                <TableCell align="right">
                  <ButtonResponsive
                    id="primary"
                    value="Editar"
                    onClick={() => setIdSelecioando(a.id)}
                    icon={<Edit />}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow id="table-row-footer">
              <TableCell id="table-pagination" colSpan={4}>
                <TablePagination
                  component="div"
                  count={totalElements}
                  rowsPerPageOptions={[]}
                  rowsPerPage={20}
                  page={page}
                  labelDisplayedRows={({from, to, count}) =>
                    `${from}-${to} de ${count}`
                  }
                  onPageChange={handleChangePage}
                />
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <EditarPerfilAdministrador
        open={openModal}
        onClose={() => {
          setOpenModal(false);
          setIdSelecioando(null);
          adminQuery?.refetch();
        }}
        administradorId={idSelecionado}
        isAdmin
      />
    </Grid>
  );
}
