import {Route, Routes} from 'react-router-dom';

import NoticiasBusca from './busca';
import NoticiaCadastro from './cadastrar';
import NoticiaEditar from './editar';
import VisualizarNoticia from './visualizar';

export default function Noticia() {
  return (
    <Routes>
      <Route path={`/`} element={<NoticiasBusca />} />
      <Route path={`/cadastrar`} element={<NoticiaCadastro />} />
      <Route path={`/editar/:noticiaId`} element={<NoticiaEditar />} />
      <Route path={`/:noticiaId`} element={<VisualizarNoticia />} />
    </Routes>
  );
}
