const config = {
  BASE_URL: process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_AUTHENTICATION_URL,
  OAUTH_BASE_URL: `${process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_AUTHENTICATION_URL}/oauth/token`,
  AUTH_API_BASE_URL: `${process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_AUTHENTICATION_URL}/api/v1`,
  API_BASE_URL: `${process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_API_URL}/api/v1/graphql`,
  AMAZON_S3_BASE_URL: `${process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_AMAZON_S3_URL}`,
  AMAZON_S3_URL: `${process.env.REACT_APP_PROTOCOL + process.env.REACT_APP_AMAZON_S3_URL}/amazon/s3/`,

  AUTH_USERNAME: process.env.REACT_APP_AUTH_USERNAME,
  AUTH_PASSWORD: process.env.REACT_APP_AUTH_PASSWORD,
};

export default config;
