import {AppBar, Button, Grid} from '@mui/material';
import {useNavigate} from 'react-router-dom';

import {LogoBeta} from '../icons';

export default function ToolbarLogin() {
  const navigate = useNavigate();

  return (
    <AppBar sx={styles.appBar}>
      <Grid container sx={styles.container}>
        <Grid
          item
          alignSelf={'center'}
          sx={styles.logo}
          onClick={() => navigate('/home')}>
          <LogoBeta />
        </Grid>
        <Grid container item columnGap={2} sx={styles.content}>
          <Button
            id="text"
            sx={styles.createButton}
            onClick={() => navigate('/home')}>
            VOLTAR AO INÍCIO
          </Button>
        </Grid>
      </Grid>
    </AppBar>
  );
}

const styles = {
  appBar: {
    height: (theme) => theme.toolbarHeight,
    padding: '8px 64px',
    backgroundColor: (theme) => theme.palette.backgroundColor,
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: 'none',

    '@media (max-width: 900px)': {
      padding: '8px 16px',
    },
  },
  container: {
    maxWidth: 1200,
    justifyContent: 'space-between',
  },
  logo: {
    cursor: 'pointer',
  },
  content: {
    width: 'auto',
    alignSelf: 'center',
  },
  createButton: {
    '@media (max-width: 900px)': {
      padding: 1,
    },
  },
};
