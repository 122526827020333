import {useMutation} from '@apollo/client';
import {Close} from '@mui/icons-material';
import {
  Button,
  Grid,
  IconButton,
  Modal,
  Slide,
  Typography,
} from '@mui/material';
import {useEffect, useState} from 'react';

import Input from '../../../../components/input/input';
import {toastNotification} from '../../../../components/toastify';
import {CREATE_ANAMNESE_GRUPO} from '../../../../graphql/mutation';

export default function GrupoModal({grupo = {}, open, onClose, onSave}) {
  const [formData, setFormData] = useState(grupo);

  useEffect(() => {
    setFormData(grupo);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const [saveGrupo] = useMutation(CREATE_ANAMNESE_GRUPO);

  const onSaveGrupo = () => {
    if (!formData.ordem || !formData.nome) return;

    saveGrupo({
      variables: {
        anamneseModeloGrupo: {
          id: grupo.id,
          nome: formData.nome,
          ordem: formData.ordem,
        },
      },
    })
      .then(({data}) => onSave(data.grupo))
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      );
  };

  useEffect(() => {
    if (open) return;

    setFormData({});
  }, [open]);

  return (
    <Modal open={open} onClose={onClose} sx={styles.modal}>
      <Slide in={open} appear>
        <Grid
          container
          className="styled-scroll-transparent-desktop"
          sx={styles.container}>
          <Grid container item sx={styles.header}>
            <Typography sx={styles.title}>GRUPO DA PERGUNTA</Typography>
            <IconButton sx={styles.closeIcon} onClick={onClose}>
              <Close />
            </IconButton>
          </Grid>
          <Grid container sx={styles.content}>
            <Grid container item spacing={2}>
              <Grid item md={4} xs={12}>
                <Input
                  label="Ordem"
                  placeholder={'Ordem'}
                  name="ordem"
                  type="number"
                  value={formData.ordem || ''}
                  onChange={({target}) =>
                    setFormData((f) => ({...f, ordem: target?.value}))
                  }
                />
              </Grid>
              <Grid item md={8} xs={12}>
                <Input
                  label="Nome"
                  placeholder={'Nome'}
                  name="nome"
                  type="text"
                  value={formData.nome || ''}
                  onChange={({target}) =>
                    setFormData((f) => ({...f, nome: target?.value}))
                  }
                />
              </Grid>
            </Grid>
            <Grid item xs={12} paddingTop={4}>
              <Button id="primary" onClick={onSaveGrupo}>
                Salvar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Slide>
    </Modal>
  );
}

const styles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    position: 'relative',
    maxHeight: '90vh',
    width: '80%',
    borderRadius: '6px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    padding: 3,
    paddingTop: 0,
    zIndex: (theme) => theme.zIndex.modal,
    background: (theme) => theme.palette.blackSecundary,
    boxShadow: '0px 0px 134.60001px 0px rgba(255, 204, 41, 0.25)',
    outline: 'none',
    overflowY: 'auto',

    '@media (max-width: 900px)': {
      width: '90%',
    },
  },
  header: {
    flexGrow: 0,
    padding: 2,
    justifyContent: 'space-between',
    svg: {
      color: '#fff',
    },
  },
  closeIcon: {
    width: '32px',
    height: '32px',
  },
  title: {
    color: '#FFF',
    textAlign: 'center',
    fontFamily: 'Now',
    fontSize: '24px',
    fontWeight: '700',
    textTransform: 'uppercase',

    '@media (max-width: 1200px)': {
      fontSize: '20px',
    },
  },
  content: {
    flexDirection: 'row',
    width: '100%',
    padding: '16px',
  },
};
