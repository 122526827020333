import {useLazyQuery} from '@apollo/client';
import {CircularProgress, Grid, Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import {useSelector} from 'react-redux';

import Toolbar from '../../components/toolbar/toolbar';
import {FIND_ALL_NOTICIAS} from '../../graphql/queries';
import CardBusca from './components/card-busca';

const pageable = {
  pageSize: 20,
  sortField: 'dataCadastro',
  sortDir: 'ASC',
};

export default function NoticiasBusca() {
  const {filtros} = useSelector((state) => state.Busca);

  const [noticias, setNoticias] = useState([]);
  const [searchDTO, setSearchDTO] = useState({});
  const [pageElements, setPageElements] = useState(0);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);

  const [loadNoticias, noticiasQuery] = useLazyQuery(FIND_ALL_NOTICIAS);

  useEffect(() => {
    setLoading(noticiasQuery.loading);
    if (noticiasQuery.loading || !noticiasQuery.data?.noticias) return;

    const result = noticiasQuery.data?.noticias;

    setPageElements({
      totalElements: result.totalElements,
      hasMore: result.last === false,
    });
    if (page === 0) setNoticias(result.content || []);
    else setNoticias((e) => [...e, ...(result.content || [])]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noticiasQuery]);

  useEffect(() => {
    loadNoticias({
      variables: {
        pageable: {...pageable, pageNumber: page},
        searchDTO: {...searchDTO, situacao: 'APROVADO'},
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, searchDTO]);

  // eslint-disable-next-line
  const handleFilter = () => {
    setPage(0);
    setSearchDTO({
      modalidadeId: filtros.modalidade,
      faixaEtaria: filtros.faixaEtaria,
      funcoesId: getChecboxFilters(filtros.posicoes),
      search: filtros.search,
      cidadeId: filtros.cidade?.id,
    });
  };

  const getChecboxFilters = (filtros = {}) => {
    var keys =
      Object.keys(filtros).filter((key) => filtros[key] === true) || [];
    return keys.length > 0 ? keys : undefined;
  };

  const handleMore = () => {
    if (loading) return;
    setLoading(true);
    setPage((p) => ++p);
  };

  return (
    <Grid container sx={styles.container}>
      <Toolbar />
      <Grid container sx={{maxWidth: '1200px', alignContent: 'flex-start'}}>
        <Grid container item xs={12} sx={styles.listContainer}>
          {loading && (
            <Grid sx={styles.loadingContainer}>
              <CircularProgress />
            </Grid>
          )}
          <InfiniteScroll
            pageStart={0}
            loadMore={handleMore}
            hasMore={pageElements.hasMore}
            threshold={20}
            style={{width: '100%'}}>
            <ListaCards itens={noticias} />
          </InfiniteScroll>
        </Grid>
      </Grid>
    </Grid>
  );
}

const ListaCards = ({itens}) => {
  if (itens.length === 0) {
    return (
      <Typography sx={styles.emptyText}>Nenhum resultado encontrado</Typography>
    );
  }

  return (
    <React.Fragment>
      {itens.map((item) => (
        <CardBusca key={item.id} item={item} />
      ))}
    </React.Fragment>
  );
};

const styles = {
  container: {
    backgroundColor: (theme) => theme.palette.backgroundColor,
    paddingTop: (theme) => theme.toolbarHeight,
    justifyContent: 'center',
    minHeight: '100vh',
    paddingX: `64px`,

    '@media (max-width: 900px)': {
      padding: (theme) => `calc(${theme.toolbarHeight} + 16px) 0 0 0`,
    },
  },
  header: {
    position: 'sticky',
    top: '75px',
    '@media (max-width: 900px)': {
      position: 'fixed',
      zIndex: 2,
      top: (theme) => theme.toolbarHeight,
      flexDirection: 'center',
      backgroundColor: (theme) => theme.palette.backgroundColor,
      paddingTop: '15px',
      height: '55px',
    },
  },
  totalElements: {
    '@media (max-width: 900px)': {
      display: 'flex',
      alignItems: 'center',
      alignSelf: 'center',
      height: '40.5px',
      borderRight: '1px solid #141412',
      backgroundColor: (theme) => theme.palette.blackSecundary,
    },
  },
  filtrosContainer: {
    '@media (max-width: 900px)': {
      height: '40.5px',
      backgroundColor: (theme) => theme.palette.blackSecundary,
    },
  },
  resultadosNumero: {
    color: '#FFF',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 500,
    marginBottom: 2,

    '@media (max-width: 900px)': {
      marginBottom: 0,
      marginLeft: 2,
    },
  },
  listContainer: {
    position: 'relative',
    padding: '37px 24px',
    alignContent: 'flex-start',

    '@media (max-width: 900px)': {
      paddingX: '0px',
      paddingTop: '75px',
    },
  },
  loadingContainer: {
    position: 'fixed',
    justifyContent: 'center',
    top: '200px',
    left: '50%',
    zIndex: 2,
    padding: 1,
    width: '56px',
    height: '56px',
    borderRadius: '50%',
    background: (theme) => theme.palette.backgroundColor,
  },
  emptyText: {
    marginTop: '30px',
    width: '100%',
    maxWidth: '653px',
    textAlign: 'center',
    color: '#FFF',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: 'normal',
    letterSpacing: '0.42px',
  },
};
