import {useLazyQuery, useMutation, useQuery} from '@apollo/client';
import {
  Add,
  Edit,
  Facebook,
  Instagram,
  LinkedIn,
  Mail,
  PersonRounded,
  PhotoCamera,
} from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Link,
  Typography,
} from '@mui/material';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import ImagemCrop from '../../components/crop-image';
import {X} from '../../components/icons';
import Midia from '../../components/midia';
import NewsCard from '../../components/news-card';
import {toastNotification} from '../../components/toastify';
import {useAuth} from '../../contexts/auth';
import {UPDATE_EMPRESARIO_FOTO_PERFIL} from '../../graphql/mutation';
import {FIND_ALL_NOTICIAS, FIND_EMPRESARIO_BY_ID} from '../../graphql/queries';
import {getImageUrl, uploadNestor} from '../../utils/file';
import String from '../../utils/string';
import EditarPerfilEmpresario from './modais/editar-perfil';
import MidiaLink from './modais/midia-link';
import SobreEmpresario from './modais/sobre';

export default function EmpresarioPerfil({empresarioId, readOnly = true}) {
  const navigate = useNavigate();
  const {refetchEntidade} = useAuth();

  const [empresario, setEmpresario] = useState({});
  const [noticias, setNoticias] = useState([]);
  const [openPerfil, setOpenPerfil] = useState(false);
  const [openBase, setOpenBase] = useState(false);
  const [openSobre, setOpenSobre] = useState(false);
  const [openMidia, setOpenMidia] = useState(false);

  const noticiasQuery = useQuery(FIND_ALL_NOTICIAS, {
    variables: {
      pageable: {
        pageNumber: 0,
        pageSize: 3,
        sortField: 'dataCadastro',
        sortDir: 'DESC',
      },
      searchDTO: {
        situacao: 'APROVADO',
      },
    },
  });

  const [loadEmpresario, empresarioQuery] = useLazyQuery(FIND_EMPRESARIO_BY_ID);

  const [saveEmpresarioPerfil] = useMutation(UPDATE_EMPRESARIO_FOTO_PERFIL);

  useEffect(() => {
    if (!empresarioId) return;

    loadEmpresario({
      variables: {
        id: empresarioId,
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [empresarioId]);

  useEffect(() => {
    if (empresarioQuery.loading) return;

    setEmpresario(empresarioQuery.data?.empresario || {});
  }, [empresarioQuery]);

  useEffect(() => {
    if (noticiasQuery.loading) return;

    setNoticias(noticiasQuery.data?.noticias.content || []);
  }, [noticiasQuery]);

  useEffect(() => {
    if (openBase || openSobre || openMidia) return;

    empresarioQuery.refetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openBase, openSobre, openMidia]);

  const getStringEndereco = () => {
    if (!empresario.enderecos?.length) return '';
    const endereco =
      empresario.enderecos?.filter((e) => e.principal)?.[0] ||
      empresario.enderecos?.[0];

    return `${endereco?.cidade?.nome} - ${endereco?.cidade?.uf}`;
  };

  const handleSavePerfil = async (file) => {
    if (!file) return;

    let response = null;
    try {
      response = await uploadNestor(file);
    } catch (error) {
      console.error('Erro upload', error);
    }

    saveEmpresarioPerfil({
      variables: {
        empresario: {
          id: empresario.id,
          fotoPerfil: response?.data?.id,
        },
      },
    })
      .then(() => {
        setOpenPerfil(false);
        toastNotification({
          message: 'Imagem de perfil atualizada!',
          type: 'success',
        });
        empresarioQuery.refetch();
        refetchEntidade();
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      );
  };

  return (
    <Grid container sx={styles.container}>
      <Grid container sx={styles.content} padding={2}>
        <Grid container item xs={12} gap={2} sx={styles.dadosContainer}>
          <Grid container item gap={1} xs sx={styles.avatarContainer}>
            <Avatar
              src={getImageUrl(empresario?.fotoPerfil)}
              sx={[
                styles.avatar,
                empresario?.fotoPerfil && styles.imagemPerfil,
                !readOnly && {cursor: 'pointer'},
              ]}
              onClick={() => setOpenPerfil(!readOnly && true)}>
              <PhotoCamera />
              {!readOnly && (
                <Typography sx={styles.fotoPerfilText}>
                  ADICIONAR FOTO DE PERFIL
                </Typography>
              )}
            </Avatar>
            <Grid container item sx={styles.redesContainer} columnGap={1}>
              {empresario.urlFacebook && (
                <Link
                  title="Facebook"
                  href={empresario.urlFacebook}
                  target="_blank"
                  rel="noopener"
                  sx={styles.footerText}>
                  <Facebook />
                </Link>
              )}
              {empresario.urlInstagram && (
                <Link
                  title="Instragram"
                  href={empresario.urlInstagram}
                  target="_blank"
                  rel="noopener"
                  sx={styles.footerText}>
                  <Instagram />
                </Link>
              )}
              {empresario.urlX && (
                <Link
                  title={'X'}
                  href={empresario.urlX}
                  target="_blank"
                  rel="noopener"
                  sx={styles.footerText}>
                  <X style={styles.icon} />
                </Link>
              )}
              {empresario.urlLinkedin && (
                <Link
                  title="LinkedIn"
                  href={empresario.urlLinkedin}
                  target="_blank"
                  rel="noopener"
                  sx={styles.footerText}>
                  <LinkedIn />
                </Link>
              )}
              <Link
                title="Email"
                href={`mailto://${empresario.email}`}
                sx={styles.footerText}>
                <Mail />
              </Link>
            </Grid>
          </Grid>
          <Grid container item flex={1} direction={'column'} xs>
            <Typography id="modalidade-tag" className="empresario">
              Empresário
            </Typography>
            <Typography sx={styles.title}>{empresario.nome}</Typography>
            {!!empresario.enderecos?.length && (
              <Typography sx={styles.text}>{getStringEndereco()}</Typography>
            )}
            <Typography sx={styles.text}>
              {String.formatTelefone(empresario.telefone)}
            </Typography>
          </Grid>
          <Grid
            container
            item
            sx={[styles.editarDadosContainer, readOnly && styles.hidden]}
            gap={1}
            xs>
            <IconButton
              id="icon-outlined"
              sx={styles.editarButton}
              onClick={() => setOpenBase(true)}>
              <Edit />
            </IconButton>
          </Grid>
        </Grid>
        <Grid container paddingY={'32px'}>
          <Divider sx={{borderColor: '#fff', width: '100%'}} />
        </Grid>
        <Grid container item xs={12}>
          <Grid container justifyContent={'space-between'} pb={1}>
            <Typography sx={styles.title}>SOBRE MIM</Typography>
            <IconButton
              id="icon-outlined"
              onClick={() => setOpenSobre(true)}
              sx={[styles.editarButton, readOnly && styles.hidden]}>
              <Edit />
            </IconButton>
          </Grid>
          <Typography component="pre" sx={styles.text}>
            {empresario.descricao}
          </Typography>
        </Grid>
        <Grid container paddingY={'32px'}>
          <Divider sx={{borderColor: '#fff', width: '100%'}} />
        </Grid>
        <Grid container item xs={12}>
          <Grid container justifyContent={'space-between'} pb={2}>
            <Typography sx={styles.title}>MÍDIA</Typography>
            <IconButton
              id="icon-outlined"
              onClick={() => setOpenMidia(true)}
              sx={[styles.editarButton, readOnly && styles.hidden]}>
              <Add />
            </IconButton>
          </Grid>
          <Grid container spacing={1}>
            {empresario.midiaLinks?.map((midia, index) => (
              <Grid key={midia.id} item md={3} xs={6}>
                <Midia
                  midia={midia}
                  midias={empresario.midiaLinks}
                  index={index}
                  refetch={empresarioQuery.refetch}
                  readOnly={readOnly}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid container paddingY={'32px'}>
          <Divider sx={{borderColor: '#fff', width: '100%'}} />
        </Grid>
        <Grid display={readOnly ? 'none' : 'flex'} container item xs={12}>
          <Grid container justifyContent={'space-between'}>
            <Typography sx={styles.title}>ATLETAS FAVORITOS</Typography>
          </Grid>
          {empresario?.usuario?.atletasConexao?.map(({atleta}) => (
            <Grid key={atleta?.id} item xs={12}>
              <Grid
                container
                sx={styles.favoritoContainer}
                columnGap={2}
                onClick={() => navigate(`/atleta/${atleta.id}`)}>
                <Avatar
                  src={getImageUrl(atleta?.fotoPerfil)}
                  alt={atleta?.nome}
                  sx={styles.avatarEmpresario}>
                  <PersonRounded />
                </Avatar>
                <Box>
                  <Typography className="nome" sx={styles.empresarioNome}>
                    {atleta?.nome}
                  </Typography>
                  <Typography sx={styles.dataContrato}>
                    {atleta.modalidade?.descricao}
                  </Typography>
                  <Typography sx={styles.dataContrato}>
                    {atleta.funcoes?.map((f) => f.descricao).join(' | ')}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          ))}
        </Grid>
        <Grid container paddingY={'32px'}>
          <Divider sx={{borderColor: '#fff', width: '100%'}} />
        </Grid>
        <Grid container item xs={12}>
          <Grid container justifyContent={'space-between'}>
            <Typography sx={styles.title}>NOTÍCIAS</Typography>
          </Grid>
          <Grid container gap={2} pt={2} justifyContent={'center'} pb={2}>
            {noticias.map((n) => (
              <NewsCard key={n.id} item={n} />
            ))}
          </Grid>
          <Grid container justifyContent={'center'}>
            <Button id="primary" onClick={() => navigate('/noticias')}>
              Ver mais
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <ImagemCrop
        open={openPerfil}
        imagemId={empresario?.fotoPerfil}
        onClose={() => setOpenPerfil(false)}
        onUploadImage={handleSavePerfil}
        aspect={1}
      />
      <EditarPerfilEmpresario
        open={openBase}
        onClose={() => setOpenBase(false)}
        empresario={empresario}
      />
      <SobreEmpresario
        open={openSobre}
        onClose={() => setOpenSobre(false)}
        empresario={empresario}
      />
      <MidiaLink
        open={openMidia}
        onClose={() => setOpenMidia(false)}
        empresario={empresario}
      />
    </Grid>
  );
}

const styles = {
  container: {
    backgroundColor: (theme) => theme.palette.backgroundColor,
    paddingTop: (theme) => `calc(${theme.toolbarHeight} + 16px)`,
    justifyContent: 'center',
  },
  content: {
    maxWidth: 1200,
    alignContent: 'flex-start',
    paddingX: `16px`,

    '@media (max-width: 900px)': {
      flexWrap: 'nowrap',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  dadosContainer: {
    position: 'relative',
    alignSelf: 'flex-start',

    '@media (max-width: 900px)': {
      flexDirection: 'column',
    },
  },
  avatarContainer: {
    flexDirection: 'column',
    flexGrow: '0 !important',
  },
  editarDadosContainer: {
    position: 'absolute',
    width: '42px',
    height: '42px',
    right: 0,
  },
  avatar: {
    height: '220px',
    width: '200px',
    borderRadius: '6px',
    flexDirection: 'column',
    backgroundColor: 'transparent',
    border: (theme) => `1px solid ${theme.palette.primary.main}`,

    '@media (max-width: 900px)': {
      height: '80px',
      width: '80px',
    },

    svg: {
      color: (theme) => theme.palette.primary.main,
      width: '40px',
      height: '40px',
      maxWidth: '50%',
      maxHeight: '50%',
    },
  },
  imagemPerfil: {
    border: '2px solid #fff',
  },
  fotoPerfilText: {
    color: (theme) => theme.palette.primary.main,
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: '700',
    width: '70%',
    marginTop: 1,

    '@media (max-width: 900px)': {
      display: 'none',
    },
  },
  title: {
    color: '#FFF',
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: 600,
  },
  text: {
    color: '#FFF',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'break-spaces',

    svg: {
      height: '18px',
      width: '18px',
      marginRight: 1,
    },
  },
  redesContainer: {
    justifyContent: 'center',
    width: '100%',

    '@media (max-width: 900px)': {
      justifyContent: 'flex-start',
    },
  },
  footerText: {
    color: '#fff',
  },
  icon: {
    width: '1.2rem',
    height: '1.5rem',
  },
  editarButton: {
    padding: 1,
  },
  avatarEmpresario: {
    width: '80px',
    height: '80px',
    borderRadius: '6px',
    border: '3px solid #fff',

    svg: {
      width: '50%',
      height: '50%',
    },
  },
  empresarioNome: {
    color: '#FFF',
    fontFamily: 'Inter',
    fontSize: '20px',
    fontWeight: 600,
  },
  dataContrato: {
    color: '#FFF',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 400,
  },
  hidden: {
    visibility: 'hidden',
  },
  favoritoContainer: {
    width: 'fit-content',
    flexWrap: 'nowrap',
    alignItems: 'flex-start',
    paddingY: 1,
    cursor: 'pointer',

    ':hover': {
      '.nome': {
        color: (theme) => theme.palette.yellowLight,
      },
      '.MuiAvatar-root': {
        borderColor: (theme) => theme.palette.yellowLight,
      },
    },
  },
};
