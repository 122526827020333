import {useMutation} from '@apollo/client';
import {Cancel, Close, Save} from '@mui/icons-material';
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
  Slide,
  Typography,
} from '@mui/material';
import {useEffect, useState} from 'react';

import {confirmAlert} from '../../../components/confirm-alert/confirm-alert';
import {Logo} from '../../../components/icons';
import Input from '../../../components/input/input';
import {SelectAnamneseModelo} from '../../../components/input/select/anamnese-modelo';
import {SelectAtletas} from '../../../components/input/select/atletas';
import {SelectProfissionais} from '../../../components/input/select/profissionais';
import {toastNotification} from '../../../components/toastify';
import {
  UPDATE_AGENDAMENTO,
  UPDATE_AGENDAMENTO_SITUACAO,
} from '../../../graphql/mutation';
import Data from '../../../utils/data';
import Object from '../../../utils/object';

export default function AgendamentoModal({agendamento = {}, open, onClose}) {
  const [formData, setFormData] = useState({});

  const [updateAgendamento, {loading}] = useMutation(UPDATE_AGENDAMENTO);
  const [cancelarAgendamento, {loading: loadingCancelar}] = useMutation(
    UPDATE_AGENDAMENTO_SITUACAO,
  );

  const handleUpdateAgendamento = () => {
    if (!formData?.profissionais?.length || !formData.atleta?.id) return;

    updateAgendamento({
      variables: {
        agendamento: {
          id: agendamento?.id,
          atleta: {
            id: formData.atleta?.id
          },
          profissionais: formData.profissionais?.map((p) => ({id: p.id})),
          data: Data.dataFormat(formData.data, 'YYYY-MM-DD') || undefined,
          hora: formData.hora || undefined,
          modelo: formData.modelo ? {id: formData.modelo?.value} : undefined,
          situacao:
            formData.data && formData.hora
              ? 'AGUARDANDO_AVALIACAO'
              : 'AGUARDANDO_AGENDAMENTO',
        },
      },
    })
      .then(() => {
        toastNotification({message: 'Agendamento salvo!', type: 'success'});
        onClose();
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      );
  };

  const handleCancelarClick = () => {
    confirmAlert({
      title: 'Deseja cancelar a solicitação?',
      onConfirm: handleCancelar,
    });
  };

  const handleCancelar = () => {
    cancelarAgendamento({
      variables: {
        agendamento: {
          id: agendamento?.id,
          situacao: 'CANCELADO',
        },
      },
    })
      .then(() => {
        toastNotification({message: 'Agendamento cancelado!', type: 'success'});
        onClose();
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      );
  };

  useEffect(() => {
    if (!open) return setFormData({});

    setFormData(agendamento);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Modal open={open} onClose={onClose} sx={styles.modal}>
      <Slide in={open} appear>
        <Grid
          container
          className="styled-scroll-transparent-desktop"
          sx={styles.container}>
          <Grid container item sx={styles.header}>
            <Logo />
            <IconButton sx={styles.closeIcon} onClick={onClose}>
              <Close />
            </IconButton>
          </Grid>
          <Grid container item sx={styles.titleContainer}>
            <Typography sx={styles.title}>SOLICITAÇÃO DE AVALIAÇÃO</Typography>
          </Grid>
          <Grid container item sx={styles.content} spacing={2}>
            {agendamento?.id && (
              <Grid item xs={12}>
                <Typography sx={styles.atleta}>
                  <span>Atleta:</span>{' '}
                  {`${agendamento.atleta?.nome} | ${
                    Object.getEnderecoPrincipal(agendamento.atleta?.enderecos)
                      ?.cidade?.nomeEUf
                  }`}
                </Typography>
              </Grid>
            )}
            {agendamento?.id && (
              <Grid item xs={12}>
                <Typography sx={styles.atleta}>
                  <span>Data solicitação:</span>{' '}
                  {Data.dataFormat(agendamento.dataCriacao)}
                </Typography>
              </Grid>
            )}
            {!agendamento?.id && (
              <Grid item xs={12}>
                <SelectAtletas
                  label="Atleta"
                  name="atleta"
                  placeholder="Seleciono o atleta"
                  value={formData.atleta}
                  onChange={(atleta) =>
                    setFormData((f) => ({...f, atleta}))
                  }
                  isClearable
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <SelectAnamneseModelo
                label="Modelo"
                name="modelo"
                placeholder="Seleciono o modelo"
                value={formData.modelo}
                isClearable
                onChange={(modelo) => setFormData((f) => ({...f, modelo}))}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectProfissionais
                label="Profissional"
                placeholder="Profissional"
                value={formData.profissionais || ''}
                isClearable
                onChange={(e) => setFormData((f) => ({...f, profissionais: e}))}
                closeMenuOnSelect={false}
                isMulti
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                label={'Data'}
                placeholder={'Data'}
                name="data"
                type="date"
                value={formData.data || ''}
                onChange={({target}) =>
                  setFormData((f) => ({...f, data: target?.value}))
                }
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                label={'Hora'}
                placeholder={'Hora'}
                name="hora"
                type="time"
                value={formData.hora || ''}
                onChange={({target}) =>
                  setFormData((f) => ({...f, hora: target?.value}))
                }
              />
            </Grid>
          </Grid>
          <Grid container mt={'auto'} padding={2} spacing={2}>
            <Grid item md={6} xs={12}>
              <Button id="outlined" onClick={onClose} fullWidth>
                Cancelar
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                id="primary"
                onClick={handleUpdateAgendamento}
                fullWidth
                endIcon={loading ? <CircularProgress size={20} /> : <Save />}>
                Salvar
              </Button>
            </Grid>
            {agendamento.situacao !== 'CANCELADO' && (
              <Grid item xs={12}>
                <Button
                  id="text"
                  onClick={handleCancelarClick}
                  fullWidth
                  endIcon={
                    loadingCancelar ? (
                      <CircularProgress size={20} />
                    ) : (
                      <Cancel />
                    )
                  }>
                  Cancelar agendamento
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Slide>
    </Modal>
  );
}

const styles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    position: 'relative',
    maxHeight: '696px',
    width: '40%',
    borderRadius: '6px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    padding: 3,
    paddingTop: 0,
    zIndex: (theme) => theme.zIndex.modal,
    background: (theme) => theme.palette.blackSecundary,
    boxShadow: '0px 0px 134.60001px 0px rgba(255, 204, 41, 0.25)',
    outline: 'none',
    overflowY: 'auto',

    '@media (max-width: 900px)': {
      width: '90%',
    },
  },
  header: {
    flexGrow: 0,
    padding: 2,
    justifyContent: 'center',
    svg: {
      color: '#fff',
    },
  },
  titleContainer: {
    flexGrow: 0,
    justifyContent: 'center',
    padding: '16px 0',
    maxWidth: '420px',

    '@media (max-width: 1200px)': {
      padding: '16px 0',
    },
  },
  closeIcon: {
    position: 'absolute',
    top: '28px',
    right: '28px',
    width: '32px',
    height: '32px',
  },
  title: {
    color: '#FFF',
    textAlign: 'center',
    fontFamily: 'Now',
    fontSize: '24px',
    fontWeight: '700',
    textTransform: 'uppercase',

    '@media (max-width: 1200px)': {
      fontSize: '20px',
    },
  },
  content: {
    flexDirection: 'row',
    width: '100%',
    padding: '16px',
  },
  atleta: {
    fontSize: '16px',
    color: '#fff',
    span: {
      fontWeight: '500',
    },
  },
};
