import gql from 'graphql-tag';

export const USUARIO_LOGADO = gql`
  query usuarioLogado {
    usuario: usuarioLogado {
      id
      nome
      authorities {
        nome
      }
    }
  }
`;

export const ADMINISTRADOR_LOGADO = gql`
  query administradorLogado {
    entidade: administradorLogado {
      id
      nome
      dataCadastro
      fotoPerfil
    }
  }
`;

export const ATLETA_LOGADO = gql`
  query atletaLogado {
    entidade: atletaLogado {
      id
      nome
      dataCadastro
      fotoPerfil
    }
  }
`;

export const EMPRESARIO_LOGADO = gql`
  query empresarioLogado {
    entidade: empresarioLogado {
      id
      nome
      dataCadastro
      fotoPerfil
      filtros {
        id
        descricao
        filtro
      }
    }
  }
`;

export const JORNALISTA_LOGADO = gql`
  query jornalistaLogado {
    entidade: jornalistaLogado {
      id
      nome
      dataCadastro
      fotoPerfil
    }
  }
`;

export const PROFISSIONAL_LOGADO = gql`
  query profissionalLogado {
    entidade: profissionalLogado {
      id
      nome
      dataCadastro
      fotoPerfil
      especialidades {
        id
        descricao
      }
    }
  }
`;

export const FIND_ALL_MODALIDADES = gql`
  query findAllModalidades(
    $pageable: PageableDTOInput
    $searchDTO: SearchDTOInput
  ) {
    modalidades: findAllModalidades(
      pageable: $pageable
      searchDTO: $searchDTO
    ) {
      last
      totalElements
      content {
        id
        descricao
        value: id
        label: descricao
        funcoes {
          id
          descricao
          value: id
          label: descricao
        }
      }
    }
  }
`;

export const FIND_ALL_FUNCOES = gql`
  query findAllModalidadesFuncao(
    $pageable: PageableDTOInput
    $searchDTO: SearchDTOInput
  ) {
    funcoes: findAllModalidadesFuncao(
      pageable: $pageable
      searchDTO: $searchDTO
    ) {
      last
      totalElements
      content {
        id
        descricao
        value: id
        label: descricao
      }
    }
  }
`;

export const FIND_ALL_ATLETAS = gql`
  query findAllAtletas(
    $pageable: PageableDTOInput
    $searchDTO: SearchDTOInput
  ) {
    atletas: findAllAtletas(pageable: $pageable, searchDTO: $searchDTO) {
      last
      totalElements
      content {
        id
        nome
        cpf
        email
        dataNascimento
        fotoPerfil
        ativo
        favorito
        modalidade {
          id
          descricao
        }
        anamneses {
          id
          dataHoraLancamento
        }
        usuario {
          plano {
            titulo
          }
          detalhesPlano {
            statusPagamento
          }
        }
        enderecos {
          id
          principal
          cidade {
            id
            nome
            uf
            nomeEUf
          }
        }
      }
    }
  }
`;

export const FIND_ALL_EMPRESARIOS = gql`
  query findAllEmpresarios(
    $pageable: PageableDTOInput
    $searchDTO: SearchDTOInput
  ) {
    empresarios: findAllEmpresarios(
      pageable: $pageable
      searchDTO: $searchDTO
    ) {
      last
      totalElements
      content {
        id
        nome
        email
        ativo
        enderecos {
          id
          cidade {
            id
            nome
            uf
            nomeEUf
          }
        }
      }
    }
  }
`;

export const FIND_ALL_CIDADES = gql`
  query findAllCidades(
    $pageable: PageableDTOInput
    $searchDTO: SearchDTOInput
  ) {
    cidades: findAllCidades(pageable: $pageable, searchDTO: $searchDTO) {
      last
      totalElements
      content {
        id
        nome
        uf
        value: id
        label: nomeEUf
      }
    }
  }
`;

export const FIND_ATLETA_BY_ID = gql`
  query findByIdAtleta($id: UUID) {
    atleta: findByIdAtleta(id: $id) {
      id
      nome
      cpf
      email
      sexo
      peso
      estatura
      urlFacebook
      urlInstagram
      urlX
      urlLinkedin
      dataNascimento
      dataCadastro
      fotoPerfil
      telefone
      descricao
      situacao
      favorito
      midiaLinks {
        id
        url
        imagem
      }
      experiencias {
        id
        equipe
        dataInicio
        dataFim
        funcao {
          id
          descricao
          value: id
          label: descricao
        }
      }
      modalidade {
        id
        descricao
        value: id
        label: descricao
      }
      funcoes {
        id
        descricao
        value: id
        label: descricao
      }
      enderecos {
        id
        cep
        logradouro
        numero
        bairro
        complemento
        principal
        cidade {
          id
          nome
          uf
          nomeEUf
          value: id
          label: nomeEUf
        }
      }
      usuario {
        id
      }
      anamneses {
        id
        dataHoraLancamento
        dataHoraTermino
        modelo {
          nome
        }
      }
    }
  }
`;

export const FIND_DADOS_ATLETA_BY_ID = gql`
  query findByIdAtleta($id: UUID) {
    atleta: findByIdAtleta(id: $id) {
      id
      nome
      cpf
      email
      sexo
      peso
      estatura
      urlFacebook
      urlInstagram
      urlX
      urlLinkedin
      dataNascimento
      dataCadastro
      telefone
      descricao
      situacao
      modalidade {
        id
        descricao
        value: id
        label: descricao
      }
      funcoes {
        id
        descricao
        value: id
        label: descricao
      }
      enderecos {
        id
        cep
        logradouro
        numero
        bairro
        complemento
        principal
        cidade {
          id
          nome
          uf
          nomeEUf
          value: id
          label: nomeEUf
        }
      }
      responsavel {
        id
        nome
        cpf
        email
        telefone
        dataNascimento
        grauParentesco
      }
    }
  }
`;

export const FIND_ALL_NOTICIAS = gql`
  query findAllNoticias(
    $pageable: PageableDTOInput
    $searchDTO: SearchDTOInput
  ) {
    noticias: findAllNoticias(pageable: $pageable, searchDTO: $searchDTO) {
      last
      totalElements
      content {
        id
        titulo
        fotoCapa
        banner: fotoCapa
        texto
        dataCadastro
        ultimaAlteracao
        tempoLeitura
        publicada
        situacao
        jornalista {
          id
          nome
        }
        profissional {
          id
          nome
        }
        avaliacoes {
          avaliacao
        }
      }
    }
  }
`;

export const FIND_ALL_NOTICIAS_JORNALISTA = gql`
  query findAllNoticiasByJornalista(
    $pageable: PageableDTOInput
    $searchDTO: SearchDTOInput
  ) {
    noticias: findAllNoticiasByJornalista(
      pageable: $pageable
      searchDTO: $searchDTO
    ) {
      last
      totalElements
      content {
        id
        titulo
        fotoCapa
        texto
        dataCadastro
        ultimaAlteracao
        tempoLeitura
        publicada
        situacao
        jornalista {
          id
          nome
        }
      }
    }
  }
`;

export const FIND_NOTICIA_BY_ID = gql`
  query findByIdNoticia($id: UUID) {
    noticia: findByIdNoticia(id: $id) {
      id
      titulo
      texto
      fotoCapa
      dataCadastro
      ultimaAlteracao
      tempoLeitura
      publicada
      situacao
      possuiFontes
      jornalista {
        id
        nome
      }
      profissional {
        id
        nome
      }
    }
  }
`;

export const FIND_JORNALISTA_BY_ID = gql`
  query findByIdJornalista($id: UUID) {
    jornalista: findByIdJornalista(id: $id) {
      id
      nome
      cpfCnpj
      dataCadastro
      telefone
      fotoPerfil
      email
      ativo
      endereco {
        id
        cep
        logradouro
        numero
        bairro
        complemento
        cidade {
          id
          nome
          nomeEUf
          value: id
          label: nomeEUf
        }
      }
    }
  }
`;

export const FIND_ALL_JORNALISTAS = gql`
  query findAllJornalistas(
    $pageable: PageableDTOInput
    $searchDTO: SearchDTOInput
  ) {
    jornalistas: findAllJornalistas(
      pageable: $pageable
      searchDTO: $searchDTO
    ) {
      last
      totalElements
      content {
        id
        nome
        cpfCnpj
        dataCadastro
        telefone
        fotoPerfil
        email
        ativo
        endereco {
          id
          cep
          logradouro
          numero
          bairro
          complemento
          cidade {
            id
            nome
            nomeEUf
            value: id
            label: nomeEUf
          }
        }
      }
    }
  }
`;

export const FIND_ALL_ADMINISTRADORES = gql`
  query findAllAdministradores(
    $pageable: PageableDTOInput
    $searchDTO: SearchDTOInput
  ) {
    administradores: findAllAdministradores(
      pageable: $pageable
      searchDTO: $searchDTO
    ) {
      last
      totalElements
      content {
        id
        nome
        cpf
        dataCadastro
        telefone
        fotoPerfil
        email
        ativo
        endereco {
          id
          cep
          logradouro
          numero
          bairro
          complemento
          cidade {
            id
            nome
            nomeEUf
            value: id
            label: nomeEUf
          }
        }
      }
    }
  }
`;

export const FIND_ADMINISTRADOR_BY_ID = gql`
  query findByIdAdministrador($id: UUID) {
    administrador: findByIdAdministrador(id: $id) {
      id
      nome
      cpf
      dataCadastro
      telefone
      fotoPerfil
      email
      ativo
      endereco {
        id
        cep
        logradouro
        numero
        bairro
        complemento
        cidade {
          id
          nome
          nomeEUf
          value: id
          label: nomeEUf
        }
      }
    }
  }
`;

export const FIND_ALL_PROFISSIONAIS_SAUDE = gql`
  query findAllProfissionaisSaude(
    $pageable: PageableDTOInput
    $searchDTO: SearchDTOInput
  ) {
    profissionais: findAllProfissionaisSaude(
      pageable: $pageable
      searchDTO: $searchDTO
    ) {
      last
      totalElements
      content {
        id
        nome
        cpf
        dataCadastro
        telefone
        fotoPerfil
        email
        ativo
        especialidades {
          id
          descricao
        }
        enderecos {
          id
          cidade {
            id
            nomeEUf
          }
        }
      }
    }
  }
`;

export const FIND_PROFISSIONAL_BY_ID = gql`
  query findByIdProfissionalSaude($id: UUID) {
    profissional: findByIdProfissionalSaude(id: $id) {
      id
      nome
      cpf
      dataCadastro
      telefone
      fotoPerfil
      email
      cns
      numeroConselho
      ufConselho
      ativo
      especialidades {
        id
        descricao
        value: id
        label: descricao
      }
      enderecos {
        id
        cep
        logradouro
        numero
        bairro
        complemento
        principal
        cidade {
          id
          nome
          uf
          nomeEUf
          value: id
          label: nomeEUf
        }
      }
    }
  }
`;

export const FIND_ALL_ESPECIALIDADES = gql`
  query findAllEspecialidadesMedicas(
    $pageable: PageableDTOInput
    $searchDTO: SearchDTOInput
  ) {
    especialidades: findAllEspecialidadesMedicas(
      pageable: $pageable
      searchDTO: $searchDTO
    ) {
      last
      totalElements
      content {
        id
        descricao
        value: id
        label: descricao
      }
    }
  }
`;

export const FIND_EMPRESARIO_BY_ID = gql`
  query findByIdEmpresario($id: UUID) {
    empresario: findByIdEmpresario(id: $id) {
      id
      nome
      cpfCnpj
      email
      urlFacebook
      urlInstagram
      urlX
      urlLinkedin
      dataCadastro
      fotoPerfil
      telefone
      descricao
      midiaLinks {
        id
        url
        imagem
      }
      enderecos {
        id
        cep
        logradouro
        numero
        bairro
        complemento
        principal
        cidade {
          id
          nome
          uf
          nomeEUf
          value: id
          label: nomeEUf
        }
      }
      usuario {
        atletasConexao {
          atleta {
            id
            fotoPerfil
            nome
            modalidade {
              id
              descricao
              value: id
              label: descricao
            }
            funcoes {
              id
              descricao
              value: id
              label: descricao
            }
          }
        }
      }
    }
  }
`;

export const GET_AVALIACAO_NOTICIA = gql`
  query hasAvaliacaoNoticia($noticiaId: UUID, $usuarioId: UUID) {
    avaliacao: hasAvaliacaoNoticia(noticiaId: $noticiaId, usuarioId: $usuarioId)
  }
`;

export const FIND_ALL_CONTATOS = gql`
  query findAllEmailContato(
    $pageable: PageableDTOInput
    $searchDTO: SearchDTOInput
  ) {
    contatos: findAllEmailContato(pageable: $pageable, searchDTO: $searchDTO) {
      last
      totalElements
      content {
        id
        nome
        email
        tipo
        concluido
        dataCriacao
        mensagem
        telefone
      }
    }
  }
`;

export const FIND_ALL_AGENDAMENTOS = gql`
  query findAllAgendamentos(
    $pageable: PageableDTOInput
    $searchDTO: SearchDTOInput
  ) {
    agendamentos: findAllAgendamentos(
      pageable: $pageable
      searchDTO: $searchDTO
    ) {
      last
      totalElements
      content {
        id
        data
        hora
        dataCriacao
        situacao
        observacao
        atleta {
          id
          nome
          enderecos {
            id
            cidade {
              id
              nomeEUf
            }
          }
        }
        modelo {
          id
          nome
          value: id
          label: nome
        }
        profissionais {
          id
          nome
          value: id
          label: nome
        }
      }
    }
  }
`;

export const FIND_ALL_AGENDAMENTOS_BY_PROFISSIONAL = gql`
  query findAllAgendamentosByProfissional(
    $pageable: PageableDTOInput
    $searchDTO: SearchDTOInput
  ) {
    agendamentos: findAllAgendamentosByProfissional(
      pageable: $pageable
      searchDTO: $searchDTO
    ) {
      last
      totalElements
      content {
        id
        data
        hora
        dataCriacao
        situacao
        observacao
        modelo {
          id
          nome
          value: id
          label: nome
        }
        atleta {
          id
          nome
          email
          telefone
          enderecos {
            id
            cidade {
              id
              nomeEUf
            }
          }
        }
        profissionais {
          id
          nome
        }
      }
    }
  }
`;

export const FIND_AGENDAMENTO_BY_ID = gql`
  query findByIdAgendamento($id: UUID) {
    agendamento: findByIdAgendamento(id: $id) {
      id
      data
      hora
      dataCriacao
      situacao
      observacao
      atleta {
        id
        nome
        cpf
        email
        sexo
        peso
        estatura
        urlFacebook
        urlInstagram
        urlX
        urlLinkedin
        dataNascimento
        dataCadastro
        fotoPerfil
        telefone
        descricao
        situacao
        favorito
        midiaLinks {
          id
          url
          imagem
        }
        experiencias {
          id
          equipe
          dataInicio
          dataFim
          funcao {
            id
            descricao
            value: id
            label: descricao
          }
        }
        modalidade {
          id
          descricao
          value: id
          label: descricao
        }
        funcoes {
          id
          descricao
          value: id
          label: descricao
        }
        enderecos {
          id
          cep
          logradouro
          numero
          bairro
          complemento
          principal
          cidade {
            id
            nome
            uf
            nomeEUf
            value: id
            label: nomeEUf
          }
        }
      }
      profissionais {
        id
        nome
      }
      modelo {
        perguntas {
          id
          ordem
          pergunta {
            id
            pergunta
            tipo
            ordem
            modeloGrupo {
              id
              nome
              ordem
            }
            valoresReferencia {
              nome
              corHex
              valorMin
              valorMax
              indice
            }
          }
        }
      }
    }
  }
`;

export const FIND_ALL_FORMULARIO_GRUPOS = gql`
  query findAllAnamneseModeloGrupo(
    $pageableDTO: PageableDTOInput
    $searchDTO: SearchDTOInput
  ) {
    grupos: findAllAnamneseModeloGrupo(
      pageableDTO: $pageableDTO
      searchDTO: $searchDTO
    ) {
      last
      totalElements
      content {
        id
        nome
        ordem
        value: id
        label: nome
      }
    }
  }
`;

export const FIND_ALL_ANAMNESE_MODELO = gql`
  query findAllAnamneseModelo(
    $pageableDTO: PageableDTOInput
    $searchDTO: SearchDTOInput
  ) {
    modelos: findAllAnamneseModelo(
      pageableDTO: $pageableDTO
      searchDTO: $searchDTO
    ) {
      last
      totalElements
      content {
        id
        nome
        ativo
        perguntas {
          id
          ordem
          pergunta {
            id
            pergunta
            urlReferencia
            tipo
            modeloGrupo {
              id
              nome
              value: id
              label: nome
            }
            valoresReferencia {
              id
              nome
              valorMin
              valorMax
              corHex
            }
          }
        }
      }
    }
  }
`;

export const FIND_ALL_PERGUNTAS = gql`
  query findAllAnamnesePergunta(
    $pageableDTO: PageableDTOInput
    $searchDTO: SearchDTOInput
  ) {
    perguntas: findAllAnamnesePergunta(
      pageableDTO: $pageableDTO
      searchDTO: $searchDTO
    ) {
      last
      totalElements
      content {
        id
        pergunta
        tipo
        urlReferencia
        value: id
        label: pergunta
        modeloGrupo {
          id
          nome
          ordem
          value: id
          label: nome
        }
        valoresReferencia {
          id
          nome
          corHex
          valorMin
          valorMax
          indice
        }
      }
    }
  }
`;

export const FIND_ANAMNESE_BY_ID = gql`
  query findByIdAnamnese($id: UUID) {
    anamnese: findByIdAnamnese(id: $id)  {
      id
      dataHoraLancamento
      atleta {
        id
        nome
        cpf
        email
        sexo
        peso
        estatura
        urlFacebook
        urlInstagram
        urlX
        urlLinkedin
        dataNascimento
        dataCadastro
        fotoPerfil
        telefone
        descricao
        situacao
        favorito
        midiaLinks {
          id
          url
          imagem
        }
        experiencias {
          id
          equipe
          dataInicio
          dataFim
          funcao {
            id
            descricao
            value: id
            label: descricao
          }
        }
        modalidade {
          id
          descricao
          value: id
          label: descricao
        }
        funcoes {
          id
          descricao
          value: id
          label: descricao
        }
        enderecos {
          id
          cep
          logradouro
          numero
          bairro
          complemento
          principal
          cidade {
            id
            nome
            uf
            nomeEUf
            value: id
            label: nomeEUf
          }
        }
      }
      profissionalSaude {
        id
        nome
      }
      respostas {
        id
        anexoId
        valorResposta
        modeloPergunta {
          id
          ordem
          pergunta {
            id
            pergunta
            tipo
            ordem
            urlReferencia
            modeloGrupo {
              id
              nome
              ordem
            }
            valoresReferencia {
              id
              nome
              corHex
              valorMin
              valorMax
              indice
            }
          }
        }
      }
    }
  }
`;

export const FIND_ALL_PLANO = gql`
  query FindAllPlano($ativo: Boolean, $search: String, $pageableDto: PageableDTOInput) {
    plano: findAllPlano(ativo: $ativo, search: $search, pageableDTO: $pageableDto) {
      last
      totalElements
      content {
        ativo
        descontoExame
        descricao
        exibeNoSite
        id
        numeroFoto
        numeroRetornoTeste
        numeroVideo
        quemSondouPerfil
        quemVisitouPerfil
        tamanhoMaximoTexto
        titulo
        valorAnual
        valorMensal
      }
    }
  }
`

export const FIND_USUARIO_BY_ID = gql`
  query findUsuarioById($usuarioId: UUID) {
   usuario: findUsuarioById(usuarioId: $usuarioId) {
      id
      plano{
        id
        titulo
      }
      detalhesPlano {
        dataHoraVencimento
        statusPagamento
        periodoEmissao
        valor
      }
    }
  }
`