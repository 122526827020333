import {useMutation} from '@apollo/client';
import {Close} from '@mui/icons-material';
import {
  Button,
  Grid,
  IconButton,
  Modal,
  Slide,
  Typography,
} from '@mui/material';
import {useEffect, useState} from 'react';
import * as Yup from 'yup';

import Input from '../../../../components/input/input';
import {toastNotification} from '../../../../components/toastify';
import {SALVAR_FILTRO_EMPRESARIO} from '../../../../graphql/mutation';

export default function FiltroSaveModal({open, onClose, filtros = {}}) {
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});

  const [saveFiltro] = useMutation(SALVAR_FILTRO_EMPRESARIO);

  const handleSubmit = (event) => {
    event.preventDefault();
    filtroScheme
      .validate(formData, {abortEarly: false})
      .then(handleSaveFiltros)
      .catch((errors) => {
        const camposComErro = {};
        errors?.inner?.forEach((error) => {
          camposComErro[error.path] = error.message;
        });
        setErrors(camposComErro);
      });
  };

  const handleSaveFiltros = () => {
    saveFiltro({
      variables: {
        filtros: {
          id: filtros?.id,
          descricao: formData.descricao,
          filtro: JSON.stringify(filtros),
        },
      },
    })
      .then(() => {
        toastNotification({message: 'Filtro salvo!', type: 'success'});
        onClose();
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      );
  };

  useEffect(() => {
    setErrors({});
  }, [open]);

  return (
    <Modal open={open} onClose={onClose} sx={styles.modal}>
      <Slide in={open} appear>
        <Grid
          container
          className="styled-scroll-transparent-desktop"
          sx={styles.container}>
          <Grid container item sx={styles.header}>
            <Typography sx={styles.subtitle}>Salvar filtro</Typography>
            <IconButton sx={styles.closeIcon} onClick={onClose}>
              <Close />
            </IconButton>
          </Grid>
          <Grid container item sx={styles.buttonsContainer} gap={2}>
            <Grid item xs={12}>
              <Input
                name="descricao"
                type="text"
                autoCapitalize="on"
                placeholder={'Descrição do filtro'}
                onChange={({target}) =>
                  setFormData((f) => ({...f, descricao: target?.value}))
                }
                errors={errors}
              />
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Button id="outlined" fullWidth onClick={onClose}>
                  Cancelar
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button id="primary" fullWidth onClick={handleSubmit}>
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Slide>
    </Modal>
  );
}

const filtroScheme = Yup.object().shape({
  descricao: Yup.string().required('Campo obrigatório'),
});

const styles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    maxWidth: '500px',
    width: '40%',
    borderRadius: '6px',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    padding: 3,
    zIndex: (theme) => theme.zIndex.modal,
    background: (theme) => theme.palette.blackSecundary,
    boxShadow: '0px 0px 134.60001px 0px rgba(255, 204, 41, 0.25)',
    outline: 'none',
    overflowY: 'auto',

    '@media (max-width: 900px)': {
      width: '90%',
    },
  },

  header: {
    background: (theme) => theme.palette.blackSecundary,
    justifyContent: 'space-between',
    alignItems: 'center',
    svg: {
      color: '#fff',
    },
    paddingBottom: 2,
    zIndex: 2,
  },
  closeIcon: {
    width: '32px',
    height: '32px',
  },
  subtitle: {
    color: '#FFF',
    fontSize: '20px',
    fontWeight: '500',
    textTransform: 'uppercase',

    '@media (max-width: 1200px)': {
      fontSize: '14px',
    },
  },
};
