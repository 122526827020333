import {Cancel, Save, Upload} from '@mui/icons-material';
import {Button, Grid, IconButton, Modal} from '@mui/material';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import Cropper from 'react-easy-crop';
import {useSelector} from 'react-redux';

import {b64toBlob, getImageUrl} from '../../utils/file';
import getCroppedImg from './crop-image';

export default function ImagemCrop({
  imagemId,
  open,
  onClose,
  onUploadImage,
  disabled,
  aspect = 4 / 3,
}) {
  const inputRef = useRef();
  const {isMobile} = useSelector((state) => state.App);

  const [image, setImage] = useState(undefined);
  const [crop, setCrop] = useState({x: 0, y: 0});
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  useEffect(() => {
    if (!imagemId) {
      setZoom(1);
      return;
    }

    setImage(getImageUrl(imagemId));
  }, [imagemId]);

  useEffect(() => {
    if (open && !imagemId) inputRef?.current?.click();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const showCroppedImage = async () => {
    try {
      const croppedImage = await getCroppedImg(image, croppedAreaPixels);

      croppedImage.substring(croppedImage.indexOf(',') + 1);
      const type = croppedImage.substring(5, croppedImage.indexOf(';'));
      const file = b64toBlob(
        croppedImage.substring(croppedImage.indexOf(',') + 1),
        type,
      );

      onUploadImage(file);
      onClose();
    } catch (e) {
      console.error(e);
    }
  };

  function handleChangeUploadImage(e) {
    const file = Array.from(e.target.files)[0];

    const reader = new FileReader();

    reader.addEventListener(
      'load',
      function () {
        setImage(reader.result);
      },
      false,
    );

    if (file) {
      reader.readAsDataURL(file);
    }
  }

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  return (
    <Modal
      open={open}
      onClose={onClose}
      disablePortal
      disableEnforceFocus
      disableAutoFocus
      aria-labelledby="server-modal-title"
      aria-describedby="server-modal-description"
      sx={styles.modal}>
      <Grid container sx={styles.modalBody}>
        <Cancel onClick={onClose} sx={styles.closeIcon} />
        <Grid
          item
          sx={[styles.cropContainer, disabled && styles.cropContainerDisabled]}>
          <Cropper
            image={image}
            crop={crop}
            zoom={zoom}
            aspect={aspect}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={onCropComplete}
          />
        </Grid>
        <Grid
          item
          sx={styles.controls}
          style={disabled ? {display: 'none'} : null}>
          <Grid container item direction="row" justifyContent="space-between">
            <label htmlFor="input-file">
              {isMobile && (
                <IconButton id="icon-outlined" component="span">
                  <Upload />
                </IconButton>
              )}
              {!isMobile && (
                <Button id="outlined" endIcon={<Upload />} component="span">
                  Selecionar imagem
                </Button>
              )}
            </label>
            {isMobile && (
              <IconButton id="icon-primary" onClick={showCroppedImage}>
                <Save />
              </IconButton>
            )}
            {!isMobile && (
              <Button
                id="primary"
                onClick={showCroppedImage}
                endIcon={<Save />}>
                Salvar
              </Button>
            )}
            <input
              id="input-file"
              ref={inputRef}
              type="file"
              accept="image/*"
              style={{width: '10px', opacity: 0, position: 'absolute'}}
              onChange={handleChangeUploadImage}
              disabled={disabled}
            />
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
}

const styles = {
  modal: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalBody: {
    position: 'relative',
    backgroundColor: (theme) => theme.palette.backgroundColor,
    padding: '0px',
    borderRadius: '6px',
    height: '80%',
    maxWidth: '60%',
    display: 'flex',
    flexDirection: 'column !important',
    boxShadow: '0px 0px 134.60001px 0px rgba(255, 204, 41, 0.25)',
    overflow: 'hidden',

    '@media (max-width: 900px)': {
      maxWidth: '95vw',
      height: '110vw',
      maxHeight: '95vh',
    },
  },
  cropContainer: {
    position: 'relative',
    width: '100%',
    height: 'calc(100% - 60px)',
    background: (theme) => theme.palette.backgroundColor,
    opacity: 1,
  },
  cropContainerDisabled: {
    height: '100% !important',
  },
  controls: {
    display: 'flex',
    flexDirection: 'row',
    height: '60px',
    width: '100%',
    padding: '20px 25px',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  sliderContainer: {
    display: 'flex',
    flexDirection: 'column !important',
    width: '100%',

    '& .MuiSlider-root': {
      margin: '0 5px',
      maxWidth: 500,
    },
  },
  cropButton: {
    height: 40,
    textTransform: 'none !important',
    marginLeft: 10,
    width: 160,
  },
  closeIcon: {
    position: 'absolute',
    top: 5,
    right: 5,
    color: '#fff',
    cursor: 'pointer',
    height: 30,
    width: 30,
    zIndex: 2,
  },
};
