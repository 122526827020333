import {Route, Routes} from 'react-router-dom';

import CadastroAtleta from './cadastro-atleta';
import CadastroEmpresario from './cadastro-empresario';
import CadastroTipos from './cadastro-tipos';

export default function Cadastro() {
  return (
    <Routes>
      <Route exact path="/" element={<CadastroTipos />} />
      <Route path={`/atleta`} element={<CadastroAtleta />} />
      <Route path={`/empresario`} element={<CadastroEmpresario />} />
    </Routes>
  );
}
