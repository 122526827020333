import {useLazyQuery, useMutation} from '@apollo/client';
import {Close, Save} from '@mui/icons-material';
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
  Slide,
  Typography
} from '@mui/material';
import {useEffect, useState} from 'react';
import * as Yup from 'yup';

import CheckboxInput from '../../../components/input/checkbox';
import Input from '../../../components/input/input';
import InputMask from '../../../components/input/input-mask';
import {SelectCidades} from '../../../components/input/select/cidades';
import {toastNotification} from '../../../components/toastify';
import {CADASTRAR_JORNALISTA} from '../../../graphql/mutation';
import {FIND_JORNALISTA_BY_ID} from '../../../graphql/queries';
import Masks from '../../../utils/masks';
import String from '../../../utils/string';

export default function EditarPerfilJornalista({
  open,
  onClose,
  jornalistaId,
  isAdmin,
}) {
  const [formData, setFormData] = useState({});
  const [formDataEndereco, setFormDataEndereco] = useState({});
  const [errors, setErrors] = useState({});

  const [loadJornalista, jornalistaQuery] = useLazyQuery(FIND_JORNALISTA_BY_ID);
  const [saveJornalista, {loading}] = useMutation(CADASTRAR_JORNALISTA);

  const handleSubmit = (event) => {
    event.preventDefault();
    jornalistaScheme
      .validate({...formData, ...formDataEndereco}, {abortEarly: false})
      .then(handleSave)
      .catch((errors) => {
        const camposComErro = {};
        errors?.inner?.forEach((error) => {
          camposComErro[error.path] = error.message;
        });
        setErrors(camposComErro);
      });
  };

  const handleSave = () => {
    saveJornalista({
      variables: {
        jornalista: {
          ...formData,
          cpfCnpj: String.removeSpecialChars(formData.cpfCnpj),
          telefone: String.removeSpecialChars(formData.telefone),
          endereco: {
            ...formDataEndereco,
            cep: String.removeSpecialChars(formDataEndereco.cep),
            cidade: {
              id: formDataEndereco.cidade.value,
            },
          },
        },
      },
    })
      .then(() => {
        toastNotification({message: 'Registro salvo!', type: 'success'});
        onClose();
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      );
  };

  useEffect(() => {
    setErrors({});
  }, [open]);

  useEffect(() => {
    if (!jornalistaId) {
      setFormData({});
      setFormDataEndereco({});
      return;
    }

    loadJornalista({
      variables: {
        id: jornalistaId,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jornalistaId]);

  useEffect(() => {
    if (jornalistaQuery.loading) return;
    const jornalista = jornalistaQuery.data?.jornalista;

    setFormData(jornalista || {});
    setFormDataEndereco(jornalista?.endereco || {});
  }, [jornalistaQuery]);

  return (
    <Modal open={open} onClose={onClose} sx={styles.modal}>
      <Slide in={open} appear>
        <Grid
          container
          className="styled-scroll-transparent-desktop"
          sx={styles.container}>
          <Grid container item sx={styles.header}>
            <Typography sx={styles.subtitle}>Editar Jornalista</Typography>
            <IconButton sx={styles.closeIcon} onClick={onClose}>
              <Close />
            </IconButton>
          </Grid>
          <Grid container item sx={styles.content} gap={2}>
            <Input
              label={'Nome'}
              name="nome"
              value={formData.nome || ''}
              type="text"
              autoCapitalize="on"
              placeholder={'Nome completo'}
              onChange={({target}) =>
                setFormData((f) => ({...f, nome: target?.value}))
              }
              errors={errors}
            />
            <InputMask
              label={'CPF/CNPJ'}
              name="cpfCnpj"
              value={formData.cpfCnpj || ''}
              type="tel"
              mask={Masks.cpfCnpj}
              placeholder={'Digite o número do documento'}
              onChange={({target}) =>
                setFormData((f) => ({...f, cpfCnpj: target?.value}))
              }
              errors={errors}
            />
            <InputMask
              label={'Telefone'}
              name="telefone"
              value={formData.telefone || ''}
              type="tel"
              autoComplete="tel"
              mask={Masks.telelefone}
              placeholder={'Digite seu número de telefone'}
              onChange={({target}) =>
                setFormData((f) => ({...f, telefone: target?.value}))
              }
              errors={errors}
            />
            <Input
              label={'E-mail'}
              name="email"
              value={formData.email || ''}
              type="email"
              autoComplete="email"
              placeholder={'Digite seu e-mail'}
              onChange={({target}) =>
                setFormData((f) => ({...f, email: target?.value}))
              }
              errors={errors}
            />
            <InputMask
              label={'CEP'}
              name="cep"
              value={formDataEndereco.cep || ''}
              type="tel"
              autoComplete="tel"
              mask={Masks.cep}
              placeholder={'Digite seu CEP'}
              onChange={({target}) =>
                setFormDataEndereco((f) => ({...f, cep: target?.value}))
              }
              errors={errors}
            />
            <Input
              label={'Logradouro'}
              name="logradouro"
              value={formDataEndereco.logradouro || ''}
              type="text"
              autoCapitalize="on"
              placeholder={'Digite seu logradouro'}
              onChange={({target}) =>
                setFormDataEndereco((f) => ({...f, logradouro: target?.value}))
              }
              errors={errors}
            />
            <Input
              label={'Nº'}
              name="numero"
              value={formDataEndereco.numero || ''}
              type="tel"
              placeholder={'Digite o número'}
              onChange={({target}) =>
                setFormDataEndereco((f) => ({...f, numero: target?.value}))
              }
              errors={errors}
            />
            <Input
              label={'Bairro'}
              name="bairro"
              value={formDataEndereco.bairro || ''}
              type="text"
              autoCapitalize="on"
              placeholder={'Digite seu bairro'}
              onChange={({target}) =>
                setFormDataEndereco((f) => ({...f, bairro: target?.value}))
              }
              errors={errors}
            />
            <Input
              label={'Complemento'}
              name="complemento"
              value={formDataEndereco.complemento || ''}
              type="text"
              autoCapitalize="on"
              placeholder={'Digite um complemento'}
              onChange={({target}) =>
                setFormDataEndereco((f) => ({...f, complemento: target?.value}))
              }
              errors={errors}
            />
            <SelectCidades
              label="Cidade"
              name="cidade"
              placeholder="Selecione a cidade"
              value={formDataEndereco.cidade}
              isClearable
              menuPlacement="top"
              onChange={(e) => setFormDataEndereco((f) => ({...f, cidade: e}))}
              errors={errors}
            />
            {isAdmin && (
              <CheckboxInput
                name="ativo"
                label={'Ativo'}
                checked={formData.ativo || false}
                onChange={({target}) =>
                  setFormData((f) => ({...f, ativo: target?.checked}))
                }
              />
            )}
          </Grid>
          <Grid container py={2}>
            <Button
              id="primary"
              onClick={handleSubmit}
              fullWidth
              endIcon={loading ? <CircularProgress size={20} /> : <Save />}>
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Slide>
    </Modal>
  );
}

const jornalistaScheme = Yup.object().shape({
  nome: Yup.string().required('Campo obrigatório'),
  telefone: Yup.string().required('Campo obrigatório'),
  cpfCnpj: Yup.string()
    .required('Campo obrigatório')
    .test('validar-cpfCnpj', 'CPF/CNPJ inválido', (value) =>
      String.validaCpfCnpj(value),
    ),
  email: Yup.string().required('Campo obrigatório').email('E-mail inválido'),
  cep: Yup.string().required('Campo obrigatório'),
  logradouro: Yup.string().required('Campo obrigatório'),
  numero: Yup.string().required('Campo obrigatório'),
  bairro: Yup.string().required('Campo obrigatório'),
  cidade: Yup.object().required('Campo obrigatório'),
});

const styles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    position: 'relative',
    maxWidth: '683px',
    height: '95%',
    width: '40%',
    borderRadius: '6px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    padding: 3,
    paddingTop: 0,
    zIndex: (theme) => theme.zIndex.modal,
    background: (theme) => theme.palette.blackSecundary,
    boxShadow: '0px 0px 134.60001px 0px rgba(255, 204, 41, 0.25)',
    outline: 'none',
    overflowY: 'auto',

    '@media (max-width: 900px)': {
      width: '90%',
    },
  },
  header: {
    flexGrow: 0,
    paddingTop: 2,
    position: 'sticky',
    top: 0,
    background: (theme) => theme.palette.blackSecundary,
    justifyContent: 'space-between',
    alignItems: 'center',
    svg: {
      color: '#fff',
    },
    paddingBottom: 2,
    borderBottom: '1px solid #fff',
    zIndex: 2,
  },
  closeIcon: {
    width: '32px',
    height: '32px',
  },
  titleContainer: {
    flexGrow: 0,
    justifyContent: 'center',
    padding: '16px 0',
    maxWidth: '420px',

    '@media (max-width: 1200px)': {
      padding: '16px 0',
    },
  },
  title: {
    color: '#FFF',
    textAlign: 'center',
    fontFamily: 'Now',
    fontSize: '24px',
    fontWeight: '700',
    textTransform: 'uppercase',

    '@media (max-width: 1200px)': {
      fontSize: '20px',
    },
  },
  subtitle: {
    color: '#FFF',
    fontSize: '20px',
    fontWeight: '700',
    textTransform: 'uppercase',

    '@media (max-width: 1200px)': {
      fontSize: '14px',
    },
  },
  content: {
    flexDirection: 'column',
    width: '100%',
    paddingY: '16px',
  },
  avatarContainer: {
    flexDirection: 'column',
    flexGrow: '0 !important',
    alignContent: 'center',
  },
  avatar: {
    height: '120px',
    width: '120px',
    borderRadius: '50%',
    flexDirection: 'column',
    backgroundColor: 'transparent',
    border: (theme) => `1px solid ${theme.palette.primary.main}`,
    cursor: 'pointer',

    svg: {
      color: (theme) => theme.palette.primary.main,
      width: '40px',
      height: '40px',
      maxWidth: '50%',
      maxHeight: '50%',
    },
  },
  fotoPerfilText: {
    color: (theme) => theme.palette.primary.main,
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: '700',
    width: '70%',
    marginTop: 1,

    '@media (max-width: 900px)': {
      display: 'none',
    },
  },
};
