import {useMutation} from '@apollo/client';
import {Add, Close, Save} from '@mui/icons-material';
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
  Slide,
  Typography,
} from '@mui/material';
import {useEffect, useState} from 'react';
import * as Yup from 'yup';

import Input from '../../../components/input/input';
import {SelectFuncoes} from '../../../components/input/select/funcoes';
import {toastNotification} from '../../../components/toastify';
import {UPDATE_ATLETA_EXPERIENCIA} from '../../../graphql/mutation';
import Object from '../../../utils/object';

export default function ExperienciaAtleta({open, onClose, atleta}) {
  const [formData, setFormData] = useState([]);
  const [errors, setErrors] = useState({});

  const [saveAtleta, {loading}] = useMutation(UPDATE_ATLETA_EXPERIENCIA);

  const handleSubmit = (event) => {
    event.preventDefault();

    experienciaScheme
      .validate(formData, {abortEarly: false})
      .then(handleSave)
      .catch((errors) => {
        const camposComErro = {};
        errors?.inner?.forEach((error) => {
          camposComErro[error.path] = error.message;
        });
        setErrors(Object.yupArrayToJs(camposComErro));
      });
  };

  const handleSave = () => {
    saveAtleta({
      variables: {
        atleta: {
          id: atleta.id,
          experiencias: formData.map((e) => ({
            ...e,
            funcao: {id: e.funcao?.id},
          })),
        },
      },
    })
      .then(() => {
        toastNotification({message: 'Registro salvo!', type: 'success'});
        onClose();
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      );
  };

  useEffect(() => {
    setErrors({});
  }, [open]);

  useEffect(() => {
    if (!atleta) {
      setFormData([]);
      return;
    }

    setFormData(atleta.experiencias);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [atleta?.experiencias]);

  const onChange = (field, value, experiencia, index) => {
    formData[index] = {
      ...experiencia,
      [field]: value,
    };

    setFormData([...formData]);
  };

  return (
    <Modal open={open} onClose={onClose} sx={styles.modal}>
      <Slide in={open} appear>
        <Grid
          container
          className="styled-scroll-transparent-desktop"
          sx={styles.container}>
          <Grid container item sx={styles.header}>
            <Typography sx={styles.subtitle}>Experiências</Typography>
            <IconButton sx={styles.closeIcon} onClick={onClose}>
              <Close />
            </IconButton>
          </Grid>
          <Grid container item sx={styles.content} spacing={2}>
            <Grid container item justifyContent={'flex-end'}>
              <Button
                id="text"
                title="Adicionar"
                onClick={() => setFormData([...formData, {}])}
                endIcon={<Add />}>
                Adicionar novo
              </Button>
              <Box sx={styles.experienciasContainer}>
                {formData?.map((experiencia, index) => (
                  <Experiencia
                    key={index}
                    atleta={atleta}
                    experiencia={experiencia}
                    errors={errors[`[${index}]`]}
                    onRemove={() => {
                      formData.splice(index, 1);
                      setFormData([...formData]);
                    }}
                    onChange={(field, value) =>
                      onChange(field, value, experiencia, index)
                    }
                  />
                ))}
              </Box>
            </Grid>
          </Grid>
          <Grid container py={2}>
            <Button
              id="primary"
              onClick={handleSubmit}
              fullWidth
              endIcon={loading ? <CircularProgress size={20} /> : <Save />}>
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Slide>
    </Modal>
  );
}

const Experiencia = ({experiencia, atleta, onChange, onRemove, errors}) => {
  return (
    <Grid container sx={styles.experienciaItem} spacing={2}>
      <Grid item md={6} xs={12}>
        <Input
          label={'Equipe'}
          name="equipe"
          value={experiencia.equipe || ''}
          placeholder={'Digite o nome da equipe'}
          onChange={({target}) => onChange('equipe', target?.value)}
          errors={errors}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <SelectFuncoes
          label={'Função'}
          name="funcao"
          placeholder={'Função na modadidade'}
          value={experiencia.funcao}
          onChange={(funcao) => onChange('funcao', funcao)}
          searchDTO={{modalidadeId: atleta.modalidade?.id}}
          disabled={!atleta.modalidade?.id}
          isClearable
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <Input
          label={'Início da atuação'}
          name="dataInicio"
          value={experiencia.dataInicio || ''}
          type="date"
          onChange={({target}) => onChange('dataInicio', target?.value)}
          errors={errors}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        <Input
          label={'Fim da atuação'}
          name="dataFim"
          value={experiencia.dataFim || ''}
          type="date"
          onChange={({target}) => onChange('dataFim', target?.value)}
          errors={errors}
        />
      </Grid>
      <Grid container item xs={12} justifyContent={'flex-end'}>
        <Button id="underline-text" onClick={onRemove}>
          Excluir
        </Button>
      </Grid>
    </Grid>
  );
};

const experienciaScheme = Yup.array().of(
  Yup.object({
    equipe: Yup.string().required('Campo obrigatório'),
    funcao: Yup.object().required('Campo obrigatório'),
    dataInicio: Yup.date().required('Campo obrigatório'),
  }),
);

const styles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    position: 'relative',
    maxWidth: '683px',
    height: '95%',
    width: '40%',
    borderRadius: '6px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    padding: 3,
    paddingTop: 0,
    zIndex: (theme) => theme.zIndex.modal,
    background: (theme) => theme.palette.blackSecundary,
    boxShadow: '0px 0px 134.60001px 0px rgba(255, 204, 41, 0.25)',
    outline: 'none',
    overflowY: 'auto',

    '@media (max-width: 900px)': {
      width: '90%',
    },
  },
  header: {
    flexGrow: 0,
    paddingTop: 2,
    position: 'sticky',
    top: 0,
    background: (theme) => theme.palette.blackSecundary,
    justifyContent: 'space-between',
    alignItems: 'center',
    svg: {
      color: '#fff',
    },
    paddingBottom: 2,
    borderBottom: '1px solid #fff',
    zIndex: 2,
  },
  closeIcon: {
    width: '32px',
    height: '32px',
  },
  title: {
    color: '#FFF',
    textAlign: 'center',
    fontFamily: 'Now',
    fontSize: '24px',
    fontWeight: '700',
    textTransform: 'uppercase',

    '@media (max-width: 1200px)': {
      fontSize: '20px',
    },
  },
  subtitle: {
    color: '#FFF',
    fontSize: '20px',
    fontWeight: '700',
    textTransform: 'uppercase',

    '@media (max-width: 1200px)': {
      fontSize: '14px',
    },
  },
  experienciaItem: {
    borderBottom: '1px solid #fff',
    padding: '20px 0',
  },
  content: {
    flexDirection: 'row',
    width: '100%',
    paddingY: '16px',
  },
  experienciasContainer: {
    div: {
      '&:last-child': {
        borderBottom: 'none',
      },
    },
  },
};
