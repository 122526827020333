import {useMutation} from '@apollo/client';
import {Save} from '@mui/icons-material';
import {Button, CircularProgress, Grid} from '@mui/material';
import {useEffect, useState} from 'react';
import * as Yup from 'yup';

import Input from '../../../components/input/input';
import InputMask from '../../../components/input/input-mask';
import {toastNotification} from '../../../components/toastify';
import {UPDATE_EMPRESARIO} from '../../../graphql/mutation';
import Masks from '../../../utils/masks';
import Object from '../../../utils/object';
import String from '../../../utils/string';

export default function DadosBaseEmpresario({empresario}) {
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});

  const [saveEmpresario, {loading}] = useMutation(UPDATE_EMPRESARIO);

  const handleSubmit = (event) => {
    event.preventDefault();
    empresarioScheme
      .validate(formData, {abortEarly: false})
      .then(handleSave)
      .catch((errors) => {
        const camposComErro = {};
        errors?.inner?.forEach((error) => {
          camposComErro[error.path] = error.message;
        });

        setErrors(Object.yupArrayToJs(camposComErro));
      });
  };

  const handleSave = async () => {
    saveEmpresario({
      variables: {
        empresario: {
          id: formData.id,
          nome: formData.nome,
          cpfCnpj: String.removeSpecialChars(formData.cpfCnpj),
          telefone: String.removeSpecialChars(formData.telefone),
          email: formData.email,
        },
      },
    })
      .then(() => {
        toastNotification({message: 'Registro salvo!', type: 'success'});
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      );
  };

  useEffect(() => {
    setErrors({});
  }, []);

  useEffect(() => {
    if (!empresario) {
      setFormData({});
    }
    setFormData(empresario || {});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [empresario]);

  return (
    <Grid container>
      <Grid container item sx={styles.content} spacing={2}>
        <Grid item xs={12}>
          <Input
            label={'Nome'}
            name="nome"
            value={formData.nome || ''}
            type="text"
            autoCapitalize="on"
            placeholder={'Nome completo'}
            onChange={({target}) =>
              setFormData((f) => ({...f, nome: target?.value}))
            }
            errors={errors}
          />
        </Grid>
        <Grid item xs={12}>
          <InputMask
            label={'CPF/CNPJ'}
            name="cpfCnpj"
            value={formData.cpfCnpj || ''}
            type="tel"
            mask={Masks.cpfCnpj}
            placeholder={'Digite o número do documento'}
            onChange={({target}) =>
              setFormData((f) => ({...f, cpfCnpj: target?.value}))
            }
            errors={errors}
          />
        </Grid>
        <Grid item xs={12}>
          <InputMask
            label={'Telefone'}
            name="telefone"
            value={formData.telefone || ''}
            type="tel"
            autoComplete="tel"
            mask={Masks.telelefone}
            placeholder={'Digite seu número de telefone'}
            onChange={({target}) =>
              setFormData((f) => ({...f, telefone: target?.value}))
            }
            errors={errors}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            label={'E-mail'}
            name="email"
            value={formData.email || ''}
            type="email"
            autoComplete="email"
            placeholder={'Digite seu e-mail'}
            onChange={({target}) =>
              setFormData((f) => ({...f, email: target?.value}))
            }
            errors={errors}
          />
        </Grid>
      </Grid>
      <Grid container mt={'auto'}>
        <Button
          id="primary"
          onClick={handleSubmit}
          fullWidth
          endIcon={loading ? <CircularProgress size={20} /> : <Save />}>
          Salvar
        </Button>
      </Grid>
    </Grid>
  );
}

const empresarioScheme = Yup.object().shape({
  nome: Yup.string().required('Campo obrigatório'),
  telefone: Yup.string().required('Campo obrigatório'),
  cpfCnpj: Yup.string()
    .required('Campo obrigatório')
    .test('validar-cpfCnpj', 'CPF/CNPJ inválido', (value) =>
      String.validaCpfCnpj(value),
    ),
  email: Yup.string().required('Campo obrigatório').email('E-mail inválido'),
});

const styles = {
  content: {
    alignContent: 'flex-start',
    flexDirection: 'row',
    paddingBottom: '16px',
  },
};
