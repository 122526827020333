import {withApollo} from '@apollo/client/react/hoc';
import React from 'react';

import Input from '../input';
import SelectInput from './select-input';

const getVariables = ({pageNumber, search = '', pageSize = 20}) => {
  return {
    pageable: {pageNumber, pageSize},
    searchDTO: {search},
  };
};

const transformOptions = ({content, labelProp = 'nome', valueProp = 'id'}) => {
  return Array.isArray(content)
    ? content.map((item) => ({
        ...item,
        label: item[labelProp],
        value: item[valueProp],
      }))
    : [];
};

const loadOptions = async ({
  client,
  responseObjectName,
  customTransformOptions,
  customGetVariables,
  labelProp,
  valueProp,
  search,
  page,
  query,
}) => {
  try {
    const {data} = await client.query({
      query,
      variables: customGetVariables
        ? customGetVariables({pageNumber: page, search})
        : getVariables({pageNumber: page, search}),
    });

    return {
      options: customTransformOptions
        ? customTransformOptions({
            content: data[responseObjectName].content,
          })
        : transformOptions({
            content: data[responseObjectName].content,
            labelProp,
            valueProp,
          }),
      hasMore: !data[responseObjectName].last,
      additional: {
        page: page + 1,
      },
    };
  } catch (error) {
    return {
      options: [],
      hasMore: false,
      additional: {
        page: 0,
      },
    };
  }
};

export const SelectBase = withApollo(
  ({
    client,
    responseObjectName,
    transformOptions,
    getVariables,
    valueProp,
    labelProp,
    query,
    value,
    disabled = false,
    onCreateOption,
    ...props
  }) => {
    if (disabled) {
      return <Input value={value?.label} disabled {...props} />;
    }

    return (
      <SelectInput
        defaultOptions
        loadOptions={async (search, loadedOptions, {page}) => {
          if (disabled) return {options: []};

          return loadOptions({
            client,
            responseObjectName,
            customTransformOptions: transformOptions,
            customGetVariables: getVariables,
            search,
            valueProp,
            labelProp,
            query,
            page,
          });
        }}
        paginate
        additional={{
          page: 0,
        }}
        debounceTimeout={300}
        value={value}
        onCreateOption={onCreateOption}
        {...props}
      />
    );
  },
);
