import {EditRounded, LogoutRounded, PersonRounded} from '@mui/icons-material';
import {
  Avatar,
  Backdrop,
  Box,
  Button,
  Divider,
  Grid,
  List,
  Popper,
  Typography,
} from '@mui/material';
import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';

import {useAuth} from '../../contexts/auth';
import {getImageUrl} from '../../utils/file';

const getTipoUrl = (usuario) => {
  const authority = usuario?.authorities?.[0].nome;
  switch (authority) {
    case 'ROLE_ADMINISTRADOR':
      return 'administrador';
    case 'ROLE_ATLETA':
      return 'atleta';
    case 'ROLE_EMPRESARIO':
      return 'empresario';
    case 'ROLE_JORNALISTA':
      return 'jornalista';
    case 'ROLE_PROFISSIONAL_SAUDE':
      return 'profissional';
    default:
      return '';
  }
};

export default function PerfilLogado({mobile}) {
  const {entidadeLogada, logout} = useAuth();

  const [openMenu, setOpenMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickAvatar = (event) => {
    setOpenMenu(true);
    setAnchorEl(event.currentTarget);
  };

  if (mobile) {
    return (
      <Box>
        <PerfilLogadoContent entidadeLogada={entidadeLogada} logout={logout} />
      </Box>
    );
  }

  return (
    <Grid position={'relative'}>
      <Avatar
        src={getImageUrl(entidadeLogada?.fotoPerfil)}
        alt={entidadeLogada?.nome}
        onClick={handleClickAvatar}
        sx={styles.avatar}>
        <PersonRounded />
      </Avatar>
      <Backdrop
        open={openMenu}
        onClick={() => setOpenMenu(false)}
        sx={{backgroundColor: '#0000'}}>
        <Popper open={openMenu} anchorEl={anchorEl} sx={styles.modal}>
          <PerfilLogadoContent
            entidadeLogada={entidadeLogada}
            logout={logout}
          />
        </Popper>
      </Backdrop>
    </Grid>
  );
}

function PerfilLogadoContent({entidadeLogada, logout}) {
  const {usuarioLogado} = useAuth();
  const navigate = useNavigate();

  return (
    <List sx={{width: '100%'}}>
      <Grid container item xs={12}>
        <Button
          onClick={() =>
            navigate(`/${getTipoUrl(usuarioLogado)}/${entidadeLogada?.id}`)
          }
          sx={styles.button}>
          <Grid container sx={{flexWrap: 'nowrap', alignItems: 'center'}}>
            <Avatar
              src={getImageUrl(entidadeLogada?.fotoPerfil)}
              alt={entidadeLogada?.nome}
              sx={styles.avatar}>
              <PersonRounded />
            </Avatar>
            <Box>
              <Typography sx={styles.nomeUsuario}>
                {entidadeLogada?.nome}
              </Typography>
            </Box>
          </Grid>
        </Button>
        <Button
          sx={styles.button}
          startIcon={<EditRounded />}
          onClick={() => navigate('/perfil')}>
          Ver perfil
        </Button>
      </Grid>
      <Divider variant="middle" sx={{borderColor: '#fff', marginY: 1}} />
      <Grid container item xs={12}>
        <Button
          onClick={logout}
          sx={styles.button}
          startIcon={<LogoutRounded />}>
          Sair
        </Button>
      </Grid>
    </List>
  );
}

const styles = {
  avatar: {
    cursor: 'pointer',
  },
  modal: {
    maxWidth: '85%',
    width: 'min-content',
    borderRadius: '6px',
    zIndex: (theme) => theme.zIndex.modal,
    backgroundColor: (theme) => theme.palette.blackSecundary,
    boxShadow: '-48px -21px 134.60001px 0px rgba(255, 204, 41, 0.25)',
    maxHeight: (theme) => `calc(100% - 50px - ${theme.toolbarHeight})`,
    inset: '12px auto auto -70px !important',
  },
  content: {},
  button: {
    color: '#fff',
    width: '100%',
    justifyContent: 'flex-start',
    padding: '8px 16px',
    textTransform: 'none',
    svg: {
      fontSize: 18,
    },
  },
  nomeUsuario: {
    color: '#fff',
    fontWeight: '700',
    fontSize: '18px',
    textWrap: 'nowrap',
    marginX: 2,
    maxWidth: '200px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  editarUsuario: {
    color: '#fffa',
    fontFamily: 'Inter',
    textWrap: 'nowrap',
    textAlign: 'left',
    fontSize: '12px',
    marginX: 2,
    textDecoration: 'underline',
  },
};
