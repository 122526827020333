const Number = {
  format: (value, options = {}, locales = 'pt-BR') => {
    if (!parseInt(value, 10)) return '0';

    return new Intl.NumberFormat(locales, {
      maximumFractionDigits: 2,
      ...options,
    }).format(value);
  },
  safeParseInt: (value) => {
    if (!value || isNaN(value)) return 0;

    return parseInt(value);
  },
  safeParseFloat: (value) => {
    if (!value || isNaN(value)) return 0;

    return parseFloat(value);
  },
  safeDivision: (valor, divisor, format) => {
    if (isNaN(valor) || isNaN(divisor) || divisor === 0) return 0;

    const resultado = valor / divisor;

    if (format) return Number.format(resultado);

    return resultado;
  },
};

export default Number;
