import {useLazyQuery, useReactiveVar} from '@apollo/client';
import React, {createContext, useContext, useEffect, useState} from 'react';

import {isAuthenticated} from '../../config/apollo';
import {
  getTokens,
  logoutAccount,
  recoverPassword,
  refreshAccessToken,
  removeAllTokens,
  resetPassword,
} from '../../config/auth';
import {
  ACCESS_TOKEN_KEY,
  REFRESH_TOKEN_KEY,
} from '../../constants/storage-keys';
import {
  ADMINISTRADOR_LOGADO,
  ATLETA_LOGADO,
  EMPRESARIO_LOGADO,
  JORNALISTA_LOGADO,
  PROFISSIONAL_LOGADO,
  USUARIO_LOGADO,
} from '../../graphql/queries';

const AuthContext = createContext({});

const AuthContextProvider = ({children}) => {
  const [getUsuarioLogado, {data: response, loading, error}] = useLazyQuery(
    USUARIO_LOGADO,
    {
      fetchPolicy: 'cache-and-network',
    },
  );

  const [getAdminLogado, adminQuery] = useLazyQuery(ADMINISTRADOR_LOGADO);
  const [getAtletaLogado, atletaQuery] = useLazyQuery(ATLETA_LOGADO);
  const [getEmpresarioLogado, empresarioQuery] =
    useLazyQuery(EMPRESARIO_LOGADO);
  const [getJornalistaLogado, jornalistaQuery] =
    useLazyQuery(JORNALISTA_LOGADO);
  const [getProfissionalLogado, profissionalQuery] =
    useLazyQuery(PROFISSIONAL_LOGADO);

  const [isLoading, setIsLoading] = useState(true);
  const [entidadeLogada, setEntidadeLogada] = useState({});
  const isLogged = useReactiveVar(isAuthenticated);
  const usuarioLogado = response?.usuario || null;
  const role = response?.usuario?.authorities?.[0].nome || null;

  const login = async (username, password) => {
    return new Promise(async (resolve, reject) => {
      try {
        setIsLoading(true);
        const response = await getTokens(username, password);
        await getUsuarioLogado();
        isAuthenticated(true);
        setIsLoading(false);
        resolve(response);
      } catch (error) {
        setIsLoading(false);
        isAuthenticated(false);
        reject(error);
      }
    });
  };

  const recuperarSenha = async (email) => {
    return new Promise(async (resolve, reject) => {
      try {
        setIsLoading(true);
        const response = await recoverPassword(email);
        setIsLoading(false);
        resolve(response);
      } catch (error) {
        setIsLoading(false);
        isAuthenticated(false);
        reject(error);
      }
    });
  };

  const redefinirSenha = async (token, senha) => {
    return new Promise(async (resolve, reject) => {
      try {
        setIsLoading(true);
        const response = await resetPassword(token, senha);
        setIsLoading(false);
        resolve(response);
      } catch (error) {
        setIsLoading(false);
        isAuthenticated(false);
        reject(error);
      }
    });
  };

  const logout = async () => {
    await logoutAccount();
    await removeAllTokens();
    isAuthenticated(false);
  };

  const checkTokensExists = async () => {
    const refreshToken = window.localStorage.getItem(REFRESH_TOKEN_KEY);
    if (!refreshToken) return isAuthenticated(false);
    if (isLogged) return;
    const token = window.localStorage.getItem(ACCESS_TOKEN_KEY);

    if (token) {
      try {
        await getUsuarioLogado();
      } catch (error) {
        const e = error;
        if (
          e.networkError &&
          'statusCode' in e.networkError &&
          e.networkError.statusCode === 401
        ) {
          await window.localStorage.removeItem(ACCESS_TOKEN_KEY);
        }
      }
    } else {
      refreshAccessToken().then(checkTokensExists);
    }
  };

  useEffect(() => {
    if (loading || !response?.usuario) return;

    isAuthenticated(true);

    const authority = response?.usuario?.authorities?.[0].nome;

    switch (authority) {
      case 'ROLE_ADMINISTRADOR':
        getAdminLogado();
        break;
      case 'ROLE_ATLETA':
        getAtletaLogado();
        break;
      case 'ROLE_EMPRESARIO':
        getEmpresarioLogado();
        break;
      case 'ROLE_JORNALISTA':
        getJornalistaLogado();
        break;
      case 'ROLE_PROFISSIONAL_SAUDE':
        getProfissionalLogado();
        break;
      default:
        break;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [response?.usuario]);

  useEffect(() => {
    if (loading || !response?.usuario) return;
    const authority = response?.usuario?.authorities?.[0].nome;

    switch (authority) {
      case 'ROLE_ADMINISTRADOR':
        setEntidadeLogada(adminQuery.data?.entidade);
        break;
      case 'ROLE_ATLETA':
        setEntidadeLogada(atletaQuery.data?.entidade);
        break;
      case 'ROLE_EMPRESARIO':
        setEntidadeLogada(empresarioQuery.data?.entidade);
        break;
      case 'ROLE_JORNALISTA':
        setEntidadeLogada(jornalistaQuery.data?.entidade);
        break;
      case 'ROLE_PROFISSIONAL_SAUDE':
        setEntidadeLogada(profissionalQuery.data?.entidade);
        break;
      default:
        break;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    adminQuery,
    atletaQuery,
    empresarioQuery,
    jornalistaQuery,
    profissionalQuery,
  ]);

  useEffect(() => {
    setIsLoading(true);
    void checkTokensExists();
    setIsLoading(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged]);

  const refetchEntidade = () => {
    switch (role) {
      case 'ROLE_ADMINISTRADOR':
        adminQuery?.refetch();
        break;
      case 'ROLE_ATLETA':
        atletaQuery?.refetch();
        break;
      case 'ROLE_EMPRESARIO':
        empresarioQuery?.refetch();
        break;
      case 'ROLE_JORNALISTA':
        jornalistaQuery?.refetch();
        break;
      case 'ROLE_PROFISSIONAL_SAUDE':
        profissionalQuery?.refetch();
        break;
      default:
        break;
    }
  };

  return (
    <AuthContext.Provider
      value={{
        login,
        logout,
        recuperarSenha,
        redefinirSenha,
        loading: loading || isLoading,
        error,
        isLogged,
        usuarioLogado,
        entidadeLogada,
        refetchEntidade,
        role,
        getUsuarioLogado,
      }}>
      {children}
    </AuthContext.Provider>
  );
};

function useAuth() {
  const context = useContext(AuthContext);

  return context;
}

export {AuthContextProvider, useAuth};

