import {useQuery} from '@apollo/client';
import {Visibility} from '@mui/icons-material';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import {useEffect, useState} from 'react';

import ButtonResponsive from '../../../components/buttons/button-responsive';
import Filtros from '../../../components/filtro/filtros';
import Input from '../../../components/input/input';
import SelectInput from '../../../components/input/select/select-input';
import {tipoContatoOptions} from '../../../components/modais/contato';
import {tipoContato} from '../../../constants/enum-labels';
import {FIND_ALL_CONTATOS} from '../../../graphql/queries';
import Data from '../../../utils/data';
import ContatoModal from './contato-modal';

export default function ConsultaContatos() {
  const [contatos, setContatos] = useState([]);
  const [searchDTO, setSearchDTO] = useState({});
  const [page, setPage] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [selecionado, setSelecioando] = useState(undefined);

  const contatosQuery = useQuery(FIND_ALL_CONTATOS, {
    variables: {
      pageable: {
        pageNumber: page,
        pageSize: 20,
        orderBy: [
          {
            campo: 'dataCriacao',
            ordem: 'DESC',
          },
          {
            campo: 'nome',
            ordem: 'ASC',
          },
        ],
      },
      searchDTO: {
        search: searchDTO.search,
        tipo: searchDTO.tipo?.value || undefined,
        ativo: searchDTO?.concluido?.value,
      },
    },
  });

  useEffect(() => {
    if (contatosQuery.loading) return;

    setContatos(contatosQuery.data?.contatos?.content || []);
    setTotalElements(contatosQuery.data?.contatos?.totalElements || 0);
  }, [contatosQuery]);

  useEffect(() => {
    if (!selecionado) return;

    setOpenModal(true);
  }, [selecionado]);

  useEffect(() => {
    setPage(0);
  }, [searchDTO]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Grid container item xs={12} paddingBottom={4}>
      <Filtros>
        <Grid item xs={6}>
          <Input
            name="busca"
            placeholder="Pesquisar por nome"
            type="text"
            autoComplete="off"
            value={searchDTO.search || ""}
            onChange={({target}) =>
              setSearchDTO((f) => ({...f, search: target?.value}))
            }
          />
        </Grid>
        <Grid item xs={3}>
          <SelectInput
            name="tipo"
            placeholder={'Tipo do assunto'}
            value={searchDTO.tipo}
            onChange={(tipo) => setSearchDTO((f) => ({...f, tipo}))}
            options={tipoContatoOptions}
            isClearable
          />
        </Grid>
        <Grid item xs={3}>
          <SelectInput
            name="concluido"
            placeholder={'Conluído'}
            value={searchDTO.concluido}
            onChange={(concluido) => setSearchDTO((f) => ({...f, concluido}))}
            options={[
              {value: true, label: 'Sim'},
              {value: false, label: 'Não'},
            ]}
            isClearable
          />
        </Grid>
      </Filtros>
      <TableContainer id="table-container">
        <Table id="table">
          <TableHead id="table-head">
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell>E-mail</TableCell>
              <TableCell>Tipo</TableCell>
              <TableCell>Data</TableCell>
              <TableCell>Concluído</TableCell>
              <TableCell sx={{width: '182px'}} align="center">
                Ações
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody id="table-body">
            {contatos.map((c) => (
              <TableRow key={c.id} id="table-row">
                <TableCell>{c.nome}</TableCell>
                <TableCell>{c.email}</TableCell>
                <TableCell>{tipoContato[c.tipo]}</TableCell>
                <TableCell>{Data.dataFormat(c.dataCriacao)}</TableCell>
                <TableCell>{c.concluido ? 'Sim' : 'Não'}</TableCell>
                <TableCell align="right">
                  <ButtonResponsive
                    id="primary"
                    value="Visualizar"
                    onClick={() => setSelecioando(c)}
                    icon={<Visibility />}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow id="table-row-footer">
              <TableCell id="table-pagination" colSpan={6}>
                <TablePagination
                  component="div"
                  count={totalElements}
                  rowsPerPageOptions={[]}
                  rowsPerPage={20}
                  page={page}
                  labelDisplayedRows={({from, to, count}) =>
                    `${from}-${to} de ${count}`
                  }
                  onPageChange={handleChangePage}
                />
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <ContatoModal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
          setSelecioando(undefined);
          contatosQuery.refetch();
        }}
        contato={selecionado}
        isAdmin
      />
    </Grid>
  );
}
