import {Save} from '@mui/icons-material';
import {Button, Grid, Typography} from '@mui/material';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {useAuth} from '../../../contexts/auth';
import {setFiltrosBusca} from '../../../redux/Busca/slice';

export default function FiltrosSalvosBusca({onSelect}) {
  const {isMobile} = useSelector((state) => state.App);

  if (isMobile) {
    return (
      <Grid container sx={styles.containerMobile}>
        <FiltrosSalvos onSelect={onSelect} />
      </Grid>
    );
  }

  return (
    <Grid container sx={styles.container}>
      <Grid container item sx={styles.header}>
        <Save sx={styles.headerIcon} />
        <Typography sx={styles.headerText}>Filtros salvos</Typography>
      </Grid>
      <FiltrosSalvos />
    </Grid>
  );
}

const FiltrosSalvos = ({onSelect}) => {
  const {entidadeLogada} = useAuth();
  const {filtros} = useSelector((state) => state.Busca);
  const dispatch = useDispatch();

  const [filtrosSalvos, setFiltrosSalvos] = useState([]);

  useEffect(() => {
    if (!entidadeLogada) return;

    setFiltrosSalvos(entidadeLogada?.filtros || []);
  }, [entidadeLogada]);

  const handleClick = ({id, descricao, filtro}) => {
    if (id === filtros?.id)
      return dispatch(setFiltrosBusca({perfil: filtros.perfil}));

    dispatch(setFiltrosBusca({...JSON.parse(filtro || `{}`), id, descricao}));
    onSelect && onSelect();
  };

  return (
    <Grid
      container
      className="styled-scroll-transparent-desktop"
      sx={styles.filtrosContent}>
      {filtrosSalvos.map((f) => (
        <Button
          key={f.id}
          onClick={() => handleClick(f)}
          sx={[
            styles.filtroButton,
            f?.id === filtros?.id ? styles.filtroButtonSelecionado : {},
          ]}>
          <Typography sx={styles.filtro}>{f.descricao}</Typography>
        </Button>
      ))}
    </Grid>
  );
};

const styles = {
  container: {
    backgroundColor: (theme) => theme.palette.blackSecundary,
    borderRadius: '6px',
    padding: '16px 0',
    overflowY: 'auto',
    maxHeight: '100%',
  },
  filtrosContent: {
    overflowY: 'auto',
    overflowX: 'hidden',
    alignContent: 'flex-start',
    maxHeight: 'calc(100vh - 272px)',
    paddingX: '16px',

    '@media (max-width: 900px)': {
      maxHeight: 'calc(100vh - 256px)',
      paddingX: '32px',
    },
  },
  containerMobile: {
    position: 'relative',
    justifyContent: 'flex-end',
    minHeight: '50vh',
  },
  header: {
    color: '#fff',
    paddingBottom: '8px',
    paddingX: '16px',
    alignItems: 'center',
  },
  headerIcon: {
    marginRight: 1,
    fontSize: '18px',
  },
  headerText: {
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: '700',
    lineHeight: '22px',
    letterSpacing: '0em',
    textAlign: 'left',
  },
  filtroButton: {
    '.MuiTypography-root': {
      color: '#fff',
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: 600,
      lineHeight: '17px',
      letterSpacing: '0.03em',
      textAlign: 'left',
    },
    borderRadius: '6px',
    border: '1px solid #fff',
    width: '100%',
    padding: 1,
    marginY: 1,
    textTransform: 'none',
    justifyContent: 'flex-start',
    ':hover': {
      backgroundColor: (theme) => theme.palette.yellowLight,
      color: (theme) => theme.palette.blackSecundary,
    },
  },
  filtroButtonSelecionado: {
    '.MuiTypography-root': {
      color: (theme) => theme.palette.blackSecundary,
    },
    border: 'none',
    backgroundColor: (theme) => theme.palette.yellow,
    ':hover': {
      boxShadow: '0px 4px 64.6px 0px rgba(255, 204, 41, 0.30)',
      backgroundColor: (theme) => theme.palette.yellow,
      color: (theme) => theme.palette.blackSecundary,
    },
  },
};
