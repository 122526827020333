import {useMutation} from '@apollo/client';
import {Save} from '@mui/icons-material';
import {Button, CircularProgress, Grid} from '@mui/material';
import {useEffect, useState} from 'react';

import Input from '../../../components/input/input';
import {toastNotification} from '../../../components/toastify';
import {UPDATE_ATLETA_REDES_SOCIAIS} from '../../../graphql/mutation';

export default function RedesSociaisAtleta({atleta}) {
  const [formData, setFormData] = useState({});

  const [saveAtleta, {loading}] = useMutation(UPDATE_ATLETA_REDES_SOCIAIS);

  const handleSave = async () => {
    saveAtleta({
      variables: {
        atleta: {
          id: formData.id,
          urlFacebook: formData.urlFacebook,
          urlInstagram: formData.urlInstagram,
          urlX: formData.urlX,
          urlLinkedin: formData.urlLinkedin,
        },
      },
    })
      .then(() => {
        toastNotification({message: 'Registro salvo!', type: 'success'});
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      );
  };

  useEffect(() => {
    if (!atleta) {
      setFormData({});
    }
    setFormData(atleta || {});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [atleta]);

  return (
    <Grid container sx={{height: '100%'}}>
      <Grid container item sx={styles.content} spacing={2}>
        <Grid item md={6} xs={12}>
          <Input
            label={'Facebook'}
            name="urlFacebook"
            value={formData.urlFacebook || ''}
            type="text"
            placeholder={'URL do seu Facebook'}
            onChange={({target}) =>
              setFormData((f) => ({...f, urlFacebook: target?.value}))
            }
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Input
            label={'Instagram'}
            name="urlInstagram"
            value={formData.urlInstagram || ''}
            type="text"
            placeholder={'URL do seu Instagram'}
            onChange={({target}) =>
              setFormData((f) => ({...f, urlInstagram: target?.value}))
            }
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Input
            label={'X'}
            name="urlX"
            value={formData.urlX || ''}
            type="text"
            placeholder={'URL do seu X (Twitter)'}
            onChange={({target}) =>
              setFormData((f) => ({...f, urlX: target?.value}))
            }
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Input
            label={'LinkedIn'}
            name="urlLinkedin"
            value={formData.urlLinkedin || ''}
            type="text"
            placeholder={'URL do seu X (Twitter)'}
            onChange={({target}) =>
              setFormData((f) => ({...f, urlLinkedin: target?.value}))
            }
          />
        </Grid>
      </Grid>
      <Grid container mt={'auto'}>
        <Button
          id="primary"
          onClick={handleSave}
          fullWidth
          endIcon={loading ? <CircularProgress size={20} /> : <Save />}>
          Salvar
        </Button>
      </Grid>
    </Grid>
  );
}

const styles = {
  content: {
    alignContent: 'flex-start',
    flexDirection: 'row',
    paddingBottom: '16px',
  },
};
