import {CameraAlt, CloudUpload} from '@mui/icons-material';
import {Box, Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';

import {getImageUrl} from '../utils/file';
import ImagemModal from './modais/imagem-modal';

const UploadableFotoPerfil = ({
  label,
  value,
  onChange,
  index = '',
  readOnly,
  sx,
}) => {
  const [isBase64, setIsBase64] = useState(false);
  const [image, setImage] = useState(value);
  const [openImage, setOpenImage] = useState(false);

  useEffect(() => {
    setImage(value);
  }, [value]);

  useEffect(() => {
    setIsBase64(image?.includes('base64'));
  }, [image]);

  function handlePreviewImage(file) {
    const reader = new FileReader();

    reader.addEventListener(
      'load',
      function () {
        setImage(reader.result);
      },
      false,
    );

    if (file) {
      reader.readAsDataURL(file);
    }
  }

  function handleChangeUploadImage(e) {
    const file = Array.from(e.target.files)[0];
    onChange(file);
    handlePreviewImage(file);
  }

  if (readOnly) {
    return (
      <Box sx={sx}>
        <Typography sx={styles.label}>{label}</Typography>
        {image && (
          <Box
            className="foto-container"
            sx={styles.fotoContainerDisabled}
            onClick={() => setOpenImage(true)}>
            <img
              src={isBase64 ? image : getImageUrl(image)}
              alt={label}
              style={styles.fotoImg}
              onError={() => setImage(null)}
            />
          </Box>
        )}
        {!image && (
          <Box className="foto-container" sx={styles.fotoContainerDisabled}>
            <CameraAlt sx={styles.placeholderIconDisabled} />
            <Typography sx={styles.placeholderLabelDisabled}>
              Nenhuma foto disponível
            </Typography>
          </Box>
        )}
        {openImage && image && (
          <ImagemModal
            visible={openImage}
            id={image}
            titulo={label}
            onClose={() => setOpenImage(false)}
          />
        )}
      </Box>
    );
  }

  return (
    <Box>
      <Typography sx={styles.label}>{label}</Typography>
      <label
        sx={styles.uploadButtonContainer}
        htmlFor={`logo-input-file${index !== '' ? '-' + index : ''}`}>
        {image && (
          <Box sx={styles.fotoContainer}>
            <img
              src={isBase64 ? image : getImageUrl(image)}
              alt={label}
              style={styles.fotoImg}
              onError={() => setImage(null)}
            />
          </Box>
        )}
        {!image && (
          <Box sx={styles.fotoContainer} style={{background: '#fff'}}>
            <CloudUpload sx={styles.placeholderIcon} />
            <Typography sx={styles.placeholderLabel}>Enviar imagem</Typography>
          </Box>
        )}
        <input
          id={`logo-input-file${index !== '' ? '-' + index : ''}`}
          type="file"
          style={styles.uploadInputFile}
          onChange={handleChangeUploadImage}
          disabled={readOnly}
        />
      </label>
    </Box>
  );
};

const styles = {
  label: {
    display: 'block',
    color: '#fff',
    fontSize: '14px',
    fontWeight: 'bold',
    marginBottom: '2px',
  },
  uploadButtonContainer: {
    display: 'block',
    width: '100%',
    cursor: 'pointer',
    position: 'relative',
  },
  fotoContainer: {
    width: '100%',
    height: '125px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #fff',
    borderRadius: '6px',
  },
  fotoContainerDisabled: {
    width: '100%',
    height: '125px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #CDCDCD',
    borderRadius: '6px',
  },
  fotoImg: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    cursor: 'pointer',
  },
  uploadInputFile: {
    width: '1px',
    opacity: '0.0',
    position: 'absolute',
  },
  placeholderIcon: {
    color: '#8995b1',
    width: ' 40px',
    height: ' 40px',
  },
  placeholderLabel: {
    color: '#8995b1',
    fontSize: '13px',
    marginBottom: '0px',
    marginTop: '10px',
    cursor: 'default',
    textAlign: 'center',
  },
  placeholderIconDisabled: {
    color: '#CDCDCD',
    width: ' 40px',
    height: ' 40px',
  },
  placeholderLabelDisabled: {
    color: '#CDCDCD',
    fontSize: '13px',
    marginBottom: '0px',
    marginTop: '10px',
    cursor: 'default',
    textAlign: 'center',
  },
};

export default UploadableFotoPerfil;
