import {useQuery} from '@apollo/client';
import {CircularProgress, Grid} from '@mui/material';
import {useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';

import {useAuth} from '../../contexts/auth';
import {FIND_NOTICIA_BY_ID} from '../../graphql/queries';
import NoticiaFormulario from './formulario';

export default function NoticiaEditar() {
  const {noticiaId} = useParams();
  const {role} = useAuth();
  const navigate = useNavigate();

  const noticiaQuery = useQuery(FIND_NOTICIA_BY_ID, {
    variables: {
      id: noticiaId,
    },
  });

  const {data = {}} = noticiaQuery;

  useEffect(() => {
    if (['ROLE_JORNALISTA', 'ROLE_PROFISSIONAL_SAUDE'].includes(role)) return;

    return navigate('/');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role]);

  const refetch = () => {
    noticiaQuery.refetch();
  };

  if (noticiaQuery.loading || noticiaQuery.error) {
    return (
      <Grid id="container" container>
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <NoticiaFormulario
      noticia={data.noticia}
      isArtigo={role === 'ROLE_PROFISSIONAL_SAUDE'}
      refetch={refetch}
    />
  );
}
