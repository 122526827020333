import {useMutation} from '@apollo/client';
import {Close} from '@mui/icons-material';
import {
  Button,
  Grid,
  IconButton,
  Modal,
  Slide,
  Typography,
} from '@mui/material';
import {useEffect, useState} from 'react';
import * as Yup from 'yup';

import {CRIAR_EMAIL_CONTATO} from '../../graphql/mutation';
import Masks from '../../utils/masks';
import String from '../../utils/string';
import {Logo} from '../icons';
import Input from '../input/input';
import InputMask from '../input/input-mask';
import SelectInput from '../input/select/select-input';
import {toastNotification} from '../toastify';

export const tipoContatoOptions = [
  {value: 'CADASTRO', label: 'Cadastro'},
  {value: 'CRITICA', label: 'Crítica'},
  {value: 'DUVIDA', label: 'Dúvida'},
  {value: 'ELOGIO', label: 'Elogio'},
  {value: 'SUGESTAO', label: 'Sugestão'},
];

export default function ContatoModal({tipo = tipoContatoOptions[2], open, onClose}) {
  const [formData, setFormData] = useState({tipo});
  const [errors, setErrors] = useState({});

  const [createContato] = useMutation(CRIAR_EMAIL_CONTATO);

  const handleSubmit = (event) => {
    event.preventDefault();
    contatoScheme
      .validate(formData, {abortEarly: false})
      .then(handleCreateContato)
      .catch((errors) => {
        const camposComErro = {};
        errors?.inner?.forEach((error) => {
          camposComErro[error.path] = error.message;
        });
        setErrors(camposComErro);
      });
  };

  const handleCreateContato = () => {
    createContato({
      variables: {
        contato: {
          ...formData,
          tipo: formData.tipo?.value,
          telefone: String.removeSpecialChars(formData.telefone),
        },
      },
    })
      .then(() => {
        toastNotification({message: 'Mensagem enviada!', type: 'success'});
        onClose();
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      );
  };

  useEffect(() => {
    setErrors({});
  }, [open]);

  return (
    <Modal open={open} onClose={onClose} sx={styles.modal}>
      <Slide in={open} appear>
        <Grid
          container
          className="styled-scroll-transparent-desktop"
          sx={styles.container}>
          <Grid container item sx={styles.header}>
            <Logo />
            <IconButton sx={styles.closeIcon} onClick={onClose}>
              <Close />
            </IconButton>
          </Grid>
          <Grid container item sx={styles.titleContainer}>
            <Typography sx={styles.title}>ENTRE EM CONTATO</Typography>
            <Typography sx={styles.subtitle}>
              Nos informe seus dados que entramos em contato
            </Typography>
          </Grid>
          <Grid container item sx={styles.buttonsContainer} gap={2}>
            <Input
              label={'Nome'}
              name="nome"
              type="text"
              autoCapitalize="on"
              placeholder={'Nome completo'}
              onChange={({target}) =>
                setFormData((f) => ({...f, nome: target?.value}))
              }
              errors={errors}
            />
            <InputMask
              label={'Telefone'}
              name="telefone"
              type="tel"
              mask={Masks.telelefone}
              placeholder={'Digite seu número de telefone'}
              onChange={({target}) =>
                setFormData((f) => ({...f, telefone: target?.value}))
              }
              errors={errors}
            />
            <Input
              label={'E-mail'}
              name="email"
              type="email"
              autoComplete="email"
              placeholder={'Digite seu e-mail'}
              onChange={({target}) =>
                setFormData((f) => ({...f, email: target?.value}))
              }
              errors={errors}
            />
            <SelectInput
              label={'Tipo'}
              name="tipo"
              placeholder={'Digite o tipo do assunto'}
              defaultChecked={'DUVIDA'}
              value={formData.tipo}
              menuPlacement="top"
              onChange={(tipo) => setFormData((f) => ({...f, tipo}))}
              options={tipoContatoOptions}
              errors={errors}
            />
            <Input
              label={'Assunto'}
              name="mensagem"
              type="text"
              autoCapitalize="on"
              placeholder={'Digite o assunto'}
              multiline
              rows={3}
              onChange={({target}) =>
                setFormData((f) => ({...f, mensagem: target?.value}))
              }
              errors={errors}
            />
            <Button id="primary" onClick={handleSubmit}>
              ENVIAR
            </Button>
          </Grid>
        </Grid>
      </Slide>
    </Modal>
  );
}

const contatoScheme = Yup.object().shape({
  nome: Yup.string().required('Campo obrigatório'),
  telefone: Yup.string().required('Campo obrigatório'),
  email: Yup.string().required('Campo obrigatório').email('E-mail inválido'),
  mensagem: Yup.string().required('Campo obrigatório'),
});

const styles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    position: 'relative',
    maxHeight: '696px',
    maxWidth: '683px',
    height: '80%',
    width: '40%',
    borderRadius: '6px',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    padding: 3,
    zIndex: (theme) => theme.zIndex.modal,
    background: (theme) => theme.palette.blackSecundary,
    boxShadow: '0px 0px 134.60001px 0px rgba(255, 204, 41, 0.25)',
    outline: 'none',
    overflowY: 'auto',

    '@media (max-width: 900px)': {
      width: '90%',
    },
  },
  header: {
    flexGrow: 0,
    justifyContent: 'center',
    svg: {
      color: '#fff',
    },
  },
  titleContainer: {
    flexGrow: 0,
    justifyContent: 'center',
    padding: '16px 0',
    maxWidth: '420px',

    '@media (max-width: 1200px)': {
      padding: '16px 0',
    },
  },
  closeIcon: {
    position: 'absolute',
    top: '28px',
    right: '28px',
    width: '32px',
    height: '32px',
  },
  title: {
    color: '#FFF',
    textAlign: 'center',
    fontFamily: 'Now',
    fontSize: '24px',
    fontWeight: '700',
    textTransform: 'uppercase',

    '@media (max-width: 1200px)': {
      fontSize: '20px',
    },
  },
  subtitle: {
    color: '#E3E3E3',
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: '500',

    '@media (max-width: 1200px)': {
      fontSize: '14px',
    },
  },
  buttonsContainer: {
    maxWidth: '420px',
    flexDirection: 'column',
    width: '100%',
    paddingBottom: '16px',
  },
  button: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    border: '1px solid #fff',
    width: '100%',
    height: '100%',

    '@media (max-width: 1200px)': {
      flexDirection: 'row',
    },

    '.MuiTypography-root': {
      color: '#fff',
      textTransform: 'none',
      fontSize: '16px',
      lineHeight: '38px',
      fontWeight: '500',
    },
  },
  buttonIconAtleta: {
    width: '28px',
    height: '28px',
  },
  buttonIconEmpresario: {
    width: '24px',
    height: '24px',
  },
};
