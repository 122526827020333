import React from 'react';
import Viewer from 'react-viewer';

import {getImageUrl} from '../../utils/file';

const ImagemModal = ({id, titulo, ...props}) => {
  const isBase64 = id?.includes('base64');
  return (
    <Viewer
      {...props}
      images={[{src: isBase64 ? id : getImageUrl(id), alt: `${titulo} `}]}
      noNavbar
      changeable={false}
      showTotal={false}
    />
  );
};

export default ImagemModal;
