import {useMutation} from '@apollo/client';
import {
  Bookmark,
  CakeOutlined,
  PersonRounded,
  PlaceOutlined,
} from '@mui/icons-material';
import {Avatar, Button, Grid, IconButton, Typography} from '@mui/material';
import {useNavigate} from 'react-router-dom';

import {useAuth} from '../../../contexts/auth';
import {
  ADICIONAR_ATLETA_FAVORITO,
  REMOVER_ATLETA_FAVORITO,
} from '../../../graphql/mutation';
import Data from '../../../utils/data';
import {getImageUrl} from '../../../utils/file';
import String from '../../../utils/string';

export default function CardBusca({item = {}, tipo, refetch}) {
  const navigate = useNavigate();
  const {isLogged} = useAuth();

  const [addFavorito] = useMutation(ADICIONAR_ATLETA_FAVORITO, {
    variables: {atleta: {id: item?.id}},
  });
  const [removerFavorito] = useMutation(REMOVER_ATLETA_FAVORITO, {
    variables: {atleta: {id: item?.id}},
  });

  const cidade = item.enderecos?.[0]?.cidade;

  const isMenor =
    item.dataNascimento && Data.calcularIdade(item.dataNascimento) < 16;

  const getTipoEntidade = () => {
    if (tipo === 'ATLETAS') return item.modalidade?.descricao;
    if (tipo === 'EMPRESARIOS') return 'Empresário';
  };

  const getTipoUrl = () => {
    if (tipo === 'ATLETAS') return 'atleta';
    if (tipo === 'EMPRESARIOS') return 'empresario';
  };

  const handleFavoritoClick = () => {
    if (item.favorito)
      return removerFavorito().then(() => refetch && refetch());

    addFavorito().then(() => refetch && refetch());
  };

  return (
    <Grid container sx={styles.container}>
      <Grid container item md={4} xs={12} sx={styles.fotoPerfilContainer}>
        <Avatar
          src={getImageUrl(item?.fotoPerfil)}
          alt={isMenor ? String.abreviarNome(item.nome) : item.nome}
          sx={styles.fotoPerfil}>
          <PersonRounded />
        </Avatar>
      </Grid>
      <Grid container item md={8} xs={12} sx={styles.content}>
        {tipo === 'ATLETAS' && isLogged && (
          <IconButton
            id={item?.favorito ? 'icon-primary' : 'icon-outlined'}
            title="Favorito"
            onClick={handleFavoritoClick}
            sx={styles.favoritoIcon}>
            <Bookmark />
          </IconButton>
        )}
        <Typography id="modalidade-tag" className={getTipoUrl()}>
          {getTipoEntidade()}
        </Typography>
        <Typography sx={styles.nome}>
          {isMenor ? String.abreviarNome(item.nome) : item.nome}
        </Typography>
        <Grid container alignItems={'center'}>
          {item.dataNascimento && (
            <>
              <CakeOutlined sx={styles.icone} />
              <Typography sx={styles.endereco}>
                {`${Data.calcularIdade(item.dataNascimento)} anos`}
              </Typography>
            </>
          )}
          {cidade?.id && (
            <>
              <PlaceOutlined sx={styles.icone} />
              <Typography sx={styles.endereco}>
                {`${cidade?.nome} - ${cidade?.uf}`}
              </Typography>
            </>
          )}
        </Grid>
        <Button
          id="outlined"
          onClick={() => navigate(`/${getTipoUrl()}/${item.id}`)}
          fullWidth
          sx={styles.button}>
          VER PERFIL
        </Button>
      </Grid>
    </Grid>
  );
}

const styles = {
  container: {
    maxWidth: '653px',
    marginBottom: 2,
    backgroundColor: (theme) => theme.palette.blackSecundary,

    '@media (min-width: 901px)': {
      borderRadius: '6px',
      height: '200px',
    },
  },
  fotoPerfilContainer: {
    maxHeight: '100%',
    '@media (max-width: 900px)': {
      aspectRatio: 5 / 3,
      padding: 2,
      paddingBottom: 0,
    },
  },
  fotoPerfil: {
    borderRadius: '6px',
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
    svg: {
      width: '50%',
      height: '50%',
    },
  },
  content: {
    position: 'relative',
    flexDirection: 'column',
    padding: 2,
  },
  tipo: {
    width: 'fit-content',
    borderRadius: '3px',
    padding: '4px 8px',
    color: '#FFF',
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: 500,
    marginBottom: 2,
  },
  nome: {
    color: '#FFF',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 500,
    marginBottom: 1,
  },
  icone: {
    color: '#fff',
    width: '18px',
    height: '18px',
    marginRight: '4px',
  },
  endereco: {
    color: '#FFF',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 500,
    marginRight: 1,
  },
  button: {
    marginTop: 1,
  },
  favoritoIcon: {
    position: 'absolute',
    top: '16px',
    right: '16px',
    zIndex: 1,
  },
};
