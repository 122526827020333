import {FilterList} from '@mui/icons-material';
import {Box, Button, Grid, Modal, Slide} from '@mui/material';
import {useState} from 'react';
import {useSelector} from 'react-redux';

export default function Filtros({children, onFilter}) {
  const {isMobile} = useSelector((state) => state.App);

  const [openFiltro, setOpenFiltro] = useState(false);

  if (isMobile) {
    return (
      <Grid container sx={styles.mobileFiltrosContainer}>
        <Button
          id="text"
          onClick={() => setOpenFiltro(true)}
          endIcon={<FilterList />}>
          Filtrar
        </Button>
        <Modal
          componentsProps={{
            backdrop: {
              sx: {
                background:
                  'linear-gradient(to bottom, transparent 80px, rgb(0, 0, 0, 0.5) 80px)',
              },
            },
          }}
          onClose={() => setOpenFiltro(false)}
          open={openFiltro}>
          <Slide direction="left" in={openFiltro} appear>
            <Box sx={styles.modalBusca}>
              <Grid container item xs={12} spacing={2}>
                <Grid container item xs={12} spacing={2} sx={styles.content}>
                  {children}
                </Grid>
                <Grid item xs={12} ml>
                  <Button
                    id="primary"
                    startIcon={<FilterList />}
                    fullWidth
                    onClick={onFilter}>
                    FILTRAR
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Slide>
        </Modal>
      </Grid>
    );
  }

  return (
    <Grid container sx={styles.filtrosContainer}>
      <Grid container item xs={12} spacing={2}>
        <Grid container item xs={10} spacing={2} sx={styles.content}>
          {children}
        </Grid>
        <Grid container item xs={2} ml="auto" justifyContent={'flex-end'}>
          <Button
            id="primary"
            startIcon={<FilterList />}
            fullWidth
            onClick={onFilter}>
            FILTRAR
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

const styles = {
  filtrosContainer: {
    backgroundColor: (theme) => theme.palette.blackSecundary,
    borderRadius: 1,
    padding: 2,
    marginBottom: 2,
  },
  mobileFiltrosContainer: {
    justifyContent: 'flex-end',
    backgroundColor: (theme) => theme.palette.blackSecundary,
    borderRadius: 1,
  },
  modalBusca: {
    width: '100%',
    borderTopLeftRadius: '6px',
    zIndex: (theme) => theme.zIndex.modal,
    backgroundColor: (theme) => theme.palette.blackSecundary,
    padding: 2,
    position: 'absolute',
    right: '0px',
    top: (theme) => theme.toolbarHeight,
  },
  content: {
    '.MuiGrid-item': {
      alignSelf: 'center',
    },
  },
};
