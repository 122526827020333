import {useLazyQuery, useMutation, useQuery} from '@apollo/client';
import {
  CheckCircle,
  Delete,
  FilterListRounded,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Save,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Link,
  Modal,
  Radio,
  RadioGroup,
  Slide,
  Typography,
} from '@mui/material';
import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {confirmAlert} from '../../../components/confirm-alert/confirm-alert';
import Input from '../../../components/input/input';
import SwitchInput from '../../../components/input/switch';
import {toastNotification} from '../../../components/toastify';
import ToggleOptions from '../../../components/toggle-options';
import {useAuth} from '../../../contexts/auth';
import {
  REMOVER_FILTRO_EMPRESARIO,
  SALVAR_FILTRO_EMPRESARIO,
} from '../../../graphql/mutation';
import {FIND_ALL_FUNCOES, FIND_ALL_MODALIDADES} from '../../../graphql/queries';
import {setFiltrosBusca} from '../../../redux/Busca/slice';
import FiltrosSalvosBusca from './filtros-salvos';
import FiltroSaveModal from './modais/filtro-save';

const menuOptions = [
  {
    value: 'FILTRO',
    label: 'Dados básicos',
  },
  {
    value: 'SALVO',
    label: 'Dados básicos',
  },
];

export default function FiltroBusca({onFilter}) {
  const {role} = useAuth();
  const {isMobile} = useSelector((state) => state.App);
  const [openMenu, setOpenMenu] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState(menuOptions[0].value);

  const handleFilter = () => {
    setOpenMenu(false);
    onFilter();
  };

  const renderContent = () => {
    switch (selectedMenu) {
      case menuOptions[0].value:
        return <Filtros onFilter={handleFilter} />;
      case menuOptions[1].value:
        return (
          <FiltrosSalvosBusca onSelect={() => setSelectedMenu(menuOptions[0].value)} />
        );
      default:
        return null;
    }
  };

  const renderMobileContent = () => {
    if (role === 'ROLE_EMPRESARIO') {
      return (
        <Box sx={styles.modal}>
          <Grid container item xs={12} paddingY={1} justifyContent={'center'}>
            <ToggleOptions
              options={menuOptions}
              selected={selectedMenu}
              onChange={setSelectedMenu}
            />
          </Grid>
          {renderContent()}
        </Box>
      );
    }

    return (
      <Box sx={styles.modal}>
        <Filtros onFilter={handleFilter} />
      </Box>
    );
  };

  if (isMobile) {
    return (
      <Grid container sx={styles.containerMobile}>
        <Button
          id="text"
          onClick={() => setOpenMenu(!openMenu)}
          sx={styles.mobileMenuOpen}>
          Filtrar
          <FilterListRounded />
        </Button>
        <Modal
          componentsProps={{
            backdrop: {
              sx: {
                background: 'rgb(0, 0, 0, 0.0)',
              },
            },
          }}
          onClose={() => setOpenMenu(false)}
          open={openMenu}>
          <Slide direction="left" in={openMenu} appear>
            {renderMobileContent()}
          </Slide>
        </Modal>
      </Grid>
    );
  }

  return (
    <Grid>
      <Filtros onFilter={handleFilter} />
    </Grid>
  );
}

const Filtros = ({onFilter}) => {
  const {role, refetchEntidade} = useAuth();
  const dispatch = useDispatch();
  const {filtros} = useSelector((state) => state.Busca);

  const [numeroFiltros, setNumeroFiltros] = useState(0);
  const [modalidades, setModalidades] = useState([]);
  const [funcoes, setFuncoes] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  const modalidadesQuery = useQuery(FIND_ALL_MODALIDADES, {
    variables: {
      pageable: {
        pageNumber: 0,
        pageSize: -1,
        sortField: 'descricao',
        sortDir: 'ASC',
      },
      searchDTO: {},
    },
  });

  const [loadFuncoes, funcoesQuery] = useLazyQuery(FIND_ALL_FUNCOES, {
    variables: {
      pageable: {
        pageNumber: 0,
        pageSize: -1,
        sortField: 'descricao',
        sortDir: 'ASC',
      },
      searchDTO: {},
    },
  });

  const [saveFiltro] = useMutation(SALVAR_FILTRO_EMPRESARIO);
  const [removerFiltro] = useMutation(REMOVER_FILTRO_EMPRESARIO);

  const handleLimparFiltro = () => {
    dispatch(setFiltrosBusca({perfil: filtros.perfil}));
  };

  const onChange = (filtros) => {
    dispatch(setFiltrosBusca(filtros));
  };

  useEffect(() => {
    if (modalidadesQuery.loading) return;
    setModalidades(modalidadesQuery.data?.modalidades?.content || []);
  }, [modalidadesQuery]);

  useEffect(() => {
    if (funcoesQuery.loading) return;
    setFuncoes(funcoesQuery.data?.funcoes?.content || []);
  }, [funcoesQuery]);

  useEffect(() => {
    if (!filtros.modalidade) {
      setFuncoes([]);
      onChange({...filtros, funcoes: {}});
      return;
    }

    loadFuncoes({
      variables: {
        pageable: {
          pageNumber: 0,
          pageSize: -1,
          sortField: 'descricao',
          sortDir: 'ASC',
        },
        searchDTO: {
          modalidadeId: filtros.modalidade,
        },
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtros?.modalidade]);

  useEffect(() => {
    const keys = [];

    function getKeyRecursive(obj) {
      for (const prop in obj) {
        if (typeof obj[prop] === 'object' && prop !== 'cidade') {
          getKeyRecursive(obj[prop]);
        } else if (!!obj[prop]) keys.push(prop);
      }
    }

    getKeyRecursive(filtros);
    setNumeroFiltros(keys.length);
  }, [filtros]);

  const handleClickDelete = () => {
    confirmAlert({
      title: 'Deseja mesmo excluir o filtro?',
      message: filtros.descricao || '',
      onConfirm: handleDelete,
    });
  };

  const handleDelete = () => {
    removerFiltro({
      variables: {
        filtros: {
          id: filtros?.id,
        },
      },
    })
      .then(() => {
        toastNotification({message: 'Filtro removidos!', type: 'success'});
        refetchEntidade();
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      );
  };

  const handleSaveFiltros = () => {
    saveFiltro({
      variables: {
        filtros: {
          id: filtros?.id,
          filtro: JSON.stringify(filtros),
        },
      },
    })
      .then(() => {
        toastNotification({message: 'Filtro salvo!', type: 'success'});
        refetchEntidade();
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      );
  };

  const renderActtionButtons = () => {
    if (filtros?.id && filtros.perfil === 'ATLETAS') {
      return (
        <Grid container padding={'12px 16px'} spacing={2}>
          {filtros.perfil === 'ATLETAS' && (
            <Grid item>
              <IconButton
                id="icon-outlined"
                title="Apagar filtro"
                onClick={handleClickDelete}>
                <Delete />
              </IconButton>
            </Grid>
          )}
          <Grid item flex={1}>
            <Button id="primary" fullWidth onClick={handleSaveFiltros}>
              SALVAR FILTRO
            </Button>
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid container padding={'12px 16px'} spacing={2}>
        {role === 'ROLE_EMPRESARIO' && filtros.perfil === 'ATLETAS' && (
          <Grid item>
            <IconButton
              id="icon-outlined"
              title="Salvar filtro"
              onClick={() => setOpenModal(true)}>
              <Save />
            </IconButton>
          </Grid>
        )}
        <Grid item flex={1}>
          <Button id="primary" fullWidth onClick={onFilter}>
            APLICAR FILTRO
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <Grid container sx={styles.container}>
      <Grid container item xs={12} sx={styles.header}>
        <Grid container item xs={8}>
          <FilterListRounded />
          <Typography sx={styles.headerTitle}>
            Filtrar <Typography component="span">({numeroFiltros})</Typography>
          </Typography>
        </Grid>
        <Grid container item xs={4} justifyContent={'flex-end'}>
          <Link onClick={handleLimparFiltro} sx={styles.limparFiltros}>
            Limpar
          </Link>
        </Grid>
      </Grid>
      <Grid
        container
        className="styled-scroll-transparent-desktop"
        sx={styles.filtrosContent}>
        <Grid container sx={styles.groupContainer}>
          <Typography
            sx={styles.groupTitle}
            disabled={filtros.perfil === 'EMPRESARIOS'}>
            Mostrar favoritos
          </Typography>
          <SwitchInput
            checked={filtros.favoritos}
            onChange={() =>
              onChange({...filtros, favoritos: !filtros.favoritos})
            }
            disabled={filtros.perfil === 'EMPRESARIOS'}
          />
        </Grid>
        <GrupoFiltro
          titulo="Mostrar perfis de"
          value={filtros.perfil || false}
          opcoes={[
            {value: 'ATLETAS', label: 'Atletas'},
            {value: 'EMPRESARIOS', label: 'Empresários'},
          ]}
          onChange={({target}) => onChange({perfil: target?.value})}
          tipo={'radio'}
        />
        <InputFiltro
          titulo="Faixa etária"
          disabled={filtros.perfil === 'EMPRESARIOS'}>
          <Grid container item spacing={2} pt={1}>
            <Grid item xs={6}>
              <Input
                name="idadeMin"
                value={filtros.idadeMin || ''}
                type="number"
                placeholder={'De'}
                InputProps={{inputProps: {min: 0}}}
                onChange={({target}) =>
                  onChange({
                    ...filtros,
                    idadeMin: target?.value || null,
                  })
                }
              />
            </Grid>
            <Grid item xs={6}>
              <Input
                name="idadeMax"
                value={filtros.idadeMax || ''}
                type="number"
                placeholder={'Até'}
                InputProps={{inputProps: {min: 0}}}
                onChange={({target}) =>
                  onChange({
                    ...filtros,
                    idadeMax: target?.value || null,
                  })
                }
              />
            </Grid>
          </Grid>
        </InputFiltro>
        <GrupoFiltro
          titulo="Modalidade"
          value={filtros.modalidade || {}}
          opcoes={modalidades}
          onChange={({target}) =>
            onChange({
              ...filtros,
              modalidade: target?.value,
            })
          }
          tipo={'radio'}
          disabled={filtros.perfil === 'EMPRESARIOS'}
        />
        {filtros.modalidade && (
          <GrupoFiltro
            titulo="Posições"
            value={filtros.posicoes || {}}
            opcoes={funcoes}
            onChange={({target}) =>
              onChange({
                ...filtros,
                posicoes: {
                  ...filtros.posicoes,
                  [target.value]: target.checked,
                },
              })
            }
            tipo={'checkbox'}
            disabled={filtros.perfil === 'EMPRESARIOS'}
          />
        )}
      </Grid>
      {renderActtionButtons()}
      <FiltroSaveModal
        open={openModal}
        onClose={() => {
          setOpenModal(false);
          refetchEntidade();
        }}
        filtros={filtros}
      />
    </Grid>
  );
};

const InputFiltro = ({titulo, disabled, children}) => {
  const [open, setOpen] = useState(true);

  useEffect(() => {
    setOpen(!disabled);
  }, [disabled]);

  return (
    <Grid container sx={styles.groupContainer}>
      <Grid
        container
        onClick={() => setOpen(!open && !disabled)}
        sx={styles.groupHeader}
        disabled={disabled}>
        <Typography sx={styles.groupTitle}>{titulo}</Typography>
        <IconButton
          aria-label="toggle password visibility"
          edge="end"
          sx={styles.groupTitleButton}>
          {open && <KeyboardArrowUp />}
          {!open && <KeyboardArrowDown />}
        </IconButton>
      </Grid>
      <Collapse in={open} timeout="auto" unmountOnExit sx={styles.collapse}>
        {children}
      </Collapse>
    </Grid>
  );
};

const GrupoFiltro = ({titulo, opcoes, tipo, value, onChange, disabled}) => {
  const [open, setOpen] = useState(true);
  const [showRows, setShowRows] = useState(4);

  const showMore = () => {
    setShowRows(showRows + 4);
  };

  useEffect(() => {
    setOpen(!disabled);
  }, [disabled]);

  return (
    <Grid container sx={styles.groupContainer}>
      <Grid
        container
        onClick={() => setOpen(!open && !disabled)}
        sx={styles.groupHeader}
        disabled={disabled}>
        <Typography sx={styles.groupTitle}>{titulo}</Typography>
        <IconButton
          aria-label="toggle password visibility"
          edge="end"
          sx={styles.groupTitleButton}>
          {open && <KeyboardArrowUp />}
          {!open && <KeyboardArrowDown />}
        </IconButton>
      </Grid>
      <Collapse in={open} timeout="auto" unmountOnExit sx={styles.collapse}>
        {tipo === 'radio' && (
          <RadioGroup value={value} onChange={onChange}>
            {opcoes.slice(0, showRows).map((item) => (
              <FormControlLabel
                key={item.value}
                labelPlacement="start"
                value={item.value}
                control={<Radio checkedIcon={<CheckCircle />} />}
                label={item.label}
                sx={styles.optionControl}
                disabled={disabled}
              />
            ))}
          </RadioGroup>
        )}
        {tipo === 'checkbox' && (
          <FormGroup>
            {opcoes.slice(0, showRows).map((item) => (
              <FormControlLabel
                key={item.value}
                labelPlacement="start"
                value={item.value}
                checked={value[item.value] || false}
                label={item.label}
                control={<Checkbox onChange={onChange} />}
                sx={styles.optionControl}
                disabled={disabled}
              />
            ))}
          </FormGroup>
        )}
        {showRows < opcoes.length && (
          <Typography
            sx={[styles.groupTitle, styles.groupMore]}
            onClick={showMore}>
            Mostrar mais <KeyboardArrowDown />
          </Typography>
        )}
      </Collapse>
    </Grid>
  );
};

const styles = {
  container: {
    backgroundColor: (theme) => theme.palette.blackSecundary,
    borderRadius: '6px',
    padding: '16px 0',
    overflowY: 'auto',
    maxHeight: '100%',
  },
  filtrosContent: {
    overflowY: 'auto',
    overflowX: 'hidden',
    alignContent: 'flex-start',
    maxHeight: 'calc(100vh - 272px)',
    paddingX: '16px',

    '@media (max-width: 900px)': {
      maxHeight: 'calc(100vh - 320px)',
      paddingX: '32px',
    },
  },
  containerMobile: {
    position: 'relative',
    justifyContent: 'flex-end',
  },
  mobileMenuOpen: {
    marginX: 1,
    width: '122px',
    svg: {
      marginLeft: 1,
      fontSize: '16px',
      marginBottom: '4px',
    },
  },
  modal: {
    width: '99%',
    borderTopLeftRadius: '6px',
    zIndex: (theme) => theme.zIndex.modal,
    background: (theme) => theme.palette.blackSecundary,
    boxShadow: '-48px -21px 134.60001px 0px rgba(255, 204, 41, 0.25)',
    position: 'absolute',
    right: '0px',
    maxHeight: (theme) => `calc(100% - 50px - ${theme.toolbarHeight})`,
    top: (theme) => `calc(60px + ${theme.toolbarHeight})`,
  },
  header: {
    color: '#fff',
    paddingBottom: '8px',
    paddingX: '16px',
  },
  headerTitle: {
    fontSize: '18px',
    fontWeight: '700',
    marginLeft: '4px',
    fontFamily: 'Inter',

    span: {
      fontFamily: 'Inter',
      fontSize: '18px',
      fontWeight: '400',
    },
  },
  limparFiltros: {
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: '600',
    cursor: 'pointer',
    alignSelf: 'center',
    color: '#fff',
    textDecorationColor: '#fff',
    padding: '5px 0',
    ':hover': {
      color: (theme) => theme.palette.primary.main,
      textDecorationColor: (theme) => theme.palette.yellowLight,
    },
  },
  groupContainer: {
    paddingY: '12px',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px solid #fff',
  },
  groupHeader: {
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
    paddingY: '4px',
    '&[disabled]': {
      opacity: 0.3,
    },
    ':hover': {
      opacity: 0.8,
    },
  },
  groupTitle: {
    color: '#FFF',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: 'normal',
    letterSpacing: '0.42px',
    '&[disabled]': {
      opacity: 0.3,
    },
  },
  groupTitleButton: {
    color: '#FFF',
    padding: 0,
    margin: 0,

    svg: {
      width: '24px',
      height: '24px',
    },
  },
  groupMore: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'underline',
    cursor: 'pointer',
    svg: {
      width: '16px',
      height: '16px',
    },
  },
  collapse: {
    width: '100%',
  },
  optionControl: {
    margin: 0,
    color: '#FFF !important',
    '.MuiButtonBase-root': {
      padding: 0,
    },
    '&.Mui-disabled': {
      opacity: 0.3,
    },
    '.MuiFormControlLabel-label': {
      color: 'inherit !important',
      marginRight: 'auto',
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: 'normal',
    },
    svg: {
      color: '#fff',
    },
  },
};
