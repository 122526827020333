import { useMutation, useQuery } from '@apollo/client';
import { Add, Block, Check, Delete, Edit, Visibility } from '@mui/icons-material';
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ButtonResponsive from '../../../components/buttons/button-responsive';
import { confirmAlert } from '../../../components/confirm-alert/confirm-alert';
import Filtros from '../../../components/filtro/filtros';
import Input from '../../../components/input/input';
import { SelectCidades } from '../../../components/input/select/cidades';
import { toastNotification } from '../../../components/toastify';
import {
  DELETE_MODALIDADE,
  UPDATE_ATLETA_STATUS,
} from '../../../graphql/mutation';
import { FIND_ALL_ATLETAS, FIND_ALL_MODALIDADES } from '../../../graphql/queries';
import ModalidadeModal from './modais/modalidade';

export default function ConsultaAtletas() {
  const [searchDTO, setSearchDTO] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [tipoSelecionado, setTipoSelecionado] = useState('atleta');

  return (
    <Grid container item xs={12} paddingBottom={4}>
      <Filtros>
        <Grid item md xs={12}>
          <Input
            name="busca"
            placeholder="Pesquisar"
            type="text"
            autoComplete="off"
            value={searchDTO.search || ''}
            onChange={({target}) =>
              setSearchDTO((f) => ({...f, search: target?.value}))
            }
          />
        </Grid>
        {tipoSelecionado === 'atleta' && (
          <Grid item md={6} xs={12}>
            <SelectCidades
              placeholder="Pesquisar por cidade"
              value={searchDTO.cidade}
              onChange={(cidade) => setSearchDTO((f) => ({...f, cidade}))}
              isClearable
            />
          </Grid>
        )}
      </Filtros>
      <Grid
        container
        item
        xs={12}
        paddingBottom={1}
        alignItems={'center'}
        justifyContent={'space-between'}
        sx={styles.submenuContainer}>
        <ToggleButtonGroup
          exclusive
          value={tipoSelecionado}
          onChange={({target}) => setTipoSelecionado(target.value)}
          aria-label="Tipo">
          <ToggleButton value="atleta" sx={styles.tabButton}>
            Atletas
          </ToggleButton>
          <ToggleButton value="modalidade" sx={styles.tabButton}>
            Modalidades
          </ToggleButton>
        </ToggleButtonGroup>
        <Button
          id="text"
          endIcon={<Add />}
          onClick={() => setOpenModal(true)}
          sx={{
            visibility: tipoSelecionado === 'modalidade' ? 'visible' : 'hidden',
          }}>
          Cadastrar novo
        </Button>
      </Grid>
      <Grid container item xs={12}>
        {tipoSelecionado === 'atleta' && (
          <AtletasTabela searchDTO={searchDTO} />
        )}
        {tipoSelecionado === 'modalidade' && (
          <ModalidadesTabela
            searchDTO={searchDTO}
            openCadNew={openModal}
            onCloseCadNew={() => setOpenModal(false)}
          />
        )}
      </Grid>
    </Grid>
  );
}

const AtletasTabela = ({searchDTO}) => {
  const navigate = useNavigate();

  const [atletas, setAtletas] = useState([]);
  const [page, setPage] = useState(0);
  const [totalElements, setTotalElements] = useState(0);

  const atletasQuery = useQuery(FIND_ALL_ATLETAS, {
    variables: {
      pageable: {
        pageNumber: page,
        pageSize: 20,
        sortField: 'nome',
        sortDir: 'ASC',
      },
      searchDTO: {
        search: searchDTO.search,
        cidadeId: searchDTO.cidade?.id,
      },
    },
  });

  const [updateStatus, {loading}] = useMutation(UPDATE_ATLETA_STATUS);

  useEffect(() => {
    if (atletasQuery.loading) return;

    setAtletas(atletasQuery.data?.atletas?.content || []);
    setTotalElements(atletasQuery.data?.atletas?.totalElements || 0);
  }, [atletasQuery]);

  useEffect(() => {
    setPage(0);
  }, [searchDTO]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleAlterarStatus = (atleta) => {
    if (loading) return;

    updateStatus({
      variables: {
        atleta: {
          id: atleta.id,
          ativo: !atleta.ativo,
        },
      },
    }).then(() => atletasQuery.refetch());
  };

  return (
    <Grid container>
      <TableContainer id="table-container">
        <Table id="table">
          <TableHead id="table-head">
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell>E-mail</TableCell>
              <TableCell>Cidade</TableCell>
              <TableCell>Plano</TableCell>
              <TableCell>Avaliado</TableCell>
              <TableCell sx={{width: '182px'}} align="center">
                Ações
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody id="table-body">
            {atletas.map((a) => (
              <TableRow key={a.id} id="table-row">
                <TableCell>{a.nome}</TableCell>
                <TableCell>{a.email}</TableCell>
                <TableCell>
                  {a.enderecos?.find((e) => e.principal)?.cidade?.nomeEUf ||
                    a.enderecos?.[0]?.cidade?.nomeEUf}
                </TableCell>
                <TableCell>
                  {a.usuario === null ||
                  a.usuario?.detalhesPlano === null ||
                  a.usuario?.detalhesPlano?.statusPagamento === 'ABORTED'
                    ? 'Free'
                    : a.usuario?.plano?.titulo ?? 'Free'}
                </TableCell>
                <TableCell>{a.anamneses?.length > 0 ? 'Sim' : 'Não'}</TableCell>
                <TableCell
                  align="right"
                  sx={{display: 'flex', flexDirection: 'row'}}>
                  <ButtonResponsive
                    id="primary"
                    value="Visualizar"
                    onClick={() => navigate(`/atleta/${a.id}`)}
                    icon={<Visibility />}
                    sx={{marginRight: 2}}
                  />
                  {a.ativo && (
                    <ButtonResponsive
                      id="outlined"
                      value="Ativo"
                      onClick={() => handleAlterarStatus(a)}
                      icon={<Check />}
                    />
                  )}
                  {!a.ativo && (
                    <ButtonResponsive
                      id="outlined"
                      value="Inativo"
                      onClick={() => handleAlterarStatus(a)}
                      icon={<Block />}
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow id="table-row-footer">
              <TableCell id="table-pagination" colSpan={4}>
                <TablePagination
                  component="div"
                  count={totalElements}
                  rowsPerPageOptions={[]}
                  rowsPerPage={20}
                  page={page}
                  labelDisplayedRows={({from, to, count}) =>
                    `${from}-${to} de ${count}`
                  }
                  onPageChange={handleChangePage}
                />
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Grid>
  );
};

const ModalidadesTabela = ({searchDTO, openCadNew, onCloseCadNew}) => {
  const [modalidades, setModalidades] = useState([]);
  const [page, setPage] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [selecionada, setSelecionada] = useState(undefined);

  const modalidadesQuery = useQuery(FIND_ALL_MODALIDADES, {
    variables: {
      pageable: {
        pageNumber: page,
        pageSize: 20,
        sortField: 'descricao',
        sortDir: 'ASC',
      },
      searchDTO: {
        search: searchDTO.search,
      },
    },
  });

  const [deleteModalidade] = useMutation(DELETE_MODALIDADE);

  useEffect(() => {
    if (modalidadesQuery.loading) return;

    setModalidades(modalidadesQuery.data?.modalidades?.content || []);
    setTotalElements(modalidadesQuery.data?.modalidades?.totalElements || 0);
  }, [modalidadesQuery]);

  useEffect(() => {
    if (!selecionada) return;

    setOpenModal(true);
  }, [selecionada]);

  useEffect(() => {
    setPage(0);
  }, [searchDTO]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const onDeleteModalidade = (modalidade) => {
    confirmAlert({
      title: 'Deseja excluir a modalidade?',
      onConfirm: () => handleDeleteModalidade(modalidade),
    });
  };

  const handleDeleteModalidade = (modalidade) => {
    deleteModalidade({
      variables: {
        modalidadeId: modalidade?.id,
      },
    })
      .then(() => {
        toastNotification({message: 'Modalidade excluída', type: 'success'});
        modalidadesQuery.refetch();
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      );
  };

  return (
    <Grid container>
      <TableContainer id="table-container">
        <Table id="table">
          <TableHead id="table-head">
            <TableRow>
              <TableCell>Descricao</TableCell>
              <TableCell>Funções</TableCell>
              <TableCell sx={{width: '182px'}} align="center">
                Ações
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody id="table-body">
            {modalidades.map((m) => (
              <TableRow key={m.id} id="table-row">
                <TableCell>{m.descricao}</TableCell>
                <TableCell>{m.funcoes?.length}</TableCell>
                <TableCell
                  align="right"
                  sx={{display: 'flex', flexDirection: 'row'}}>
                  <ButtonResponsive
                    id="primary"
                    value="Editar"
                    onClick={() => setSelecionada(m)}
                    icon={<Edit />}
                    sx={{marginRight: 2}}
                  />
                  <ButtonResponsive
                    id="outlined"
                    value="Excluir"
                    onClick={() => onDeleteModalidade(m)}
                    icon={<Delete />}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow id="table-row-footer">
              <TableCell id="table-pagination" colSpan={3}>
                <TablePagination
                  component="div"
                  count={totalElements}
                  rowsPerPageOptions={[]}
                  rowsPerPage={20}
                  page={page}
                  labelDisplayedRows={({from, to, count}) =>
                    `${from}-${to} de ${count}`
                  }
                  onPageChange={handleChangePage}
                />
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <ModalidadeModal
        open={openModal || openCadNew}
        onClose={() => {
          setOpenModal(false);
          onCloseCadNew(false);
          setSelecionada(undefined);
          modalidadesQuery?.refetch();
        }}
        modalidade={selecionada}
      />
    </Grid>
  );
};

const styles = {
  submenuContainer: {
    '@media (max-width: 900px)': {
      flexDirection: 'column-reverse',
      alignItems: 'flex-start',
    },
  },
  tabButton: {
    marginBottom: '-15px',
    color: (theme) => theme.palette.white,
    border: (theme) => `1px solid ${theme.palette.white}`,
    borderRadius: '3px',
    fontWeight: '700',
    fontFamily: 'Now',
    fontSize: '14px',
    padding: '8px 32px',
    '&.Mui-selected': {
      backgroundColor: (theme) => theme.palette.yellow,
      ':hover': {
        backgroundColor: (theme) => theme.palette.yellow,
        color: (theme) => theme.palette.blackSecundary,
      },
    },

    '@media (max-width: 900px)': {
      marginBottom: '-9px',
    },
  },
};
