import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import {
  Add,
  Bookmark,
  DirectionsRun,
  Edit,
  Facebook,
  Instagram,
  LinkedIn,
  Mail,
  MedicalInformation,
  Person,
  PersonRounded,
  PhotoCamera,
  RecordVoiceOver,
  WorkspacePremium,
} from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Link,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ButtonResponsive from '../../components/buttons/button-responsive';
import { confirmAlert } from '../../components/confirm-alert/confirm-alert';
import ImagemCrop from '../../components/crop-image';
import { X } from '../../components/icons';
import Midia from '../../components/midia';
import NewsCard from '../../components/news-card';
import { toastNotification } from '../../components/toastify';
import { ANAMNESE } from '../../constants/routes';
import { useAuth } from '../../contexts/auth';
import {
  ADICIONAR_ATLETA_FAVORITO,
  CREATE_AGENDAMENTO,
  REMOVER_ATLETA_FAVORITO,
  UPDATE_ATLETA_FOTO_PERFIL,
} from '../../graphql/mutation';
import {
  FIND_ALL_NOTICIAS,
  FIND_ATLETA_BY_ID,
  FIND_USUARIO_BY_ID,
} from '../../graphql/queries';
import Data from '../../utils/data';
import { getImageUrl, uploadNestor } from '../../utils/file';
import Number from '../../utils/number';
import String from '../../utils/string';
import EditarPerfilAtleta from './modais/editar-perfil';
import ExperienciaAtleta from './modais/experiencia';
import MidiaLink from './modais/midia-link';
import Planos from './modais/planos';
import SobreAtleta from './modais/sobre';

export default function AtletaPerfil({atletaId, readOnly = true}) {
  const navigate = useNavigate();
  const {usuarioLogado, refetchEntidade, isLogged, role} = useAuth();

  const [atleta, setAtleta] = useState({});
  const [usuario, setUsuario] = useState(null);
  const [noticias, setNoticias] = useState([]);
  const [openBase, setOpenBase] = useState(false);
  const [openSobre, setOpenSobre] = useState(false);
  const [openPerfil, setOpenPerfil] = useState(false);
  const [openExperiencia, setOpenExperiencia] = useState(false);
  const [openMidia, setOpenMidia] = useState(false);
  const [isMenor, setIsMenor] = useState(true);
  const [openPlanos, setOpenPlanos] = useState(false);

  const noticiasQuery = useQuery(FIND_ALL_NOTICIAS, {
    variables: {
      pageable: {
        pageNumber: 0,
        pageSize: 3,
        sortField: 'dataCadastro',
        sortDir: 'DESC',
      },
      searchDTO: {
        situacao: 'APROVADO',
      },
    },
  });

  const [loadUsuario, usuarioQuery] = useLazyQuery(FIND_USUARIO_BY_ID);

  const [loadAtleta, atletaQuery] = useLazyQuery(FIND_ATLETA_BY_ID);

  const [saveAtletaPerfil] = useMutation(UPDATE_ATLETA_FOTO_PERFIL);
  const [createAgendamento, agendamentoQuery] = useMutation(CREATE_AGENDAMENTO);
  const [addFavorito] = useMutation(ADICIONAR_ATLETA_FAVORITO, {
    variables: {atleta: {id: atleta?.id}},
  });
  const [removerFavorito] = useMutation(REMOVER_ATLETA_FAVORITO, {
    variables: {atleta: {id: atleta?.id}},
  });

  useEffect(() => {
    if (!atleta?.usuario?.id) return;

    loadUsuario({
      variables: {
        usuarioId: atleta.usuario.id,
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [atleta]);

  useEffect(() => {
    if (!atletaId) return;

    loadAtleta({
      variables: {
        id: atletaId,
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [atletaId]);

  useEffect(() => {
    if (usuarioQuery.loading) return;

    setUsuario(usuarioQuery.data?.usuario || null);
  }, [usuarioQuery]);

  useEffect(() => {
    if (atletaQuery.loading) return;

    const atleta = atletaQuery.data?.atleta || {};
    setIsMenor(
      usuarioLogado?.authorities?.[0].nome === 'ROLE_ADMINISTRADOR'
        ? false
        : Data.calcularIdade(atleta.dataNascimento) < 16,
    );
    setAtleta(atleta);
  }, [atletaQuery, usuarioLogado?.authorities]);

  useEffect(() => {
    if (noticiasQuery.loading) return;

    setNoticias(noticiasQuery.data?.noticias.content || []);
  }, [noticiasQuery]);

  useEffect(() => {
    if (openBase || openSobre || openExperiencia || openMidia || !atletaId)
      return;

    atletaQuery.refetch();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openBase, openSobre, openExperiencia, openMidia]);

  const getStringEndereco = () => {
    if (!atleta.enderecos?.length) return '';
    const endereco =
      atleta.enderecos?.filter((e) => e.principal)?.[0] ||
      atleta.enderecos?.[0];

    return `${endereco?.cidade?.nome} - ${endereco?.cidade?.uf}`;
  };

  const handleCreateAgendamento = () => {
    if (agendamentoQuery.loading) return;
    createAgendamento({
      variables: {
        agendamento: {
          atleta: {
            id: atletaId,
          },
        },
      },
    })
      .then(() =>
        toastNotification({message: 'Solicitação enviada!', type: 'success'}),
      )
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      );
  };

  const handleSavePerfil = async (file) => {
    if (!file) return;

    let response = null;
    try {
      response = await uploadNestor(file);
    } catch (error) {
      console.error('Erro upload', error);
    }

    saveAtletaPerfil({
      variables: {
        atleta: {
          id: atleta.id,
          fotoPerfil: response?.data?.id,
        },
      },
    })
      .then(() => {
        setOpenPerfil(false);
        toastNotification({
          message: 'Imagem de perfil atualizada!',
          type: 'success',
        });
        atletaQuery.refetch();
        refetchEntidade();
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      );
  };

  const handleFavoritoClick = () => {
    if (atleta.favorito)
      return removerFavorito().then(() => atletaQuery.refetch());

    addFavorito().then(() => atletaQuery.refetch());
  };

  const handleSolicitarAvaliacao = () => {
    confirmAlert({
      title: 'Deseja solicitar uma avaliação médica?',
      message: 'Entraremos em contato em breve sobre o agendamento.',
      onConfirm: handleCreateAgendamento,
    });
  };

  const showAnamnese = () => {
    return (
      !!atleta?.anamneses?.length &&
      ('ROLE_ADMINISTRADOR' === role || 'ROLE_PROFISSIONAL_SAUDE' === role  || !readOnly)
    );
  };

  return (
    <Grid container sx={styles.container}>
      <Grid container sx={styles.content} padding={2}>
        <Grid container item xs={12} gap={2} sx={styles.dadosContainer}>
          <Grid container item gap={1} xs sx={styles.avatarContainer}>
            <Avatar
              src={getImageUrl(atleta?.fotoPerfil)}
              sx={[
                styles.avatar,
                atleta?.fotoPerfil && styles.imagemPerfil,
                !readOnly && {cursor: 'pointer'},
              ]}
              onClick={() => setOpenPerfil(!readOnly && true)}>
              <PhotoCamera />
              {!readOnly && (
                <Typography sx={styles.fotoPerfilText}>
                  ADICIONAR FOTO DE PERFIL
                </Typography>
              )}
            </Avatar>
            <Grid container item sx={styles.redesContainer} columnGap={1}>
              {atleta.urlFacebook && (
                <Link
                  title="Facebook"
                  href={atleta.urlFacebook}
                  target="_blank"
                  rel="noopener"
                  sx={styles.footerText}>
                  <Facebook />
                </Link>
              )}
              {atleta.urlInstagram && (
                <Link
                  title="Instragram"
                  href={atleta.urlInstagram}
                  target="_blank"
                  rel="noopener"
                  sx={styles.footerText}>
                  <Instagram />
                </Link>
              )}
              {atleta.urlX && (
                <Link
                  title={'X'}
                  href={atleta.urlX}
                  target="_blank"
                  rel="noopener"
                  sx={styles.footerText}>
                  <X style={styles.icon} />
                </Link>
              )}
              {atleta.urlLinkedin && (
                <Link
                  title="LinkedIn"
                  href={atleta.urlLinkedin}
                  target="_blank"
                  rel="noopener"
                  sx={styles.footerText}>
                  <LinkedIn />
                </Link>
              )}
              {!isMenor && (
                <Link
                  title="Email"
                  href={`mailto://${atleta.email}`}
                  sx={styles.footerText}>
                  <Mail />
                </Link>
              )}
            </Grid>
          </Grid>
          <Grid container item flex={1} direction={'column'} xs>
            <Typography
              id="modalidade-tag"
              sx={{
                marginBottom: '16px',
                visibility: atleta.modalidade ? 'visible' : 'hidden',
              }}>
              {`${atleta.modalidade?.descricao} `}
            </Typography>
            <Typography sx={styles.title}>
              {isMenor ? String.abreviarNome(atleta.nome) : atleta.nome}
            </Typography>
            <Typography sx={styles.text}>{`${Data.calcularIdade(
              atleta.dataNascimento,
            )} anos`}</Typography>
            {!!atleta.enderecos?.length && (
              <Typography sx={styles.text}>{getStringEndereco()}</Typography>
            )}
            {!isMenor && (
              <Typography sx={styles.text}>
                {String.formatTelefone(atleta.telefone)}
              </Typography>
            )}
            <Grid item container>
              <Grid item container xs md={6} direction={'column'}>
                <Typography mt={1} sx={styles.title}>
                  Dados básicos
                </Typography>
                <Typography sx={styles.text}>
                  {!!atleta.funcoes?.length && <DirectionsRun />}{' '}
                  {atleta.funcoes?.map((f) => f.descricao).join(' | ')}
                </Typography>
                {atleta.idiomas?.length && (
                  <Typography sx={styles.text}>
                    <RecordVoiceOver /> {atleta.idiomas?.join(' | ')}
                  </Typography>
                )}
                {atleta?.estatura && atleta?.peso && (
                  <Typography sx={styles.text}>
                    <Person />{' '}
                    {`${Number.format(atleta?.estatura, {
                      minimumFractionDigits: 2,
                    })} m | ${Number.format(atleta?.peso)} Kg`}
                  </Typography>
                )}
                {atleta.situacao === 'CADASTRO' && !readOnly && (
                  <Button
                    id="outlined"
                    p={2}
                    endIcon={<Edit />}
                    onClick={() => setOpenBase(true)}
                    sx={styles.concluirButton}>
                    Conclua seu cadastro
                  </Button>
                )}
              </Grid>
              {(!readOnly ||
                usuarioLogado?.authorities?.[0].nome ===
                  'ROLE_ADMINISTRADOR') &&
                usuario !== null && (
                  <Grid item container xs md={6} direction={'column'}>
                    <Typography mt={1} sx={styles.title}>
                      Dados do Plano
                    </Typography>

                    <Typography sx={styles.text}>
                      Plano:{' '}
                      {usuario.detalhesPlano === null ||
                      usuario.detalhesPlano?.statusPagamento === 'ABORTED'
                        ? 'Free'
                        : usuario.plano?.titulo}
                    </Typography>
                    {usuario.detalhesPlano === null ||
                    usuario.detalhesPlano?.statusPagamento ===
                      'ABORTED' ? null : (
                      <Typography sx={styles.text}>
                        Status:{' '}
                        {usuario.detalhesPlano?.statusPagamento ===
                        'PAGAMENTO_CONFIRMADO'
                          ? 'Pagamento confirmado'
                          : usuario.detalhesPlano?.statusPagamento === 'PENDING'
                            ? 'Pendente'
                            : usuario.detalhesPlano?.statusPagamento}
                      </Typography>
                    )}
                    {usuario.detalhesPlano === null ||
                    usuario.detalhesPlano?.statusPagamento === null ||
                    usuario.detalhesPlano?.statusPagamento ===
                      'ABORTED' ? null : (
                      <Typography sx={styles.text}>
                        Vencimento:{' '}
                        {Data.dataFormat(
                          usuario.detalhesPlano?.dataHoraVencimento,
                          'DD/MM/YYYY',
                        )}
                      </Typography>
                    )}
                  </Grid>
                )}
            </Grid>
          </Grid>
          <Grid
            item
            xs={1}
            md={6}
            gap={1}
            container
            sx={[styles.editarDadosContainer, readOnly && styles.hidden]}>
            <ButtonResponsive
              id="outlined"
              icon={<WorkspacePremium />}
              loading={agendamentoQuery.loading}
              onClick={() => setOpenPlanos(true)}
              value={
                usuario === null ||
                usuario.detalhesPlano === null ||
                usuario.detalhesPlano?.statusPagamento === 'ABORTED'
                ? 'Seja um Usuário premium'
                : 'Confira seus benefícios'
              }
              sx={{
                '@media (min-width: 901px)': {
                  width: '362px',
                },
              }}
            />
            <ButtonResponsive
              id="outlined"
              icon={<MedicalInformation />}
              onClick={handleSolicitarAvaliacao}
              loading={agendamentoQuery.loading}
              value={'Solicitar avaliação médica'}
            />
            <IconButton
              id="icon-outlined"
              sx={styles.editarButton}
              onClick={() => setOpenBase(true)}>
              <Edit />
            </IconButton>
          </Grid>
          {readOnly && isLogged && (
            <IconButton
              id={atleta?.favorito ? 'icon-primary' : 'icon-outlined'}
              title="Favorito"
              onClick={handleFavoritoClick}
              sx={styles.favoritoIcon}>
              <Bookmark />
            </IconButton>
          )}
        </Grid>
        <Grid container paddingY={'32px'}>
          <Divider sx={{borderColor: '#fff', width: '100%'}} />
        </Grid>
        <Grid container item xs={12}>
          <Grid container justifyContent={'space-between'} pb={1}>
            <Typography sx={styles.title}>SOBRE MIM</Typography>
            <IconButton
              id="icon-outlined"
              onClick={() => setOpenSobre(true)}
              sx={[styles.editarButton, readOnly && styles.hidden]}>
              <Edit />
            </IconButton>
          </Grid>
          <Typography component="pre" sx={styles.text}>
            {atleta.descricao}
          </Typography>
        </Grid>
        <Grid container paddingY={'32px'}>
          <Divider sx={{borderColor: '#fff', width: '100%'}} />
        </Grid>
        <Grid container item xs={12}>
          <Grid container justifyContent={'space-between'} pb={2}>
            <Typography sx={styles.title}>MÍDIA</Typography>
            <IconButton
              id="icon-outlined"
              onClick={() => setOpenMidia(true)}
              sx={[styles.editarButton, readOnly && styles.hidden]}>
              <Add />
            </IconButton>
          </Grid>
          <Grid container spacing={1}>
            {atleta.midiaLinks?.map((midia, index) => (
              <Grid key={midia.id} item md={3} xs={6}>
                <Midia
                  midia={midia}
                  midias={atleta.midiaLinks}
                  index={index}
                  refetch={atletaQuery.refetch}
                  readOnly={readOnly}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid container paddingY={'32px'}>
          <Divider sx={{borderColor: '#fff', width: '100%'}} />
        </Grid>
        <Grid container item xs={12}>
          <Grid container justifyContent={'space-between'}>
            <Typography sx={styles.title}>EXPERIÊNCIA</Typography>
            <IconButton
              id="icon-outlined"
              onClick={() => setOpenExperiencia(true)}
              sx={[styles.editarButton, readOnly && styles.hidden]}>
              <Edit />
            </IconButton>
          </Grid>
          <Grid container gap={2}>
            {atleta?.experiencias?.map((e) => (
              <Grid
                key={e.id}
                item
                container
                sx={{flexWrap: 'nowrap', alignItems: 'flex-start'}}
                columnGap={2}>
                <Box>
                  <Typography sx={styles.empresarioNome}>{e.equipe}</Typography>
                  <Typography sx={styles.text}>{e.funcao?.label}</Typography>
                  <Typography sx={styles.text}>
                    {`${Data.dataFormat(
                      e.dataInicio,
                      'MM/YYYY',
                    )} - ${Data.dataFormat(e.dataFim, 'MM/YYYY')}`}
                  </Typography>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Grid>
        {showAnamnese() && (
          <>
            <Grid container paddingY={'32px'}>
              <Divider sx={{borderColor: '#fff', width: '100%'}} />
            </Grid>
            <Grid container item xs={12}>
              <Grid container justifyContent={'space-between'}>
                <Typography sx={styles.title}>AVALIAÇÕES</Typography>
              </Grid>
              <Grid container gap={2} pt={2}>
                {atleta?.anamneses?.map((a) => (
                  <Grid key={a.id}>
                    <Button
                      id="outlined"
                      onClick={() => navigate(`${ANAMNESE}/${a.id}`)}>
                      <Box sx={styles.anamneseContainer}>
                        <Typography sx={styles.empresarioNome}>
                          {a.modelo.nome}
                        </Typography>
                        <Typography sx={styles.text}>
                          {Data.dataFormat(
                            a.dataHoraTermino || a.dataHoraLancamento,
                            'DD/MM/YYYY',
                          )}
                        </Typography>
                      </Box>
                    </Button>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </>
        )}
        <Grid container paddingY={'32px'} sx={{display: 'none'}}>
          <Divider sx={{borderColor: '#fff', width: '100%'}} />
        </Grid>
        <Grid container item xs={12} sx={{display: 'none'}}>
          <Grid container justifyContent={'space-between'}>
            <Typography sx={styles.title}>EMPRESÁRIO(A)</Typography>
            <IconButton
              id="icon-outlined"
              sx={[styles.editarButton, readOnly && styles.hidden]}>
              <Add />
            </IconButton>
          </Grid>
          {atleta?.empresario && (
            <Grid container gap={2}>
              <Grid
                container
                sx={{flexWrap: 'nowrap', alignItems: 'flex-start'}}
                columnGap={2}>
                <Avatar
                  src={getImageUrl(atleta?.empresario?.fotoPerfil)}
                  alt={atleta?.empresario?.nome}
                  sx={styles.avatarEmpresario}>
                  <PersonRounded />
                </Avatar>
                <Box>
                  <Typography sx={styles.empresarioNome}>
                    {atleta?.empresario?.nome}
                  </Typography>
                  <Typography sx={styles.dataContrato}>
                    {`Contrato assinado em ${Data.dataFormat(
                      atleta.empresarios?.dataContrato,
                      'MM/YYYY',
                    )}`}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid container paddingY={'32px'}>
          <Divider sx={{borderColor: '#fff', width: '100%'}} />
        </Grid>
        <Grid
          display={noticias.length > 0 ? 'flex' : 'none'}
          container
          item
          xs={12}>
          <Grid container justifyContent={'space-between'}>
            <Typography sx={styles.title}>NOTÍCIAS</Typography>
          </Grid>
          <Grid container gap={2} pt={2} justifyContent={'center'} pb={2}>
            {noticias.map((n) => (
              <NewsCard key={n.id} item={n} />
            ))}
          </Grid>
          <Grid container justifyContent={'center'}>
            <Button id="primary" onClick={() => navigate('/noticias')}>
              Ver mais
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <ImagemCrop
        open={openPerfil}
        imagemId={atleta?.fotoPerfil}
        onClose={() => setOpenPerfil(false)}
        onUploadImage={handleSavePerfil}
        aspect={1}
      />
      <EditarPerfilAtleta
        open={openBase}
        onClose={() => setOpenBase(false)}
        atleta={atleta}
      />
      <SobreAtleta
        open={openSobre}
        onClose={() => setOpenSobre(false)}
        atleta={atleta}
      />
      <ExperienciaAtleta
        open={openExperiencia}
        onClose={() => setOpenExperiencia(false)}
        atleta={atleta}
      />
      <MidiaLink
        open={openMidia}
        onClose={() => setOpenMidia(false)}
        atleta={atleta}
      />
      <Planos
        open={openPlanos}
        onClose={() => setOpenPlanos(false)}
        usuario={
          usuario == null ||
          usuario.detalhesPlano === null ||
          usuario.detalhesPlano?.statusPagamento === 'ABORTED'
            ? null
            : usuario.plano
        }
      />
    </Grid>
  );
}

const styles = {
  container: {
    backgroundColor: (theme) => theme.palette.backgroundColor,
    paddingTop: (theme) => `calc(${theme.toolbarHeight} + 16px)`,
    justifyContent: 'center',
  },
  content: {
    maxWidth: 1200,
    alignContent: 'flex-start',
    paddingX: `16px`,

    '@media (max-width: 900px)': {
      flexWrap: 'nowrap',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  dadosContainer: {
    position: 'relative',
    alignSelf: 'flex-start',

    '@media (max-width: 900px)': {
      flexDirection: 'column',
    },
  },
  avatarContainer: {
    flexDirection: 'column',
    flexGrow: '0 !important',
  },
  editarDadosContainer: {
    position: 'absolute',
    justifyContent: 'end',
    right: 0,
  },
  avatar: {
    height: '220px',
    width: '200px',
    borderRadius: '6px',
    flexDirection: 'column',
    backgroundColor: 'transparent',
    border: (theme) => `1px solid ${theme.palette.primary.main}`,

    '@media (max-width: 900px)': {
      height: '80px',
      width: '80px',
    },

    svg: {
      color: (theme) => theme.palette.primary.main,
      width: '40px',
      height: '40px',
      maxWidth: '50%',
      maxHeight: '50%',
    },
  },
  imagemPerfil: {
    border: '2px solid #fff',
  },
  fotoPerfilText: {
    color: (theme) => theme.palette.primary.main,
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: '700',
    width: '70%',
    marginTop: 1,

    '@media (max-width: 900px)': {
      display: 'none',
    },
  },
  title: {
    color: '#FFF',
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: 600,
  },
  text: {
    color: '#FFF',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'break-spaces',

    svg: {
      height: '18px',
      width: '18px',
      marginRight: 1,
    },
  },
  concluirButton: {
    width: 'fit-content',
    alignSelf: 'center',
    margin: 2,
  },
  redesContainer: {
    justifyContent: 'center',
    width: '100%',

    '@media (max-width: 900px)': {
      justifyContent: 'flex-start',
    },
  },
  footerText: {
    color: '#fff',
  },
  icon: {
    width: '1.2rem',
    height: '1.5rem',
  },
  editarButton: {
    height: '42px',
    width: '42px',
    padding: 1,
  },
  avatarEmpresario: {
    width: '80px',
    height: '80px',
    borderRadius: '6px',
    border: '3px solid #fff',

    svg: {
      width: '50%',
      height: '50%',
    },
  },
  empresarioNome: {
    color: '#FFF',
    fontFamily: 'Inter',
    fontSize: '20px',
    fontWeight: 600,
  },
  dataContrato: {
    color: '#FFF',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 400,
  },
  hidden: {
    visibility: 'hidden',
  },
  favoritoIcon: {
    position: 'absolute',
    top: '16px',
    right: '16px',
    zIndex: 1,
  },
  anamneseContainer: {
    flexDirection: 'column',
    padding: 2,
    alignItems: 'center',
  },
};
