import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  from,
  makeVar,
} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';
import {onError} from '@apollo/client/link/error';

import {ACCESS_TOKEN_KEY} from '../constants/storage-keys';
import {refreshAccessToken} from './auth';
import config from './config';

export const isAuthenticated = makeVar(undefined);

const errorLink = onError(({graphQLErrors, networkError}) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({message}) =>
      console.error(`[GraphQL error]: Message: ${message}`),
    );

  if (networkError)
    console.error(`[Network error]: ${networkError.toString()}`);

  if (
    networkError &&
    'statusCode' in networkError &&
    networkError.statusCode === 500
  ) {
    alert('Ocorreu um problema interno no sevidor!');
  }

  if (
    networkError &&
    'statusCode' in networkError &&
    networkError.statusCode === 401
  ) {
    void window.localStorage.removeItem(ACCESS_TOKEN_KEY);
    handleRefreshToken();
  }
});

const handleRefreshToken = async () => {
  const refreshToken = await window.localStorage.getItem(ACCESS_TOKEN_KEY);
  if (!refreshToken) return isAuthenticated(false);
  refreshAccessToken();
};

const authHttpLink = new HttpLink({
  uri: `${String(config.BASE_URL)}/oauth/token`,
});

const httpLink = new HttpLink({
  uri: `${String(config.BASE_URL)}/api/v1/graphql`,
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

const authMiddleware = setContext(async (_, {headers}) => {
  const token = await window.localStorage.getItem(ACCESS_TOKEN_KEY);
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
    },
  };
});

export const client = new ApolloClient({
  cache: new InMemoryCache({addTypename: false}),
  link: ApolloLink.split(
    (operation) => operation.getContext().clientName === 'auth',
    from([errorLink, authMiddleware, authHttpLink]),
    from([errorLink, authMiddleware, httpLink]),
  ),
  defaultOptions: defaultOptions,
});
