import {useMutation, useQuery} from '@apollo/client';
import {Add, Delete, Edit} from '@mui/icons-material';
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import {useEffect, useState} from 'react';

import ButtonResponsive from '../../../components/buttons/button-responsive';
import {confirmAlert} from '../../../components/confirm-alert/confirm-alert';
import Filtros from '../../../components/filtro/filtros';
import Input from '../../../components/input/input';
import {SelectCidades} from '../../../components/input/select/cidades';
import {SelectEspecialidades} from '../../../components/input/select/espcialidades';
import {toastNotification} from '../../../components/toastify';
import {DELETE_ESPECIALIDADE_MEDICA} from '../../../graphql/mutation';
import {
  FIND_ALL_ESPECIALIDADES,
  FIND_ALL_PROFISSIONAIS_SAUDE,
} from '../../../graphql/queries';
import EditarPerfilProfissional from '../../profissional-saude/modal/editar-perfil';
import EspecialidadeModal from '../../profissional-saude/modal/especialidade';

export default function ConsultaProfissionais() {
  const [searchDTO, setSearchDTO] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [tipoSelecionado, setTipoSelecionado] = useState('profissional');

  return (
    <Grid container item xs={12} paddingY={4}>
      <Filtros>
        <Grid item md xs={12}>
          <Input
            name="busca"
            placeholder="Pesquisar"
            type="text"
            autoComplete="off"
            value={searchDTO.search || ''}
            onChange={({target}) =>
              setSearchDTO((f) => ({...f, search: target?.value}))
            }
          />
        </Grid>
        {tipoSelecionado === 'profissional' && (
          <>
            <Grid item md={4} xs={12}>
              <SelectEspecialidades
                placeholder="Pesquisar por especialidade"
                value={searchDTO.especialidade}
                onChange={(especialidade) =>
                  setSearchDTO((f) => ({...f, especialidade}))
                }
                isClearable
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <SelectCidades
                placeholder="Pesquisar por cidade"
                value={searchDTO.cidade}
                onChange={(cidade) => setSearchDTO((f) => ({...f, cidade}))}
                isClearable
              />
            </Grid>
          </>
        )}
      </Filtros>
      <Grid
        container
        item
        xs={12}
        paddingBottom={1}
        alignItems={'center'}
        justifyContent={'space-between'}
        sx={styles.submenuContainer}>
        <ToggleButtonGroup
          exclusive
          value={tipoSelecionado}
          onChange={({target}) => setTipoSelecionado(target.value)}
          aria-label="Tipo">
          <ToggleButton value="profissional" sx={styles.tabButton}>
            Profissionais
          </ToggleButton>
          <ToggleButton value="especialidade" sx={styles.tabButton}>
            Especialidades
          </ToggleButton>
        </ToggleButtonGroup>
        <Button id="text" endIcon={<Add />} onClick={() => setOpenModal(true)}>
          Cadastrar novo
        </Button>
      </Grid>
      <Grid container item xs={12}>
        {tipoSelecionado === 'profissional' && (
          <ProfissionaisTabela
            searchDTO={searchDTO}
            openCadNew={openModal}
            onCloseCadNew={() => setOpenModal(false)}
          />
        )}
        {tipoSelecionado === 'especialidade' && (
          <EspecialidadesTabela
            searchDTO={searchDTO}
            openCadNew={openModal}
            onCloseCadNew={() => setOpenModal(false)}
          />
        )}
      </Grid>
    </Grid>
  );
}

const ProfissionaisTabela = ({searchDTO, openCadNew, onCloseCadNew}) => {
  const [profissionais, setProfissionais] = useState([]);
  const [page, setPage] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [idSelecionado, setIdSelecionado] = useState(undefined);

  const profissionaisQuery = useQuery(FIND_ALL_PROFISSIONAIS_SAUDE, {
    variables: {
      pageable: {
        pageNumber: page,
        pageSize: 20,
        sortField: 'nome',
        sortDir: 'ASC',
      },
      searchDTO: {
        search: searchDTO.search,
        especialidadeId: searchDTO.especialidade?.id,
        cidadeId: searchDTO.cidade?.id,
      },
    },
  });

  useEffect(() => {
    if (profissionaisQuery.loading) return;

    setProfissionais(profissionaisQuery.data?.profissionais?.content || []);
    setTotalElements(
      profissionaisQuery.data?.profissionais?.totalElements || 0,
    );
  }, [profissionaisQuery]);

  useEffect(() => {
    if (!idSelecionado) return;

    setOpenModal(true);
  }, [idSelecionado]);

  useEffect(() => {
    setPage(0);
  }, [searchDTO]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Grid container>
      <TableContainer id="table-container">
        <Table id="table">
          <TableHead id="table-head">
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell>E-mail</TableCell>
              <TableCell>Especialidades</TableCell>
              <TableCell>Cidades</TableCell>
              <TableCell sx={{width: '182px'}} align="center">
                Ações
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody id="table-body">
            {profissionais.map((p) => (
              <TableRow key={p.id} id="table-row">
                <TableCell>{p.nome}</TableCell>
                <TableCell>{p.email}</TableCell>
                <TableCell>
                  {p.especialidades?.map((e) => e.descricao).join(' | ')}
                </TableCell>
                <TableCell>
                  {p.enderecos?.map((e) => e.cidade?.nomeEUf).join(' | ')}
                </TableCell>
                <TableCell align="right">
                  <ButtonResponsive
                    id="primary"
                    value="Editar"
                    onClick={() => setIdSelecionado(p.id)}
                    icon={<Edit />}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow id="table-row-footer">
              <TableCell id="table-pagination" colSpan={5}>
                <TablePagination
                  component="div"
                  count={totalElements}
                  rowsPerPageOptions={[]}
                  rowsPerPage={20}
                  page={page}
                  labelDisplayedRows={({from, to, count}) =>
                    `${from}-${to} de ${count}`
                  }
                  onPageChange={handleChangePage}
                />
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <EditarPerfilProfissional
        open={openModal || openCadNew}
        onClose={() => {
          setOpenModal(false);
          onCloseCadNew(false);
          setIdSelecionado(undefined);
          profissionaisQuery?.refetch();
        }}
        profissionalId={idSelecionado}
        isAdmin
      />
    </Grid>
  );
};

const EspecialidadesTabela = ({searchDTO, openCadNew, onCloseCadNew}) => {
  const [especialidades, setEspecialidades] = useState([]);
  const [page, setPage] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [selecionada, setSelecionada] = useState(undefined);

  const especialidadesQuery = useQuery(FIND_ALL_ESPECIALIDADES, {
    variables: {
      pageable: {
        pageNumber: page,
        pageSize: 20,
        sortField: 'descricao',
        sortDir: 'ASC',
      },
      searchDTO: {
        search: searchDTO.search,
      },
    },
  });

  const [deleteEspecialidade] = useMutation(DELETE_ESPECIALIDADE_MEDICA);

  useEffect(() => {
    if (especialidadesQuery.loading) return;

    setEspecialidades(especialidadesQuery.data?.especialidades?.content || []);
    setTotalElements(
      especialidadesQuery.data?.especialidades?.totalElements || 0,
    );
  }, [especialidadesQuery]);

  useEffect(() => {
    if (!selecionada) return;

    setOpenModal(true);
  }, [selecionada]);

  useEffect(() => {
    setPage(0);
  }, [searchDTO]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const onDeleteEspecialidade = (especialidade) => {
    confirmAlert({
      title: 'Deseja excluir a especialidade?',
      onConfirm: () => handleDeleteEspecialidade(especialidade),
    });
  };

  const handleDeleteEspecialidade = (especialidade) => {
    deleteEspecialidade({
      variables: {
        especialidadeId: especialidade?.id,
      },
    })
      .then(() => {
        toastNotification({message: 'Especialdiade excluída', type: 'success'});
        especialidadesQuery.refetch();
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      );
  };

  return (
    <Grid container>
      <TableContainer id="table-container">
        <Table id="table">
          <TableHead id="table-head">
            <TableRow>
              <TableCell>Descricao</TableCell>
              <TableCell sx={{width: '182px'}} align="center">
                Ações
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody id="table-body">
            {especialidades.map((e) => (
              <TableRow key={e.id} id="table-row">
                <TableCell>{e.descricao}</TableCell>
                <TableCell
                  align="right"
                  sx={{display: 'flex', flexDirection: 'row'}}>
                  <ButtonResponsive
                    id="primary"
                    value="Editar"
                    onClick={() => setSelecionada(e)}
                    icon={<Edit />}
                    sx={{marginRight: 2}}
                  />
                  <ButtonResponsive
                    id="outlined"
                    value="Excluir"
                    onClick={() => onDeleteEspecialidade(e)}
                    icon={<Delete />}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow id="table-row-footer">
              <TableCell id="table-pagination" colSpan={2}>
                <TablePagination
                  component="div"
                  count={totalElements}
                  rowsPerPageOptions={[]}
                  rowsPerPage={20}
                  page={page}
                  labelDisplayedRows={({from, to, count}) =>
                    `${from}-${to} de ${count}`
                  }
                  onPageChange={handleChangePage}
                />
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <EspecialidadeModal
        open={openModal || openCadNew}
        onClose={() => {
          setOpenModal(false);
          onCloseCadNew(false);
          setSelecionada(undefined);
          especialidadesQuery?.refetch();
        }}
        especialidade={selecionada}
      />
    </Grid>
  );
};

const styles = {
  submenuContainer: {
    '@media (max-width: 900px)': {
      flexDirection: 'column-reverse',
      alignItems: 'flex-start',
    },
  },
  tabButton: {
    marginBottom: '-15px',
    color: (theme) => theme.palette.white,
    border: (theme) => `1px solid ${theme.palette.white}`,
    borderRadius: '3px',
    fontWeight: '700',
    fontFamily: 'Now',
    fontSize: '14px',
    padding: '8px 32px',
    '&.Mui-selected': {
      backgroundColor: (theme) => theme.palette.yellow,
      ':hover': {
        backgroundColor: (theme) => theme.palette.yellow,
        color: (theme) => theme.palette.blackSecundary,
      },
    },

    '@media (max-width: 900px)': {
      marginBottom: '-9px',
    },
  },
};
