import {useMutation} from '@apollo/client';
import {
  CheckCircle,
  PhotoCamera,
  Publish,
  VisibilityOff,
} from '@mui/icons-material';
import {
  Avatar,
  Button,
  CircularProgress,
  Grid,
  Typography,
} from '@mui/material';
import {useState} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import ImagemCrop from '../../components/crop-image';
import Footer from '../../components/footer';
import CheckboxInput from '../../components/input/checkbox';
import Input from '../../components/input/input';
import TextEditor from '../../components/text-editor';
import {toastNotification} from '../../components/toastify';
import Toolbar from '../../components/toolbar/toolbar';
import {useAuth} from '../../contexts/auth';
import {PUBLICAR_NOTICIA, SALVAR_NOTICIA} from '../../graphql/mutation';
import {getImageUrl, uploadNestor} from '../../utils/file';

export default function NoticiaFormulario({noticia = {}, isArtigo, refetch}) {
  const navigate = useNavigate();
  const {entidadeLogada} = useAuth();
  const {isMobile} = useSelector((state) => state.App);

  const [salvo, setSalvo] = useState(!!noticia.id);
  const [formData, setFormData] = useState({
    ...noticia,
    texto: noticia.texto && JSON.parse(noticia.texto),
  });
  const [errors, setErrors] = useState({});
  const [openFotoCapa, setOpenFotoCapa] = useState(false);
  const [fotoCapaId, setFotoCapaId] = useState(null);
  const [fotoCapaPreview, setFotoCapaPreview] = useState(null);

  const [saveNoticia, {loading}] = useMutation(SALVAR_NOTICIA);
  const [publicarNoticia, {loading: loadingPublicar}] =
    useMutation(PUBLICAR_NOTICIA);

  const handleSave = () => {
    if (isArtigo && !formData.possuiFontes) {
      return setErrors({possuiFontes: 'Campo obrigatório'});
    }

    saveNoticia({
      variables: {
        noticia: {
          id: noticia.id,
          titulo: formData.titulo,
          fotoCapa: fotoCapaId || formData.fotoCapa,
          texto: JSON.stringify(formData.texto),
          tempoLeitura: formData.tempoLeitura,
          publicada: formData.publicada,
          situacao: formData.situacao,
          profissional: (isArtigo && {id: entidadeLogada?.id}) || undefined,
          jornalista: (!isArtigo && {id: entidadeLogada?.id}) || undefined,
          possuiFontes: formData.possuiFontes,
        },
      },
    })
      .then(({data}) => {
        toastNotification({message: 'Texto salvo!', type: 'success'});
        setSalvo(true);
        if (!noticia?.id)
          return navigate(`/noticias/editar/${data?.noticia?.id}`);
        refetch && refetch();
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      );
  };
  
  const handleSaveFotoCapa = async (file) => {
    if (!file) return;

    try {
      const response = await uploadNestor(file);

      handlePreview(file);
      setFotoCapaId(response?.data?.id);
      setSalvo(false);

      setOpenFotoCapa(false);
    } catch (error) {
      toastNotification({message: "Erro ao salvar a imagem!", type: 'error'});
      console.error('Erro upload', error);
    }
  };

  function handlePreview(file) {
    const reader = new FileReader();

    reader.addEventListener(
      'load',
      function () {
        setFotoCapaPreview(reader.result);
      },
      false,
    );

    if (file) {
      reader.readAsDataURL(file);
    }
  }

  const handlePublicar = (publicar) => {
    if (!salvo || !noticia.id) {
      return toastNotification({
        message: 'Salve a notícia antes de publicar!',
        type: 'warning',
      });
    }
    publicarNoticia({
      variables: {
        noticia: {
          id: noticia.id,
        },
        publicar,
      },
    })
      .then(() => {
        toastNotification({
          message: `Notícia ${publicar ? 'publicada' : 'removida'}!`,
          type: 'success',
        });
        refetch && refetch();
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      );
  };

  return (
    <Grid container>
      <Toolbar />
      <Grid id="container" container>
        <Grid id="content" container item gap={3}>
          <Grid container item xs={12} justifyContent="center">
            <Typography sx={styles.title}>CRIAR NOVA MATÉRIA</Typography>
          </Grid>
          <Grid container item xs={12} flexDirection={'column'}>
            <Typography sx={styles.observacoes}>
              Todas as noticias são avaliadas pela nossa curadoria, sendo
              extremamente cuidadosos e criteriosos. Uma postagem será aprovada
              é necessário seguir os seguintes passos:
            </Typography>
            <Typography sx={styles.observacoes} className="topico">
              Ter um conteúdo relevante a nossa comunidade
            </Typography>
            <Typography sx={styles.observacoes} className="topico">
              Título de no máximo 20 caracteres
            </Typography>
            <Typography sx={styles.observacoes} className="topico">
              Descrição com no máximo 200 caracteres
            </Typography>
            <Typography sx={styles.observacoes} className="topico">
              Imagem de boa qualidade
            </Typography>
          </Grid>
          <Grid container item gap={1} xs sx={styles.avatarContainer}>
            <Avatar src={fotoCapaPreview || getImageUrl(noticia?.fotoCapa)} sx={styles.avatar} onClick={() => setOpenFotoCapa(true)}>
              <PhotoCamera />
              <Typography sx={styles.fotoCapaText}>
                ADICIONAR FOTO DE CAPA
              </Typography>
            </Avatar>
          </Grid>
          <Grid
            component="form"
            container
            item
            flex={1}
            spacing={1}
            direction={'row'}
            xs>
            <Grid item md={9} xs={12}>
              <Input
                label={'Título da matéria'}
                value={formData.titulo || ''}
                name="titulo"
                type="text"
                placeholder={'Digite o título'}
                onChange={({target}) => {
                  setFormData((f) => ({...f, titulo: target?.value}));
                  setSalvo(false);
                }}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <Input
                label={'Tempo de leitura (min)'}
                value={formData.tempoLeitura || ''}
                name="tempoLeitura"
                type="text"
                placeholder={'Tempo estimado'}
                onChange={({target}) => {
                  setFormData((f) => ({...f, tempoLeitura: target?.value}));
                  setSalvo(false);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextEditor
                texto={formData.texto || ''}
                onChange={(texto) => {
                  setFormData((f) => ({...f, texto}));
                  setSalvo(false);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <CheckboxInput
                name="possuiFontes"
                label={'DECLARO TER ADICIONADO AO TEXTO FONTES E BIBLIOGRAFIA.'}
                checked={formData.possuiFontes}
                onChange={({target}) =>
                  setFormData((f) => ({
                    ...f,
                    possuiFontes: target?.checked,
                  }))
                }
                sx={{visibility: isArtigo ? 'visible' : 'hidden'}}
                errors={errors}
              />
            </Grid>
          </Grid>
          <Grid container marginBottom={3} spacing={1}>
            <Grid item md={6} xs={12}>
              {noticia.id && !noticia.publicada && (
                <Button
                  id="outlined"
                  onClick={() => handlePublicar(true)}
                  endIcon={
                    loadingPublicar ? (
                      <CircularProgress size={20} />
                    ) : (
                      <Publish />
                    )
                  }
                  fullWidth={isMobile}
                  disabled={loading}>
                  PUBLICAR
                </Button>
              )}
              {noticia.id && noticia.publicada && (
                <Button
                  id="outlined"
                  onClick={() => handlePublicar(false)}
                  endIcon={
                    loadingPublicar ? (
                      <CircularProgress size={20} />
                    ) : (
                      <VisibilityOff />
                    )
                  }
                  fullWidth={isMobile}
                  disabled={loading}>
                  REMOVER PUBLICAÇÃO
                </Button>
              )}
            </Grid>
            <Grid container item md={6} xs={12}>
              {!salvo && (
                <Button
                  id="primary"
                  sx={{marginLeft: 'auto'}}
                  onClick={handleSave}
                  fullWidth={isMobile}
                  endIcon={loading && <CircularProgress size={20} />}>
                  SALVAR NOTÍCIA
                </Button>
              )}
              {salvo && (
                <Button
                  id="primary"
                  sx={{marginLeft: 'auto'}}
                  onClick={handleSave}
                  fullWidth={isMobile}
                  endIcon={<CheckCircle size={20} />}
                  disabled>
                  SALVO
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Footer />
      <ImagemCrop
        open={openFotoCapa}
        imagemId={noticia?.fotoCapa}
        onClose={() => setOpenFotoCapa(false)}
        onUploadImage={handleSaveFotoCapa}
        aspect={2.5}
      />
    </Grid>
  );
}

const styles = {
  title: {
    color: '#FFF',
    textAlign: 'center',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 500,
    textTransform: 'uppercase',
  },
  observacoes: {
    color: '#FFF',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 400,
    '&.topico': {
      '::before': {
        content: '"•"',
        display: 'inline-block',
        width: '10px',
        marginX: '6px',
      },
    },
  },
  avatarContainer: {
    flexDirection: 'column',
    flexGrow: '0 !important',
  },
  avatar: {
    height: '220px',
    width: '200px',
    borderRadius: '6px',
    flexDirection: 'column',
    backgroundColor: 'transparent',
    border: '2px solid #fff',
    cursor: 'pointer',

    '@media (max-width: 900px)': {
      height: '80px',
      width: '80px',
    },

    svg: {
      color: '#fff',
      width: '40px',
      height: '40px',
      maxWidth: '50%',
      maxHeight: '50%',
    },
  },
  fotoCapaText: {
    color: '#fff',
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: '700',
    width: '70%',
    marginTop: 1,

    '@media (max-width: 900px)': {
      display: 'none',
    },
  },
};
