import {Button, CircularProgress, IconButton} from '@mui/material';
import {useSelector} from 'react-redux';

export default function ButtonResponsive({id, value, icon, loading, ...props}) {
  const {isMobile} = useSelector((state) => state.App);

  const getId = () => {
    if (!isMobile) return id;

    switch (id) {
      case 'outlined':
        return 'icon-outlined';
      case 'text':
        return 'icon-text';
      default:
        return 'icon-primary';
    }
  };

  if (isMobile) {
    return (
      <IconButton id={getId()} title={value} {...props}>
        {loading ? <CircularProgress size="small" /> : icon}
      </IconButton>
    );
  }

  return (
    <Button
      id={id}
      title={value}
      endIcon={loading ? <CircularProgress size="small" /> : icon}
      {...props}>
      {value}
    </Button>
  );
}
