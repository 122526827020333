import {createSlice} from '@reduxjs/toolkit';

const initialState = {
  filter: {}
};

const acaoSetFilter = (state, action) => {
  state.filter = action.payload;
};

const resumeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    setFilter: acaoSetFilter,
  },
});

export const {actions, reducer} = resumeSlice;
export const {setFilter} = actions;
