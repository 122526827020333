import {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';

import {useAuth} from '../../contexts/auth';
import NoticiaFormulario from './formulario';

export default function NoticiaCadastro() {
  const {role} = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (['ROLE_JORNALISTA', 'ROLE_PROFISSIONAL_SAUDE'].includes(role)) return;

    return navigate('/');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role]);

  return <NoticiaFormulario isArtigo={role === 'ROLE_PROFISSIONAL_SAUDE'} />;
}
