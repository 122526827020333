import {createSlice} from '@reduxjs/toolkit';

import {getIsMobile} from '../../utils/screen';

const initialState = {
  isMobile: getIsMobile(),
};

const acaoSetIsMobile = (state, action) => {
  state.isMobile = action.payload;
};

const resumeSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setIsMobile: acaoSetIsMobile,
  },
});

export const {actions, reducer} = resumeSlice;
export const {setIsMobile} = actions;
