import {useLazyQuery} from '@apollo/client';
import {Close} from '@mui/icons-material';
import {Grid, IconButton, Modal, Slide, Typography} from '@mui/material';
import {useEffect, useState} from 'react';

import ToggleOptions from '../../../components/toggle-options';
import {FIND_PROFISSIONAL_BY_ID} from '../../../graphql/queries';
import DadosBaseProfissional from '../formularios/dados-base';
import EnderecosProfissional from '../formularios/enderecos';

const menuOptions = [
  {value: 'DADOS_BASE', label: 'Dados básicos'},
  {value: 'ENDERECOS', label: 'Endereços'},
];

export default function EditarPerfilProfissional({
  open,
  onClose,
  profissionalId,
  isAdmin,
}) {
  const [option, setOption] = useState(menuOptions[0].value);
  const [profissional, setProfissional] = useState({});

  const [loadProfissional, profissionalQuery] = useLazyQuery(
    FIND_PROFISSIONAL_BY_ID,
  );

  useEffect(() => {
    if (!open || !profissionalId) {
      setOption(menuOptions[0].value);
      setProfissional({})
      return;
    }

    loadProfissional({
      variables: {
        id: profissionalId,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, profissionalId]);

  useEffect(() => {
    if (profissionalQuery.loading) return;

    setProfissional(profissionalQuery.data?.profissional || {});
  }, [profissionalQuery]);

  const renderForm = () => {
    switch (option) {
      case menuOptions[0].value:
        return (
          <DadosBaseProfissional
            profissional={profissional}
            isAdmin={isAdmin}
          />
        );
      case menuOptions[1].value:
        return <EnderecosProfissional profissional={profissional} />;
      default:
        return null;
    }
  };

  return (
    <Modal open={open} onClose={onClose} sx={styles.modal}>
      <Slide in={open} appear>
        <Grid
          container
          className="styled-scroll-transparent-desktop"
          sx={styles.container}>
          <Grid container item sx={styles.header}>
            <Typography sx={styles.title}>Editar Profissional</Typography>
            <IconButton sx={styles.closeIcon} onClick={onClose}>
              <Close />
            </IconButton>
            <Grid container item xs={12} paddingY={1} justifyContent={'center'}>
              <ToggleOptions
                options={menuOptions}
                selected={option}
                onChange={setOption}
              />
            </Grid>
          </Grid>
          <Grid container item sx={styles.content}>
            {renderForm()}
          </Grid>
        </Grid>
      </Slide>
    </Modal>
  );
}

const styles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    position: 'relative',
    maxWidth: '683px',
    height: '95%',
    width: '40%',
    borderRadius: '6px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    padding: 3,
    paddingTop: 0,
    zIndex: (theme) => theme.zIndex.modal,
    background: (theme) => theme.palette.blackSecundary,
    boxShadow: '0px 0px 134.60001px 0px rgba(255, 204, 41, 0.25)',
    outline: 'none',
    overflowY: 'auto',

    '@media (max-width: 900px)': {
      width: '90%',
    },
  },
  header: {
    flexGrow: 0,
    paddingTop: 2,
    position: 'sticky',
    top: 0,
    background: (theme) => theme.palette.blackSecundary,
    justifyContent: 'space-between',
    alignItems: 'center',
    svg: {
      color: '#fff',
    },
    zIndex: 2,
  },
  closeIcon: {
    width: '32px',
    height: '32px',
  },
  title: {
    color: '#FFF',
    fontSize: '20px',
    fontWeight: '700',
    textTransform: 'uppercase',

    '@media (max-width: 1200px)': {
      fontSize: '14px',
    },
  },
  content: {
    flexDirection: 'row',
    width: '100%',
    height: '100%',
    paddingTop: '16px',
  },
};
