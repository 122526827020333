import {Grid} from '@mui/material';
import {useState} from 'react';

import Footer from '../../components/footer';
import ContatoModal from '../../components/modais/contato';
import PerguntasFrequentes from '../../components/perguntas-frequentes';
import Toolbar from '../../components/toolbar/toolbar';
import HomeAtleta from './atleta';
import HomeEmpresario from './empresario';
import HomeJornalista from './jornalista';
import HomeMain from './main';
import HomeMedico from './medico';

export default function Home() {
  const [openContato, setOpenContato] = useState(false);

  return (
    <Grid container sx={styles.container}>
      <Toolbar dark />
      <Grid component={'section'} container>
        <HomeMain />
      </Grid>
      <Grid component={'section'} container>
        <HomeEmpresario />
      </Grid>
      <Grid component={'section'} container>
        <HomeAtleta />
      </Grid>
      <Grid component={'section'} container>
        <HomeMedico />
      </Grid>
      <Grid component={'section'} container>
        <HomeJornalista />
      </Grid>
      <Grid display={'none'} component={'section'} container>
        <PerguntasFrequentes onOpenContato={() => setOpenContato(true)} />
      </Grid>
      <Footer />
      <ContatoModal open={openContato} onClose={() => setOpenContato(false)} />
    </Grid>
  );
}

const styles = {
  container: {
    backgroundColor: (theme) => theme.palette.blackSecundary,
    paddingTop: (theme) => `calc(64px + ${theme.toolbarHeight})`,
  },
};
