import {useLazyQuery} from '@apollo/client';
import {CircularProgress, Grid, Typography} from '@mui/material';
import React, {useEffect, useState} from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import {useSelector} from 'react-redux';

import ToolbarBusca from '../../components/toolbar/toolbar-busca';
import {useAuth} from '../../contexts/auth';
import {FIND_ALL_ATLETAS, FIND_ALL_EMPRESARIOS} from '../../graphql/queries';
import CardBusca from './components/card-busca';
import FiltroBusca from './components/filtro-busca';
import FiltrosSalvosBusca from './components/filtros-salvos';

const pageable = {
  pageSize: 20,
  sortField: 'nome',
  sortDir: 'ASC',
};

export default function Busca() {
  const {filtros} = useSelector((state) => state.Busca);
  const {isMobile} = useSelector((state) => state.App);
  const {usuarioLogado, role} = useAuth();

  const [entidades, setEntidades] = useState([]);
  const [searchDTO, setSearchDTO] = useState({});
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [pageElements, setPageElements] = useState(0);

  const [loadAtleta, atletaQuery] = useLazyQuery(FIND_ALL_ATLETAS);
  const [loadEmpresario, empresarioQuery] = useLazyQuery(FIND_ALL_EMPRESARIOS);

  useEffect(() => {
    if (atletaQuery.loading || empresarioQuery.loading) return setLoading(true);

    let result = {};

    if (filtros.perfil === 'ATLETAS') {
      result = atletaQuery.data?.atletas || {};
    }
    if (filtros.perfil === 'EMPRESARIOS') {
      result = empresarioQuery.data?.empresarios || {};
    }

    setPageElements({
      totalElements: result.totalElements,
      hasMore: result.last === false,
    });
    if (page === 0) setEntidades(result.content || []);
    else setEntidades((e) => [...e, ...(result.content || [])]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [atletaQuery, empresarioQuery]);

  useEffect(() => {
    if (atletaQuery.loading || empresarioQuery.loading) return;

    setLoading(false);
  }, [atletaQuery, empresarioQuery]);

  useEffect(() => {
    if (filtros.perfil === 'ATLETAS')
      loadAtleta({
        variables: {
          pageable: {...pageable, pageNumber: page},
          searchDTO: {
            ...searchDTO,
            usuarioId: filtros?.favoritos ? usuarioLogado?.id : undefined,
            situacao: 'COMPLETO',
            ativo: true,
          },
        },
      });
    if (filtros.perfil === 'EMPRESARIOS')
      loadEmpresario({
        variables: {
          pageable: {...pageable, pageNumber: page},
          searchDTO: {
            ...searchDTO,
            ativo: true
          },
        },
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, searchDTO]);

  useEffect(() => {
    handleFilter();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtros.perfil, filtros.cidade, filtros.search]);

  const handleFilter = () => {
    setPage(0);
    setSearchDTO({
      modalidadeId: filtros.modalidade,
      idadeMin: filtros.idadeMin,
      idadeMax: filtros.idadeMax,
      funcoesId: getChecboxFilters(filtros.posicoes),
      search: filtros.search,
      cidadeId: filtros.cidade?.id,
    });
  };

  const getChecboxFilters = (filtros = {}) => {
    var keys =
      Object.keys(filtros).filter((key) => filtros[key] === true) || [];
    return keys.length > 0 ? keys : undefined;
  };

  const handleMore = () => {
    if (loading) return;
    setLoading(true);
    setPage((p) => ++p);
  };

  const handleRefetch = () => {
    if (loading) return;

    if (filtros.perfil === 'ATLETAS') return atletaQuery.refetch();

    empresarioQuery.refetch();
  };

  return (
    <Grid container sx={styles.container}>
      <ToolbarBusca />
      <Grid container sx={{maxWidth: '1200px', alignContent: 'flex-start'}}>
        <Grid container item md={3} xs={12} alignContent="flex-start">
          <Grid container sx={styles.header}>
            <Grid item md={12} xs={6} sx={styles.totalElements}>
              <Typography sx={styles.resultadosNumero}>
                {pageElements.totalElements} resultados
              </Typography>
            </Grid>
            <Grid item md={12} xs={6} sx={styles.filtrosContainer}>
              <FiltroBusca onFilter={handleFilter} />
            </Grid>
          </Grid>
        </Grid>
        <Grid container item md={7} xs={12} sx={styles.listContainer}>
          {loading && (
            <Grid sx={styles.loadingContainer}>
              <CircularProgress />
            </Grid>
          )}
          <InfiniteScroll
            pageStart={0}
            loadMore={handleMore}
            hasMore={pageElements.hasMore}
            threshold={20}
            style={{width: '100%'}}>
            <ListaCards
              itens={entidades}
              tipo={filtros.perfil}
              refetch={handleRefetch}
            />
          </InfiniteScroll>
        </Grid>
        {filtros.perfil === 'ATLETAS' &&
          !isMobile &&
          role === 'ROLE_EMPRESARIO' && (
            <Grid container item md={2} xs={12} sx={styles.salvosContainer}>
              <FiltrosSalvosBusca />
            </Grid>
          )}
      </Grid>
    </Grid>
  );
}

const ListaCards = ({itens, tipo, refetch}) => {
  if (itens.length === 0) {
    return (
      <Typography sx={styles.emptyText}>Nenhum resultado encontrado</Typography>
    );
  }

  return (
    <React.Fragment>
      {itens.map((item) => (
        <CardBusca key={item.id} item={item} tipo={tipo} refetch={refetch} />
      ))}
    </React.Fragment>
  );
};

const styles = {
  container: {
    backgroundColor: (theme) => theme.palette.backgroundColor,
    paddingTop: (theme) => theme.toolbarHeight,
    justifyContent: 'center',
    minHeight: '100vh',
    paddingX: `64px`,

    '@media (max-width: 900px)': {
      padding: (theme) => `calc(${theme.toolbarHeight} + 16px) 0 0 0`,
    },
  },
  header: {
    position: 'sticky',
    top: '75px',
    '@media (max-width: 900px)': {
      position: 'fixed',
      zIndex: 2,
      top: (theme) => theme.toolbarHeight,
      flexDirection: 'center',
      backgroundColor: (theme) => theme.palette.backgroundColor,
      paddingTop: '15px',
      height: '55px',
    },
  },
  totalElements: {
    '@media (max-width: 900px)': {
      display: 'flex',
      alignItems: 'center',
      alignSelf: 'center',
      height: '40.5px',
      borderRight: '1px solid #141412',
      backgroundColor: (theme) => theme.palette.blackSecundary,
    },
  },
  filtrosContainer: {
    '@media (max-width: 900px)': {
      height: '40.5px',
      backgroundColor: (theme) => theme.palette.blackSecundary,
    },
  },
  resultadosNumero: {
    color: '#FFF',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 500,
    marginBottom: 2,

    '@media (max-width: 900px)': {
      marginBottom: 0,
      marginLeft: 2,
    },
  },
  listContainer: {
    position: 'relative',
    padding: '37px 24px',
    alignContent: 'flex-start',

    '@media (max-width: 900px)': {
      paddingX: '0px',
      paddingTop: '75px',
    },
  },
  salvosContainer: {
    paddingY: '37px',
    alignContent: 'flex-start',

    '@media (max-width: 900px)': {
      paddingTop: '75px',
    },
  },
  loadingContainer: {
    position: 'fixed',
    justifyContent: 'center',
    top: '200px',
    left: '50%',
    zIndex: 2,
    padding: 1,
    width: '56px',
    height: '56px',
    borderRadius: '50%',
    background: (theme) => theme.palette.backgroundColor,
  },
  emptyText: {
    marginTop: '30px',
    width: '100%',
    maxWidth: '653px',
    textAlign: 'center',
    color: '#FFF',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: 'normal',
    letterSpacing: '0.42px',
  },
};
