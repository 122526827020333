import {Image} from '@mui/icons-material';
import {Avatar, Grid, Typography} from '@mui/material';
import {useNavigate} from 'react-router-dom';

import Data from '../../../utils/data';
import {getImageUrl} from '../../../utils/file';

export default function CardBusca({item = {}}) {
  const navigate = useNavigate();

  return (
    <Grid
      container
      sx={styles.container}
      onClick={() => navigate(`/noticias/${item.id}`)}>
      <Grid container item md={4} xs={12} sx={styles.bannerContainer}>
        <Avatar
          src={getImageUrl(item.banner)}
          alt={item.nome}
          sx={styles.banner}>
          <Image />
        </Avatar>
      </Grid>
      <Grid container item md={8} xs={12} sx={styles.content}>
        <Typography sx={styles.titulo}>{item.titulo}</Typography>
        <Typography sx={styles.data}>
          {Data.dataFormat(item.ultimaAlteracao, 'DD/MM/YYYY [-] HH:mm')}
        </Typography>
      </Grid>
    </Grid>
  );
}

const styles = {
  container: {
    position: 'relative',
    marginBottom: 2,
    backgroundColor: (theme) => theme.palette.blackSecundary,
    cursor: 'pointer',

    '@media (min-width: 901px)': {
      borderRadius: '6px',
      height: '200px',
    },
  },
  bannerContainer: {
    maxHeight: '100%',
    '@media (max-width: 900px)': {
      aspectRatio: 5 / 3,
      padding: 2,
      paddingBottom: 0,
    },
  },
  banner: {
    borderRadius: '6px',
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    objectPosition: 'center',
    svg: {
      width: '50%',
      height: '50%',
    },
  },
  content: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: 2,
  },
  titulo: {
    color: '#FFF',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 500,
    marginBottom: 1,
  },
  data: {
    color: '#FFF',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: 500,
    marginRight: 1,
  },
};
