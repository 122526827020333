import {useMutation, useQuery} from '@apollo/client';
import {Block, Check, Visibility} from '@mui/icons-material';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import ButtonResponsive from '../../../components/buttons/button-responsive';
import Filtros from '../../../components/filtro/filtros';
import Input from '../../../components/input/input';
import {SelectCidades} from '../../../components/input/select/cidades';
import {UPDATE_EMPRESARIO_STATUS} from '../../../graphql/mutation';
import {FIND_ALL_EMPRESARIOS} from '../../../graphql/queries';

export default function ConsultaEmpresarios() {
  const navigate = useNavigate();

  const [empresarios, setEmpresarios] = useState([]);
  const [searchDTO, setSearchDTO] = useState({});
  const [page, setPage] = useState(0);
  const [totalElements, setTotalElements] = useState(0);

  const empresariosQuery = useQuery(FIND_ALL_EMPRESARIOS, {
    variables: {
      pageable: {
        pageNumber: page,
        pageSize: 20,
        sortField: 'nome',
        sortDir: 'ASC',
      },
      searchDTO: {
        search: searchDTO.search,
        cidadeId: searchDTO.cidade?.id,
      },
    },
  });

  const [updateStatus, {loading}] = useMutation(UPDATE_EMPRESARIO_STATUS);

  useEffect(() => {
    if (empresariosQuery.loading) return;

    setEmpresarios(empresariosQuery.data?.empresarios?.content || []);
    setTotalElements(empresariosQuery.data?.empresarios?.totalElements || 0);
  }, [empresariosQuery]);

  useEffect(() => {
    setPage(0);
  }, [searchDTO]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleAlterarStatus = (empresario) => {
    if (loading) return;

    updateStatus({
      variables: {
        empresario: {
          id: empresario.id,
          ativo: !empresario.ativo,
        },
      },
    }).then(() => empresariosQuery.refetch());
  };

  return (
    <Grid container item xs={12} paddingBottom={4}>
      <Filtros>
        <Grid item md={6} xs={12}>
          <Input
            name="busca"
            placeholder="Pesquisar por nome"
            type="text"
            autoComplete="off"
            value={searchDTO.search || ""}
            onChange={({target}) =>
              setSearchDTO((f) => ({...f, search: target?.value}))
            }
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <SelectCidades
            placeholder="Pesquisar por cidade"
            value={searchDTO.cidade}
            onChange={(cidade) => setSearchDTO((f) => ({...f, cidade}))}
            isClearable
          />
        </Grid>
      </Filtros>
      <TableContainer id="table-container">
        <Table id="table">
          <TableHead id="table-head">
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell>E-mail</TableCell>
              <TableCell>Cidade</TableCell>
              <TableCell sx={{width: '182px'}} align="center">
                Ações
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody id="table-body">
            {empresarios.map((e) => (
              <TableRow key={e.id} id="table-row">
                <TableCell>{e.nome}</TableCell>
                <TableCell>{e.email}</TableCell>
                <TableCell>{e.enderecos?.[0]?.cidade?.nomeEUf}</TableCell>
                <TableCell
                  align="right"
                  sx={{display: 'flex', flexDirection: 'row'}}>
                  <ButtonResponsive
                    id="primary"
                    value="Visualizar"
                    onClick={() => navigate(`/empresario/${e.id}`)}
                    icon={<Visibility />}
                    sx={{marginRight: 2}}
                  />
                  {e.ativo && (
                    <ButtonResponsive
                      id="outlined"
                      value="Ativo"
                      onClick={() => handleAlterarStatus(e)}
                      icon={<Check />}
                    />
                  )}
                  {!e.ativo && (
                    <ButtonResponsive
                      id="outlined"
                      value="Inativo"
                      onClick={() => handleAlterarStatus(e)}
                      icon={<Block />}
                    />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow id="table-row-footer">
              <TableCell id="table-pagination" colSpan={4}>
                <TablePagination
                  component="div"
                  count={totalElements}
                  rowsPerPageOptions={[]}
                  rowsPerPage={20}
                  page={page}
                  labelDisplayedRows={({from, to, count}) =>
                    `${from}-${to} de ${count}`
                  }
                  onPageChange={handleChangePage}
                />
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Grid>
  );
}
