import {Grid} from '@mui/material';
import {useParams} from 'react-router-dom';

import Footer from '../../components/footer';
import Toolbar from '../../components/toolbar/toolbar';
import EmpresarioPerfil from './perfil';

export default function Empresario() {
  const {empresarioId} = useParams();
  return (
    <Grid container>
      <Toolbar />
      <EmpresarioPerfil empresarioId={empresarioId} readOnly />
      <Footer />
    </Grid>
  );
}
