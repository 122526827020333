import { useMutation } from '@apollo/client';
import { Close, Save } from '@mui/icons-material';
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
  Slide,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';

import CheckboxInput from '../../../components/input/checkbox';
import Input from '../../../components/input/input';
import { toastNotification } from '../../../components/toastify';
import { CREATE_PLANO } from '../../../graphql/mutation';

export default function PlanoModal({open, onClose, plano}) {
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});

  const [savePlano, {loading}] = useMutation(CREATE_PLANO);

  const handleSubmit = (event) => {
    event.preventDefault();
    planoScheme
      .validate({...formData}, {abortEarly: false})
      .then(handleSave)
      .catch((errors) => {
        const camposComErro = {};
        errors?.inner?.forEach((error) => {
          camposComErro[error.path] = error.message;
        });
        setErrors(camposComErro);
      });
  };

  const handleSave = () => {
    savePlano({
      variables: {
        plano: {
          ...formData,
          id: plano?.id,
        },
      },
    })
      .then(() => {
        toastNotification({message: 'Registro salvo!', type: 'success'});
        onClose();
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      );
  };

  useEffect(() => {
    setErrors({});
  }, [open]);

  useEffect(() => {
    setFormData(plano || {});
  }, [plano]);

  return (
    <Modal open={open} onClose={onClose} sx={styles.modal}>
      <Slide in={open} appear>
        <Grid
          container
          className="styled-scroll-transparent-desktop"
          sx={styles.container}>
          <Grid container item sx={styles.header}>
            <Typography sx={styles.subtitle}>{`${
              plano ? 'Editar' : 'Cadastrar'
            } Plano`}</Typography>
            <IconButton sx={styles.closeIcon} onClick={onClose}>
              <Close />
            </IconButton>
          </Grid>
          <Grid container item sx={styles.content} gap={2}>
            <Input
              label={'Título'}
              name="titulo"
              value={formData.titulo || ''}
              type="text"
              placeholder={'Digite o título'}
              onChange={({target}) =>
                setFormData((f) => ({...f, titulo: target?.value}))
              }
              errors={errors}
            />
            <Input
              label="Valor mensal"
              name="valorMensal"
              type="number"
              errors={errors}
              placeholder="Digite o valor mensal"
              value={formData.valorMensal || ''}
              onChange={({target}) =>
                setFormData((f) => ({...f, valorMensal: target?.value}))
              }
            />
            <Input
              label="Valor anual"
              name="valorAnual"
              type="number"
              errors={errors}
              placeholder="Digite o valor anual"
              value={formData.valorAnual || ''}
              onChange={({target}) =>
                setFormData((f) => ({...f, valorAnual: target?.value}))
              }
            />
            <Input
              type="text"
              name="descricao"
              label={'Descrição'}
              multiline
              rows={3}
              value={formData.descricao || ''}
              placeholder="Digite uma descrição"
              onChange={({target}) =>
                setFormData((f) => ({...f, descricao: target?.value}))
              }
              errors={errors}
            />

            <CheckboxInput
              name="exibeNoSite"
              label="Exibe no site"
              checked={formData.exibeNoSite || false}
              onChange={({target}) =>
                setFormData((f) => ({...f, exibeNoSite: target?.checked}))
              }
            />
            <CheckboxInput
              name="ativo"
              label="Ativo"
              checked={formData.ativo === undefined ? true : formData.ativo}
              onChange={({target}) =>
                setFormData((f) => ({...f, ativo: target?.checked}))
              }
            />
          </Grid>
          <Grid container py={2}>
            <Button
              id="primary"
              onClick={handleSubmit}
              fullWidth
              endIcon={loading ? <CircularProgress size={20} /> : <Save />}>
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Slide>
    </Modal>
  );
}

const planoScheme = Yup.object().shape({
  titulo: Yup.string().required('Campo obrigatório'),
  descricao: Yup.string().required('Campo obrigatório'),
  valorMensal: Yup.string().required('Campo obrigatório'),
  valorAnual: Yup.string().required('Campo obrigatório'),
});

const styles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    position: 'relative',
    maxWidth: '683px',
    width: '40%',
    borderRadius: '6px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    padding: 3,
    paddingTop: 0,
    zIndex: (theme) => theme.zIndex.modal,
    background: (theme) => theme.palette.blackSecundary,
    boxShadow: '0px 0px 134.60001px 0px rgba(255, 204, 41, 0.25)',
    outline: 'none',
    overflowY: 'auto',

    '@media (max-width: 900px)': {
      width: '90%',
    },
  },
  header: {
    flexGrow: 0,
    paddingTop: 2,
    position: 'sticky',
    top: 0,
    background: (theme) => theme.palette.blackSecundary,
    justifyContent: 'space-between',
    alignItems: 'center',
    svg: {
      color: '#fff',
    },
    paddingBottom: 2,
    borderBottom: '1px solid #fff',
    zIndex: 2,
  },
  closeIcon: {
    width: '32px',
    height: '32px',
  },
  titleContainer: {
    flexGrow: 0,
    justifyContent: 'center',
    padding: '16px 0',
    maxWidth: '420px',

    '@media (max-width: 1200px)': {
      padding: '16px 0',
    },
  },
  title: {
    color: '#FFF',
    textAlign: 'center',
    fontFamily: 'Now',
    fontSize: '24px',
    fontWeight: '700',
    textTransform: 'uppercase',

    '@media (max-width: 1200px)': {
      fontSize: '20px',
    },
  },
  subtitle: {
    color: '#FFF',
    fontSize: '20px',
    fontWeight: '700',
    textTransform: 'uppercase',

    '@media (max-width: 1200px)': {
      fontSize: '14px',
    },
  },
  content: {
    flexDirection: 'column',
    width: '100%',
    paddingY: '16px',
  },
  avatarContainer: {
    flexDirection: 'column',
    flexGrow: '0 !important',
    alignContent: 'center',
  },
  avatar: {
    height: '120px',
    width: '120px',
    borderRadius: '50%',
    flexDirection: 'column',
    backgroundColor: 'transparent',
    border: (theme) => `1px solid ${theme.palette.primary.main}`,
    cursor: 'pointer',

    svg: {
      color: (theme) => theme.palette.primary.main,
      width: '40px',
      height: '40px',
      maxWidth: '50%',
      maxHeight: '50%',
    },
  },
  fotoPerfilText: {
    color: (theme) => theme.palette.primary.main,
    textAlign: 'center',
    fontSize: '14px',
    fontWeight: '700',
    width: '70%',
    marginTop: 1,

    '@media (max-width: 900px)': {
      display: 'none',
    },
  },
};
