import {Button, Stack, Typography} from '@mui/material';
import {ThemeProvider} from '@mui/material/styles';
import React from 'react';

import {theme} from '../../theme/theme';

function CustomUi({title, onConfirm, onClose, onCancel, message}) {
  const handleCancelClick = () => {
    onCancel && onCancel();
    onClose();
  };

  const handleConfirmCLick = () => {
    onConfirm && onConfirm();
    onClose();
  };

  return (
    <Stack
      direction={'column'}
      spacing={3}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: '#28292A',
        padding: '15px',
        borderRadius: '4px',
        boxShadow: '0px 0px 13px 0px rgba(255, 204, 41, 0.25)',
      }}>
      <ThemeProvider theme={theme}>
        <Typography
          sx={{
            color: '#fff',
            fontWeight: '500',
            textAlign: 'center',
          }}>
          {title}
        </Typography>
        <Typography
          sx={{
            fontFamily: (theme) => theme.typography.fontFamily.pattern,
            color: '#fff',
            textAlign: 'center',
            whiteSpace: 'pre-line',
          }}>
          {message}
        </Typography>
        <Stack direction={'row'} spacing={2} justifyContent="space-between">
          <Button
            id="outlined"
            onClick={handleCancelClick}
            sx={{width: '150px'}}>
            Cancelar
          </Button>
          <Button
            id="primary"
            onClick={handleConfirmCLick}
            sx={{width: '150px'}}
            autoFocus>
            Confirmar
          </Button>
        </Stack>
      </ThemeProvider>
    </Stack>
  );
}

export default CustomUi;
