import {Box, Button, Grid, Typography} from '@mui/material';
import {useNavigate} from 'react-router-dom';

import AtletaImage from '../../assets/images/home-atleta.png';

export default function HomeAtleta() {
  const navigate = useNavigate();

  return (
    <Grid container sx={styles.container}>
      <Grid container sx={styles.content} justifyContent={'space-between'}>
        <Grid container item md={5.5} xs={12} sx={styles.image}>
          <Box component="img" src={AtletaImage} />
        </Grid>
        <Grid container item md={5.5} xs={12} alignContent={'center'}>
          <Box sx={styles.labelContainer}>
            <Typography sx={styles.label}>PARA ATLETAS</Typography>
          </Box>
          <Typography id="home-title">
            Vamos evoluir sua carreira para o próximo nível!
          </Typography>
          <Box sx={styles.topicContainer}>
            <Box>
              <Typography sx={styles.topicIndex}>1º</Typography>
            </Box>
            <Box>
              <Typography sx={styles.topicTitle}>Crie sua conta</Typography>
              <Typography sx={styles.topic}>
                Crie a sua conta e comece a se conectar com os atletas,
                empresários, clubes e profissionais da saúde
              </Typography>
            </Box>
          </Box>
          <Box sx={styles.topicContainer} margin={'24px 0'}>
            <Box>
              <Typography sx={styles.topicIndex}>2º</Typography>
            </Box>
            <Box>
              <Typography sx={styles.topicTitle}>
                Complete seu perfil
              </Typography>
              <Typography sx={styles.topic}>
                Preencha seus dados básicos e faça sua avaliação médica
              </Typography>
            </Box>
          </Box>
          <Box sx={styles.topicContainer}>
            <Box>
              <Typography sx={styles.topicIndex}>3º</Typography>
            </Box>
            <Box>
              <Typography sx={styles.topicTitle}>Conecte-se</Typography>
              <Typography sx={styles.topic}>
                Busque por empresários para conseguir novas oportunidades ou
                atletas para se inspirar.
              </Typography>
            </Box>
          </Box>
          <Grid container gap={2} sx={styles.buttonContainer}>
            <Grid item>
              <Button id="outlined" onClick={() => navigate('/cadastro/atleta')}>
                CRIAR CONTA
              </Button>
            </Grid>
            <Grid item>
              <Button id="primary" onClick={() => navigate('/login')}>
                LOGIN
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

const styles = {
  container: {
    flex: 1,
    justifyContent: 'center',
    padding: '112px 64px',
    backgroundColor: '#28292A',

    '@media (max-width: 900px)': {
      padding: '16px 16px 32px 16px',
    },
  },
  content: {
    maxWidth: 1200,
    '@media (max-width: 900px)': {
      flexWrap: 'nowrap',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  labelContainer: {
    display: 'flex',
    backgroundColor: '#FEFBE8',
    marginBottom: '16px',
    padding: '4px 8px',
    borderRadius: (theme) => theme.borderRadius,
  },
  label: {
    color: '#141412',
    fontSize: 16,
    fontWeight: '700',
    marginTop: '4px',
  },
  topicContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'row',
  },
  topicIndex: {
    fontSize: 32,
    fontWeight: '700',
    marginRight: 2,
    color: '#28292A',
    textShadow: (theme) =>
      `1px 1px 0 ${theme.palette.yellow}, 
      1px -1px 0 ${theme.palette.yellow}, 
      -1px 1px 0 ${theme.palette.yellow}, 
      -1px -1px 0 ${theme.palette.yellow}, 
      1px 0px 0 ${theme.palette.yellow}, 
      0px 1px 0 ${theme.palette.yellow}, 
      -1px 0px 0 ${theme.palette.yellow}, 
      0px -1px 0 ${theme.palette.yellow}`,
  },
  topicTitle: {
    fontSize: '24px',
    lineHeight: '48px',
    fontWeight: '700',
    fontFamily: 'Inter',
    color: '#fff',
  },
  topic: {
    fontSize: 16,
    fontWeight: '400',
    fontFamily: 'Inter',
    color: '#fff',
  },
  buttonContainer: {
    width: 'fit-content',
    marginTop: 4,

    '@media (max-width: 900px)': {
      width: '100%',
      '.MuiGrid-item': {
        width: '100%',
      },
      button: {
        width: '100%',
      },
    },
  },
  image: {
    justifyContent: 'flex-end',
    img: {
      width: '100%',
      objectFit: 'contain',
    },

    '@media (max-width: 900px)': {
      justifyContent: 'center',
      width: '100vw',
      padding: '24px 0',
    },
  },
};
