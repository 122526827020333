const Object = {
  yupArrayToJs: (yupArray) => {
    const arrayConvertido = {};
    for (const key in yupArray) {
      const parts = key.split('.');
      let currentObject = arrayConvertido;

      for (let i = 0; i < parts.length; i++) {
        const part = parts[i];
        const isLastPart = i === parts.length - 1;

        if (!currentObject[part]) {
          currentObject[part] = isLastPart ? yupArray[key] : {};
        }

        currentObject = currentObject[part];
      }
    }

    return arrayConvertido;
  },
  getEnderecoPrincipal: (enderecos = []) => {
    const principal = enderecos?.find((e) => e.principal);
    if (principal) return principal;

    return enderecos[0] || {};
  },
};

export default Object;
