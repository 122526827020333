import {useLazyQuery, useMutation} from '@apollo/client';
import {Image, ThumbDown, ThumbUp} from '@mui/icons-material';
import {
  Avatar,
  Box,
  Divider,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import draftToHtml from 'draftjs-to-html';
import {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';

import Footer from '../../components/footer';
import Toolbar from '../../components/toolbar/toolbar';
import {useAuth} from '../../contexts/auth';
import {AVALIAR_NOTICIA} from '../../graphql/mutation';
import {FIND_NOTICIA_BY_ID, GET_AVALIACAO_NOTICIA} from '../../graphql/queries';
import Data from '../../utils/data';
import {getImageUrl} from '../../utils/file';

export default function VisualizarNoticia() {
  const {noticiaId} = useParams();
  const {usuarioLogado} = useAuth();

  const [noticia, setNoticia] = useState({});
  const [avaliacao, setAvaliacao] = useState({});

  const [loadAvaliacao, avaliacaoQuery] = useLazyQuery(GET_AVALIACAO_NOTICIA);
  const [loadNoticia, noticiaQuery] = useLazyQuery(FIND_NOTICIA_BY_ID, {
    variables: {
      id: noticiaId,
    },
  });

  const [avaliarNoticia] = useMutation(AVALIAR_NOTICIA);

  useEffect(() => {
    if (!noticiaId) return;
    loadNoticia();

    if (!usuarioLogado) return;

    loadAvaliacao({
      variables: {
        noticiaId,
        usuarioId: usuarioLogado.id,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noticiaId]);

  useEffect(() => {
    if (noticiaQuery.loading) return;

    const result = noticiaQuery.data?.noticia || {};

    setNoticia({...result, texto: result.texto && JSON.parse(result.texto)});
  }, [noticiaQuery]);

  useEffect(() => {
    if (avaliacaoQuery.loading) return;

    setAvaliacao(avaliacaoQuery.data?.avaliacao);
  }, [avaliacaoQuery]);

  const handleAvaliar = (value) => {
    const newValue = value === avaliacao ? null : value;
    setAvaliacao(newValue);
    avaliarNoticia({
      variables: {
        noticiaId,
        usuarioId: usuarioLogado?.id,
        avaliacao: newValue,
      },
    });
  };

  return (
    <Grid id="container" container>
      <Toolbar />
      <Grid id="content" container sx={styles.content}>
        <Grid item xs={12}>
          <Avatar
            src={getImageUrl(noticia.fotoCapa)}
            alt={noticia.titulo}
            sx={styles.banner}>
            <Image />
          </Avatar>
        </Grid>
        <Grid
          container
          item
          xs={12}
          justifyContent="space-between"
          padding={'8px 0 16px 0'}>
          <Typography sx={styles.autor}>
            Ultima atualização:{' '}
            {Data.dataFormat(noticia.ultimaAlteracao, 'DD/MM/YY HH:mm')}
          </Typography>
          <Typography sx={styles.autor}>
            Autor: {noticia.jornalista?.nome || noticia.profissional?.nome}
          </Typography>
        </Grid>
        <Grid container paddingBottom={'32px'}>
          <Divider sx={styles.divider} />
        </Grid>
        <Grid item xs={12} pb={3}>
          <Typography sx={styles.titulo}>{noticia.titulo}</Typography>
          <Box
            className="content"
            dangerouslySetInnerHTML={{__html: draftToHtml(noticia.texto)}}
            sx={styles.texto}
          />
        </Grid>
        {usuarioLogado && (
          <Grid container item xs={12} paddingY={2} gap={2}>
            <Grid item>
              <IconButton
                id={'icon-outlined'}
                title='Curti'
                onClick={() => handleAvaliar(true)}
                sx={avaliacao === true && styles.checked}>
                <ThumbUp />
              </IconButton>
            </Grid>
            <Grid item>
              <IconButton
                id={'icon-outlined'}
                title='Não curti'
                onClick={() => handleAvaliar(false)}
                sx={avaliacao === false && styles.checked}>
                <ThumbDown />
              </IconButton>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Footer />
    </Grid>
  );
}

const styles = {
  content: {
    maxWidth: '920px',
    paddingBottom: '100px',
  },
  banner: {
    width: '100%',
    height: '350px',
    borderRadius: (theme) => theme.borderRadius,
    svg: {
      width: '50%',
      height: '50%',
    },
    img: {
      objectFit: 'contain',
    }
  },
  titulo: {
    color: '#FFF',
    fontSize: '24px',
    fontWeight: '500',
    textTransform: 'uppercase',
    paddingBottom: '12px',
  },
  texto: {
    color: '#FFF',
    fontFamily: 'Inter',
    fontSize: '14px',
  },
  divider: {
    borderColor: (theme) => theme.palette.secundaryGray,
    width: '100%',
  },
  autor: {
    color: '#fff4',
    fontSize: '12px',
  },
  checked: {
    backgroundColor: (theme) => theme.palette.primary.main,
    color: (theme) => theme.palette.blackSecundary,
  },
};
