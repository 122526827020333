import axios from 'axios';

import config from './config';

const nestorApi = axios.create({
  baseURL: config.AMAZON_S3_BASE_URL,
  headers: {
    Accept: 'application/json',
  },
});

// Handle API request errors
const response = async (res) => {
  return res;
};

const requestRepository = async (res) => {
  return res;
};

nestorApi.interceptors.response.use(response, (error) => error);
nestorApi.interceptors.request.use(requestRepository, (error) => error);

export const NestorApi = nestorApi;
