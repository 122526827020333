import moment from 'moment';

const Data = {
  dataFormat: (data, format = 'DD/MM/YYYY') => {
    if (!data) return '';

    return moment(data).format(format);
  },
  calcularIdade: (dataNascimento) => {
    if (!dataNascimento) return '';

    return moment().diff(dataNascimento, 'years');
  },
};

export default Data;
