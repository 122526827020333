import imageCompression from 'browser-image-compression';

import {NestorApi} from '../config/api';
import config from '../config/config';
import {ACCESS_TOKEN_KEY} from '../constants/storage-keys';

export const uploadNestor = async (image) => {
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1920,
    useWebWorker: true,
  };

  const compressedFile = await imageCompression(image, options);

  const formData = new FormData();
  formData.append('file', compressedFile);
  return NestorApi.post('/resource/wolf', formData);
};

export const getImageUrl = (id) => {
  if (!id) return '';
  const token = window.localStorage.getItem(ACCESS_TOKEN_KEY);

  return `${config.AMAZON_S3_BASE_URL}/resource/wolf/${token}/${id}`;
};

export const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, {type: contentType});
  return blob;
};
