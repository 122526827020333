import {Box} from '@mui/material';
import {HashRouter, Navigate, Route, Routes} from 'react-router-dom';

import {
  ANAMNESE,
  ATLETA,
  AVALIACAO,
  BUSCA,
  CADASTRO,
  EMPRESARIO,
  HOME,
  LOGIN,
  NOTICIAS,
  PERFIL,
  RECUPERAR_SENHA,
  REDEFINIR_SENHA,
} from '../constants/routes';
import AnamneseConsulta from '../views/anamnese/consulta';
import Anamnese from '../views/anamnese/formulario';
import Atleta from '../views/atleta';
import Busca from '../views/busca';
import Cadastro from '../views/cadastro';
import Empresario from '../views/empresario';
import Home from '../views/home';
import Login from '../views/login';
import RecuperarSenha from '../views/login/recuperar-senha';
import RedefinirSenha from '../views/login/redefinir-senha';
import Noticia from '../views/noticias';
import Perfil from '../views/perfil';
import ScrollTop from './scroll-top';

export function RouterAuthComponent() {
  return (
    <HashRouter>
      <Box component="main" sx={styles.main}>
        <ScrollTop />
        <Routes>
          <Route path={HOME} element={<Home />} />
          <Route path={LOGIN} element={<Login />} />
          <Route path={`${CADASTRO}/*`} element={<Cadastro />} />
          <Route path={RECUPERAR_SENHA} element={<RecuperarSenha />} />
          <Route path={REDEFINIR_SENHA} element={<RedefinirSenha />} />
          <Route path={BUSCA} element={<Busca />} />
          <Route path={`${ATLETA}/:atletaId`} element={<Atleta />} />
          <Route
            path={`${EMPRESARIO}/:empresarioId`}
            element={<Empresario />}
          />
          <Route path={`${NOTICIAS}/*`} element={<Noticia />} />
          <Route path="*" element={<Navigate to={LOGIN} />} />
        </Routes>
      </Box>
    </HashRouter>
  );
}

export function RouterComponent() {
  return (
    <HashRouter>
      <Box component="main" sx={styles.main}>
        <ScrollTop />
        <Routes>
          <Route path={BUSCA} element={<Busca />} />
          <Route path={`${ATLETA}/:atletaId`} element={<Atleta />} />
          <Route
            path={`${EMPRESARIO}/:empresarioId`}
            element={<Empresario />}
          />
          <Route path={PERFIL} element={<Perfil />} />
          <Route path={`${NOTICIAS}/*`} element={<Noticia />} />
          <Route path={REDEFINIR_SENHA} element={<RedefinirSenha />} />
          <Route path={`${AVALIACAO}/:avaliacaoId`} element={<Anamnese />} />
          <Route path={`${ANAMNESE}/:anamneseId`} element={<AnamneseConsulta />} />
          <Route path="*" element={<Navigate to={PERFIL} />} />
        </Routes>
      </Box>
    </HashRouter>
  );
}

const styles = {
  main: {
    display: 'flex',
    padding: 0,
    width: '100%',
    backgroundColor: (theme) => theme.palette.backgroundColor,
  },
};
