import {Menu} from '@mui/icons-material';
import {
  AppBar,
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  Slide,
  Typography,
} from '@mui/material';
import {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';

import {useAuth} from '../../contexts/auth';
import {setFiltrosBusca} from '../../redux/Busca/slice';
import {Atleta, Empresario, LogoBeta, Noticias} from '../icons';
import PerfilLogado from './perfil-logado';

export default function Toolbar({dark}) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {isLogged, loading} = useAuth();

  const {filtros} = useSelector((state) => state.Busca);
  const {isMobile} = useSelector((state) => state.App);

  const [openMenu, setOpenMenu] = useState(false);

  const handleButtonClick = (perfil, route) => {
    if (perfil === 'ATLETA') dispatch(setFiltrosBusca({...filtros, perfil}));
    else dispatch(setFiltrosBusca({perfil}));
    navigate(`/${route}`);
  };

  if (isMobile) {
    return (
      <AppBar className={dark && 'dark'} sx={styles.appBar}>
        <Grid container sx={styles.container}>
          <Grid
            item
            alignSelf={'center'}
            sx={styles.logoMobile}
            onClick={() => navigate('/home')}>
            <LogoBeta />
          </Grid>
          <Grid container item columnGap={1} width={'auto'} alignContent={'center'}>
            <IconButton
              id="icon-outlined"
              onClick={() => setOpenMenu(true)}
              sx={openMenu ? styles.mobileMenuOpen : {}}>
              <Menu />
            </IconButton>
          </Grid>
        </Grid>
        <Modal
          componentsProps={{
            backdrop: {
              sx: {
                background: 'rgb(0, 0, 0, 0.0)',
              },
            },
          }}
          onClose={() => setOpenMenu(false)}
          open={openMenu}>
          <Slide direction="left" in={openMenu} appear>
            <Box sx={styles.modal}>
              <Grid container sx={styles.content}>
                <Grid container>
                  <Grid item xs={12}>
                    <Button
                      sx={styles.menuButtonMobile}
                      onClick={() => handleButtonClick('ATLETAS', 'busca')}>
                      <Atleta />
                      <Typography>Atletas</Typography>
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      sx={styles.menuButtonMobile}
                      onClick={() => handleButtonClick('EMPRESARIOS', 'busca')}>
                      <Empresario />
                      <Typography>Empresários</Typography>
                    </Button>
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      sx={styles.menuButtonMobile}
                      onClick={() => handleButtonClick('NOTICIAS', 'noticias')}>
                      <Noticias />
                      <Typography>Notícias</Typography>
                    </Button>
                  </Grid>
                </Grid>
                {isLogged && <PerfilLogado mobile />}
                {!isLogged && (
                  <Grid container item xs={12} gap={2}>
                    <Grid container item>
                      <Button
                        id="text"
                        sx={styles.createButton}
                        fullWidth
                        onClick={() => navigate('/cadastro')}>
                        CRIAR CONTA
                      </Button>
                    </Grid>
                    <Grid container item>
                      <Button
                        id="outlined"
                        fullWidth
                        onClick={() => navigate('/login')}>
                        Login
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Slide>
        </Modal>
      </AppBar>
    );
  }

  return (
    <AppBar className={dark && 'dark'} sx={styles.appBar}>
      <Grid container sx={styles.container}>
        <Grid
          item
          alignSelf={'center'}
          sx={styles.logo}
          onClick={() => navigate('/home')}>
          <LogoBeta />
        </Grid>
        <Grid container item columnGap={1} width={'auto'}>
          <Button
            id="text"
            sx={styles.menuButton}
            onClick={() => handleButtonClick('ATLETAS', 'busca')}>
            <Atleta />
            <Typography>Atletas</Typography>
          </Button>
          <Button
            id="text"
            sx={styles.menuButton}
            onClick={() => handleButtonClick('EMPRESARIOS', 'busca')}>
            <Empresario />
            <Typography>Empresários</Typography>
          </Button>
          <Button
            id="text"
            sx={styles.menuButton}
            onClick={() => handleButtonClick('NOTICIAS', 'noticias')}>
            <Noticias />
            <Typography>Notícias</Typography>
          </Button>
        </Grid>
        {isLogged && (
          <Grid
            container
            item
            columnGap={2}
            width={'295px'}
            alignSelf={'center'}
            justifyContent={'flex-end'}>
            <PerfilLogado />
          </Grid>
        )}
        {!isLogged && (
          <Grid
            container
            item
            columnGap={2}
            sx={{
              width: '295px',
              alignSelf: 'center',
              visibility: loading ? 'hidden' : 'visible',
            }}>
            <Grid item>
              <Button
                id="text"
                sx={styles.createButton}
                onClick={() => navigate('/cadastro')}>
                CRIAR CONTA
              </Button>
            </Grid>
            <Grid item>
              <Button id="outlined" onClick={() => navigate('/login')}>
                Login
              </Button>
            </Grid>
          </Grid>
        )}
      </Grid>
    </AppBar>
  );
}

const styles = {
  appBar: {
    height: (theme) => theme.toolbarHeight,
    padding: '8px 64px',
    backgroundColor: (theme) => theme.palette.backgroundColor,
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: 'none',

    '&.dark': {
      backgroundColor: (theme) => theme.palette.blackSecundary,
    },

    '@media (max-width: 900px)': {
      padding: '8px 16px',
    },
  },
  container: {
    maxWidth: 1200,
    justifyContent: 'space-between',
  },
  logo: {
    cursor: 'pointer',
    width: '295px',
  },
  logoMobile: {
    cursor: 'pointer',
  },
  content: {
    height: '100%',
    alignContent: 'space-between',
    padding: 4,
  },
  createButton: {
    fontWeight: '400',
  },
  menuButton: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    cursor: 'pointer',
    borderRadius: (theme) => theme.borderRadius,
    color: (theme) => theme.palette.white,
    padding: '8px 16px',
    '.MuiTypography-root': {
      fontWeight: '500',
      textTransform: 'none',
    },
  },
  mobileMenuOpen: {
    backgroundColor: (theme) => `${theme.palette.primary.main} !important`,
    color: '#000',
  },
  modal: {
    width: '80%',
    borderTopLeftRadius: '6px',
    zIndex: (theme) => theme.zIndex.modal,
    background: (theme) => theme.palette.blackSecundary,
    boxShadow: '-48px -21px 134.60001px 0px rgba(255, 204, 41, 0.25)',
    position: 'absolute',
    right: '0px',
    height: (theme) => `calc(100% - 8px - ${theme.toolbarHeight})`,
    top: (theme) => `calc(8px + ${theme.toolbarHeight})`,
  },
  menuButtonMobile: {
    color: '#fff',
    textTransform: 'none',
    padding: '16px 0',
    borderBottom: '1px solid #fff',
    borderRadius: '0',
    width: '100%',
    justifyContent: 'flex-start',
    '.MuiTypography-root': {
      fontSize: '16px',
      fontWeight: '500',
    },
    svg: {
      width: '24px',
      height: '24px',
      marginRight: 1,
    },
  },
};
