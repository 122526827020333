import {useMutation} from '@apollo/client';
import {Add, Save} from '@mui/icons-material';
import {Button, CircularProgress, Grid, Typography} from '@mui/material';
import {useEffect, useState} from 'react';
import * as Yup from 'yup';

import CheckboxInput from '../../../components/input/checkbox';
import Input from '../../../components/input/input';
import InputMask from '../../../components/input/input-mask';
import {SelectCidades} from '../../../components/input/select/cidades';
import {toastNotification} from '../../../components/toastify';
import {
  UPDATE_PROFISSIONAL_ENDERECOS
} from '../../../graphql/mutation';
import Masks from '../../../utils/masks';
import Object from '../../../utils/object';

export default function EnderecosProfissional({profissional}) {
  const [formDataEnderecos, setFormDataEnderecos] = useState([]);
  const [errors, setErrors] = useState({});

  const [saveProfissional, {loading}] = useMutation(
    UPDATE_PROFISSIONAL_ENDERECOS,
  );

  const handleSubmit = (event) => {
    event.preventDefault();
    profissionalScheme
      .validate({enderecos: formDataEnderecos}, {abortEarly: false})
      .then(handleSave)
      .catch((errors) => {
        const camposComErro = {};
        errors?.inner?.forEach((error) => {
          camposComErro[error.path] = error.message;
        });

        setErrors(Object.yupArrayToJs(camposComErro));
      });
  };

  const handleSave = async () => {
    saveProfissional({
      variables: {
        profissional: {
          id: profissional.id,
          enderecos: formDataEnderecos?.map((e) => ({
            ...e,
            cidade: {id: e.cidade?.id},
          })),
        },
      },
    })
      .then(() => {
        toastNotification({message: 'Registro salvo!', type: 'success'});
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      );
  };

  useEffect(() => {
    if (!profissional) {
      setFormDataEnderecos([]);
    }
    setFormDataEnderecos(profissional.enderecos || []);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profissional]);

  const onChangeEndereco = (field, value, endereco, index) => {
    formDataEnderecos[index] = {
      ...endereco,
      [field]: value,
    };

    if (field === 'principal' && value) {
      return setFormDataEnderecos(
        formDataEnderecos.map((e, i) => ({
          ...e,
          principal: index === i,
        })),
      );
    }

    setFormDataEnderecos([...formDataEnderecos]);
  };

  return (
    <Grid container sx={{height: '100%'}}>
      <Grid container item sx={styles.content}>
        <Grid container justifyContent="flex-end">
          <Button
            id="outlined"
            onClick={() => setFormDataEnderecos([...formDataEnderecos, {}])}
            endIcon={<Add />}>
            Adicionar endereço
          </Button>
        </Grid>
        {formDataEnderecos?.map((endereco, index) => (
          <Endereco
            key={index}
            endereco={endereco}
            titulo={`Endereço ${index + 1}`}
            errors={errors[`enderecos[${index}]`]}
            onRemove={() => {
              formDataEnderecos.splice(index, 1);
              setFormDataEnderecos([...formDataEnderecos]);
            }}
            onChange={(field, value) =>
              onChangeEndereco(field, value, endereco, index)
            }
          />
        ))}
      </Grid>
      <Grid container mt={'auto'}>
        <Button
          id="primary"
          onClick={handleSubmit}
          fullWidth
          endIcon={loading ? <CircularProgress size={20} /> : <Save />}>
          Salvar
        </Button>
      </Grid>
    </Grid>
  );
}

const Endereco = ({endereco, titulo, onChange, onRemove, errors}) => {
  return (
    <Grid container sx={styles.enderecoItem}>
      <Grid container sx={styles.enderecoHeader}>
        <Typography sx={styles.enderecoTitulo}>{titulo}</Typography>
      </Grid>
      <Grid container item spacing={2}>
        <Grid item md={6} xs={12}>
          <InputMask
            label={'CEP'}
            name="cep"
            value={endereco.cep || ''}
            type="tel"
            autoComplete="tel"
            mask={Masks.cep}
            placeholder={'Digite seu CEP'}
            onChange={({target}) => onChange('cep', target?.value)}
            errors={errors}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Input
            label={'Logradouro'}
            name="logradouro"
            value={endereco.logradouro || ''}
            type="text"
            autoCapitalize="on"
            placeholder={'Digite seu logradouro'}
            onChange={({target}) => onChange('logradouro', target?.value)}
            errors={errors}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Input
            label={'Nº'}
            name="numero"
            value={endereco.numero || ''}
            type="tel"
            placeholder={'Digite o número'}
            onChange={({target}) => onChange('numero', target?.value)}
            errors={errors}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Input
            label={'Bairro'}
            name="bairro"
            value={endereco.bairro || ''}
            type="text"
            autoCapitalize="on"
            placeholder={'Digite seu bairro'}
            onChange={({target}) => onChange('bairro', target?.value)}
            errors={errors}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Input
            label={'Complemento'}
            name="complemento"
            value={endereco.complemento || ''}
            type="text"
            autoCapitalize="on"
            placeholder={'Digite um complemento'}
            onChange={({target}) => onChange('complemento', target?.value)}
            errors={errors}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <SelectCidades
            label="Cidade"
            name="cidade"
            placeholder="Selecione a cidade"
            value={endereco.cidade}
            isClearable
            menuPlacement="top"
            onChange={(cidade) => onChange('cidade', cidade)}
            errors={errors}
          />
        </Grid>
        <Grid item xs={6}>
          <CheckboxInput
            name="principal"
            label={'Principal'}
            checked={endereco.principal || false}
            onChange={({target}) => onChange('principal', target?.checked)}
          />
        </Grid>
        <Grid container item xs={6} justifyContent={'flex-end'}>
          <Button id="underline-text" onClick={onRemove}>
            Excluir
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

const profissionalScheme = Yup.object().shape({
  enderecos: Yup.array().of(
    Yup.object({
      cep: Yup.string().required('Campo obrigatório'),
      logradouro: Yup.string().required('Campo obrigatório'),
      numero: Yup.string().required('Campo obrigatório'),
      bairro: Yup.string().required('Campo obrigatório'),
      cidade: Yup.object().required('Campo obrigatório'),
    }),
  ),
});

const styles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    position: 'relative',
    maxWidth: '683px',
    height: '95%',
    width: '40%',
    borderRadius: '6px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    padding: 3,
    paddingTop: 0,
    zIndex: (theme) => theme.zIndex.modal,
    background: (theme) => theme.palette.blackSecundary,
    boxShadow: '0px 0px 134.60001px 0px rgba(255, 204, 41, 0.25)',
    outline: 'none',
    overflowY: 'auto',

    '@media (max-width: 900px)': {
      width: '90%',
    },
  },
  header: {
    flexGrow: 0,
    paddingTop: 2,
    position: 'sticky',
    top: 0,
    background: (theme) => theme.palette.blackSecundary,
    justifyContent: 'space-between',
    alignItems: 'center',
    svg: {
      color: '#fff',
    },
    paddingBottom: 2,
    borderBottom: '1px solid #fff',
    zIndex: 2,
  },
  closeIcon: {
    width: '32px',
    height: '32px',
  },
  title: {
    color: '#FFF',
    textAlign: 'center',
    fontFamily: 'Now',
    fontSize: '24px',
    fontWeight: '700',
    textTransform: 'uppercase',

    '@media (max-width: 1200px)': {
      fontSize: '20px',
    },
  },
  subtitle: {
    color: '#FFF',
    fontSize: '20px',
    fontWeight: '700',
    textTransform: 'uppercase',

    '@media (max-width: 1200px)': {
      fontSize: '14px',
    },
  },
  enderecoItem: {
    borderBottom: '1px solid #fff',
    padding: '10px 0 20px 0',
  },
  enderecoHeader: {
    justifyContent: 'space-between',
    alignContent: 'center',
    cursor: 'pointer',
    color: '#fff',
    ':hover': {
      color: (theme) => theme.palette.yellowLight,
    },
    svg: {
      color: 'inherit',
    },
  },
  enderecoTitulo: {
    color: '#E3E3E3',
    textAlign: 'center',
    alignSelf: 'center',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: '500',
    paddingBottom: 1,
  },
  content: {
    alignContent: 'flex-start',
    flexDirection: 'row',
    paddingBottom: '16px',
  },
};
