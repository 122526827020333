import {Info} from '@mui/icons-material';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Popover,
  Typography,
} from '@mui/material';
import {useEffect, useRef, useState} from 'react';

export default function CheckboxInput({
  label,
  customLabel,
  placeholder,
  errors = {},
  name,
  InputProps = {},
  checked,
  ...props
}) {
  const ref = useRef();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!errors[name]) return;
    setAnchorEl(ref.current);
    setOpen(true);
    setTimeout(() => setOpen(false), 2000);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  return (
    <FormGroup>
      <FormControlLabel
        name="name"
        control={
          <Checkbox
            ref={ref}
            checked={checked}
            sx={[
              styles.checkbox,
              !!errors[name] && {color: (theme) => theme.palette.primary.main},
            ]}
          />
        }
        label={
          customLabel || (
            <Typography sx={styles.checkboxLabel}>{label}</Typography>
          )
        }
        errors={errors}
        {...props}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        className="hidePopoverBackdrop"
        disableScrollLock
        sx={styles.popover}>
        <Typography sx={styles.popoverText}>
          <Info sx={styles.popoverIcon} />
          {errors[name]}
        </Typography>
      </Popover>
    </FormGroup>
  );
}

const styles = {
  checkbox: {
    color: '#FFF',

    '& .MuiSvgIcon-root': {
      fontSize: 32,
    },
  },
  checkboxLabel: {
    color: '#FFF',
    fontSize: '14px',
    fontWeight: '400',
    letterSpacing: '0.42px',
    lineHeight: 'normal',

    a: {
      fontWeight: '700',
      color: '#FFF',
      textDecorationColor: '#FFF',
    },
  },
  popover: {
    '.MuiPopover-root': {
      zIndex: '0 !important',
    },
    '.MuiPaper-root': {
      borderTopLeftRadius: 0,
      marginLeft: '16px',
      marginTop: '-10px',
    },
  },
  popoverIcon: {
    color: (theme) => theme.palette.primary.main,
    fontSize: '16px',
    marginBottom: '-2px',
    marginRight: '4px',
  },
  popoverText: {
    padding: ' 0 8px',
  },
};
