import {
  faAlignCenter,
  faAlignLeft,
  faAlignRight,
  faBold,
  faChevronDown,
  faChevronUp,
  faCode,
  faHighlighter,
  faItalic,
  faListOl,
  faListUl,
  faQuoteRight,
  faStrikethrough,
  faSubscript,
  faSuperscript,
  faTextWidth,
  faUnderline,
} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {RichUtils} from 'draft-js';
import React from 'react';

const tools = [
  {
    label: 'Negrito',
    style: 'BOLD',
    icon: <FontAwesomeIcon icon={faBold} />,
    method: 'inline',
  },
  {
    label: 'Itálico',
    style: 'ITALIC',
    icon: <FontAwesomeIcon icon={faItalic} />,
    method: 'inline',
  },
  {
    label: 'Sublinhado',
    style: 'UNDERLINE',
    icon: <FontAwesomeIcon icon={faUnderline} />,
    method: 'inline',
  },
  {
    label: 'Destacar',
    style: 'HIGHLIGHT',
    icon: <FontAwesomeIcon icon={faHighlighter} />,
    method: 'inline',
  },
  {
    label: 'Tachado',
    style: 'STRIKETHROUGH',
    icon: <FontAwesomeIcon icon={faStrikethrough} />,
    method: 'inline',
  },
  {
    label: 'Sobrescrito',
    style: 'SUPERSCRIPT',
    icon: <FontAwesomeIcon icon={faSuperscript} />,
    method: 'inline',
  },
  {
    label: 'Subescrito',
    style: 'SUBSCRIPT',
    icon: <FontAwesomeIcon icon={faSubscript} />,
    method: 'inline',
  },
  {
    label: 'Monoespaçado',
    style: 'CODE',
    icon: <FontAwesomeIcon icon={faTextWidth} transform="grow-3" />,
    method: 'inline',
  },
  {
    label: 'Citação',
    style: 'blockQuote',
    icon: <FontAwesomeIcon icon={faQuoteRight} transform="grow-2" />,
    method: 'block',
  },
  {
    label: 'Lista',
    style: 'unordered-list-item',
    method: 'block',
    icon: <FontAwesomeIcon icon={faListUl} transform="grow-6" />,
  },
  {
    label: 'List ordenada',
    style: 'ordered-list-item',
    method: 'block',
    icon: <FontAwesomeIcon icon={faListOl} transform="grow-6" />,
  },
  {
    label: 'Bloco de código',
    style: 'CODEBLOCK',
    icon: <FontAwesomeIcon icon={faCode} transform="grow-3" />,
    method: 'inline',
  },
  {
    label: 'Maiúsculas',
    style: 'UPPERCASE',
    icon: <FontAwesomeIcon icon={faChevronUp} transform="grow-3" />,
    method: 'inline',
  },
  {
    label: 'Minúsculas',
    style: 'LOWERCASE',
    icon: <FontAwesomeIcon icon={faChevronDown} transform="grow-3" />,
    method: 'inline',
  },
  {
    label: 'Esquerda',
    style: 'leftAlign',
    icon: <FontAwesomeIcon icon={faAlignLeft} transform="grow-2" />,
    method: 'block',
  },
  {
    label: 'Centro',
    style: 'centerAlign',
    icon: <FontAwesomeIcon icon={faAlignCenter} transform="grow-2" />,
    method: 'block',
  },
  {
    label: 'Direita',
    style: 'rightAlign',
    icon: <FontAwesomeIcon icon={faAlignRight} transform="grow-2" />,
    method: 'block',
  },
  {label: 'H1', style: 'header-one', method: 'block'},
  {label: 'H2', style: 'header-two', method: 'block'},
  {label: 'H3', style: 'header-three', method: 'block'},
  {label: 'H4', style: 'header-four', method: 'block'},
];

const Toolbar = ({editorState, setEditorState}) => {
  const applyStyle = (e, style, method) => {
    e.preventDefault();
    method === 'block'
      ? setEditorState(RichUtils.toggleBlockType(editorState, style))
      : setEditorState(RichUtils.toggleInlineStyle(editorState, style));
  };

  const isActive = (style, method) => {
    if (method === 'block') {
      const selection = editorState.getSelection();
      const blockType = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
        .getType();
      return blockType === style;
    } else {
      const currentStyle = editorState.getCurrentInlineStyle();
      return currentStyle.has(style);
    }
  };

  return (
    <div className="toolbar-grid">
      {tools.map((item, idx) => (
        <button
          style={{
            color: isActive(item.style, item.method)
              ? 'rgba(0, 0, 0, 1)'
              : 'rgba(0, 0, 0, 0.5)',
          }}
          key={`${item.label}-${idx}`}
          title={item.label}
          onClick={(e) => applyStyle(e, item.style, item.method)}
          onMouseDown={(e) => e.preventDefault()}>
          {item.icon || item.label}
        </button>
      ))}
    </div>
  );
};

export default Toolbar;
