import {useMutation} from '@apollo/client';
import {Save} from '@mui/icons-material';
import {Button, CircularProgress, Grid} from '@mui/material';
import {useEffect, useState} from 'react';
import * as Yup from 'yup';

import Input from '../../../components/input/input';
import InputMask from '../../../components/input/input-mask';
import SelectInput from '../../../components/input/select/select-input';
import {toastNotification} from '../../../components/toastify';
import {UPDATE_ATLETA_RESPONSAVEL} from '../../../graphql/mutation';
import Data from '../../../utils/data';
import Masks from '../../../utils/masks';
import Object from '../../../utils/object';
import String from '../../../utils/string';

const parentescoOptions = [
  {value: 'PAIS', label: 'Pais'},
  {value: 'AVOS', label: 'Avós'},
  {value: 'TIOS', label: 'Tios'},
  {value: 'OUTROS', label: 'Outros'},
];

export default function ResponsavelAtleta({atleta}) {
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});

  const [saveAtleta, {loading}] = useMutation(UPDATE_ATLETA_RESPONSAVEL);

  const handleSubmit = (event) => {
    event.preventDefault();
    atletaScheme
      .validate(formData, {abortEarly: false})
      .then(handleSave)
      .catch((errors) => {
        const camposComErro = {};
        errors?.inner?.forEach((error) => {
          camposComErro[error.path] = error.message;
        });

        setErrors(Object.yupArrayToJs(camposComErro));
      });
  };

  const handleSave = async () => {
    saveAtleta({
      variables: {
        atleta: {
          id: atleta.id,
          responsavel: {
            ...formData,
            cpf: String.removeSpecialChars(formData.cpf),
            telefone: String.removeSpecialChars(formData.telefone),
            grauParentesco: formData.grauParentesco?.value,
          },
        },
      },
    })
      .then(() => {
        toastNotification({message: 'Registro salvo!', type: 'success'});
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      );
  };

  useEffect(() => {
    setErrors({});
  }, []);

  useEffect(() => {
    if (!atleta) {
      setFormData({});
    }

    const responsavel = atleta?.responsavel || {};
    setFormData({
      ...responsavel,
      grauParentesco: parentescoOptions.find(
        (g) => g.value === responsavel?.grauParentesco,
      ),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [atleta]);

  return (
    <Grid container>
      <Grid container item sx={styles.content} spacing={2}>
        <Grid item md={6} xs={12}>
          <Input
            label={'Nome do responsável'}
            name="nome"
            value={formData.nome || ''}
            type="text"
            autoCapitalize="on"
            placeholder={'Nome completo'}
            onChange={({target}) =>
              setFormData((f) => ({...f, nome: target?.value}))
            }
            errors={errors}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <InputMask
            label={'CPF'}
            name="cpf"
            value={formData.cpf || ''}
            type="tel"
            mask={Masks.cpf}
            placeholder={'Digite o número do documento'}
            onChange={({target}) =>
              setFormData((f) => ({...f, cpf: target?.value}))
            }
            errors={errors}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Input
            label={'E-mail'}
            name="email"
            value={formData.email || ''}
            type="email"
            autoComplete="email"
            placeholder={'Digite seu e-mail'}
            onChange={({target}) =>
              setFormData((f) => ({...f, email: target?.value}))
            }
            errors={errors}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <InputMask
            label={'Telefone'}
            name="telefone"
            value={formData.telefone || ''}
            type="tel"
            autoComplete="tel"
            mask={Masks.telelefone}
            placeholder={'Digite seu número de telefone'}
            onChange={({target}) =>
              setFormData((f) => ({...f, telefone: target?.value}))
            }
            errors={errors}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Input
            label={'Data de nascimento'}
            name="dataNascimento"
            value={formData.dataNascimento || ''}
            type="date"
            onChange={({target}) =>
              setFormData((f) => ({...f, dataNascimento: target?.value}))
            }
            errors={errors}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <SelectInput
            label={'Grau de parentesco'}
            placeholder={'Selecione'}
            name="grauParentesco"
            value={formData.grauParentesco || ''}
            menuPlacement="top"
            onChange={(grau) =>
              setFormData((f) => ({...f, grauParentesco: grau}))
            }
            options={parentescoOptions}
            errors={errors}
          />
        </Grid>
      </Grid>
      <Grid container mt={'auto'}>
        <Button
          id="primary"
          onClick={handleSubmit}
          fullWidth
          endIcon={loading ? <CircularProgress size={20} /> : <Save />}>
          Salvar
        </Button>
      </Grid>
    </Grid>
  );
}

const atletaScheme = Yup.object().shape({
  nome: Yup.string().required('Campo obrigatório'),
  cpf: Yup.string()
    .required('Campo obrigatório')
    .test('validar-cpf', 'CPF inválido', (value) => String.validaCpf(value)),
  email: Yup.string().required('Campo obrigatório').email('E-mail inválido'),
  telefone: Yup.string().required('Campo obrigatório'),
  dataNascimento: Yup.date()
    .required('Campo obrigatório')
    .test(
      'maior-idade',
      'O responsável precisa possuir 18 anos ou mais',
      (value) => Data.calcularIdade(value) > 17,
    ),
  grauParentesco: Yup.object().required('Campo obrigatório'),
});

const styles = {
  content: {
    alignContent: 'flex-start',
    flexDirection: 'row',
    paddingBottom: '16px',
  },
};
