import { useQuery } from '@apollo/client';
import { Close, ShoppingCart } from '@mui/icons-material';
import {
  Card,
  CardContent,
  Grid,
  IconButton,
  Modal,
  Slide,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Swiper, SwiperSlide } from 'swiper/react';

import ButtonResponsive from '../../../components/buttons/button-responsive';
import ToggleOptions from '../../../components/toggle-options';
import { FIND_ALL_PLANO } from '../../../graphql/queries';
import Compra from './compra';

const menuOptions = [
  {
    value: 'Monthly',
    label: 'Mensal',
  },
  {
    value: 'Annual',
    label: 'Anual',
  },
];

export default function Planos({open, onClose, usuario}) {
  const [plano, setPlano] = useState(null);
  const [planos, setPlanos] = useState([]);
  const [periodo, setPeriodo] = useState(null);
  const [openAssinar, setOpenAssinar] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState(menuOptions[0].value);

  const planosQuery = useQuery(FIND_ALL_PLANO, {
    variables: {
      pageableDto: {
        pageSize: 20,
        pageNumber: 0,
        sortDir: 'DESC',
        sortField: 'descricao',
      },
      ativo: true,
    },
  });

  useEffect(() => {
    if (planosQuery.loading) return;

    setPlanos(planosQuery.data?.plano?.content || []);
  }, [planosQuery]);

  const handleAssinar = (p, pe) => {
    setPlano(p);
    setPeriodo(pe);
    setOpenAssinar(true);
  };

  return (
    <Modal open={open} onClose={onClose} sx={styles.modal}>
      <Slide in={open} appear>
        <Grid
          container
          className="styled-scroll-transparent-desktop"
          sx={styles.container}>
          <Grid container item sx={styles.header}>
            <div>
              <Typography sx={styles.title}>
                Planos{' '}
                <ToggleOptions
                  options={menuOptions}
                  selected={selectedMenu}
                  onChange={setSelectedMenu}
                />
              </Typography>
            </div>
            <IconButton sx={styles.closeIcon} onClick={onClose}>
              <Close />
            </IconButton>
          </Grid>
          <Swiper
            onResize={() => {}}
            slidesPerView={usuario ? 1 : Math.min(planos.length, 3)}>
            {[
              ...(usuario ? planos.filter((p) => p.id === usuario.id) : planos),
            ].map((p) => (
              <SwiperSlide key={p.id} style={{height: 'auto'}}>
                <Card
                  sx={{
                    width: 319,
                    height: '100%',
                    margin: 'auto',
                    marginTop: '16px',
                    marginRight: '8px',
                  }}>
                  <CardContent
                    sx={{
                      height: '100%',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                    }}>
                    <div>
                      <Typography variant="h6" component="div">
                        {p.titulo}
                      </Typography>
                      <Typography variant="body1" component="div">
                        {p.descricao}
                      </Typography>
                      {selectedMenu === 'Annual' && (
                        <Typography
                          variant="h6"
                          style={{textDecoration: 'line-through'}}
                          color="text.secondary">
                          R$ {p.valorMensal} /mês
                        </Typography>
                      )}
                      <Typography variant="h4" color="text.secondary">
                        R${' '}
                        {selectedMenu === 'Annual'
                          ? Math.round(p.valorAnual / 12)
                          : p.valorMensal}
                        /mês
                      </Typography>
                      {selectedMenu === 'Annual' && (
                        <Typography variant="body2" color="text.secondary">
                          *Valor anual pago em uma única vez
                        </Typography>
                      )}
                    </div>

                    <div>
                      {usuario ? null : (
                        <ButtonResponsive
                          id="outlined"
                          value="Assinar"
                          icon={<ShoppingCart />}
                          sx={{marginTop: '16px', width: '100%'}}
                          onClick={() => handleAssinar(p, selectedMenu)}
                        />
                      )}
                    </div>
                  </CardContent>
                </Card>
              </SwiperSlide>
            ))}
          </Swiper>

          <Compra
            plano={plano}
            periodo={periodo}
            open={openAssinar}
            onClose={(sucesso) => {
              setOpenAssinar(false);
              if (sucesso === true) {
                onClose();
              }
            }}
          />
        </Grid>
      </Slide>
    </Modal>
  );
}

const styles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    width: 'auto',
    position: 'relative',
    maxWidth: '1029px',
    borderRadius: '6px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    padding: 3,
    paddingTop: 0,
    zIndex: (theme) => theme.zIndex.modal,
    background: (theme) => theme.palette.blackSecundary,
    boxShadow: '0px 0px 134.60001px 0px rgba(255, 204, 41, 0.25)',
    outline: 'none',
    overflowY: 'auto',

    '@media (max-width: 900px)': {
      width: '90%',
    },
  },
  header: {
    flexGrow: 0,
    paddingTop: 2,
    position: 'sticky',
    top: 0,
    background: (theme) => theme.palette.blackSecundary,
    justifyContent: 'space-between',
    alignItems: 'center',
    svg: {
      color: '#fff',
    },
    paddingBottom: 2,
    borderBottom: '1px solid #fff',
    zIndex: 2,
  },
  closeIcon: {
    width: '32px',
    height: '32px',
  },
  title: {
    color: '#FFF',
    display: 'flex',
    fontSize: '20px',
    fontWeight: '700',
    textTransform: 'uppercase',

    '@media (max-width: 1200px)': {
      fontSize: '14px',
    },
  },
  subtitle: {
    color: '#E3E3E3',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: '500',
    marginBottom: 1,
  },
  content: {
    flexDirection: 'row',
    width: '100%',
    paddingY: '16px',
  },
  maxCaracter: {
    color: '#fff',
    fontSize: '12px',
  },
};
