import {useMutation, useQuery} from '@apollo/client';
import {Block, ThumbDown, Visibility} from '@mui/icons-material';
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
} from '@mui/material';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import ButtonResponsive from '../../../components/buttons/button-responsive';
import Filtros from '../../../components/filtro/filtros';
import Input from '../../../components/input/input';
import {toastNotification} from '../../../components/toastify';
import {PUBLICAR_NOTICIA} from '../../../graphql/mutation';
import {FIND_ALL_NOTICIAS} from '../../../graphql/queries';
import Data from '../../../utils/data';

export default function ConsultaNoticias() {
  const navigate = useNavigate();

  const [noticias, setNoticias] = useState([]);
  const [searchDTO, setSearchDTO] = useState({});
  const [page, setPage] = useState(0);
  const [totalElements, setTotalElements] = useState(0);

  const noticiasQuery = useQuery(FIND_ALL_NOTICIAS, {
    variables: {
      pageable: {
        pageNumber: page,
        pageSize: 20,
        sortField: 'dataCadastro',
        sortDir: 'DESC',
      },
      searchDTO: {
        search: searchDTO.search,
      },
    },
  });

  const [publicarNoticia, {loading}] = useMutation(PUBLICAR_NOTICIA);

  useEffect(() => {
    if (noticiasQuery.loading) return;

    setNoticias(noticiasQuery.data?.noticias?.content || []);
    setTotalElements(noticiasQuery.data?.noticias?.totalElements || 0);
  }, [noticiasQuery]);

  useEffect(() => {
    setPage(0);
  }, [searchDTO]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const removerNoticia = (noticia) => {
    if (loading) return;
    publicarNoticia({
      variables: {
        noticia: {
          id: noticia.id,
        },
        publicar: false,
      },
    })
      .then(() => {
        toastNotification({
          message: `Notícia removida!`,
          type: 'success',
        });
        noticiasQuery.refetch();
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      );
  };

  return (
    <Grid container item xs={12} paddingBottom={4}>
      <Filtros>
        <Grid item xs>
          <Input
            name="busca"
            placeholder="Pesquisar por titulo ou autor"
            type="text"
            autoComplete="off"
            value={searchDTO.search || ""}
            onChange={({target}) =>
              setSearchDTO((f) => ({...f, search: target?.value}))
            }
          />
        </Grid>
      </Filtros>
      <TableContainer id="table-container">
        <Table id="table">
          <TableHead id="table-head">
            <TableRow>
              <TableCell>Titulo</TableCell>
              <TableCell>Data</TableCell>
              <TableCell>Autor</TableCell>
              <TableCell>Situação</TableCell>
              <TableCell>Negativas</TableCell>
              <TableCell sx={{width: '182px'}} align="center">
                Ações
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody id="table-body">
            {noticias.map((n) => (
              <TableRow key={n.id} id="table-row">
                <TableCell>{n.titulo}</TableCell>
                <TableCell>{Data.dataFormat(n.ultimaAlteracao)}</TableCell>
                <TableCell>
                  {n.jornalista?.nome || n.profissional?.nome}
                </TableCell>
                <TableCell>
                  {n.publicada ? 'Publicada' : 'Não publicada'}
                </TableCell>
                <TableCell>
                  {n.avaliacoes?.filter((a) => !a.avaliacao)?.length || 0}{' '}
                  <span><ThumbDown sx={styles.dislikeIcon} /></span>
                </TableCell>
                <TableCell
                  align="right"
                  sx={{display: 'flex', flexDirection: 'row'}}>
                  <ButtonResponsive
                    id="primary"
                    value="Visualizar"
                    onClick={() => navigate(`/noticias/${n.id}`)}
                    icon={<Visibility />}
                    sx={{marginRight: 2}}
                  />
                  <ButtonResponsive
                    id="outlined"
                    value="Remover"
                    onClick={() => removerNoticia(n)}
                    icon={<Block />}
                    disabled={!n.publicada}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow id="table-row-footer">
              <TableCell id="table-pagination" colSpan={6}>
                <TablePagination
                  component="div"
                  count={totalElements}
                  rowsPerPageOptions={[]}
                  rowsPerPage={20}
                  page={page}
                  labelDisplayedRows={({from, to, count}) =>
                    `${from}-${to} de ${count}`
                  }
                  onPageChange={handleChangePage}
                />
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
    </Grid>
  );
}

const styles = {
  dislikeIcon: {
    fontSize: '14px',
    marginLeft: 1,
  },
};
