import {useMutation, useQuery} from '@apollo/client';
import {Add, Edit} from '@mui/icons-material';
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import {useEffect, useState} from 'react';

import ButtonResponsive from '../../../components/buttons/button-responsive';
import Filtros from '../../../components/filtro/filtros';
import Input from '../../../components/input/input';
import {SelectFormularioGrupo} from '../../../components/input/select/formulario-grupo';
import {toastNotification} from '../../../components/toastify';
import {anamnesePerguntaTipo} from '../../../constants/enum-labels';
import {CREATE_ANAMNESE_PERGUNTA} from '../../../graphql/mutation';
import {
  FIND_ALL_ANAMNESE_MODELO,
  FIND_ALL_FORMULARIO_GRUPOS,
  FIND_ALL_PERGUNTAS,
} from '../../../graphql/queries';
import AvaliacaoModeloModal from './modais/avaliacao-modelo';
import GrupoModal from './modais/grupo';
import PerguntaModal from './modais/pergunta';

export default function ConsultaFormularios() {
  const [searchDTO, setSearchDTO] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [tipoSelecionado, setTipoSelecionado] = useState('modelo');

  return (
    <Grid container item xs={12} paddingBottom={4}>
      <Filtros>
        <Grid item xs>
          <Input
            name="busca"
            placeholder="Pesquisar"
            type="text"
            autoComplete="off"
            value={searchDTO.search}
            onChange={({target}) =>
              setSearchDTO((f) => ({...f, search: target?.value}))
            }
          />
        </Grid>
        {tipoSelecionado === 'pergunta' && (
          <Grid item md={6} xs={12}>
            <SelectFormularioGrupo
              placeholder={'Selecione um grupo'}
              name="modeloGrupo"
              value={searchDTO.modeloGrupo}
              onChange={(modeloGrupo) =>
                setSearchDTO((f) => ({...f, modeloGrupo}))
              }
            />
          </Grid>
        )}
      </Filtros>
      <Grid
        container
        item
        xs={12}
        paddingBottom={1}
        alignItems={'center'}
        justifyContent={'space-between'}
        sx={styles.submenuContainer}>
        <ToggleButtonGroup
          exclusive
          value={tipoSelecionado}
          onChange={({target}) => setTipoSelecionado(target.value)}
          aria-label="Tipo">
          <ToggleButton value="modelo" sx={styles.tabButton}>
            Modelos
          </ToggleButton>
          <ToggleButton value="grupo" sx={styles.tabButton}>
            Grupos
          </ToggleButton>
          <ToggleButton value="pergunta" sx={styles.tabButton}>
            Perguntas
          </ToggleButton>
        </ToggleButtonGroup>
        <Button id="text" endIcon={<Add />} onClick={() => setOpenModal(true)}>
          Cadastrar novo
        </Button>
      </Grid>
      <Grid container item xs={12}>
        {tipoSelecionado === 'modelo' && (
          <ModelosTabela
            searchDTO={searchDTO}
            openCadNew={openModal}
            onCloseCadNew={() => setOpenModal(false)}
          />
        )}
        {tipoSelecionado === 'grupo' && (
          <GruposTabela
            searchDTO={searchDTO}
            openCadNew={openModal}
            onCloseCadNew={() => setOpenModal(false)}
          />
        )}
        {tipoSelecionado === 'pergunta' && (
          <PerguntasTabela
            searchDTO={searchDTO}
            openCadNew={openModal}
            onCloseCadNew={() => setOpenModal(false)}
          />
        )}
      </Grid>
    </Grid>
  );
}

const ModelosTabela = ({searchDTO, openCadNew, onCloseCadNew}) => {
  const [modelos, setModelos] = useState([]);
  const [page, setPage] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [selecionado, setSelecionado] = useState(undefined);

  const formulariosQuery = useQuery(FIND_ALL_ANAMNESE_MODELO, {
    variables: {
      pageableDTO: {
        pageNumber: page,
        pageSize: 20,
        sortField: 'nome',
        sortDir: 'ASC',
      },
      searchDTO: {
        search: searchDTO.search || undefined,
      },
    },
  });

  useEffect(() => {
    if (formulariosQuery.loading) return;

    setModelos(formulariosQuery.data?.modelos?.content || []);
    setTotalElements(formulariosQuery.data?.modelos?.totalElements || 0);
  }, [formulariosQuery]);

  useEffect(() => {
    setPage(0);
  }, [searchDTO]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    if (!selecionado) return;

    setOpenModal(true);
  }, [selecionado]);

  return (
    <Grid container>
      <TableContainer id="table-container">
        <Table id="table">
          <TableHead id="table-head">
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell>Perguntas</TableCell>
              <TableCell>Ativo</TableCell>
              <TableCell sx={{width: '182px'}} align="center">
                Ações
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody id="table-body">
            {modelos.map((m) => (
              <TableRow key={m.id} id="table-row">
                <TableCell>{m.nome}</TableCell>
                <TableCell>{m.perguntas.length}</TableCell>
                <TableCell>{m.ativo ? 'Sim' : 'Não'}</TableCell>
                <TableCell align="right">
                  <ButtonResponsive
                    id="primary"
                    value="Editar"
                    onClick={() => setSelecionado(m)}
                    icon={<Edit />}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow id="table-row-footer">
              <TableCell id="table-pagination" colSpan={4}>
                <TablePagination
                  component="div"
                  count={totalElements}
                  rowsPerPageOptions={[]}
                  rowsPerPage={20}
                  page={page}
                  labelDisplayedRows={({from, to, count}) =>
                    `${from}-${to} de ${count}`
                  }
                  onPageChange={handleChangePage}
                />
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <AvaliacaoModeloModal
        open={openModal || openCadNew}
        modelo={selecionado}
        onClose={() => {
          setOpenModal(false);
          onCloseCadNew(false);
          setSelecionado(undefined);
          formulariosQuery.refetch();
        }}
      />
    </Grid>
  );
};

const GruposTabela = ({searchDTO, openCadNew, onCloseCadNew}) => {
  const [grupos, setGrupos] = useState([]);
  const [page, setPage] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [selecionado, setSelecionado] = useState(undefined);

  const gruposQuery = useQuery(FIND_ALL_FORMULARIO_GRUPOS, {
    variables: {
      pageableDTO: {
        pageNumber: page,
        pageSize: 20,
        sortField: 'ordem',
        sortDir: 'ASC',
      },
      searchDTO: {
        search: searchDTO.search || undefined,
      },
    },
  });

  useEffect(() => {
    if (gruposQuery.loading) return;

    setGrupos(gruposQuery.data?.grupos?.content || []);
    setTotalElements(gruposQuery.data?.grupos?.totalElements || 0);
  }, [gruposQuery]);

  useEffect(() => {
    setPage(0);
  }, [searchDTO]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    if (!selecionado) return;

    setOpenModal(true);
  }, [selecionado]);

  const handleClose = () => {
    setOpenModal(false);
    onCloseCadNew(false);
    setSelecionado(undefined);
    gruposQuery.refetch();
  };

  return (
    <Grid container>
      <TableContainer id="table-container">
        <Table id="table">
          <TableHead id="table-head">
            <TableRow>
              <TableCell>Ordem</TableCell>
              <TableCell>Nome</TableCell>
              <TableCell sx={{width: '182px'}} align="center">
                Ações
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody id="table-body">
            {grupos.map((g) => (
              <TableRow key={g.id} id="table-row">
                <TableCell>{g.ordem}</TableCell>
                <TableCell>{g.nome}</TableCell>
                <TableCell align="right">
                  <ButtonResponsive
                    id="primary"
                    value="Editar"
                    onClick={() => setSelecionado(g)}
                    icon={<Edit />}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow id="table-row-footer">
              <TableCell id="table-pagination" colSpan={3}>
                <TablePagination
                  component="div"
                  count={totalElements}
                  rowsPerPageOptions={[]}
                  rowsPerPage={20}
                  page={page}
                  labelDisplayedRows={({from, to, count}) =>
                    `${from}-${to} de ${count}`
                  }
                  onPageChange={handleChangePage}
                />
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <GrupoModal
        open={openModal || openCadNew}
        grupo={selecionado}
        onClose={handleClose}
        onSave={handleClose}
      />
    </Grid>
  );
};

const PerguntasTabela = ({searchDTO, openCadNew, onCloseCadNew}) => {
  const [perguntas, setPerguntas] = useState([]);
  const [page, setPage] = useState(0);
  const [totalElements, setTotalElements] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [selecionado, setSelecionado] = useState(undefined);

  const perguntasQuery = useQuery(FIND_ALL_PERGUNTAS, {
    variables: {
      pageableDTO: {
        pageNumber: page,
        pageSize: 20,
        sortField: 'pergunta',
        sortDir: 'ASC',
      },
      searchDTO: {
        search: searchDTO.search || undefined,
        anamneseModeloGrupoId: searchDTO.modeloGrupo?.id,
      },
    },
  });

  const [createPergunta] = useMutation(CREATE_ANAMNESE_PERGUNTA);

  useEffect(() => {
    if (perguntasQuery.loading) return;

    setPerguntas(perguntasQuery.data?.perguntas?.content || []);
    setTotalElements(perguntasQuery.data?.perguntas?.totalElements || 0);
  }, [perguntasQuery]);

  useEffect(() => {
    setPage(0);
  }, [searchDTO]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    if (!selecionado) return;

    setOpenModal(true);
  }, [selecionado]);

  const handleSave = (pergunta) => {
    createPergunta({
      variables: {
        anamnesePergunta: {
          id: pergunta?.id,
          pergunta: pergunta?.pergunta,
          tipo: pergunta?.tipo,
          modeloGrupo: {id: pergunta?.modeloGrupo?.id},
          valoresReferencia: pergunta?.valoresReferencia,
          urlReferencia: pergunta?.urlReferencia,
        },
      },
    }).then(() => {
      toastNotification({message: 'Pergunta salva!', type: 'success'});
      handleClose();
    });
  };

  const handleClose = () => {
    setOpenModal(false);
    onCloseCadNew(false);
    setSelecionado(undefined);
    perguntasQuery.refetch();
  };

  return (
    <Grid container>
      <TableContainer id="table-container">
        <Table id="table">
          <TableHead id="table-head">
            <TableRow>
              <TableCell>Pergunta</TableCell>
              <TableCell>Tipo</TableCell>
              <TableCell>Grupo</TableCell>
              <TableCell sx={{width: '182px'}} align="center">
                Ações
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody id="table-body">
            {perguntas.map((p) => (
              <TableRow key={p.id} id="table-row">
                <TableCell>{p.pergunta}</TableCell>
                <TableCell>{anamnesePerguntaTipo[p.tipo]}</TableCell>
                <TableCell>{p.modeloGrupo?.nome}</TableCell>
                <TableCell align="right">
                  <ButtonResponsive
                    id="primary"
                    value="Editar"
                    onClick={() => setSelecionado(p)}
                    icon={<Edit />}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow id="table-row-footer">
              <TableCell id="table-pagination" colSpan={4}>
                <TablePagination
                  component="div"
                  count={totalElements}
                  rowsPerPageOptions={[]}
                  rowsPerPage={20}
                  page={page}
                  labelDisplayedRows={({from, to, count}) =>
                    `${from}-${to} de ${count}`
                  }
                  onPageChange={handleChangePage}
                />
              </TableCell>
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <PerguntaModal
        open={openModal || openCadNew}
        pergunta={selecionado}
        onClose={handleClose}
        onSave={handleSave}
      />
    </Grid>
  );
};

const styles = {
  submenuContainer: {
    '@media (max-width: 900px)': {
      flexDirection: 'column-reverse',
      alignItems: 'flex-start',
    },
  },
  tabButton: {
    marginBottom: '-15px',
    color: (theme) => theme.palette.white,
    border: (theme) => `1px solid ${theme.palette.white}`,
    borderRadius: '3px',
    fontWeight: '700',
    fontFamily: 'Now',
    fontSize: '14px',
    padding: '8px 32px',
    '&.Mui-selected': {
      backgroundColor: (theme) => theme.palette.yellow,
      ':hover': {
        backgroundColor: (theme) => theme.palette.yellow,
        color: (theme) => theme.palette.blackSecundary,
      },
    },

    '@media (max-width: 900px)': {
      marginBottom: '-9px',
    },
  },
};
