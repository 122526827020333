import {PersonRounded} from '@mui/icons-material';
import {Avatar, Button, Grid, Typography} from '@mui/material';
import {useNavigate} from 'react-router-dom';

import Data from '../utils/data';
import {getImageUrl} from '../utils/file';
import String from '../utils/string';

export default function EntityCard({item = {}}) {
  const navigate = useNavigate();

  const isMenor =
    item.dataNascimento && Data.calcularIdade(item.dataNascimento) < 16;

  return (
    <Grid key={item.id} container sx={styles.container}>
      <Avatar
        src={getImageUrl(item?.fotoPerfil)}
        alt={isMenor ? String.abreviarNome(item.nome) : item.nome}
        sx={styles.fotoPerfil}>
        <PersonRounded />
      </Avatar>
      <Grid container sx={styles.content}>
        <Grid item xs={12}>
          <Typography id="modalidade-tag">
            {item.modalidade?.descricao}
          </Typography>
          <Typography sx={styles.name}>
            {isMenor ? String.abreviarNome(item.nome) : item.nome}
          </Typography>
          <Typography sx={styles.age}>
            {`${Data.calcularIdade(item.dataNascimento)} anos`}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Button
            id="outlined"
            sx={styles.button}
            onClick={() => navigate(`/atleta/${item.id}`)}>
            VER PERFIL
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

const styles = {
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    margin: '0 6px',
    height: 400,
    width: 300,
    borderRadius: (theme) => theme.borderRadius,
    overflow: 'hidden',
  },
  fotoPerfil: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    alignItems: 'flex-start',
    borderRadius: 0,

    svg: {
      width: '50%',
      height: '70%',
    },
  },
  content: {
    padding: 2,
    height: '100%',
    alignContent: 'flex-end',
    background:
      'linear-gradient(0deg, #141412 18.28%, rgba(20, 20, 18, 0.10) 40.84%)',
    zIndex: 1,
  },
  name: {
    color: (theme) => theme.palette.white,
    fontSize: 20,
    fontWeight: '500',
  },
  age: {
    color: (theme) => theme.palette.white,
    fontSize: 16,
    fontWeight: '500',
  },
  button: {
    width: '100%',
    marginTop: 1,
  },
};
