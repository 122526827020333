import {useQuery} from '@apollo/client';
import {Box, Button, Grid, Typography} from '@mui/material';
import {useEffect, useState} from 'react';
import {useNavigate} from 'react-router-dom';

import NewsCard from '../../components/news-card';
import {FIND_ALL_NOTICIAS} from '../../graphql/queries';

export default function HomeJornalista() {
  const navigate = useNavigate();

  const [noticias, setNoticias] = useState([]);

  const noticiasQuery = useQuery(FIND_ALL_NOTICIAS, {
    variables: {
      pageable: {
        pageNumber: 0,
        pageSize: 3,
        sortField: 'dataCadastro',
        sortDir: 'DESC',
      },
      searchDTO: {
        situacao: 'APROVADO',
      },
    },
  });

  useEffect(() => {
    if (noticiasQuery.loading) return;

    setNoticias(noticiasQuery.data?.noticias?.content || []);
  }, [noticiasQuery]);

  return (
    <Grid container sx={styles.container}>
      <Grid container sx={styles.content}>
        <Box sx={styles.labelContainer}>
          <Typography sx={styles.label}>PARA JORNALISTAS</Typography>
        </Box>
        <Typography id="home-title" textAlign={'center'} padding={'0 16px'}>
          Crie noticias no ambiente esportivo e compartilhe com a comunidade
        </Typography>
        <Grid container gap={2} sx={styles.newsContainer}>
          {noticias.map((item, index) => (
            <NewsCard key={item.id || index} item={item} />
          ))}
        </Grid>
        <Grid container gap={2} sx={styles.buttonContainer}>
          <Grid item>
            <Button id="outlined" onClick={() => navigate('/login')}>
              ACESSAR O SISTEMA
            </Button>
          </Grid>
          <Grid item>
            <Button id="primary" onClick={() => navigate('/noticias')}>
              VER MAIS NOTÍCIAS
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

const styles = {
  container: {
    flex: 1,
    justifyContent: 'center',
    padding: '112px 0',
    backgroundColor: '#28292A',

    '@media (max-width: 900px)': {
      padding: '32px 16px',
    },
  },
  content: {
    maxWidth: 1200,
    justifyContent: 'center',
  },
  labelContainer: {
    display: 'flex',
    backgroundColor: '#FEFBE8',
    marginBottom: '16px',
    padding: '4px 8px',
    borderRadius: (theme) => theme.borderRadius,
  },
  label: {
    color: '#141412',
    fontSize: 16,
    fontWeight: '700',
    marginTop: '4px',
  },
  topicContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'row',
  },
  topicIndex: {
    fontSize: 32,
    fontWeight: '700',
    marginRight: 2,
    color: '#28292A',
    textShadow: (theme) =>
      `1px 1px 0 ${theme.palette.yellow}, 
      1px -1px 0 ${theme.palette.yellow}, 
      -1px 1px 0 ${theme.palette.yellow}, 
      -1px -1px 0 ${theme.palette.yellow}, 
      1px 0px 0 ${theme.palette.yellow}, 
      0px 1px 0 ${theme.palette.yellow}, 
      -1px 0px 0 ${theme.palette.yellow}, 
      0px -1px 0 ${theme.palette.yellow}`,
  },
  topicTitle: {
    fontSize: '24px',
    lineHeight: '48px',
    fontWeight: '700',
    fontFamily: 'Inter',
    color: '#fff',
  },
  topic: {
    fontSize: 16,
    fontWeight: '400',
    fontFamily: 'Inter',
    color: '#fff',
  },
  image: {
    justifyContent: 'flex-end',
  },
  newsContainer: {
    padding: '32px 0',
    justifyContent: 'space-around',
  },
  buttonContainer: {
    width: 'fit-content',
    justifyContent: 'center',
    marginTop: 4,

    '@media (max-width: 900px)': {
      width: '100%',
      '.MuiGrid-item': {
        width: '100%',
      },
      button: {
        width: '100%',
      },
    },
  },
};
