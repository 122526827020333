import {useMutation} from '@apollo/client';
import {Close, Save} from '@mui/icons-material';
import {
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
  Slide,
  Typography,
} from '@mui/material';
import {useEffect, useState} from 'react';

import Input from '../../../components/input/input';
import {toastNotification} from '../../../components/toastify';
import {UPDATE_ATLETA_SOBRE} from '../../../graphql/mutation';

export default function SobreAtleta({open, onClose, atleta}) {
  const [formData, setFormData] = useState({});

  const [saveAtleta, {loading}] = useMutation(UPDATE_ATLETA_SOBRE);

  const handleSave = (event) => {
    event.preventDefault();

    saveAtleta({
      variables: {
        atleta: {
          id: formData.id,
          descricao: formData.descricao,
        },
      },
    })
      .then(() => {
        toastNotification({message: 'Registro salvo!', type: 'success'});
        onClose();
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      );
  };

  useEffect(() => {
    if (!atleta) {
      setFormData({});
      return;
    }

    setFormData(atleta || {});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [atleta]);

  return (
    <Modal open={open} onClose={onClose} sx={styles.modal}>
      <Slide in={open} appear>
        <Grid
          container
          className="styled-scroll-transparent-desktop"
          sx={styles.container}>
          <Grid container item sx={styles.header}>
            <Typography sx={styles.title}>Sobre</Typography>
            <IconButton sx={styles.closeIcon} onClick={onClose}>
              <Close />
            </IconButton>
          </Grid>
          <Grid container item sx={styles.content} gap={1}>
            <Typography sx={styles.subtitle}>
              Escreva sobre seus anos de experiência, ou competências. Muitas
              pessoas também descrevem conquistas ou empregos anteriores.
            </Typography>
            <Grid item xs={12}>
              <Input
                name="descricao"
                type="text"
                autoCapitalize="on"
                value={formData.descricao || ''}
                placeholder={'Digite algo sobre você'}
                multiline
                rows={10}
                onChange={({target}) => {
                  setFormData((f) => ({
                    ...f,
                    descricao: target?.value?.slice(0, 4096),
                  }));
                }}
              />
            </Grid>
            <Grid container item xs={12} justifyContent={'flex-end'}>
              <Typography sx={styles.maxCaracter}>
                {formData.descricao?.length || 0}/4096 caracteres
              </Typography>
            </Grid>
          </Grid>
          <Grid container py={2} mt={'auto'}>
            <Button
              id="primary"
              onClick={handleSave}
              fullWidth
              endIcon={loading ? <CircularProgress size={20} /> : <Save />}>
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Slide>
    </Modal>
  );
}

const styles = {
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  container: {
    position: 'relative',
    maxWidth: '683px',
    width: '40%',
    borderRadius: '6px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column',
    flexWrap: 'nowrap',
    padding: 3,
    paddingTop: 0,
    zIndex: (theme) => theme.zIndex.modal,
    background: (theme) => theme.palette.blackSecundary,
    boxShadow: '0px 0px 134.60001px 0px rgba(255, 204, 41, 0.25)',
    outline: 'none',
    overflowY: 'auto',

    '@media (max-width: 900px)': {
      width: '90%',
    },
  },
  header: {
    flexGrow: 0,
    paddingTop: 2,
    position: 'sticky',
    top: 0,
    background: (theme) => theme.palette.blackSecundary,
    justifyContent: 'space-between',
    alignItems: 'center',
    svg: {
      color: '#fff',
    },
    paddingBottom: 2,
    borderBottom: '1px solid #fff',
    zIndex: 2,
  },
  closeIcon: {
    width: '32px',
    height: '32px',
  },
  title: {
    color: '#FFF',
    fontSize: '20px',
    fontWeight: '700',
    textTransform: 'uppercase',

    '@media (max-width: 1200px)': {
      fontSize: '14px',
    },
  },
  subtitle: {
    color: '#E3E3E3',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: '500',
    marginBottom: 1
  },
  content: {
    flexDirection: 'row',
    width: '100%',
    paddingY: '16px',
  },
  maxCaracter: {
    color: '#fff',
    fontSize: '12px',
  },
};
