const Masks = {
  cpf: '000.000.000-00',
  expirationDate: 'MM{/}YYYY',
  cnpj: '00.000.000/0000-00',
  cep: '00000-000',
  cpfCnpj: [
    {
      mask: '000.000.000-00',
    },
    {
      mask: '00.000.000/0000-00',
    },
  ],
  telelefone: [
    {
      mask: '(00) 0000-0000',
    },
    {
      mask: '(00) 00000-0000',
    },
  ],
};

export default Masks;
