import {useMutation} from '@apollo/client';
import {Save} from '@mui/icons-material';
import {Button, CircularProgress, Grid} from '@mui/material';
import {useEffect, useState} from 'react';
import * as Yup from 'yup';

import Input from '../../../components/input/input';
import InputMask from '../../../components/input/input-mask';
import {SelectFuncoes} from '../../../components/input/select/funcoes';
import {SelectModalidades} from '../../../components/input/select/modalidades';
import SelectInput from '../../../components/input/select/select-input';
import {toastNotification} from '../../../components/toastify';
import {UPDATE_ATLETA} from '../../../graphql/mutation';
import Masks from '../../../utils/masks';
import Number from '../../../utils/number';
import Object from '../../../utils/object';
import String from '../../../utils/string';

const sexoOptions = [
  {value: 'FEMININO', label: 'Feminino'},
  {value: 'MASCULINO', label: 'Masculino'},
  {value: 'OUTRO', label: 'Outro'},
];

export default function DadosBaseAtleta({atleta}) {
  const [formData, setFormData] = useState({});
  const [errors, setErrors] = useState({});

  const [saveAtleta, {loading}] = useMutation(UPDATE_ATLETA);

  const handleSubmit = (event) => {
    event.preventDefault();
    atletaScheme
      .validate(formData, {abortEarly: false})
      .then(handleSave)
      .catch((errors) => {
        const camposComErro = {};
        errors?.inner?.forEach((error) => {
          camposComErro[error.path] = error.message;
        });

        setErrors(Object.yupArrayToJs(camposComErro));
      });
  };

  const handleSave = async () => {
    saveAtleta({
      variables: {
        atleta: {
          id: formData.id,
          nome: formData.nome,
          cpf: String.removeSpecialChars(formData.cpf),
          dataNascimento: formData.dataNascimento,
          sexo: formData.sexo?.value,
          estatura: Number.safeParseFloat(formData.estatura),
          peso: Number.safeParseFloat(formData.peso),
          telefone: String.removeSpecialChars(formData.telefone),
          email: formData.email,
          modalidade: formData.modalidade?.id && {id: formData.modalidade?.id},
          funcoes: formData.funcoes?.map((e) => ({id: e.id})),
          situacao:
            !!formData?.modalidade && atleta?.enderecos?.length > 0
              ? 'COMPLETO'
              : 'CADASTRO',
        },
      },
    })
      .then(() => {
        toastNotification({message: 'Registro salvo!', type: 'success'});
      })
      .catch((error) =>
        toastNotification({message: error.message, type: 'error'}),
      );
  };

  useEffect(() => {
    setErrors({});
  }, []);

  useEffect(() => {
    if (!atleta) {
      setFormData({});
    }
    setFormData({
      ...(atleta || {}),
      sexo: atleta.sexo && sexoOptions.find((e) => e.value === atleta.sexo),
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [atleta]);

  return (
    <Grid container>
      <Grid container item sx={styles.content} spacing={2}>
        <Grid item md={6} xs={12}>
          <Input
            label={'Nome'}
            name="nome"
            value={formData.nome || ''}
            type="text"
            autoCapitalize="on"
            placeholder={'Nome completo'}
            onChange={({target}) =>
              setFormData((f) => ({...f, nome: target?.value}))
            }
            errors={errors}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <InputMask
            label={'CPF'}
            name="cpf"
            value={formData.cpf || ''}
            type="tel"
            mask={Masks.cpf}
            placeholder={'Digite o número do documento'}
            onChange={({target}) =>
              setFormData((f) => ({...f, cpf: target?.value}))
            }
            errors={errors}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Input
            label={'Data de nascimento'}
            name="dataNascimento"
            value={formData.dataNascimento || ''}
            type="date"
            onChange={({target}) =>
              setFormData((f) => ({...f, dataNascimento: target?.value}))
            }
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <SelectInput
            label={'Sexo'}
            name="sexo"
            value={formData.sexo || ''}
            onChange={(sexo) => setFormData((f) => ({...f, sexo}))}
            options={sexoOptions}
            errors={errors}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <InputMask
            label={'Telefone'}
            name="telefone"
            value={formData.telefone || ''}
            type="tel"
            autoComplete="tel"
            mask={Masks.telelefone}
            placeholder={'Digite seu número de telefone'}
            onChange={({target}) =>
              setFormData((f) => ({...f, telefone: target?.value}))
            }
            errors={errors}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Input
            label={'E-mail'}
            name="email"
            value={formData.email || ''}
            type="email"
            autoComplete="email"
            placeholder={'Digite seu e-mail'}
            onChange={({target}) =>
              setFormData((f) => ({...f, email: target?.value}))
            }
            errors={errors}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Input
            label={'Estatura'}
            name="estatura"
            value={formData.estatura || ''}
            type="number"
            autoCapitalize="on"
            placeholder={'Estatura em metros'}
            onChange={({target}) =>
              setFormData((f) => ({...f, estatura: target?.value}))
            }
            errors={errors}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <Input
            label={'Peso (kg)'}
            name="peso"
            value={formData.peso || ''}
            type="number"
            autoCapitalize="on"
            placeholder={'Peso em Kg'}
            onChange={({target}) =>
              setFormData((f) => ({...f, peso: target?.value}))
            }
          />
        </Grid>
        <Grid item xs={12}>
          <SelectModalidades
            label="Modalidade"
            name="modalidade"
            placeholder="Selecione o esporte"
            value={formData.modalidade}
            isClearable
            onChange={(modalidade) =>
              setFormData((f) => ({...f, modalidade, funcoes: []}))
            }
          />
        </Grid>
        <Grid flex={1} item xs={12}>
          <SelectFuncoes
            label={'Posições'}
            name="funcoes"
            placeholder={'Posições'}
            value={formData.funcoes}
            onChange={(funcoes) => setFormData((f) => ({...f, funcoes}))}
            searchDTO={{modalidadeId: formData.modalidade?.id}}
            disabled={!formData.modalidade?.id}
            closeMenuOnSelect={false}
            isMulti
            isClearable
          />
        </Grid>
      </Grid>
      <Grid container mt={'auto'}>
        <Button
          id="primary"
          onClick={handleSubmit}
          fullWidth
          endIcon={loading ? <CircularProgress size={20} /> : <Save />}>
          Salvar
        </Button>
      </Grid>
    </Grid>
  );
}

const atletaScheme = Yup.object().shape({
  nome: Yup.string().required('Campo obrigatório'),
  telefone: Yup.string().required('Campo obrigatório'),
  cpf: Yup.string()
    .required('Campo obrigatório')
    .test('validar-cpf', 'CPF inválido', (value) => String.validaCpf(value)),
  email: Yup.string().required('Campo obrigatório').email('E-mail inválido'),
  estatura: Yup.number().max(3, 'Altura deve ser em metros'),
});

const styles = {
  content: {
    alignContent: 'flex-start',
    flexDirection: 'row',
    paddingBottom: '16px',
  },
};
